import React from 'react';
import PropTypes from 'prop-types';
import Card from 'components/common/Card';

const KioskCard = ({ kiosk }) => {
  const {
    title,
    description,
    poster,
    publishedAt,
    slug,
    file,
  } = kiosk;

  const contentExcerpt =
    description ? description.replace(/<\/?[^>]+(>|$)/g, '').slice(0, 100) + '...' : '';

  const date = `Publié le ${new Date(publishedAt).toLocaleDateString()}`;

  const thumbnailUrl =
    poster && poster.path
      ? `${process.env.REACT_APP_MEDIA_BASE_URL}${poster.path}`
      : null;

  const buttons = [
    file && {
      variant: 'primary',
      href: `${process.env.REACT_APP_MEDIA_BASE_URL}${file.path}`,
      text: 'Télécharger',
      target: '_blank',
      rel: 'noopener noreferrer',
    },
    {
      variant: 'secondary',
      href: `/kiosque/${kiosk.serie.slug}/${slug}`,
      text: 'Lire en ligne',
    },
  ].filter(Boolean); // Removes any undefined entries if 'file' is not available

  return (
    <Card
      thumbnail={thumbnailUrl}
      title={title}
      date={date}
      description={contentExcerpt}
      buttons={buttons}
    />
  );
};

KioskCard.propTypes = {
  kiosk: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    poster: PropTypes.shape({
      path: PropTypes.string,
    }),
    publishedAt: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    file: PropTypes.shape({
      path: PropTypes.string,
    }),
  }).isRequired,
};

export default KioskCard;
