import React from 'react';
import { useParams } from 'react-router-dom';
import useWhitelist from 'hooks/useWhitelist';
import SubSiteHome from 'pages/subsite/SubSiteHome';
import NotFoundPage from 'pages/errors/NotFoundPage';
import Spinner from 'components/common/Spinner';

const DynamicSubSiteRoute = () => {
  const { subSiteSlug } = useParams();
  const { isAllowed, loading, error } = useWhitelist('/subsites/slugs');

  if (loading) return <div><Spinner /></div>;
  if (error) return <div>{error}</div>;

  return isAllowed(subSiteSlug) ? <SubSiteHome /> : <NotFoundPage />;
};

export default DynamicSubSiteRoute;
