/* SearchResultsSubsite.js */
import React, { useState, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select'; // Import de React Select
import api from 'services/api';
import SearchBar from 'components/search/SearchBar';
import Card from 'components/common/Card';
import ToggleView from 'components/archives/ToggleView';
import ArchivesGridView from 'components/archives/ArchivesGridView';
import ArchivesListView from 'components/archives/ArchivesListView';
import MapCta from 'components/common/MapCta';
import LiensPratiques from 'components/common/LiensPratiques';
import styles from './SearchResults.module.css';
import ListItem from 'components/archives/ListItem';
import { useSubSite } from 'contexts/SubSiteContext';
import useDebounce from 'hooks/useDebounce'; // Assurez-vous d'avoir ce hook
import { Helmet } from 'react-helmet';
import Spinner from 'components/common/Spinner';

// Définition des types avec des objets compatibles avec react-select
const TYPE_OPTIONS = [
  { value: 'pages', label: 'Pages' },
  { value: 'events', label: 'Événements' },
  { value: 'news', label: 'Actualités' },
];

const SearchResultsSubsite = () => {
  const [results, setResults] = useState({
    pages: [],
    events: [],
    news: []
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [view, setView] = useState('grid'); // État pour gérer la vue
  const [selectedTypes, setSelectedTypes] = useState(TYPE_OPTIONS); // Sélection par défaut : tous les types
  const location = useLocation();
  const navigate = useNavigate();
  const { isSubSite, subSiteSlug } = useSubSite();

  // Fonction pour extraire les paramètres de l'URL
  const getQueryParams = (search) => {
    const params = new URLSearchParams(search);
    return {
      query: params.get('q') || '',
    };
  };

  // Extraire la requête de recherche et appliquer le debouncing
  const { query } = getQueryParams(location.search);
  const debouncedQuery = useDebounce(query, 500); // Attendre 500ms après la dernière saisie

  // Récupérer les résultats via l'API
  useEffect(() => {
    if (debouncedQuery.trim().length === 0) {
      setResults({
        pages: [],
        events: [],
        news: []
      });
      setLoading(false);
      setError(null);
      return;
    }

    setLoading(true);
    setError(null); // Réinitialiser l'erreur avant une nouvelle requête
    setResults({
      pages: [],
      events: [],
      news: []
    }); // Réinitialiser les résultats avant la nouvelle recherche

    const endpoint = `/subsite/${subSiteSlug}/search`;


    api
      .get(endpoint, {
        params: { q: debouncedQuery },
      })
      .then((response) => {
        if (response.data.status === 'success') {
          const data = response.data.data;
          setResults({
            pages: data.pages || [],
            events: data.events || [],
            news: data.news || []
          });
        } else {
          setError('Erreur lors de la récupération des résultats.');
          setResults({
            pages: [],
            events: [],
            news: []
          }); // Réinitialiser les résultats en cas d'erreur
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error('Erreur lors de la recherche:', err);
        setError(err.response ? err.response.statusText : err.message);
        setResults({
          pages: [],
          events: [],
          news: []
        }); // Réinitialiser les résultats en cas d'échec de l'appel API
        setLoading(false);
      });
  }, [debouncedQuery, subSiteSlug]);


  // Gérer le toggle entre Grid et List View
  const handleViewChange = (selectedView) => setView(selectedView);

  // Gérer les changements de filtres avec react-select
  const handleTypeChange = (selectedOptions) => {
    setSelectedTypes(selectedOptions.length > 0 ? selectedOptions : TYPE_OPTIONS); // Si aucun type sélectionné, rétablir tous
  };

  // Mémoïser les items filtrés pour optimiser les performances
  const filteredItems = useMemo(() => {
    const items = [];

    // Si aucun type n'est sélectionné, considérer que tous sont sélectionnés
    const typesToFilter = selectedTypes.length > 0 ? selectedTypes : TYPE_OPTIONS;

    typesToFilter.forEach(type => {
      const typeItems = (results[type.value] || []).map(item => {
        switch (type.value) {
          case 'pages':
            return {
              uniqueId: `pages-${item.id}`,
              slug: item.slug,
              type: 'pages',
              id: item.id,
              title: item.title,
              date: null,
              categories: item.category ? [item.category.name] : [],
              thumbnail: item.thumbnail
                ? `${process.env.REACT_APP_MEDIA_BASE_URL}/images/${item.thumbnail}`
                : null,
              link: `/page/${item.slug || item.id}`,
              snippet: item.snippet,
              category: item.category,
            };
          case 'events':
            return {
              uniqueId: `events-${item.id}`,
              slug: item.slug,
              type: 'events',
              id: item.id,
              title: item.title,
              date: new Date(item.createdAt).toLocaleDateString(),
              categories: item.category ? [item.category.name] : [],
              thumbnail: item.thumbnail
                ? `${process.env.REACT_APP_MEDIA_BASE_URL}/${item.thumbnail.path}`
                : null,
              link: `/evenement/${item.slug}`,
              snippet: item.snippet,
            };
          case 'news':
            return {
              uniqueId: `news-${item.id}`,
              slug: item.slug,
              type: 'news',
              id: item.id,
              title: item.title,
              date: new Date(item.createdAt).toLocaleDateString(),
              categories: [],
              thumbnail: item.thumbnail
                ? `${process.env.REACT_APP_MEDIA_BASE_URL}/${item.thumbnail.path}`
                : null,
              link: `/actualite/${item.slug}`,
              content: item.content,
            };
          default:
            return null;
        }
      }).filter(Boolean); // Filtrer les éventuels nulls

      items.push(...typeItems);
    });

    // Éviter les duplications en utilisant uniqueId
    const uniqueItemsMap = new Map();
    items.forEach(item => {
      if (!uniqueItemsMap.has(item.uniqueId)) {
        uniqueItemsMap.set(item.uniqueId, item);
      }
    });

    return Array.from(uniqueItemsMap.values());
  }, [results, selectedTypes]);

  // Fonctions utilitaires
  const getLinkByType = (item) => {
    switch (item.type) {
      case 'pages':
        return `/page/${item.slug}`;
      case 'events':
        return `/evenement/${item.slug}`;
      case 'news':
        return `/actualite/${item.slug}`;
      default:
        return '#';
    }
  };

  const getMetaByType = (item) => {
    switch (item.type) {
      case 'pages':
        return item.category ? [`Catégorie : ${item.category.name}`] : [];
      case 'events':
        return [`Publié : ${item.date}`];
      case 'news':
        return [`Publié : ${item.date}`];
      default:
        return [];
    }
  };

  const getDescriptionByType = (item) => {
    switch (item.type) {
      case 'pages':
        return item.snippet || 'Pas de description disponible.';
      case 'events':
        return item.snippet || 'Pas de description disponible.';
      case 'news':
        return item.content && item.content.length > 200
          ? `${item.content.substring(0, 200)}...`
          : item.content || 'Pas de description disponible.';
      default:
        return 'Pas de description disponible.';
    }
  };

  // Déterminer s'il y a des résultats à afficher
  const hasResults = filteredItems.length > 0;

  return (
    <>
      <Helmet>
        <title>Ville d'Arras | Résultats de recherche pour "{debouncedQuery}"</title>
        <meta name="description" content="Une description de la page pour le SEO." />
        <meta property="og:title" content="Ville d'Arras" />
        <meta property="og:description" content="Une description adaptée aux réseaux sociaux." />
        <link rel="canonical" href="https://arras.fr/" />

      </Helmet>
      <div className={styles.resultsPage}>

        <section className={styles.resultsHeader}>
          <div className={`global-container ${styles.headerContainer}`}>
            <h1 className={styles.resultsTitle}>Résultats de recherche pour "{debouncedQuery}"</h1>
            <SearchBar onSearch={(query) => navigate(`/${subSiteSlug}/recherches?q=${encodeURIComponent(query)}`)} />

            {/* Section de filtrage avec React Select */}
            <div className={styles.filterSection}>
              <p className={styles.filterLabel}>Filtrer par type :</p>
              <Select
                options={TYPE_OPTIONS}
                isMulti
                value={selectedTypes}
                onChange={handleTypeChange}
                className={styles.reactSelect}
                classNamePrefix="select"
                placeholder="Sélectionnez les types..."
                closeMenuOnSelect={false}
              />
            </div>
          </div>
        </section>

        <div className="page-content">

          {/* Résultats */}
          <section className={`global-section ${styles.resultsSection}`}>

            {/* Toggle View */}
            <div className="global-container">
              <ToggleView view={view} setView={handleViewChange} />

              {/* Gestion de l'état de chargement */}
              {loading ? (
                <div className={styles.loading}><Spinner /></div>
              ) : (
                <>
                  {/* Afficher "Aucun résultat" en cas de résultats vides */}
                  {!hasResults && (
                    <div className={styles.noResults}>Aucun résultat</div>
                  )}

                  {/* Afficher les résultats si disponibles */}
                  {hasResults && (
                    <>
                      {/* Grid View */}
                      {view === 'grid' && (
                        <ArchivesGridView
                          items={filteredItems.map(item => ({
                            id: item.uniqueId, // Utiliser uniqueId pour éviter les doublons
                            title: item.title,
                            date: item.date,
                            categories: item.categories,
                            thumbnail: item.thumbnail,
                            link: getLinkByType(item),
                          }))}
                          renderItem={(item) => (
                            <Card
                              key={item.id}
                              thumbnail={item.thumbnail}
                              title={item.title}
                              date={item.date}
                              link={item.link}
                            />
                          )}
                          displayCount={10}
                        />
                      )}

                      {/* List View */}
                      {view === 'list' && (
                        <ArchivesListView
                          items={filteredItems.map(item => ({
                            id: item.uniqueId, // Utiliser uniqueId pour éviter les doublons
                            title: item.title,
                            meta: getMetaByType(item),
                            description: getDescriptionByType(item),
                            link: getLinkByType(item),
                            categories: item.categories,
                          }))}
                          renderItem={(item) => (
                            <ListItem
                              key={item.id}
                              title={item.title}
                              meta={item.meta}
                              description={item.description}
                              categories={item.categories}
                              link={item.link}
                            />
                          )}
                          displayCount={10} // Nombre d'éléments à afficher
                        />
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </section>

          {/* Accès à la carte et liens pratiques */}
          <MapCta />
          <LiensPratiques />
        </div>
      </div>
    </>
  );
};

export default SearchResultsSubsite;
