// src/components/PDFViewer.js

import React from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import styles from './PDFReader.module.css';

// Importer les styles de base et du plugin
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const PDFViewer = ({ fileUrl }) => {
    // Créer une instance du plugin de disposition par défaut
    const defaultLayoutPluginInstance = defaultLayoutPlugin();

    return (
        <div
            className={styles.reader}
        >
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                <Viewer
                    fileUrl={fileUrl}
                    plugins={[
                        defaultLayoutPluginInstance,
                    ]}
                    defaultScale={[1]}
                />
            </Worker>
        </div>
    );
};

export default PDFViewer;