import React, { useContext } from 'react';
import { AccessibilityContext } from 'contexts/AccessibilityContext'
import HeroSection from 'components/home/HeroSection';
import MapCta from 'components/common/MapCta';
import EventList from 'components/home/EventList';
import KiosqueHome from 'components/home/KiosqueHome';
import LiensPratiques from 'components/common/LiensPratiques';
import LiveInfo from 'components/common/LiveInfo';
import styles from './Home.module.css';
import NewsList from 'components/home/NewsList';
import useDocumentTitle from 'hooks/useDocumentTitle'
import { Helmet } from 'react-helmet';

const Home = () => {



  const { accessibilityOptions, getAccessibilityVariant } = useContext(AccessibilityContext);

  return (
    <>
      <Helmet>
        <title>Arras | Le site officiel de la ville d'Arras</title>
        <meta name="description" content="Bienvenue sur le site officiel de la Ville d'Arras, préfecture du Pas-de-Calais en région Hauts-de-France : actualité, démarches, agenda, informations pratiques…" />
        <meta property="og:title" content="Ville d'Arras" />
        <meta property="og:description" content="Bienvenue sur le site officiel de la Ville d'Arras, préfecture du Pas-de-Calais en région Hauts-de-France : actualité, démarches, agenda, informations pratiques…" />
        <link rel="canonical" href="https://www.arras.fr" />
      </Helmet>
      <main className={styles.homePage} aria-label="Page d'accueil de la ville d'Arras">
        {/* Bandeau information */}
        <LiveInfo />

        {/* Section Hero, Recherche et Liens rapides*/}
        <HeroSection />

        <div className="page-content">

          {/* CTA Map */}
          <MapCta />

          {/* Section Agenda */}
          <EventList accessibilityVariant={getAccessibilityVariant()} />
          <NewsList accessibilityVariant={getAccessibilityVariant()} />

          {/* Le Kiosque */}
          <KiosqueHome />

          {/* Liens Pratiques */}
          <LiensPratiques />

        </div>

      </main>
    </>
  );
};

export default Home;
