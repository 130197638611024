// components/home/HeroSection.jsx
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './HeroSection.module.css';
import SocialIcons from 'components/common/SocialIcons';
import SearchBar from 'components/search/SearchBar';
import QuickLinks from 'components/home/QuickLinks';
import QuickSubSiteLinks from 'components/home/subsite/QuickSubSiteLinks';
import CustomizeProfile from 'components/home/CustomizeProfile';
import { useSubSite } from 'contexts/SubSiteContext';
import useDesignSettingsData from 'hooks/useDesignSettingsData';
import { AccessibilityContext } from 'contexts/AccessibilityContext';
import Video from "components/common/VideoBanner";
import Spinner from 'components/common/Spinner'

const HeroSection = () => {
  const navigate = useNavigate();
  const { isSubSite, subSiteSlug } = useSubSite();
  const { designSettings, settingsLoading, error } = useDesignSettingsData();
  const { animationsEnabled } = useContext(AccessibilityContext);

  // Fonction pour gérer la recherche
  const handleSearch = (query) => {
    const searchUrl = isSubSite 
      ? `/${subSiteSlug}/recherches?q=${encodeURIComponent(query)}`
      : `/recherches?q=${encodeURIComponent(query)}`;
    navigate(searchUrl);
  };

  // Chargement & Erreur
  if (settingsLoading) return <div><Spinner /></div>;
  if (error) return <div>Erreur : {error}</div>;


  return (
    <section className={`global-section ${styles.hero}`} aria-label="Vue panoramique de la ville d'Arras">
      <div className={styles.heroMedia}>
        {animationsEnabled ? (
          // Si animations activées, on utilise la vidéo
          <Video
          src={designSettings.herroVideoBanner}
          autoPlay={true}
          loop={true}
          muted={true}
          controls={true}
          fallbackText="Votre navigateur ne supporte pas les vidéos HTML5."
        />
        ) : (
          // Sinon, on affiche l'image
          <img
            src={isSubSite ? designSettings.heroBanner : designSettings.heroBanner}
            alt="Panorama de la ville d'Arras"
            title="Panorama de la ville d'Arras"
            aria-label="Photo panorama de la ville d'Arras"
            className={styles.heroImage}
          />
        )}
        {/* Socials */}
        <SocialIcons />
      </div>

      {/* Section Recherche et Liens rapides */}
      <div className={`global-section ${styles.widgetSection}`}>
        <div className={`global-container ${styles.widgetContainer}`}>
          <div className={styles.widgetWrapper}>
            <div className={styles.searchContainer}>
              <img
            src={isSubSite ? designSettings.websiteLogo : designSettings.websiteLogo}
            alt="Logo de la ville d'Arras"
                title="Logo de la ville d'Arras"
                aria-label="Logo de la ville d'Arras"
              />
              <h1>Ville d'Arras</h1>
              <h2>Accès rapide</h2>
              <SearchBar onSearch={handleSearch} />
            </div>
            {!isSubSite && <QuickLinks />}
            {isSubSite && <QuickSubSiteLinks />}
            {!isSubSite && <CustomizeProfile />}
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
