// EcrireMairePage.js
import React, { useState } from 'react';
import Breadcrumbs from 'components/common/Breadcrumbs';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'; // <-- Hook v3
import BannerWithSocialIcons from 'components/common/BannerWithSocialIcons';
import MapCta from 'components/common/MapCta';
import LiensPratiques from 'components/common/LiensPratiques';
import styles from './ContactPage.module.css';
import sharedStyles from 'styles/modules/ContentPage.module.css';
import Button from 'components/common/Button';
import api from 'services/api'; // Import du service API
import useDesignSettingsData from 'hooks/useDesignSettingsData';
import { Helmet } from 'react-helmet';

const EcrireMaire = () => {

      // ----- HOOK reCAPTCHA v3 -----
      const { executeRecaptcha } = useGoogleReCaptcha();

    const { designSettings, settingsLoading, error } = useDesignSettingsData();

    const [formData, setFormData] = useState({
        nom: "",
        prenom: "",
        email: "",
        telephone: "",
        objet: "",
        message: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Vérifier que reCAPTCHA est disponible
    if (!executeRecaptcha) {
        alert('reCAPTCHA non initialisé. Réessayez plus tard.');
        return;
      }


        try {
            const token = await executeRecaptcha('contact_form');
 // 2) Construire la charge utile
 const payload = {
    firstname: formData.prenom,
    lastname: formData.nom,
    email: formData.email,
    phone: formData.telephone,
    object: formData.objet,
    message: formData.message,
    recaptchaToken: token,
  };

            const response = await api.post('/contact/submit/maire', payload);
            alert('Votre message a été envoyé avec succès.');
        } catch (error) {
            console.error('Erreur lors de la soumission du formulaire:', error);
            alert('Une erreur est survenue lors de la soumission du formulaire.');
        }
    };


    return (
        <>
                  <Helmet>
                    <title>Arras | Ecrire à M. le Maire</title>
                    <meta name="description" content="Vous souhaitez écrire à M. le Maire d’Arras ? Adressez-lui vos questions, suggestions ou remarques via notre formulaire dédié ou par courrier. Votre voix compte !" />
                    <meta property="og:title" content="Arras | Ecrire à M. le Maire" />
                    <meta property="og:description" content="Contactez directement M. le Maire d’Arras pour partager vos idées, poser vos questions ou formuler une demande. Une démarche simple pour vous exprimer !" />
                    <link rel="canonical" href={`https://www.arras.fr/ecrire-au-maire`} />
                  </Helmet>
        <div className={`${styles.contactPage} ${sharedStyles.pageWrapper}`}>
            {/* Bannière avec miniature */}
            {designSettings && designSettings.defaultPageBanner && (
                <BannerWithSocialIcons
                    imageUrl={`${designSettings.defaultPageBanner}`}
                />
            )}

            <div className={`page-content ${sharedStyles.pageContent}`}>
                <section className={`global-section ${sharedStyles.mainSection}`}>
                    <div className={`global-container ${sharedStyles.headerContainer}`}>
                        <div className={sharedStyles.header}>
                            <div className={sharedStyles.headingBlock}>
                                <h1>Ecrire à M. le Maire</h1>
                                <Breadcrumbs title="Ecrire à M. le Maire" />
                            </div>
                        </div>
                    </div>

                    <div className={`global-container ${styles.main}`}>
                        <div className={styles.block}>

                            <div className={styles.listContainer}>
                                <div className={`${styles.listContent} ${styles.orderShift}`}>
                                    <h2>Vous souhaitez écrire à M. le Maire ?</h2>
                                    <div className={styles.mayorContainer}>
                                        <figure className={styles.figure}>
                                            <img src="/images/photo_monsieur_le_maire_frederic_leturque.webp" alt="Photo de M. le maire Frédéric Leturque" />
                                        </figure>
                                        <div className={`${styles.listBlock} ${styles.metaContainer}`}>
                                            <div className={styles.header}>
                                                <h3>Frédéric Leturque</h3>
                                                <h4>Maire d'Arras</h4>
                                            </div>
                                            <ul className={`list--none`}>
                                                <li>Président de la Communauté Urbaine d'Arras</li>
                                                <li>Conseiller Régional des Hauts de France</li>
                                                <li>Président de l'Association des Maires du Pas-de-Calais - AMF 62</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className={`${styles.listBlock} ${styles.coordonnees}`}>
                                        <h4>Coordonnées :</h4>
                                        <ul className={`list--none`}>
                                            <li>
                                                <strong>Tél :</strong>
                                                <a
                                                    href="tel:0321505186"
                                                    aria-label="Composer le numéro de téléphone fixe de monsieur le maire"
                                                    className={styles.link}
                                                >
                                                    03 21 50 51 86
                                                </a>
                                                
                                            </li>
                                        </ul>
                                    </div>

                                </div>
                            </div>

                        </div>

                        <div className={`${styles.block} ${styles.formContainer}`}>
                            <div className={styles.headerForm}>
                                {/* Titre et instructions */}
                                <h2 id="form-title">Formulaire de contact</h2>
                                <p id="form-instructions">
                                    Veuillez remplir les champs obligatoires marqués d'une étoile (<span className={styles.danger}>*</span>).
                                </p>
                            </div>

                            <form
                                aria-labelledby="form-title"
                                aria-describedby="form-instructions"
                                onSubmit={handleSubmit}
                            >   
                                              {/* Objet */}
                  <div className={styles.formGroup}>
                    <label htmlFor="objet" className={styles.required}>
                      Votre demande s'adresse à
                    </label>
                    <input
                                        type="text"
                                        id="objet"
                                        name="objet"
                                        value={formData.objet}
                                        onChange={handleChange}
                                        required
                                        aria-required="true"
                                        placeholder="Objet de votre demande"
                                    />
                  </div>


                                {/* Nom */}
                                <div className={styles.formGroup}>
                                    <label htmlFor="nom" className={styles.required}>
                                        Nom
                                    </label>
                                    <input
                                        type="text"
                                        id="nom"
                                        name="nom"
                                        value={formData.nom}
                                        onChange={handleChange}
                                        required
                                        aria-required="true"
                                        placeholder="Entrez votre nom"
                                    />
                                </div>

                                {/* Prénom */}
                                <div className={styles.formGroup}>
                                    <label htmlFor="prenom" className={styles.required}>
                                        Prénom
                                    </label>
                                    <input
                                        type="text"
                                        id="prenom"
                                        name="prenom"
                                        value={formData.prenom}
                                        onChange={handleChange}
                                        required
                                        aria-required="true"
                                        placeholder="Entrez votre prénom"
                                    />
                                </div>



                                {/* Email */}
                                <div className={styles.formGroup}>
                                    <label htmlFor="email">
                                        Adresse e-mail
                                    </label>
                                    <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        required
                                        aria-required="true"
                                        placeholder="exemple@domaine.com"
                                    />
                                </div>

                                {/* Téléphone */}
                                <div className={styles.formGroup}>
                                    <label htmlFor="telephone" className={styles.required}>
                                        Téléphone
                                    </label>
                                    <input
                                        type="tel"
                                        id="telephone"
                                        name="telephone"
                                        value={formData.telephone}
                                        onChange={handleChange}
                                        required
                                        aria-required="true"
                                        placeholder="0123456789"
                                    />
                                </div>



                                {/* Message */}
                                <div className={styles.formGroup}>
                                    <label htmlFor="message" className={styles.required}>
                                        Message
                                    </label>
                                    <textarea
                                        id="message"
                                        name="message"
                                        rows="5"
                                        value={formData.message}
                                        onChange={handleChange}
                                        required
                                        aria-required="true"
                                        placeholder="Votre message ici..."
                                    ></textarea>
                                </div>

                                {/* Bouton envoyer */}
                                <div className={styles.formGroup}>
                                    <Button
                                        variant="primary"
                                        type="submit"
                                        ariaLabel="Envoyer le formulaire"
                                    >
                                        Envoyer
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>
                </section >

                {/* CTA Map */}
                < MapCta />

                {/* Liens Pratiques */}
                < LiensPratiques />
            </div >
        </div >
        </>
    );
};

export default EcrireMaire;
