// matomo.js
import { createInstance } from 'react-matomo';

const isLocal = process.env.NODE_ENV === 'development';

const matomoInstance = createInstance({
  urlBase: 'https://stats.arras.fr',
  siteId: 1,
  trackerUrl: 'https://stats.arras.fr/matomo.php',
  srcUrl: 'https://stats.arras.fr/matomo.js',
  disabled: isLocal, // Désactive le tracking en local
  heartBeat: {
    active: true,
    seconds: 10,
  },
});

export default matomoInstance;
