import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import api from 'services/api';
import EventCard from 'components/events/EventCard'; // Import du composant EventCard
import listStyles from 'styles/modules/CardList.module.css';
import { useSubSite } from 'contexts/SubSiteContext'; // Hook pour vérifier le sous-site
import Spinner from 'components/common/Spinner';

const NearestEventList = ({ slug, isNearorLast }) => {
  const [nearestEvents, setNearestEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const { isSubSite, subSiteSlug } = useSubSite();

  useEffect(() => {
    const fetchNearestEvents = async () => {
      try {
        let response = null;
        if (isSubSite) {
          response = await api.get(`/subsites/${subSiteSlug}/events/latest`);
          setNearestEvents(response.data);
        } else {
          if (isNearorLast == 'near') {
            response = await api.get(`/events/${slug}/nearest`);
            setNearestEvents(response.data);

          } else {
            response = await api.get(`/events/${slug}/latest`);
            setNearestEvents(response.data);

          }

        };
        setNearestEvents(response.data);
      } catch (error) {
        console.error("Erreur lors de la récupération des événements les plus proches :", error);
      } finally {
        setLoading(false);
      }
    };

    fetchNearestEvents();
  }, [slug]);

  if (loading) return <div><Spinner /></div>;
  if (nearestEvents.flat().length === 0) return <div>Aucun événement proche trouvé.</div>;

  return (
    <div className={`global-container ${listStyles.listSection}`}>
      <h2>Événements proches</h2>
      <div className={listStyles.grid}>
        {nearestEvents.map((group) =>
          group.map((event) => (
            <EventCard
              key={event.id}
              event={{
                title: event.title,
                start: event.start,
                end: event.end,
                location: event.location,
                thumbnail: event.thumbnail,
                slug: event.slug,
                categories: event.categories,
              }}
            />
          ))
        )}
      </div>
    </div>
  );
};

NearestEventList.propTypes = {
  slug: PropTypes.string.isRequired,
};

export default NearestEventList;
