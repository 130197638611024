import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import SubNavItem from './SubNavItem';
import stylesDesktop from './SubSidebarDesktop.module.css';
import stylesMobile from './SubSidebarMobile.module.css';
import classNames from 'classnames';
import SearchBar from 'components/navigation/SearchBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { AccessibilityOutline } from 'react-ionicons';
import useBreakpoint from 'hooks/useBreakpoint';
import { useSubSite } from 'contexts/SubSiteContext';

const SubSidebar = ({ isOpen, onClose, item, subSidebarClass, onCloseAllSidebars }) => {
  const isMobile = useBreakpoint();
  const styles = isMobile ? stylesMobile : stylesDesktop;

  const navigate = useNavigate();
  const firstNavItemRef = useRef(null);
  const [query, setQuery] = useState('');

  const { isSubSite, subSiteSlug } = useSubSite();

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape' && isOpen) {
        onClose();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [isOpen, onClose]);

  useEffect(() => {
    if (isOpen) {
      firstNavItemRef.current?.focus();
    }
  }, [isOpen]);

  const getSubItemLink = (subItem) => {
    // Vérifier le type de subItem
    if (subItem.type === 'link') {
      // Pour le type 'link', retourner directement le target
      return subItem.target;
    }

    let basePath = '';

    // Déterminer le chemin de base en fonction du type de subItem
    switch (subItem.type) {
      case 'category':
        basePath = 'categorie';
        break;
      case 'subcategory':
        basePath = 'categorie';
        break;
      case 'page':
        basePath = 'page';
        break;
      default:
        basePath = 'page'; // Valeur par défaut si le type n'est pas reconnu
    }

    // Construire l'URL en fonction de isSubSite
    return isSubSite
      ? `/${subSiteSlug}/${basePath}/${subItem.target}`
      : `/${basePath}/${subItem.target}`;
  };


  const handleSearch = (e) => {
    e.preventDefault();
    const trimmedQuery = query.trim();
    if (trimmedQuery) {
      const searchUrl = isSubSite
        ? `/${subSiteSlug}/recherches?q=${encodeURIComponent(trimmedQuery)}`
        : `/recherches?q=${encodeURIComponent(trimmedQuery)}`;
      navigate(searchUrl);
      setQuery('');
      onCloseAllSidebars();
    } else {
      alert('Veuillez entrer une requête de recherche valide.');
    }
  };

  return (
    <>
      {isOpen && <div className={styles.overlay} onClick={onClose} aria-hidden="true"></div>}

      <div
        className={classNames(styles.subSidebar, styles[subSidebarClass], { [styles.open]: isOpen })}
        role="navigation"
        aria-hidden={!isOpen}
        aria-label="Sous-menu de navigation"
      >
        {item && (
          <>
            {isMobile && (
              <SearchBar onSearch={(searchTerm) => {
                const url = isSubSite
                  ? `/${subSiteSlug}/recherches?q=${encodeURIComponent(searchTerm)}`
                  : `/recherches?q=${encodeURIComponent(searchTerm)}`;
                navigate(url);
                onCloseAllSidebars();
              }} isMobile={true} />
            )}

            <div className={styles.headingContainer}>
              <div className={styles.heading}>
                {!isMobile && (
                  item.icon ? (
                    <span
                      className={styles.icon}
                      dangerouslySetInnerHTML={{ __html: item.icon }} // Affiche l'icône dynamique
                    />
                  ) : (
                    <AccessibilityOutline
                      height=""
                      width=""
                      color="currentColor"
                      className={styles.icon}
                    />
                  )
                )}
                <Link
                  to={`/categorie/${item.target}`}
                  className={styles.categoryLink}
                  onClick={onClose}
                >
                  {item.name}
                </Link>
              </div>
              <button
                className={styles.backButton}
                onClick={onClose}
                aria-label="Retour aux catégories"
              >
                <FontAwesomeIcon icon={faArrowLeft} />
                Retour
              </button>
            </div>
            <nav className={styles.pageMenu} aria-label="Menu des pages">
              <ul className={`list--none ${styles.navList}`}>
                {item.subItems.map((subItem, index) => (
                  <SubNavItem
                    key={subItem.id}
                    label={subItem.name}
                    type={subItem.type}
                    ref={index === 0 ? firstNavItemRef : null}
                    slug={subItem.target}
                    context="subsidebar"
                    to={getSubItemLink(subItem)}
                    onClick={() => {
                      // Lorsque l'utilisateur clique sur un sous-item, on ferme toutes les sidebars.
                      onCloseAllSidebars();
                    }}
                  />
                ))}
              </ul>
            </nav>
          </>
        )}
      </div>
    </>
  );
};

SubSidebar.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  item: PropTypes.object,
  subSidebarClass: PropTypes.string,
  onCloseAllSidebars: PropTypes.func.isRequired,
};


export default SubSidebar;
