import React, { useEffect, useState } from 'react';
import SocialLinks from 'components/info/SocialLinks';  // Le composant SocialLinks
import AlloMairie from 'components/info/AlloMairie';
import OnlineServices from 'components/info/OnlineServices';
import ContactInfo from 'components/info/ContactInfo';
import UrgenceNumbers from 'components/info/UrgenceNumbers';
import MetaFooter from 'components/info/MetaFooter';
import styles from './Footer.module.css';  // Fichier CSS pour le Footer
import { useSubSite } from 'contexts/SubSiteContext'; // Hook pour vérifier le sous-site
import useDesignSettingsData from 'hooks/useDesignSettingsData';
import Spinner from 'components/common/Spinner'
import api from 'services/api';  // Ton service API pour récupérer les données

const Footer = () => {

  const { isSubSite, subSiteSlug } = useSubSite();
  const { designSettings, settingsLoading, error } = useDesignSettingsData();
  const [footerData, setFooterData] = useState([]);

  useEffect(() => {
    const fetchSocialLinks = async () => {
      try {
        const endpoint = isSubSite ? `/footer/${subSiteSlug}` : '/footer';
        const response = await api.get(endpoint);
        setFooterData(response.data);

      } catch (error) {
        console.error('Erreur lors de la récupération des réseaux sociaux:', error);
      }
    };

    fetchSocialLinks();
  }, []);


    // Chargement & Erreur
  if (settingsLoading) return <div><Spinner /></div>;
  if (error) return <div>Erreur : {error}</div>;

  return (
    <footer>

      {isSubSite ? (
        <div className={`global-section ${styles.altFooter}`}>
          <div className={`global-container ${styles.container}`}>
            <div className={styles.socials}>
              <figure>
                <img src={isSubSite ? designSettings.websiteLogoForFooter : designSettings.websiteLogoForFooter} alt="Logo du site" />
              </figure>

              {/* Composant SocialLinks pour afficher les réseaux sociaux */}
              <SocialLinks />

            </div>

            {/* Section Coordonnées de la Mairie */}
            <ContactInfo footerData={footerData} />

            {/* Allo Mairie */}
            <AlloMairie footerData={footerData} />

            {/* Numéros d'urgence */}
            <UrgenceNumbers />

          </div>

          {/* Meta infos date et legals */}
          <MetaFooter />

        </div>

      ) : (
        <div className={`global-section ${styles.footer}`}>
          <div className={`global-container ${styles.container}`}>
            <div className={styles.socials}>
              <figure>
                <img src={isSubSite ? designSettings.websiteLogoForFooter : designSettings.websiteLogoForFooter} alt="Logo de la ville d'Arras" />
              </figure>

              {/* Composant SocialLinks pour afficher les réseaux sociaux */}
              <SocialLinks />

            </div>

            {/* Section Coordonnées de la Mairie */}

            <ContactInfo footerData={footerData}/>
            {/* Allo Mairie */}
            <AlloMairie footerData={footerData} />

            {/* Numéros d'urgence */}
            <UrgenceNumbers />

          </div>

          {/* Meta infos date et legals */}
          <MetaFooter />

        </div>
      )}

    </footer>
  );
};

export default Footer;
