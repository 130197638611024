import React, { useState, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMagnifyingGlass,
  faCaretDown,
  faLocationDot,
  faChevronDown
} from '@fortawesome/free-solid-svg-icons';
import { CaretBackOutline, CaretForwardOutline } from 'react-ionicons';
import styles from './FilterControls.module.css';
import { ModalContext } from 'contexts/ModalContext';
import Button from 'components/common/Button';

// On importe la fonction pour enlever les accents
import removeAccents from 'utils/removeAccents';

/**
 * Composant FilterControls
 *
 * @param {Object} props
 */
const FilterControls = ({
  categoriesData,
  filtresCategories,
  setFiltresCategories,
  filtresSubCategories,
  setFiltresSubCategories,
  districts,
  filtresDistrict,
  setFiltresDistrict,
  points,
  searchTerm,
  setSearchTerm,
  // Ajoutés pour gérer l'affichage des couches événements / travaux
  showEvents,
  setShowEvents,
  showWorks,
  setShowWorks,
  // **Nouveaux props pour la méthode C**
  ignoreFilters,
  setIgnoreFilters,
}) => {
  const [isActive, setIsActive] = useState(false);
  const [expandedCategories, setExpandedCategories] = useState([]);
  const { isModalOpen } = useContext(ModalContext);

  const mediaBaseUrl = process.env.REACT_APP_MEDIA_BASE_URL;

  // Gère l'affichage ou non de la liste de suggestions
  const [showSuggestions, setShowSuggestions] = useState(false);

  /**
   * Détermine si une catégorie (avec ou sans sous-catégories) est cochée
   */
  const isCategoryChecked = (category) => {
    if (!category.children || category.children.length === 0) {
      return filtresCategories.includes(category.id);
    }
    // Si la catégorie a des sous-catégories, on la considère cochée si TOUTES le sont
    return category.children.every((sc) => filtresSubCategories.includes(sc.id));
  };

  /**
   * Gestion du clic sur une catégorie
   */
  const handleCategoryChange = (category) => {
    if (category.children && category.children.length > 0) {
      const allSubCatIds = category.children.map((sc) => sc.id);
      const categoryCurrentlyChecked = isCategoryChecked(category);

      if (categoryCurrentlyChecked) {
        // Décoche catégorie + sous-catégories
        setFiltresCategories((prev) => prev.filter((id) => id !== category.id));
        setFiltresSubCategories((prev) =>
          prev.filter((id) => !allSubCatIds.includes(id))
        );
      } else {
        // Coche catégorie + toutes ses sous-catégories
        setFiltresCategories((prev) => [...prev, category.id]);
        setFiltresSubCategories((prev) => {
          const newSet = new Set(prev);
          allSubCatIds.forEach((id) => newSet.add(id));
          return Array.from(newSet);
        });
      }
    } else {
      // Catégorie sans sous-catégorie
      const categoryId = category.id;
      if (filtresCategories.includes(categoryId)) {
        setFiltresCategories((prev) => prev.filter((id) => id !== categoryId));
      } else {
        setFiltresCategories((prev) => [...prev, categoryId]);
      }
    }
  };

  /**
   * Gestion du clic sur une sous-catégorie
   */
  const handleSubCategoryCheckboxChange = (subCatId) => {
    if (filtresSubCategories.includes(subCatId)) {
      // On décoche la sous-catégorie
      setFiltresSubCategories((prev) => prev.filter((id) => id !== subCatId));
      // Vérifier si la catégorie parente doit être décochée
      const parentCategory = categoriesData.find(
        (cat) => cat.children && cat.children.some((sc) => sc.id === subCatId)
      );
      if (parentCategory && filtresCategories.includes(parentCategory.id)) {
        const remainingSubs = parentCategory.children.filter((sc) => sc.id !== subCatId);
        const allOthersChecked = remainingSubs.every((sc) =>
          filtresSubCategories.includes(sc.id)
        );
        if (!allOthersChecked) {
          setFiltresCategories((prev) =>
            prev.filter((id) => id !== parentCategory.id)
          );
        }
      }
    } else {
      // On coche la sous-catégorie
      setFiltresSubCategories((prev) => [...prev, subCatId]);
      // Vérifier si la catégorie parente doit être cochée
      const parentCategory = categoriesData.find(
        (cat) => cat.children && cat.children.some((sc) => sc.id === subCatId)
      );
      if (parentCategory) {
        const allSubsChecked = parentCategory.children.every(
          (sc) => filtresSubCategories.includes(sc.id) || sc.id === subCatId
        );
        if (allSubsChecked && !filtresCategories.includes(parentCategory.id)) {
          setFiltresCategories((prev) => [...prev, parentCategory.id]);
        }
      }
    }
  };

  /**
   * Ouvrir/fermer le panneau latéral
   */
  const toggleContainer = () => {
    setIsActive(!isActive);
  };

  /**
   * Déployer/replier les sous-catégories
   */
  const toggleCategoryExpansion = (categoryId) => {
    setExpandedCategories((prev) =>
      prev.includes(categoryId)
        ? prev.filter((id) => id !== categoryId)
        : [...prev, categoryId]
    );
  };

  /**
   * Réinitialiser tous les filtres
   */
  const resetFilters = () => {
    setFiltresCategories([]);
    setFiltresSubCategories([]);
    setFiltresDistrict('');
    setSearchTerm('');
    setShowSuggestions(false);

    // Réactiver par défaut Événements et Travaux
    setShowEvents(true);
    setShowWorks(true);

    // Laissez ignoreFilters tel quel (ou repassez-le à false selon vos besoins)
    // setIgnoreFilters(false);
  };

  /**
   * Gestion de la saisie dans le champ de recherche
   */
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setShowSuggestions(true);
  };

  // On normalise le terme saisi pour l’insensibilité aux accents
  const normalizedSearchTerm = removeAccents(searchTerm.toLowerCase());

  // Génération des suggestions (on ignore ou pas les filtres si on veut,
  // mais ici on ne fait qu’un test textuel)
  const suggestions = points
    .filter((p) => {
      const normalizedName = removeAccents(p.name?.toLowerCase() || '');
      return (
        normalizedName.includes(normalizedSearchTerm) &&
        searchTerm.length > 0
      );
    })
    .slice(0, 5);

  /**
   * Clic sur une suggestion
   */
  const handleSuggestionClick = (suggestionName) => {
    setSearchTerm(suggestionName);
    setShowSuggestions(false);
  };

  const containerClass = `
    ${styles.container}
    ${isActive ? styles.isActive : ''}
    ${isModalOpen ? styles.hidden : ''}
  `;

  return (
    <div className={containerClass}>
      <div className={styles.content}>

        {/* Sélecteur de quartier */}
        <div className={styles.headingContainer}>
          <h2 className={styles.title}>Votre quartier</h2>
          <div className={styles.dropdownContainer}>
            <select
              aria-label="Sélectionnez votre quartier"
              title="Sélectionnez votre quartier"
              className={styles.dropdown}
              value={filtresDistrict}
              onChange={(e) => setFiltresDistrict(e.target.value)}
            >
              <option value="">Sélectionnez votre quartier</option>
              {districts.map((district) => (
                <option key={district.id} value={district.id}>
                  {district.name}
                </option>
              ))}
            </select>
            <FontAwesomeIcon icon={faCaretDown} />
          </div>
        </div>

        {/* Champ de recherche */}
        <div className={styles.searchFormContainer}>
          {/* LE NOUVEAU TOGGLE (Ignorer les filtres) */}
          <label className={styles.filterIgnore}>
            <span>Ignorer les filtres dans ma recherche ?</span>
            <div className={styles.switchLabel}>
              <input
                type="checkbox"
                checked={ignoreFilters}
                onChange={() => setIgnoreFilters(!ignoreFilters)}
                className={styles.switchInput}
                aria-label="Ignorer tous les filtres (catégories, quartiers...) lors de la recherche"
              />
              <span className={styles.switchSlider}></span>
            </div>
          </label>
          <form
            className={styles.searchForm}
            onSubmit={(e) => {
              e.preventDefault();
              setShowSuggestions(false);
            }}
          >
            <label htmlFor="search" className="sr-only">
              Rechercher
            </label>
            <FontAwesomeIcon icon={faMagnifyingGlass} />
            <input
              type="text"
              id="search"
              placeholder="Recherchez un point d'intérêt"
              className={styles.searchInput}
              aria-label="Champ de recherche"
              value={searchTerm}
              onChange={handleSearchChange}
            />
            {showSuggestions && suggestions.length > 0 && (
              <ul className={styles.suggestions}>
                {suggestions.map((point) => (
                  <li
                    key={point.id}
                    onClick={() => handleSuggestionClick(point.name)}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        handleSuggestionClick(point.name);
                      }
                    }}
                    tabIndex={0}
                    role="button"
                    className={styles.suggestionItem}
                  >
                    {point.name}
                  </li>
                ))}
              </ul>
            )}
          </form>

        </div>

        {/* Bloc des filtres de catégories */}
        <div className={styles.filtersContainer}>
          <div className={styles.filtersHeader}>
            <h3>Catégories</h3>
          </div>

          {categoriesData.length === 0 && (
            <p className={styles.noCategories}>Aucune catégorie disponible</p>
          )}

          <ul className={`list--none ${styles.filters}`}>
            {/* Boutons supplémentaires pour Événements et Travaux */}
            <li>
              <label className={styles.listItem}>
                <img
                  src="/images/events_dot.svg"
                  alt=""
                  className={styles.icon}
                />
                <span>Événements</span>
                <input
                  type="checkbox"
                  checked={showEvents}
                  onChange={() => setShowEvents(!showEvents)}
                  aria-label="Afficher ou masquer les événements"
                />
              </label>
            </li>

            <li>
              <label className={styles.listItem}>
                <img
                  src="/images/urgence_dot.svg"
                  alt=""
                  className={`${styles.icon} ${styles.travauxIcon}`}
                />
                <span>Travaux en cours</span>
                <input
                  type="checkbox"
                  checked={showWorks}
                  onChange={() => setShowWorks(!showWorks)}
                  aria-label="Afficher ou masquer les travaux"
                />
              </label>
            </li>

            {categoriesData.map((categorie) => {
              const hasChildren =
                categorie.children && categorie.children.length > 0;
              const categoryChecked = isCategoryChecked(categorie);
              const isExpanded = expandedCategories.includes(categorie.id);

              return (
                <li key={categorie.id}>
                  {hasChildren ? (
                    <>
                      <label className={styles.listItem}>
                        <div
                          className={styles.labelItem}
                          onClick={() => toggleCategoryExpansion(categorie.id)}
                          aria-expanded={isExpanded}
                          role="button"
                          tabIndex={0}
                          onKeyPress={(e) => {
                            if (e.key === 'Enter' || e.key === ' ') {
                              toggleCategoryExpansion(categorie.id);
                            }
                          }}
                        >
                          <img
                            src={`${mediaBaseUrl}/images/${categorie.iconUrl}`}
                            alt=""
                            className={styles.icon}
                          />
                          <span>{categorie.name}</span>
                          <FontAwesomeIcon
                            icon={faChevronDown}
                            className={`${styles.hasChildrenIcon} ${isExpanded ? styles.expanded : ''
                              }`}
                            aria-hidden="true"
                            aria-label="Voir les sous-catégories"
                            title="Voir les sous-catégories"
                          />
                        </div>
                        <input
                          type="checkbox"
                          checked={categoryChecked}
                          onChange={() => handleCategoryChange(categorie)}
                          aria-label={`Filtrer la catégorie ${categorie.name}`}
                        />
                      </label>
                      {isExpanded && (
                        <div className={styles.subCategoryContainer}>
                          {categorie.children.map((subCat) => (
                            <label
                              key={subCat.id}
                              className={styles.subCatItem}
                            >
                              <span>{subCat.name}</span>
                              <input
                                type="checkbox"
                                checked={filtresSubCategories.includes(subCat.id)}
                                onChange={() =>
                                  handleSubCategoryCheckboxChange(subCat.id)
                                }
                                aria-label={`Filtrer la sous-catégorie ${subCat.name}`}
                              />
                            </label>
                          ))}
                        </div>
                      )}
                    </>
                  ) : (
                    <label className={styles.listItem}>
                      <img
                        src={`${mediaBaseUrl}/images/${categorie.iconUrl}`}
                        alt=""
                        className={styles.icon}
                      />                      <span>{categorie.name}</span>
                      <input
                        type="checkbox"
                        checked={filtresCategories.includes(categorie.id)}
                        onChange={() => handleCategoryChange(categorie)}
                        aria-label={`Filtrer la catégorie ${categorie.name}`}
                      />
                    </label>
                  )}
                </li>
              );
            })}
          </ul>
        </div>

        {/* Bouton de réinitialisation */}
        <div className={styles.filtersReset}>
          {filtresCategories.length === 0 &&
            filtresSubCategories.length === 0 &&
            categoriesData.length > 0 &&
            !filtresDistrict &&
            !searchTerm ? (
            <p className={styles.noFiltersMsg}>Aucun filtre appliqué</p>
          ) : (
            <Button
              onClick={resetFilters}
              aria-label="Réinitialiser les filtres"
              variant="map"
            >
              Réinitialiser les filtres
            </Button>
          )}
        </div>
      </div>

      {/* Bouton qui ouvre/ferme le container */}
      <button
        className={styles.toggleContainer}
        aria-label="Bascule la navigation de la carte"
        title="Bascule la navigation de la carte"
        onClick={toggleContainer}
      >
        {isActive ? (
          <CaretForwardOutline color="currentColor" className={styles.toggle} />
        ) : (
          <CaretBackOutline color="currentColor" className={styles.toggle} />
        )}
      </button>
    </div>
  );
};

export default FilterControls;
