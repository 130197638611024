import React from 'react';
import { Link } from 'react-router-dom';
import api from 'services/api';
import SearchBar from 'components/search/SearchBar';
import Button from 'components/common/Button';
import styles from './NotFoundPage.module.css';
import { Helmet } from 'react-helmet';

const ErrorPage = () => {

    return (
        <>
                  <Helmet>
                    <title>Ville d'Arras | Page non trouvée</title>
                    <meta name="description" content="Une description de la page pour le SEO." />
                    <meta property="og:title" content="Ville d'Arras" />
                    <meta property="og:description" content="Une description adaptée aux réseaux sociaux." />
                    <link rel="canonical" href="https://arras.fr/" />

                  </Helmet>
        <section className={`global-section ${styles.error}`}>
            <div className={`global-container ${styles.container}`}>
                <h1>La page que vous recherchez n'existe pas ou n'est plus accessible.</h1>
                {/* Searchbar */}

                {/* Bouton de retour à l'accueil */}
                <Button variant="primary"><Link to="/">Retour à l'accueil</Link></Button>
            </div>
        </section>
        </>
    );
};

export default ErrorPage;