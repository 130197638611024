// src/components/EventFilter.js

import React from 'react';
import PropTypes from 'prop-types';
import styles from './EventFilter.module.css';
import archiveStyle from 'styles/modules/Archives.module.css';
import useAccessibility from 'hooks/useAccessibility';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { useSubSite } from 'contexts/SubSiteContext';

const EventFilter = ({
  nameFilter,
  setNameFilter,
  categoryFilter,
  setCategoryFilter,
  categories,
  resetFilters,
  // Ajout des props pour le toggle :
  specialFilter,
  setSpecialFilter
}) => {
  const accessibilityVariant = useAccessibility();
  const { isSubSite } = useSubSite();

  return (
    <div className={styles.filterWrap}>
      <div className={styles.filterContainer}>
        <p className={styles.label}>Filtrer le contenu :</p>
        <div className={styles.filters}>
          <label htmlFor="searchInput" className="sr-only">
            Rechercher par nom d'événement
          </label>
          <input
            id="searchInput"
            type="text"
            placeholder="Rechercher par nom d'événement"
            value={nameFilter}
            onChange={(e) => setNameFilter(e.target.value)}
            className={styles.nameFilter}
          />

          <div className={styles.dropdown}>
            {!isSubSite && (
              <>
                <label htmlFor="categoryFilter" className="sr-only">
                  Filtrer par catégorie
                </label>
                <select
                  id="categoryFilter"
                  value={categoryFilter}
                  onChange={(e) => setCategoryFilter(e.target.value)}
                  className={styles.categoryFilter}
                >
                  <option value="">Toutes les catégories</option>
                  {categories.map((category) => (
                    <option key={category.id} value={category.slug}>
                      {category.name}
                    </option>
                  ))}
                </select>
                <FontAwesomeIcon icon={faCaretDown} />
              </>
            )}
          </div>
        </div>

        {/* Toggle pour "Événements spéciaux" */}
        <div className={styles.specialFilter}>
          <label className={styles.switchWrapper}>
            <span className={styles.toggleLabel}>Événements du maire</span>
            <input
              type="checkbox"
              className={styles.switchInput}
              checked={specialFilter}
              onChange={(e) => setSpecialFilter(e.target.checked)}
            />
            <span className={styles.switchSlider} />
          </label>
        </div>
      </div>

      <div className={archiveStyle.filters}>
        <div className={archiveStyle.filterContainer}>
          {/* Les termes filtrés sont ici en exemple, à adapter selon ton usage */}
          <ul className={`list--none ${archiveStyle.filterList}`}>
            <li>
              <a
                href="/"
                className={archiveStyle.autoTag}
                aria-label="Supprimer le filtre Terme automatique"
              >
                Terme automatique<span>x</span>
              </a>
            </li>
            <li>
              <a
                href="/"
                className={archiveStyle.filteredTag}
                aria-label="Supprimer le filtre Terme automatique"
              >
                Terme filtré<span>x</span>
              </a>
            </li>
            <li>
              <a
                href="/"
                className={archiveStyle.filteredTag}
                aria-label="Supprimer le filtre Terme automatique"
              >
                Terme filtré<span>x</span>
              </a>
            </li>
          </ul>
          <button
            className={`arrow-link ${archiveStyle.resetTags}`}
            onClick={resetFilters}
          >
            Réinitialiser les filtres
          </button>
        </div>
      </div>
    </div>
  );
};

EventFilter.propTypes = {
  nameFilter: PropTypes.string.isRequired,
  setNameFilter: PropTypes.func.isRequired,
  categoryFilter: PropTypes.string.isRequired,
  setCategoryFilter: PropTypes.func.isRequired,
  categories: PropTypes.array.isRequired,
  resetFilters: PropTypes.func.isRequired,

  // Ajout des propTypes pour le toggle
  specialFilter: PropTypes.bool.isRequired,
  setSpecialFilter: PropTypes.func.isRequired,
};

export default EventFilter;
