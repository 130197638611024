import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styles from 'components/home/HeroSection.module.css';

const VideoBanner = ({
  src,
  fallbackText = 'Votre navigateur ne supporte pas les vidéos HTML5.',
  className = '',
  style = {}
}) => {
  const videoEl = useRef(null);

  const attemptPlay = () => {
    if (videoEl.current) {
      const playPromise = videoEl.current.play();
      if (playPromise !== undefined) {
        playPromise.catch(error => {
          console.error('Erreur lors de la tentative de lecture de la vidéo :', error);
        });
      }
    }
  };

  useEffect(() => {
    attemptPlay();
  }, []);

  return (
    <div className={styles.heroVideo} style={style}>
      <video
        ref={videoEl}
        autoPlay
        loop
        muted
        playsInline
        load="lazy"
      >
        <source src={src} type="video/mp4" />
        {fallbackText}
      </video>
    </div>
  );
};

VideoBanner.propTypes = {
  src: PropTypes.string.isRequired,
  fallbackText: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
};

export default VideoBanner;
