import React, { useEffect, useState } from 'react';
import api from 'services/api';  // Ton service API pour récupérer les données
import styles from './SocialLinks.module.css';  // Optionnel, fichier de styles
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';  // FontAwesome pour les icônes
import { faFacebook, faLinkedin, faTwitter, faInstagram, faXTwitter } from '@fortawesome/free-brands-svg-icons';  // Importer les icônes
import Webcam from 'icons/webcam';
import { useSubSite } from 'contexts/SubSiteContext'; // Hook pour vérifier le sous-site

const SocialLinks = () => {
  const [socialLinks, setSocialLinks] = useState([]);
  const { isSubSite, subSiteSlug } = useSubSite();

  // Fonction pour récupérer les réseaux sociaux depuis l'API
  useEffect(() => {
    const fetchSocialLinks = async () => {
      try {
        const endpoint = isSubSite ? `/general-informations/social-networks/${subSiteSlug}` : '/general-informations/social-networks';

        const response = await api.get(endpoint);

        console.log('socialLinks', response.data);
        setSocialLinks(response.data);  // Stocker les réseaux sociaux dans le state
      } catch (error) {
        console.error('Erreur lors de la récupération des réseaux sociaux:', error);
      }
    };

    fetchSocialLinks();
  }, []);



  // Correspondance entre les noms d'icônes et les icônes de FontAwesome
  const iconMap = {
    facebook: faFacebook,
    twitter: faXTwitter,
    instagram: faInstagram,
    linkedin: faLinkedin,
    // Ajouter d'autres icônes ici si nécessaire
  };

  return (
    <div className={`${styles.socialsContainer}`}>
    <p>Suivez-nous sur les réseaux sociaux</p>
      <ul className={`list--none ${styles.socialLinks}`}>
        <li key="webcam">
          <a
            href="/webcams"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.webcamLink}
            aria-label="Accès à la webcam de la ville d'Arras"
          >
            <Webcam />
          </a>
        </li>
        {socialLinks.map((social) => (
          <li key={social.id}>
            <a
              href={social.url}
              target="_blank"
              rel="noopener noreferrer"
              aria-label={social.name}
              className={styles.socialLink}
            >
              <FontAwesomeIcon icon={iconMap[social.icon]} className={styles.icon} />
            </a>
          </li>
        ))}
      </ul>
      </div>
  );
};

export default SocialLinks;
