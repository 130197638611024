import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'; // useLocation pour lire le paramètre ?cat

import api from 'services/api';

import AssociationCard from 'components/associations/AssociationCard';
import ListItem from 'components/archives/ListItem';
import LoadMoreButton from 'components/common/LoadMoreButton';
import MapCta from 'components/common/MapCta';
import LiensPratiques from 'components/common/LiensPratiques';
import ArchivesHeader from 'components/archives/ArchivesHeader';
import AssociationsArchiveFilter from 'components/associations/AssociationsArchiveFilter';
import ToggleView from 'components/archives/ToggleView';
import ArchivesGridView from 'components/archives/ArchivesGridView';
import ArchivesListView from 'components/archives/ArchivesListView';

import { useSubSite } from 'contexts/SubSiteContext';
import useDesignSettingsData from 'hooks/useDesignSettingsData';
import { Helmet } from 'react-helmet';
import Spinner from 'components/common/Spinner';
import styles from 'styles/modules/Archives.module.css';

const AssociationsPage = () => {
  const [associations, setAssociations] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);

  // Filtrage
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('all');

  // Pagination
  const [displayCount, setDisplayCount] = useState(9);

  // Vue
  const [view, setView] = useState('grid');

  // Loading
  const [loading, setLoading] = useState(true);

  const { isSubSite, subSiteSlug } = useSubSite();
  const { designSettings, settingsLoading, error } = useDesignSettingsData();
  // Permet de lire le paramètre ?cat= dans l'URL
  const location = useLocation();


  const associationsURL = isSubSite ? `/${subSiteSlug}/associations` : '/associations';

  // 1) Charger les associations
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (isSubSite) {
          const response = await api.get(`/subsites/${subSiteSlug}/associations`);
          setAssociations(response.data);
        } else {
          const response = await api.get('/associations');
          setAssociations(response.data);
        }
      } catch (err) {
        console.error('Erreur lors de la récupération des associations :', err);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [isSubSite, subSiteSlug]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const catParam = params.get('cat'); // par ex. "culturelle", "sportive"

    if (catParam) {
      setSelectedCategory(catParam);
    } else {
      setSelectedCategory('all');
    }
    // On ne fait rien de plus : on laisse l'URL comme elle est
    // (vous pourriez faire un navigate("/associations", { replace: true }) si vous voulez la nettoyer)
  }, [location.search]);

  // 2) Appliquer le filtre (category + search)
  useEffect(() => {
    let newFiltered = associations;

    // Filtre par catégorie
    if (selectedCategory !== 'all') {
      newFiltered = newFiltered.filter((assoc) => {
        // Supposez que assoc.categorySlug (ou assoc.category) est "culturelle" ou "sportive"
        // Adaptation : si c'est un champ "categorySlug"
        return assoc.category === selectedCategory;

        // OU si c'est un champ "category" direct en string :
        // return assoc.category === selectedCategory;
      });
    }

    // Filtre par recherche
    if (searchQuery.trim()) {
      const lowerSearch = searchQuery.toLowerCase();
      newFiltered = newFiltered.filter((assoc) => {
        const nameMatch = assoc.name.toLowerCase().includes(lowerSearch);
        const presentationMatch = assoc.presentation
          ? assoc.presentation.toLowerCase().includes(lowerSearch)
          : false;
        return nameMatch || presentationMatch;
      });
    }

    setFilteredItems(newFiltered);
    setDisplayCount(9); // reset pagination quand on change de filtre
  }, [associations, selectedCategory, searchQuery]);

  // 3) Handlers
  const handleFilterChange = (catSlug) => setSelectedCategory(catSlug);
  const handleSearchChange = (e) => setSearchQuery(e.target.value);
  const resetFilters = () => {
    setSelectedCategory('all');
    setSearchQuery('');
  };
  const loadMore = () => {
    setDisplayCount((prev) => prev + 9);
  };

  // Check loading / error
  if (settingsLoading) return <div><Spinner /></div>;
  if (error) return <div>Erreur : {error}</div>;

  const itemsToDisplay = filteredItems.slice(0, displayCount);

  return (
    <>
              <Helmet>
                <title>Arras | Associations</title>
                <meta name="description" content="Découvrez toutes les associations de la ville d'Arras : culture, sport, solidarité, loisirs et bien plus. Trouvez facilement une association qui correspond à vos centres d'intérêt et impliquez-vous dans la vie locale." />
                <meta property="og:title" content="Arras | Associations" />
                <meta property="og:description" content="Retrouvez toutes les associations de la ville d'Arras en un seul endroit : culture, sport, solidarité, loisirs et plus encore. Impliquez-vous dans votre communauté et participez à la vie locale !" />
                <meta property="og:image" content={`${designSettings.defaultAssoBanner}`} />
        <meta property="og:image:alt" content={`Image descriptive pour la page Associations`} />
                <link rel="canonical" href={`https://www.arras.fr/associations`} />
              </Helmet>

      <div className={styles.pageWrapper}>
        <ArchivesHeader
          title="Associations"
          imageUrl={designSettings.defaultAssoBanner}
        />

        <div className={`page-content ${styles.pageContent} ${styles.pageAssociations}`}>
          <section className={`global-section ${styles.headerSection}`}>
            <div className={`global-container ${styles.offsetHeader}`}>
              <AssociationsArchiveFilter
                selectedCategory={selectedCategory}
                handleFilterChange={handleFilterChange}
                searchQuery={searchQuery}
                handleSearchChange={handleSearchChange}
                resetFilters={resetFilters}
              />
            </div>
          </section>

          <section className={`global-section ${styles.contentSection}`}>
            <div className="global-container">
              <ToggleView view={view} setView={setView} />

              {loading ? (
                <div>Chargement des associations...</div>
              ) : itemsToDisplay && itemsToDisplay.length > 0 ? (
                view === 'grid' ? (
                  <ArchivesGridView
                    items={itemsToDisplay}
                    renderItem={(association) => (
                      <AssociationCard key={association.id} association={association} />
                    )}
                    displayCount={displayCount}
                  />
                ) : (
                  <ArchivesListView
                    items={itemsToDisplay}
                    renderItem={(association) => (
                      <ListItem
                        key={association.id}
                        title={association.name}
                        meta={[
                          association.contactMail ? `Email : ${association.contactMail}` : '',
                          association.contactPhone ? `Téléphone : ${association.contactPhone}` : '',
                        ].filter(Boolean)}
                        description={
                          association.presentation
                            ? association.presentation
                                .replace(/<\/?[^>]+(>|$)/g, '')
                                .slice(0, 100) + '...'
                            : 'Aucune description disponible.'
                        }
                        categories={
                          association.activity
                            ? [association.activity]
                            : []
                        }
                        link={`${associationsURL}/${association.slug}`}
                        buttonText="En savoir plus"
                      />
                    )}
                    displayCount={displayCount}
                  />
                )
              ) : (
                <p>Aucune association trouvée.</p>
              )}

              {displayCount < filteredItems.length && (
                <LoadMoreButton onClick={loadMore}>
                  Charger plus
                </LoadMoreButton>
              )}
            </div>
          </section>

          <MapCta />
          <LiensPratiques />
        </div>
      </div>
    </>
  );
};

export default AssociationsPage;
