// src/pages/associations/AssociationPage.js
import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import api from 'services/api';
import Badge from 'components/common/Badge';
import Button from 'components/common/Button';
import Summary from 'components/common/Summary';
import Breadcrumbs from 'components/common/Breadcrumbs';
import ShareButtons from 'components/common/ShareButtons';
import FileList from 'components/common/FileList';
import BannerWithSocialIcons from 'components/common/BannerWithSocialIcons';
import MapCta from 'components/common/MapCta';
import LiensPratiques from 'components/common/LiensPratiques';
import Gallery from 'components/associations/Gallery'; // Composant à créer pour la galerie d'images
import sharedStyles from 'styles/modules/ContentPage.module.css'; // Import styles partagés
import styles from './AssociationPage.module.css';
import archiveStyle from "styles/modules/Archives.module.css";
import { ShareSocialOutline } from 'react-ionicons'; // Retiré TimeOutline car non nécessaire
import { ReactComponent as Zoom } from 'icons/zoom.svg';
import { useSubSite } from 'contexts/SubSiteContext';
import useDesignSettingsData from 'hooks/useDesignSettingsData';
import useBreakpoint from 'hooks/useBreakpoint'; // Responsive Hook
import { Helmet } from 'react-helmet';
import Spinner from 'components/common/Spinner';

const AssociationPage = () => {
  const { associationSlug } = useParams();
  const [association, setAssociation] = useState(null);
  const [loading, setLoading] = useState(true);
  const [updatedContentHtml, setUpdatedContentHtml] = useState('');
  const pageType = "isAssociation";
  const { isSubSite, subSiteSlug } = useSubSite();
  const { designSettings, settingsLoading, error } = useDesignSettingsData();

  const isMobile = useBreakpoint(); // Utilisation du hook pour savoir si l'écran est mobile


  const associationsURL = isSubSite ? `/${subSiteSlug}/associations` : `/associations`;

  useEffect(() => {
    const fetchAssociation = async () => {
      try {
        const response = await api.get(`/associations/${associationSlug}`); // Assurez-vous que cet endpoint est correct
        setAssociation(response.data || null);
      } catch (error) {
        console.error("Erreur lors de la récupération de l'association:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAssociation();
  }, [associationSlug]);

  if (loading) return <div><Spinner /></div>;
  if (!association) return <div>Aucune association trouvée.</div>;

  const {
    name,
    presentation,
    gallery,
    contactMail,
    contactPhone,
    website,
    address,
    activity,
    files,
    banner,
    logo,
    slug,
  } = association;

  if (settingsLoading) return <div><Spinner /></div>;
  if (error) return <div>Erreur : {error}</div>;

  const cleanDescription = updatedContentHtml
  ? updatedContentHtml.replace(/<[^>]+>/g, '') // Supprime les balises HTML
  : presentation;

  return (
    <>
          <Helmet>
            <title>Arras | {name}</title>
            <meta name="description" content={cleanDescription ? cleanDescription : `Toutes les informations sur ${name}`} />
            <meta property="og:title" content={`Arras | ${name}`} />
            <meta property="og:description" content={cleanDescription ? cleanDescription : `Toutes les informations sur ${name}`} />
            <meta property="og:image" content={`${designSettings.defaultAssoBanner}`} />
        <meta property="og:image:alt" content={`Image descriptive pour la page ${name}`} />
            <link rel="canonical" href={`https://www.arras.fr/associations/${slug}`} />
          </Helmet>
    <div className={`${sharedStyles.pageWrapper} ${sharedStyles.associationPage}`}>
      {/* Bannière avec miniature */}
      {designSettings && designSettings.defaultAssoBanner && (
        <BannerWithSocialIcons
          imageUrl={`${designSettings.defaultAssoBanner}`}
        />
      )}

      <div className={`page-content ${sharedStyles.pageContent}`}>
        <section className={`global-section ${sharedStyles.mainSection}`}>
          <div className={`global-container ${sharedStyles.headerContainer}`}>
            <div className={sharedStyles.header}>

              <div className={archiveStyle.headingBlock}>
                <h1>{name}</h1>
                <Breadcrumbs pageType={'isAsso'} element={association} />
              </div>

              <div className={sharedStyles.metaContainer}>
                <div className={sharedStyles.meta}>
                  <ShareSocialOutline
                    color="currentColor"
                    height=""
                    width=""
                    className={sharedStyles.metaIcon}
                  />
                  <p className={sharedStyles.metaInfo}>Partager :</p>
                  <ShareButtons />
                </div>
              </div>
            </div>

            {activity && (
              <ul className={`list--none ${sharedStyles.badgeList}`}>
                <li>
                  <Badge
                    key={activity.id}
                    label={activity.name}
                    variant={activity.slug}
                    link={`/categorie/${activity.slug}`}
                  />
                </li>
              </ul>

            )}
          </div>

          <div className={`global-container ${sharedStyles.main}`}>

            {/* Colonne principale */}
            <div className={sharedStyles.mainColumn}>



              {/* Informations de contact */}
              <div className={styles.contactInfo}>

                {address && (
                  <p className={sharedStyles.address}>
                    <strong>Adresse :</strong> {address}
                  </p>
                )}

                {contactMail && (
                  <p>
                    <strong>Email :</strong> <a href={`mailto:${contactMail}`}>{contactMail}</a>
                  </p>
                )}
                {contactPhone && (
                  <p>
                    <strong>Téléphone :</strong> <a href={`tel:${contactPhone}`}>{contactPhone}</a>
                  </p>
                )}
                {website && (
                  <p>
                    <strong>Site Web :</strong> <a href={website} target="_blank" rel="noopener noreferrer">{website}</a>
                  </p>
                )}
              </div>

              {isMobile && (
                <div className={sharedStyles.mobileSummary}>
                  <Summary contentHtml={presentation || ''} setUpdatedContentHtml={setUpdatedContentHtml} />
                </div>
              )}

              {/* Contenu de l'association */}
              <div
                className={sharedStyles.content}
                dangerouslySetInnerHTML={{ __html: updatedContentHtml || presentation }}
              />

              {/* Fichiers associés */}
              {files && files.length > 0 && <FileList files={files} />}

              {/* Galerie d'images */}
              {gallery && gallery.length > 0 && (
                <Gallery images={gallery} />
              )}

              <div className={sharedStyles.actions}>
                <Link to={associationsURL}>
                  <Button variant="secondary">Retour aux associations</Button>
                </Link>
                <Button variant="primary" onClick={() => window.print()}>Imprimer</Button>
              </div>
            </div>

            {/* Colonne latérale droite */}
            <div className={sharedStyles.sidebar}>
              {logo && (
                <div className={sharedStyles.galleryThumbnail}>
                  <img
                    src={`${process.env.REACT_APP_MEDIA_BASE_URL}${logo}`}
                    className={sharedStyles.galleryImage}
                  />

                </div>
              )}
              {!isMobile && (
                <div className={sharedStyles.sticky}>
                  <Summary contentHtml={presentation || ''} setUpdatedContentHtml={setUpdatedContentHtml} />
                </div>
              )}

            </div>
          </div>

        </section >

        {/* CTA Map */}
        <MapCta />

        {/* Liens Pratiques */}
        <LiensPratiques />
        
      </div>

    </div>
    </>
  );
};

export default AssociationPage;
