import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import api from 'services/api';
import Breadcrumbs from 'components/common/Breadcrumbs';
import BannerWithSocialIcons from 'components/common/BannerWithSocialIcons';
import ShareButtons from 'components/common/ShareButtons';
import Button from 'components/common/Button';
import PDFReader from 'components/kiosk/PDFReader'; // Import du PDFReader
import MapCta from 'components/common/MapCta';
import LiensPratiques from 'components/common/LiensPratiques';
import sharedStyles from 'styles/modules/ContentPage.module.css';
import styles from './MagazinePage.module.css';
import { TimeOutline, ShareSocialOutline } from 'react-ionicons';
import useDesignSettingsData from 'hooks/useDesignSettingsData';
import { Helmet } from 'react-helmet';
import Spinner from 'components/common/Spinner';

const MagazinePage = () => {
  const { magazineSlug } = useParams();
  const [magazine, setMagazine] = useState(null);
  const { serieSlug } = useParams();
  const [loading, setLoading] = useState(true);
  const [showReader, setShowReader] = useState(false);
  const { designSettings, settingsLoading, error } = useDesignSettingsData();

  const pageType = "isMagazine";

  useEffect(() => {
    const fetchMagazineData = async () => {
      try {
        const response = await api.get(`/kiosk/read/${magazineSlug}`);
        setMagazine(response.data || null);
      } catch (error) {
        console.error("Erreur lors de la récupération du magazine:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchMagazineData();

  }, [magazineSlug]);

  if (loading) return <div><Spinner /></div>;
  if (!magazine) return <div>Aucun magazine trouvé.</div>;

  if (settingsLoading) return <div><Spinner /></div>;
  if (error) return <div>Erreur : {error}</div>;

  const {
    title,
    publishedAt,
    isSpecialIssue,
    description,
    file,
    serie,
    poster,
    publicationNumber,
    slug
  } = magazine;

  const formattedDate = publishedAt ? new Date(publishedAt).toLocaleDateString() : 'Date non disponible';

  // Construire l'URL du fichier
  const fileUrl = file && file.path ? `${process.env.REACT_APP_MEDIA_BASE_URL}${file.path}` : null;

  // Fonction pour gérer le clic sur le bouton "Lire le magazine"
  const handleReadClick = () => {
    setShowReader(true);
  };

  return (
    <>
      <Helmet>
        <title>Ville d'Arras | {title}</title>
        <meta name="description" content={`Découvrez la nouvelle parution ${title}`} />
        <meta property="og:title" content={`Ville d'Arras | ${title}`} />
        <meta property="og:description" content={`Découvrez la nouvelle parution ${title}`} />
        <meta property="og:image" content={poster && poster.path ? `${process.env.REACT_APP_MEDIA_BASE_URL}${poster.path}` : 'https://arras.fr/default-thumbnail.jpg'} />
        <meta property="og:url" content={`https://www.arras.fr/${serieSlug}/${magazineSlug}`} />
        <meta property="og:type" content="article" />
        <meta property="og:site_name" content="Ville d'Arras" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`Ville d'Arras | ${title}`} />
        <meta name="twitter:description" content={`Découvrez la nouvelle parution ${title}`} />
        <meta name="twitter:image" content={poster && poster.path ? `${process.env.REACT_APP_MEDIA_BASE_URL}${poster.path}` : 'https://arras.fr/default-thumbnail.jpg'} />
        <link rel="canonical" href={`https://www.arras.fr/${serieSlug}/${magazineSlug}`} />
      </Helmet>
      <div className={`${sharedStyles.pageWrapper} ${sharedStyles.magazinePage}`}>
        {/* Bannière */}
        {poster && poster.path ? (
          <BannerWithSocialIcons
            imageUrl={`${designSettings.defaultKioskBanner}`}
          />
        ) : (
          <BannerWithSocialIcons
            imageUrl={`${designSettings.defaultKioskBanner}`}
          />
        )}

        <div className={`page-content ${sharedStyles.pageContent} ${styles.pageContent}`}>
          <section className={`global-section ${sharedStyles.mainSection}`}>
            <div className={`global-container ${sharedStyles.headerContainer}`}>
              <div className={sharedStyles.header}>
                <div className={sharedStyles.headingBlock}>
                  <h1>{title}</h1>
                  <Breadcrumbs pageType={pageType} element={magazine} />

                  {/* Fichiers associés */}
                  {fileUrl && (
                    <div className={styles.download}>
                      <Link to={`${process.env.REACT_APP_MEDIA_BASE_URL}${file.path}`}>

                        <Button variant="primary">Télécharger</Button>
                      </Link>
                    </div>
                  )}

                </div>
                <div className={sharedStyles.metaContainer}>
                  <div className={sharedStyles.meta}>
                    <ShareSocialOutline
                      color="currentColor"
                      height=""
                      width=""
                      className={sharedStyles.metaIcon}
                    />
                    <p className={sharedStyles.metaInfo}>Partager :</p>
                    <ShareButtons />
                  </div>
                  <div className={sharedStyles.meta}>
                    <TimeOutline
                      color="currentColor"
                      height=""
                      width=""
                      className={sharedStyles.metaIcon}
                    />
                    <p className={sharedStyles.metaInfo}>Publié le : {formattedDate}</p>
                  </div>



                </div>
              </div>
            </div>

            <div className={`global-container ${styles.main}`}>
              {/* Colonne principale */}
              <div className={styles.mainColumn}>

                <PDFReader fileUrl={fileUrl} />

              </div>
            </div>
          </section>

          {/* Map CTA */}
          <MapCta />

          {/* Liens Pratiques */}
          <LiensPratiques />
        </div>
      </div>
    </>
  );
};

export default MagazinePage;