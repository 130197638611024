import React, { useState, useEffect } from 'react';
import styles from './ComingSoonPage.module.css';
import AlloMairie from 'components/info/AlloMairie';
import ContactInfo from 'components/info/ContactInfo';
import UrgenceNumbers from 'components/info/UrgenceNumbers';
import { Helmet } from 'react-helmet';

function MaintenancePage() {
  // Date de fin de maintenance
  const targetDate = new Date(2025, 2, 18, 16, 0, 0);

  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const calculateTimeLeft = () => {
    const now = new Date().getTime();
    const distance = targetDate.getTime() - now;

    if (distance <= 0) {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    return {
      days: Math.floor(distance / (1000 * 60 * 60 * 24)),
      hours: Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
      minutes: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
      seconds: Math.floor((distance % (1000 * 60)) / 1000),
    };
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    setTimeLeft(calculateTimeLeft());
    return () => clearInterval(timer);
  }, []);

  return (
    <>
      <Helmet>
        <title>Ville d'Arras | Maintenance en cours</title>
        <meta name="description" content="Le site est temporairement en maintenance. Revenez bientôt !" />
        <meta property="og:title" content="Ville d'Arras - Maintenance" />
        <meta property="og:description" content="Le site est temporairement indisponible." />
      </Helmet>

      <main className={styles.maintenancePage}>
        <section className={`global-section ${styles.section}`}>
          <div className={`global-container ${styles.content}`}>
            <div className={styles.heroContent}>
              <img
                src="/images/logo-ville-arras.png"
                alt="Logo de la ville d'Arras"
                title="Logo de la ville d'Arras"
                aria-label="Logo de la ville d'Arras"
              />
              <h1 className={styles.title}>Maintenance en cours</h1>
              <p className={styles.subtitle}>
                Notre site est actuellement en maintenance. Nous revenons le 16 janvier 2025 à 13h.
              </p>
            </div>
            <div className={styles.countdownSection}>
              <h2 className={styles.sectionTitle}>Retour en ligne dans :</h2>
              <div className={styles.countdown}>
                {['Jours', 'Heures', 'Minutes', 'Secondes'].map((label, index) => (
                  <div className={styles.countdownItem} key={index}>
                    <span className={styles.countdownNumber}>{Object.values(timeLeft)[index]}</span>
                    <span className={styles.countdownLabel}>{label}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>

        <section className={`global-section ${styles.metaSection}`}>
          <div className={`global-container ${styles.metaContainer}`}>
            <ContactInfo />
            <AlloMairie />
            <UrgenceNumbers />
          </div>
        </section>
      </main>
    </>
  );
}

export default MaintenancePage;