import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from 'styles/modules/Archives.module.css';
import SubCategoryList from './SubCategoryList';
import api from 'services/api';

const ArchivesListView = ({ items, renderItem, displayCount, categorySlug }) => {
  const [subcategories, setSubcategories] = useState([]);

  useEffect(() => {
    const fetchSubcategories = async () => {
      try {
        const response = await api.get(`/pages/categories/${categorySlug}/subcategories`);
        setSubcategories(response.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des sous-catégories :', error);
      }
    };

    if (categorySlug) {
      fetchSubcategories();
    }
  }, [categorySlug]);

  return (
    <>
      <ul className={`list--none global-block ${styles.list}`}>

        {/* Afficher les sous-catégories uniquement si elles existent */}
        {subcategories.length > 0 && (
          <SubCategoryList subcategories={subcategories} />
        )}

        {items.slice(0, displayCount).map((item) => renderItem(item))}
      </ul>
    </>
  );
};

ArchivesListView.propTypes = {
  items: PropTypes.array.isRequired,
  renderItem: PropTypes.func.isRequired,
  displayCount: PropTypes.number.isRequired,
  categorySlug: PropTypes.string.isRequired, // Assure que le slug est requis
};

export default ArchivesListView;
