import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faFileImage, faFileAlt, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import styles from './FileList.module.css';
import useAccessibility from 'hooks/useAccessibility';

const getFileIcon = (type) => {
  switch (type) {
    case 'pdf':
      return faFilePdf;
    case 'image':
      return faFileImage;
    default:
      return faFileAlt;
  }
};

const FileList = ({ files }) => {
  const accessibilityVariant = useAccessibility();

  return (
      <ul className={`list--none ${styles.fileList}`}>
        {files.map((file) => (
          <li key={file.id} className={styles.fileItem}>
            {/* Partie gauche : Nom et description */}
            <div className={styles.fileInfo}>
              <p className={styles.fileName}>{file.sectionTitle}</p>
              <p className={styles.fileDescription}><em>{file.description || 'Aucune description disponible'}</em></p>
            </div>

            {/* Partie droite : Icône de type de fichier et bouton d'ouverture */}
            <div className={styles.fileActions}>
              <FontAwesomeIcon icon={getFileIcon(file.fileType)} className={styles.fileIcon} title={`Fichier ${file.fileType}`} aria-label={`Fichier ${file.fileType}`} />
              <button
                onClick={() => window.open(`${process.env.REACT_APP_MEDIA_BASE_URL}${file.path}`, '_blank')}
                aria-label={`Ouvrir ${file.fileName} dans un nouvel onglet`}
                title={`Ouvrir ${file.fileName} dans un nouvel onglet`}
                className= {styles.icon}
              >
                <FontAwesomeIcon icon={faExternalLinkAlt} />
              </button>
            </div>
          </li>
        ))}
      </ul>
  );
};

FileList.propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      fileName: PropTypes.string.isRequired,
      fileType: PropTypes.string.isRequired,
      description: PropTypes.string,
      path: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default FileList;
