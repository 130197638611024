// ProfileSidebar.jsx
import React, { useState, useEffect, useContext } from 'react';
import { PersonOutline } from 'react-ionicons';
import useProfile from 'hooks/useProfile';
import { ProfileSidebarContext } from 'contexts/ProfileSidebarContext'; // Importer le contexte
import buttons from 'components/common/Button.module.css';

// On importe les styles de ProfileSidebar et ceux d'AccessibilitySidebar (renommés extraStyles).
import styles from './ProfileSidebar.module.css';
import extraStyles from './AccessibilitySidebar.module.css';

const ProfileSidebar = () => {
  const { profileOption, updateProfileOption } = useProfile();
  const { isProfileSidebarOpen, closeProfileSidebar } = useContext(ProfileSidebarContext);

  // Initialiser selectedOption avec profileOption
  const [selectedOption, setSelectedOption] = useState(profileOption);

  // Mettre à jour selectedOption si profileOption change
  useEffect(() => {
    setSelectedOption(profileOption);
  }, [profileOption]);

  // Gérer le changement d'option
  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  // Sauvegarder les préférences et mettre à jour le contexte
  const handleSave = () => {
    updateProfileOption(selectedOption); // Met à jour le contexte
    closeProfileSidebar();               // Fermer la sidebar après validation
  };

  // Réinitialiser le profil
  const handleReset = () => {
    setSelectedOption('citoyen');
    updateProfileOption('citoyen');
    localStorage.removeItem('selectedProfileOption');
  };

  // Obtenir le nom du profil actuel
  const getProfileName = (option) => {
    switch (option) {
      case 'citoyen':
        return 'Citoyen (profil par défaut)';
      case 'touriste':
        return 'Touriste';
      case 'jeune':
        return 'Jeune';
      case 'senior':
        return 'Senior';
      case 'parent':
        return 'Parent';
      case 'professionnel':
        return 'Professionnel';
      default:
        return 'Citoyen (profil par défaut)';
    }
  };

  // PLUTÔT QUE DE RETURN NULL SI FERMÉ, ON LUI APPLIQUE UNE CLASSE "FERMÉE"
  return (
    <div className={`${extraStyles.container} ${styles.container}`}>
      {/* Sidebar : si isProfileSidebarOpen = true, on ajoute .sidebarOpen */}
      <div
        className={
          isProfileSidebarOpen
            ? `${extraStyles.sidebar} ${extraStyles.sidebarOpen}`
            : extraStyles.sidebar
        }
      >
        <div className={extraStyles.closeContainer}>
          <div className={extraStyles.headingContainer}>
            <PersonOutline
              height=""
              width=""
              color="currentColor"
              className={`${extraStyles.icon} ${styles.profileIcon}`}
            />
            <span className={extraStyles.heading}>Personnaliser ma navigation</span>
          </div>
          <button
            className={extraStyles.closeButton}
            onClick={closeProfileSidebar}
            aria-label="Fermer le menu"
          >
            <div className={extraStyles.lineContainer}>
              <span className={extraStyles.line}></span>
              <span className={extraStyles.line}></span>
            </div>
            Fermer
          </button>
        </div>

        <div className={extraStyles.content}>
          <div className={extraStyles.infos}>
            <p>
              Filtrez automatiquement le contenu qui vous intéresse en ajustant votre profil de
              navigation. Il vous suffit pour cela de sélectionner l'option la plus adaptée.
            </p>
            <p className={extraStyles.textContainer}>
              Actuellement réglé sur&nbsp;
              <strong className={extraStyles.percent}>
                {getProfileName(selectedOption)}
              </strong>
            </p>
          </div>

          <div className={`${extraStyles.categoryContainer} ${styles.profileContainer}`}>
            <span className={extraStyles.headingCategory}>Vous êtes&nbsp;:</span>
            <div className={`list--none ${extraStyles.grid}`}>
              {/* Profil Citoyen */}
              <button
                className={`${extraStyles.gridItem} ${
                  selectedOption === 'citoyen' ? extraStyles.selected : ''
                }`}
                aria-label="Basculer sur le profil Citoyen"
                title="Basculer sur le profil Citoyen"
                onClick={() => handleOptionChange('citoyen')}
              >
                <span className={extraStyles.gridHeading}>
                  Citoyen (profil par défaut)
                </span>
                Naviguez librement sur le site et explorez tout le contenu disponible sur Arras.
              </button>

              {/* Profil Parent */}
              <button
                className={`${extraStyles.gridItem} ${
                  selectedOption === 'parent' ? extraStyles.selected : ''
                }`}
                aria-label="Basculer sur le profil Parent"
                title="Basculer sur le profil Parent"
                onClick={() => handleOptionChange('parent')}
              >
                <span className={extraStyles.gridHeading}>Parent</span>
                Des ressources pratiques pour votre famille et pour accompagner vos enfants au quotidien sur Arras.
              </button>

              {/* Profil Senior */}
              <button
                className={`${extraStyles.gridItem} ${
                  selectedOption === 'senior' ? extraStyles.selected : ''
                }`}
                aria-label="Basculer sur le profil Senior"
                title="Basculer sur le profil Senior"
                onClick={() => handleOptionChange('senior')}
              >
                <span className={extraStyles.gridHeading}>Senior</span>
                Retrouvez en un clic des contenus adaptés à vos besoins et intérêts.
              </button>

              {/* Profil Jeune */}
              <button
                className={`${extraStyles.gridItem} ${
                  selectedOption === 'jeune' ? extraStyles.selected : ''
                }`}
                aria-label="Basculer sur le profil Jeune"
                title="Basculer sur le profil Jeune"
                onClick={() => handleOptionChange('jeune')}
              >
                <span className={extraStyles.gridHeading}>Jeune</span>
                Étudiant ou jeune Arrageois ? Accédez rapidement aux infos clés pour votre quotidien.
              </button>

              {/* Profil Touriste */}
              <button
                className={`${extraStyles.gridItem} ${
                  selectedOption === 'touriste' ? extraStyles.selected : ''
                }`}
                aria-label="Basculer sur le profil Touriste"
                title="Basculer sur le profil Touriste"
                onClick={() => handleOptionChange('touriste')}
              >
                <span className={extraStyles.gridHeading}>Touriste</span>
                Vous visitez Arras ? Découvrez facilement les lieux à voir, les événements et les outils pratiques.
              </button>

              {/* Profil Professionnel */}
              <button
                className={`${extraStyles.gridItem} ${
                  selectedOption === 'professionnel' ? extraStyles.selected : ''
                }`}
                aria-label="Basculer sur le profil Professionnel"
                title="Basculer sur le profil Professionnel"
                onClick={() => handleOptionChange('professionnel')}
              >
                <span className={extraStyles.gridHeading}>Professionnel</span>
                Profitez d’un accès rapide aux informations utiles pour votre activité professionnelle.
              </button>
            </div>
          </div>          
        </div>

        <div className={extraStyles.buttons}>
            <button
              className={`${buttons.primary} ${extraStyles.saveButton}`}
              onClick={handleSave}
            >
              Valider mes paramètres de navigation
            </button>
            <button className={extraStyles.resetButton} onClick={handleReset}>
              Réinitialiser mes paramètres
            </button>
          </div>
      </div>

      {/* Overlay : si isProfileSidebarOpen = true => .overlayOpen */}
      <div
        className={
          isProfileSidebarOpen
            ? `${extraStyles.overlay} ${extraStyles.overlayOpen}`
            : extraStyles.overlay
        }
        onClick={closeProfileSidebar}
      ></div>
    </div>
  );
};

export default ProfileSidebar;
