import React from 'react';
import listStyles from './ListServices.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Importer Font Awesome
import { faEarDeaf } from '@fortawesome/free-solid-svg-icons';

const links = [
    { id: 1, label: 'Samu', desc: 'Urgence médicale', url: 'tel:15', number: '15' },
    { id: 2, label: 'Police Secours', desc: 'Signaler une infraction', url: 'tel:17', number: '17' },
    { id: 3, label: 'Pompiers', desc: 'Situation de péril ou accident', url: 'tel:18', number: '18' },
    { id: 4, label: 'Urgence SMS', icon: faEarDeaf, url: 'sms:114', number: '114' },
    { id: 5, label: 'Urgences Europe', desc: 'Urgence médicale, infraction, péril', url: 'tel:112', number: '112' },
    { id: 6, label: 'Enfance en danger', url: 'tel:119', number: '119' },
    { id: 7, label: 'Médecins de garde', desc: 'Permanence de soins ambulatoires', url: 'tel:116117', number: '116 117' },
    { id: 8, label: 'Femmes victimes de violence', url: 'tel:3919', number: '39 19' },
];

const UrgenceNumbers = () => {
    return (
        <div className={listStyles.container} aria-labelledby="urgence-numbers-title">
            <h3 id="urgence-numbers-title">Numéros d'urgence</h3>
            <ul className={`list--none ${listStyles.list}`}>
                {links.map(link => (
                    <li key={link.id}>
                        <a
                            href={link.url}
                            className={`arrow-link ${listStyles.link} ${listStyles.urgencyItem}`}
                            aria-label={`Contacter ${link.label}`}
                            title={`Contacter ${link.label}`}>
                            {link.label}

                            {/* Affiche l'icône si elle existe */}
                            {link.icon && (
                                <FontAwesomeIcon icon={link.icon} className={listStyles.urgencyIcon} />
                            )}

                            <span className={listStyles.urgencyNumber}>{link.number}</span>

                        </a>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default UrgenceNumbers;
