import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import api from 'services/api';

function useSubsiteDesignSettingsData() {
  const location = useLocation();

  const [designSettings, setDesignSettings] = useState(null);
  const [settingsLoading, setSettingsLoading] = useState(true);
  const [error, setError] = useState(null);

  const [subSiteSlug, setSubSiteSlug] = useState(undefined);

  // Déterminer subSiteSlug à partir de l'URL
  useEffect(() => {
    const pathSegments = location.pathname.split('/').filter(Boolean);
    const slug = pathSegments[0] || null;
    setSubSiteSlug(slug);
  }, [location]);

  // Une fois que subSiteSlug est connu (qu'il soit null ou non), on vérifie la whitelist (si nécessaire)
  // puis on récupère les design settings.
  useEffect(() => {
    if (subSiteSlug === undefined) return;

    const fetchData = async () => {
      setSettingsLoading(true);
      setError(null);

      try {
        let endpoint = `/subsites/${subSiteSlug}/settings/design`;

        // Une fois qu'on sait si c'est un sous-site ou non, on récupère les design settings
        const response = await api.get(endpoint);
        setDesignSettings(response.data);
      } catch (err) {
        console.error('Erreur lors de la récupération des paramètres de design:', err);
        setError('Impossible de charger les paramètres de design.');
      } finally {
        setSettingsLoading(false);
      }
    };

    fetchData();
  }, [subSiteSlug]);

  return { designSettings, settingsLoading, error };
}

export default useSubsiteDesignSettingsData;
