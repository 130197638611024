// CustomizeProfile.jsx
import React, { useContext } from 'react';
import { PersonOutline } from 'react-ionicons'; // Icône Personne
import { ProfileSidebarContext } from 'contexts/ProfileSidebarContext'; // Importer le contexte
import styles from './CustomizeProfile.module.css';


const CustomizeProfile = () => {
  const { openProfileSidebar } = useContext(ProfileSidebarContext);

  return (
    <div className={styles.customize}>
      <button
        className={styles.customizeButton}
        aria-label="Personnaliser sa navigation"
        title="Personnaliser sa navigation"
        onClick={openProfileSidebar} // Utiliser la fonction du contexte
      >
        <PersonOutline color="currentColor" height="" width="" className={styles.icon} />
        Personnaliser ma navigation
      </button>
      <span className={styles.separator}></span>
    </div>
  );
};

export default CustomizeProfile;
