import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom'; // <-- Import useNavigate
import { Helmet } from 'react-helmet';
import api from 'services/api';
import useFilter from 'hooks/useFilter';

import Spinner from 'components/common/Spinner';
import LoadMoreButton from 'components/common/LoadMoreButton';
import MapCta from 'components/common/MapCta';
import LiensPratiques from 'components/common/LiensPratiques';

import ArchivesHeader from 'components/archives/ArchivesHeader';
import ArchivesFilters from 'components/archives/ArchivesFilters';
import ToggleView from 'components/archives/ToggleView';
import ArchivesGridView from 'components/archives/ArchivesGridView';
import ArchivesListView from 'components/archives/ArchivesListView';
import ListItem from 'components/archives/ListItem';
import PageCard from 'components/pages/PageCard';
import SubCategoryList from 'components/archives/SubCategoryList';
import SubCategoryGrid from 'components/archives/SubCategoryGrid';

import styles from 'styles/modules/Archives.module.css';

const CategoriePage = () => {
  const { categorySlug } = useParams();
  const navigate = useNavigate(); // <-- Hook pour naviguer

  const [categoryData, setCategoryData] = useState(null);
  const [items, setItems] = useState([]);
  const [subcategories, setSubcategories] = useState([]);

  const [view, setView] = useState('grid');
  const [loading, setLoading] = useState(true);

  // State local pour stocker la sous-catégorie sélectionnée (slug)
  const [selectedSubCategory, setSelectedSubCategory] = useState('');

  const {
    filteredItems,
    displayCount,
    selectedCategory,
    setSelectedCategory,
    searchQuery,
    setSearchQuery,
    loadMore,
    resetFilters,
  } = useFilter(items, 8);

  // 1er fetch : catégorie principale + pages
  useEffect(() => {
    const fetchCategoryData = async () => {
      try {
        const response = await api.get(`/pages/categories/${categorySlug}/archives`);
        const category = response.data;
        setCategoryData(category);

        const validPages = (category.pages || []).filter(
          (page) => page && page.id && page.slug
        );
        setItems(validPages);
      } catch (error) {
        console.error('Erreur lors de la récupération des données de la catégorie:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCategoryData();
  }, [categorySlug]);

  // 2e fetch : sous-catégories
  useEffect(() => {
    const fetchSubcategories = async () => {
      try {
        const response = await api.get(`/pages/categories/${categorySlug}/subcategories`);
        setSubcategories(response.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des sous-catégories :', error);
      }
    };

    fetchSubcategories();
  }, [categorySlug]);

  // Quand l'utilisateur change la sélection dans le dropdown
  const handleSubCategoryChange = (newSlug) => {
    setSelectedSubCategory(newSlug);

    // Si l'utilisateur a choisi une sous-catégorie non vide,
    // on navigue vers /categorie/<slug>
    if (newSlug) {
      navigate(`/categorie/${newSlug}`);
    }
  };

  // Handlers pour les filtres
  const handleFilterChange = (subCategorySlug) => setSelectedCategory(subCategorySlug);
  const handleSearchChange = (e) => setSearchQuery(e.target.value);

  if (loading) return <div><Spinner /></div>;
  if (!categoryData) return <div>Catégorie non trouvée.</div>;

  return (
    <>
      <Helmet>
        <title>Arras | {categoryData.name}</title>
        <meta name="description" content={categoryData.description ? categoryData.description : `Découvrez la catégorie ${categoryData.name} sur Arras.fr et trouvez des informations utiles.`} />
        <meta property="og:title" content={`Arras | ${categoryData.name}`} />
        <meta property="og:description" content={categoryData.description ? categoryData.description : `Découvrez la catégorie ${categoryData.name} sur Arras.fr et trouvez des informations utiles.`} />
        <meta property="og:image" content={`${process.env.REACT_APP_MEDIA_BASE_URL}${categoryData.banner.path}`} />
        <meta property="og:image:alt" content={`Image descriptive pour la page ${categoryData.name}`} />
        <link rel="canonical" href={`https://www.arras.fr/${categorySlug}`} />
      </Helmet>

      <div className={styles.pageWrapper}>
        <ArchivesHeader
          title={categoryData.name}
          imageUrl={`${process.env.REACT_APP_MEDIA_BASE_URL}${categoryData.banner.path}`}
          parent={categoryData.parent}
          pageType="isCategory"
          description={categoryData.description}
        />

        <div className={`page-content ${styles.pageContent}`}>
          <section className={`global-section ${styles.headerSection}`}>
            <div className={`global-container ${styles.offsetHeader}`}>
              <ArchivesFilters
                categories={categoryData.children}
                selectedCategory={selectedCategory}
                handleFilterChange={handleFilterChange}
                searchQuery={searchQuery}
                handleSearchChange={handleSearchChange}
                resetFilters={resetFilters}
              />
            </div>
          </section>


          {/* Section d'affichage des pages */}
          <section className={`global-section ${styles.contentSection}`}>
            <div className="global-container">

              {/* Sélecteur de vue (grille / liste) */}
              <ToggleView view={view} setView={setView} />

              {filteredItems && filteredItems.length > 0 ? (
                view === 'grid' ? (
                  <ArchivesGridView
                    items={filteredItems}
                    renderItem={(page) => (
                      <PageCard
                        key={page.id}
                        page={page}
                        categorySlug={categorySlug}
                      />
                    )}
                    displayCount={displayCount}
                    categorySlug={categorySlug} // <-- Passer le slug au sous-component
                  />
                ) : (
                  <ArchivesListView
                    items={filteredItems}
                    renderItem={(page) => (
                      <ListItem
                        key={page.id}
                        title={page.title}
                        meta={[
                          page.createdAt
                            ? `Publié le ${new Date(page.createdAt).toLocaleDateString()}`
                            : null,
                        ]}
                        description={page.excerpt || 'Aucune description disponible.'}
                        categories={page.categories}
                        link={`/page/${page.slug}`}
                      />
                    )}
                    displayCount={displayCount}
                    categorySlug={categorySlug} // <-- Passer le slug au sous-component
                  />
                )
              ) : subcategories.length > 0 ? (
                // Afficher les sous-catégories uniquement si elles existent
                <>
                  {view === 'grid' ? (
                    <ul className={`list--none ${styles.grid}`}>
                    <SubCategoryGrid subcategories={subcategories}/>
                    </ul>
                  ) : (
                    <ul className={`list--none global-block ${styles.list}`}>
                    <SubCategoryList subcategories={subcategories} />
                    </ul>
                  )}
                </>
              ) : (
                <div>Aucun contenu disponible pour cette catégorie.</div>
              )}

              {displayCount < filteredItems.length && (
                <LoadMoreButton onClick={loadMore}>Charger plus</LoadMoreButton>
              )}
            </div>
          </section>

          <MapCta />
          <LiensPratiques />
        </div>
      </div>
    </>
  );
};

export default CategoriePage;
