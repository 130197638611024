import React, { useState, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import api from 'services/api';
import SearchBar from 'components/search/SearchBar';
import Card from 'components/common/Card';
import ToggleView from 'components/archives/ToggleView';
import ArchivesGridView from 'components/archives/ArchivesGridView';
import ArchivesListView from 'components/archives/ArchivesListView';
import MapCta from 'components/common/MapCta';
import LiensPratiques from 'components/common/LiensPratiques';
import styles from './SearchResults.module.css';
import ListItem from 'components/archives/ListItem';
import { useSubSite } from 'contexts/SubSiteContext';
import useDebounce from 'hooks/useDebounce';
import { Helmet } from 'react-helmet';
import Spinner from 'components/common/Spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';

// Options de types pour react-select
const TYPE_OPTIONS = [
  { value: 'pages', label: 'Pages' },
  { value: 'events', label: 'Événements' },
  { value: 'kiosks', label: 'Kiosques' },
  { value: 'associations', label: 'Associations' },
  { value: 'news', label: 'Actualités' },
];

const SearchResults = () => {
  const [results, setResults] = useState({
    pages: [],
    events: [],
    kiosks: [],
    associations: [],
    news: [],
    webcams: [] // Nouvelle propriété pour les webcams
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [view, setView] = useState('grid');
  const [selectedTypes, setSelectedTypes] = useState(TYPE_OPTIONS);
  const location = useLocation();
  const navigate = useNavigate();
  const { isSubSite, subSiteSlug } = useSubSite();

  // Extraction des paramètres de l'URL
  const getQueryParams = (search) => {
    const params = new URLSearchParams(search);
    return {
      query: params.get('q') || '',
    };
  };

  const { query } = getQueryParams(location.search);
  const debouncedQuery = useDebounce(query, 500);

  // Appel API pour récupérer les résultats
  useEffect(() => {
    if (debouncedQuery.trim().length === 0) {
      setResults({
        pages: [],
        events: [],
        kiosks: [],
        associations: [],
        news: [],
        webcams: []
      });
      setLoading(false);
      setError(null);
      return;
    }
    setLoading(true);
    setError(null);
    setResults({
      pages: [],
      events: [],
      kiosks: [],
      associations: [],
      news: [],
      webcams: []
    });

    const endpoint = '/search'; // Endpoint pour les recherches non-sous-site
    api
      .get(endpoint, { params: { q: debouncedQuery } })
      .then((response) => {
        console.log('API Response:', response.data);
        if (response.data.status === 'success') {
          const data = response.data.data;
          setResults({
            pages: data.pages || [],
            events: data.events || [],
            kiosks: data.kiosks || [],
            associations: data.associations || [],
            news: data.news || [],
            webcams: data.webcams || []
          });
        } else {
          setError('Erreur lors de la récupération des résultats.');
          setResults({
            pages: [],
            events: [],
            kiosks: [],
            associations: [],
            news: [],
            webcams: []
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error('Erreur lors de la recherche:', err);
        setError(err.response ? err.response.statusText : err.message);
        setResults({
          pages: [],
          events: [],
          kiosks: [],
          associations: [],
          news: [],
          webcams: []
        });
        setLoading(false);
      });
  }, [debouncedQuery]);

  // État pour autoriser l'annulation du filtre forcé
  const [forceKioskFilterDisabled, setForceKioskFilterDisabled] = useState(false);

  // Fonction de Levenshtein pour calculer la distance d'édition entre deux chaînes
  function levenshteinDistance(a, b) {
    const m = a.length,
      n = b.length;
    const dp = Array(m + 1)
      .fill(null)
      .map(() => Array(n + 1).fill(0));
    for (let i = 0; i <= m; i++) dp[i][0] = i;
    for (let j = 0; j <= n; j++) dp[0][j] = j;
    for (let i = 1; i <= m; i++) {
      for (let j = 1; j <= n; j++) {
        dp[i][j] = Math.min(
          dp[i - 1][j] + 1,
          dp[i][j - 1] + 1,
          dp[i - 1][j - 1] + (a[i - 1] === b[j - 1] ? 0 : 1)
        );
      }
    }
    return dp[m][n];
  }

  // Détection floue pour forcer le filtre "kiosques"
  const forceKioskFilter = useMemo(() => {
    const keywords = ['arras actu', 'actu', 'arras actu n°'];
    const checkArray = (arr) =>
      arr.some((item) => {
        if (!item.title) return false;
        const titleLower = item.title.toLowerCase();
        return keywords.some((keyword) => {
          if (titleLower.includes(keyword)) return true;
          const distance = levenshteinDistance(titleLower, keyword);
          return distance <= 2;
        });
      });
    return Object.values(results).some((arr) => checkArray(arr));
  }, [results]);

  // La force est effective si forceKioskFilter est vrai ET si l'utilisateur n'a pas désactivé ce comportement
  const effectiveForceKioskFilter = forceKioskFilter && !forceKioskFilterDisabled;

  // Si le filtrage forcé est actif, on force le Select à ne contenir que "Kiosques"
  const effectiveSelectedTypes = effectiveForceKioskFilter
    ? [{ value: 'kiosks', label: 'Kiosques' }]
    : selectedTypes;

  // Gestion du toggle entre vue "grid" et "list"
  const handleViewChange = (selectedView) => setView(selectedView);

  // Gestion des changements dans le Select
  const handleTypeChange = (selectedOptions) => {
    setSelectedTypes(selectedOptions.length > 0 ? selectedOptions : TYPE_OPTIONS);
  };

  // Préparation des items filtrés
  const filteredItems = useMemo(() => {
    const items = [];

    // Ajout prioritaire des webcams si présentes
    if (results.webcams && results.webcams.length > 0) {
      results.webcams.forEach((webcam, index) => {
        items.push({
          uniqueId: `webcams-${index}`,
          slug: webcam.slug,
          type: 'webcams',
          id: webcam.id || index,
          title: webcam.title,
          date: null,
          categories: [],
          thumbnail: `https://varras.mbscom.net//uploads/images/banniere-676d3358524e9985147523.jpg`,
          link: webcam.link,
          snippet: null,
        });
      });
    }

    // Traitement des autres types en fonction de effectiveSelectedTypes
    const typesToFilter = effectiveSelectedTypes.length > 0 ? effectiveSelectedTypes : TYPE_OPTIONS;
    typesToFilter.forEach((type) => {
      const typeItems = (results[type.value] || []).map((item) => {
        switch (type.value) {
          case 'pages':
            return {
              uniqueId: `pages-${item.id}`,
              slug: item.slug,
              type: 'pages',
              id: item.id,
              title: item.title,
              date: null,
              categories: item.category ? [item.category.name] : [],
              thumbnail: item.thumbnail
                ? `${process.env.REACT_APP_MEDIA_BASE_URL}/images/${item.thumbnail}`
                : null,
              link: `/page/${item.slug || item.id}`,
              snippet: item.snippet,
              category: item.category,
            };
          case 'events':
            return {
              uniqueId: `events-${item.id}`,
              slug: item.slug,
              type: 'events',
              id: item.id,
              title: item.title,
              date:
                new Date(item.start).toLocaleDateString() +
                ' - ' +
                new Date(item.end).toLocaleDateString(),
              categories: item.category ? [item.category.name] : [],
              thumbnail: item.thumbnail
                ? `${process.env.REACT_APP_MEDIA_BASE_URL}/${item.thumbnail.path}`
                : null,
              link: `/evenement/${item.slug}`,
              snippet: item.snippet,
            };
          case 'kiosks':
            return {
              uniqueId: `kiosks-${item.id}`,
              slug: item.slug,
              type: 'kiosks',
              id: item.id,
              title: item.title || 'Kiosk',
              date: null,
              categories: [],
              thumbnail: item.poster
                ? `${process.env.REACT_APP_MEDIA_BASE_URL}/${item.poster.path}`
                : null,
              link: `/kiosque/${item.serie.slug}/${item.slug}`,
              description: item.description,
            };
          case 'associations':
            return {
              uniqueId: `associations-${item.id}`,
              slug: item.slug,
              type: 'associations',
              id: item.id,
              title: item.name,
              date: null,
              categories: [],
              thumbnail: item.logo
                ? `${process.env.REACT_APP_MEDIA_BASE_URL}/${item.logo}`
                : null,
              link: `/associations/${item.slug}`,
              description: item.description,
            };
          case 'news':
            return {
              uniqueId: `news-${item.id}`,
              slug: item.slug,
              type: 'news',
              id: item.id,
              title: item.title,
              date: new Date(item.createdAt).toLocaleDateString(),
              categories: [],
              thumbnail: item.thumbnail
                ? `${process.env.REACT_APP_MEDIA_BASE_URL}/${item.thumbnail.path}`
                : null,
              link: `/actualite/${item.slug}`,
              content: item.content,
            };
          default:
            return null;
        }
      }).filter(Boolean);
      items.push(...typeItems);
    });

    // Éliminer les doublons via uniqueId
    const uniqueItemsMap = new Map();
    items.forEach((item) => {
      if (!uniqueItemsMap.has(item.uniqueId)) {
        uniqueItemsMap.set(item.uniqueId, item);
      }
    });
    return Array.from(uniqueItemsMap.values());
  }, [results, effectiveSelectedTypes]);

  // Fonctions utilitaires pour obtenir lien, meta et description
  const getLinkByType = (item) => {
    switch (item.type) {
      case 'pages':
        return `/page/${item.slug}`;
      case 'events':
        return `/evenement/${item.slug}`;
      case 'kiosks':
        return `${item.link}`;
      case 'associations':
        return `/associations/${item.slug}`;
      case 'news':
        return `/actualite/${item.slug}`;
      case 'webcams':
        return item.link;
      default:
        return '#';
    }
  };

  const getMetaByType = (item) => {
    switch (item.type) {
      case 'pages':
        return item.category ? [`Catégorie : ${item.category.name}`] : [];
      case 'events':
        return [`Publié : ${item.date}`];
      case 'news':
        return [`Publié : ${item.date}`];
      default:
        return [];
    }
  };

  const getDescriptionByType = (item) => {
    switch (item.type) {
      case 'pages':
        return item.snippet || 'Pas de description disponible.';
      case 'events':
        return item.snippet || 'Pas de description disponible.';
      case 'kiosks':
        return item.description || 'Pas de description disponible.';
      case 'associations':
        return item.description || 'Pas de description disponible.';
      case 'news':
        return item.content && item.content.length > 200
          ? `${item.content.substring(0, 200)}...`
          : item.content || 'Pas de description disponible.';
      default:
        return 'Pas de description disponible.';
    }
  };

  const hasResults = filteredItems.length > 0;

  return (
    <>
      <Helmet>
        <title>Ville d'Arras | Résultats de recherche pour "{debouncedQuery}"</title>
        <meta name="description" content="Une description de la page pour le SEO." />
        <meta property="og:title" content="Ville d'Arras" />
        <meta property="og:description" content="Une description adaptée aux réseaux sociaux." />
        <link rel="canonical" href="https://arras.fr/" />
      </Helmet>
      <div className={styles.resultsPage}>
        {/* Barre de recherche */}
        <section className={styles.resultsHeader}>
          <div className={`global-container ${styles.headerContainer}`}>
            <h1 className={styles.resultsTitle}>
              Résultats de recherche pour "{debouncedQuery}"
            </h1>
            <SearchBar onSearch={(query) => navigate(`/recherches?q=${encodeURIComponent(query)}`)} />
            {/* Section de filtrage avec React Select */}
            <div className={styles.filterSection}>
              <p className={styles.filterLabel}>Filtrer par type :</p>
              {effectiveForceKioskFilter ? (
                <>
                  <Select
                    options={[{ value: 'kiosks', label: 'Kiosques' }]}
                    isMulti={false}
                    value={[{ value: 'kiosks', label: 'Kiosques' }]}
                    isDisabled
                    className={styles.reactSelect}
                    classNamePrefix="select"
                    placeholder="Kiosques"
                  />
                                      <span
                      className={styles.refreshIcon}
                      onClick={() => setForceKioskFilterDisabled(true)}
                      title="Annuler le filtrage forcé"
                    >
                      <FontAwesomeIcon icon={faSyncAlt} />
                    </span>
                  <p className={styles.notice}>
                    Le filtre est automatiquement défini sur "Kiosques" en raison de votre recherche.

                  </p>
                </>
              ) : (
                <Select
                  options={TYPE_OPTIONS}
                  isMulti
                  value={selectedTypes}
                  onChange={handleTypeChange}
                  className={styles.reactSelect}
                  classNamePrefix="select"
                  placeholder="Sélectionnez les types..."
                  closeMenuOnSelect={false}
                />
              )}
            </div>
          </div>
        </section>
        <div className="page-content">
          <section className={`global-section ${styles.resultsSection}`}>
            <div className="global-container">
              <ToggleView view={view} setView={handleViewChange} />
              {loading ? (
                <div className={styles.loading}><Spinner /></div>
              ) : (
                <>
                  {!hasResults && (
                    <div className={styles.noResults}>Aucun résultat</div>
                  )}
                  {hasResults && (
                    <>
                      {view === 'grid' && (
                        <ArchivesGridView
                          items={filteredItems.map(item => ({
                            id: item.uniqueId,
                            title: item.title,
                            date: item.date,
                            categories: item.categories,
                            thumbnail: item.thumbnail,
                            link: getLinkByType(item),
                          }))}
                          renderItem={(item) => (
                            <Card
                              key={item.id}
                              thumbnail={item.thumbnail}
                              title={item.title}
                              date={item.date}
                              categories={item.categories}
                              link={item.link}
                            />
                          )}
                          displayCount={10}
                        />
                      )}
                      {view === 'list' && (
                        <ArchivesListView
                          items={filteredItems.map(item => ({
                            id: item.uniqueId,
                            title: item.title,
                            meta: getMetaByType(item),
                            description: getDescriptionByType(item),
                            link: getLinkByType(item),
                            categories: item.categories,
                          }))}
                          renderItem={(item) => (
                            <ListItem
                              key={item.id}
                              title={item.title}
                              meta={item.meta}
                              description={item.description}
                              categories={item.categories}
                              link={item.link}
                            />
                          )}
                          displayCount={10}
                        />
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </section>
          <MapCta />
          <LiensPratiques />
        </div>
      </div>
    </>
  );
};

export default SearchResults;
