// src/components/info/AlloMairie.jsx
import React from 'react';
import listStyles from './ListServices.module.css';
import useAccessibility from 'hooks/useAccessibility';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';

const AlloMairie = ({ footerData }) => {
  const accessibilityVariant = useAccessibility();

  // Imaginons que footerData.phone = "0805090062"
  const phoneNumber = footerData.phone;
  const openHours = footerData.openHours;

  return (
    <div 
      className={`${listStyles.container} ${listStyles[accessibilityVariant]}`}
      aria-labelledby="allo-mairie-title"
    >      <div className={listStyles.content}>
        <div className={listStyles.block}>
        <div 
            dangerouslySetInnerHTML={{ __html: openHours ?? '' }}
          />
          <a 
            href={`tel:${phoneNumber}`} 
            aria-label={`Téléphoner à la mairie au ${phoneNumber}`}
            title="Téléphoner à la mairie" 
            className={listStyles.freePhone}
          >
            <FontAwesomeIcon icon={faPhone} className={listStyles.icon} />
            <div>
              {phoneNumber}
              <span>Service & Appel gratuits</span>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default AlloMairie;
