import React from 'react';
import PropTypes from 'prop-types';
import SubCategoryGridItem from './SubCategoryGridItem';
import styles from './SubCategory.module.css';

const SubCategoryGrid = ({
  subcategories = [],
  selectedSubCategory,
  onSubCategoryChange,
  category
}) => {
  // Fonction qui s'exécute au changement de valeur du dropdown
  const handleSelectChange = (event) => {
    const newValue = event.target.value;
    // On informe le parent de la nouvelle sélection
    onSubCategoryChange(newValue);
  };

  // Vérification si la liste des sous-catégories n'est pas vide
  if (subcategories.length === 0) {
    return null; // Ne rien afficher si aucune sous-catégorie
  }

  return (
    <>
      {/* Liste des sous-catégories */}
        {subcategories.map((subcat) => (
          <SubCategoryGridItem
            key={subcat.id}
            name={subcat.name}
            slug={subcat.slug}
            banner={subcat.banner}
            pages={subcat.pages}
          />
        ))}
    </>
  );
};

SubCategoryGrid.propTypes = {
  subcategories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      banner: PropTypes.shape({
        path: PropTypes.string,
      }),
      pages: PropTypes.array,
    })
  ),
  selectedSubCategory: PropTypes.string,
  onSubCategoryChange: PropTypes.func.isRequired,
};

export default SubCategoryGrid;
