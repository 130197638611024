import React, { useContext, useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styles from './Dot.module.css';
import catStyles from 'styles/modules/Categories.module.css';
import useAccessibility from 'hooks/useAccessibility';


const Dot = ({ key, variant, link }) => {
  const accessibilityVariant = useAccessibility();

  return (
    <Link
      to={link}
      className={`${styles.dot} ${catStyles[variant]} ${catStyles[accessibilityVariant]}`}
      aria-label="Lien vers la catégorie"
    />
  );
};

Dot.propTypes = {
  link: PropTypes.string.isRequired,               // Lien
  colorVariant: PropTypes.string,                  // Variante de couleur
  accessibilityVariant: PropTypes.string           // Variante pour l'accessibilité
};


export default Dot;
