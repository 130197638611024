// src/components/common/SocialIcons.js

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram, faLinkedin, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import api from 'services/api'; // Importer votre service API
import styles from './SocialIcons.module.css';
import useAccessibility from 'hooks/useAccessibility';

import Webcam from 'icons/webcam';

const iconMap = {
  facebook: faFacebook,
  twitter: faXTwitter,
  instagram: faInstagram,
  linkedin: faLinkedin,
};

const SocialIcons = () => { // Correction ici
  const accessibilityVariant = useAccessibility();

  const [socialLinks, setSocialLinks] = useState([]);

  useEffect(() => {
    const fetchSocialLinks = async () => {
      try {
        const response = await api.get('/general-informations/social-networks');
        setSocialLinks(response.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des réseaux sociaux:', error);
      }
    };

    fetchSocialLinks();
  }, []);

  return (
    <div className={styles.container}>
      <ul className={`list--none ${styles.socialIconsContainer}`}>
        <li key="webcam">
          <a
            href="/webcams"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.webcamLink}
            aria-label="Accès à la webcam de la ville d'Arras"
          >
            <Webcam />
          </a>
        </li>
        {socialLinks.map((link) => (
          <li key={link.name}> {/* Clé déplacée ici */}
            <a 
              href={link.url} 
              target="_blank" 
              rel="noopener noreferrer"
              className={styles.socialLink}
              aria-label={link.name}
            >
              <FontAwesomeIcon icon={iconMap[link.icon] || faFacebook} className={styles.icon} />
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

SocialIcons.propTypes = {
  // Définir vos PropTypes si nécessaire
};

export default SocialIcons;
