import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from 'styles/modules/Archives.module.css';
import SubCategoryGrid from './SubCategoryGrid';
import api from 'services/api';

const ArchivesGridView = ({ items, renderItem, displayCount, categorySlug }) => {
  const [subcategories, setSubcategories] = useState([]);

  useEffect(() => {
    const fetchSubcategories = async () => {
      try {
        const response = await api.get(`/pages/categories/${categorySlug}/subcategories`);
        setSubcategories(response.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des sous-catégories :', error);
      }
    };

    if (categorySlug) {
      fetchSubcategories();
    }
  }, [categorySlug]);

  return (
    <>
      {/* Afficher les pages liées */}
      <ul className={`list--none ${styles.grid}`}>

        {/* Afficher les sous-catégories uniquement si elles existent */}
        {subcategories.length > 0 && (
          <SubCategoryGrid subcategories={subcategories}/>
        )}

        {items.slice(0, displayCount).map((item) => renderItem(item))}
      </ul>
    </>
  );
};

ArchivesGridView.propTypes = {
  items: PropTypes.array.isRequired,
  renderItem: PropTypes.func.isRequired,
  displayCount: PropTypes.number.isRequired,
  categorySlug: PropTypes.string.isRequired, // Assure que le slug est requis
};

export default ArchivesGridView;
