import { useState, useEffect } from 'react';

const useBreakpoint = () => {
  const [isMobile, setIsMobile] = useState(false);

  const handleResize = () => {
    if (window.innerWidth < 1024) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    handleResize(); // Vérifie la taille au premier rendu
    window.addEventListener('resize', handleResize); // Écoute les changements de taille

    return () => {
      window.removeEventListener('resize', handleResize); // Nettoie l'écouteur
    };
  }, []);

  return isMobile;
};

export default useBreakpoint;
