// src/components/common/InfoCard.js

import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useAccessibility from 'hooks/useAccessibility';
import { useSubSite } from 'contexts/SubSiteContext';
import Button from 'components/common/Button';
import styles from './InfoCard.module.css';

const InfoCard = ({ icon, title, text, linkTo, buttonText, categorySlug }) => {
  const accessibilityVariant = useAccessibility();
  const { isSubSite } = useSubSite();

  const categoryClass = isSubSite ? styles.generic : styles[categorySlug];

  return (
    <div className={`${styles.card} ${styles.infoCard} ${styles[accessibilityVariant]} ${categoryClass}`}>
      <FontAwesomeIcon icon={icon} className={styles.icon} />
      <h3 className="featured">{title}</h3>
      <p>{text}</p>
      
        <Button variant="secondary">
        <Link to={linkTo}>
        {buttonText}
        </Link>
        </Button>
      
    </div>
  );
};

InfoCard.propTypes = {
  icon: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  linkTo: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  categorySlug: PropTypes.string, // Nouvelle prop pour le slug de la catégorie
};

export default InfoCard;
