import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import api from 'services/api';
import Badge from 'components/common/Badge';
import Button from 'components/common/Button';
import Summary from 'components/common/Summary';
import Breadcrumbs from 'components/common/Breadcrumbs';
import ShareButtons from 'components/common/ShareButtons';
import FileList from 'components/common/FileList';
import BannerWithSocialIcons from 'components/common/BannerWithSocialIcons';
import MapCta from 'components/common/MapCta';
import LiensPratiques from 'components/common/LiensPratiques';
import sharedStyles from 'styles/modules/ContentPage.module.css'; // Import styles partagés
import styles from './NewsPage.module.css';
import NearestNews from 'components/news/NearestNews'; // Import du composant NearestNews
import { TimeOutline, ShareSocialOutline } from 'react-ionicons';
import { useSubSite } from 'contexts/SubSiteContext';
import PictoAccess from 'components/common/PictoAccess';
import useBreakpoint from 'hooks/useBreakpoint'; // Responsive Hook
import { Helmet } from 'react-helmet';
import Spinner from 'components/common/Spinner';

const NewsPage = () => {
  const { newsSlug } = useParams();
  const [newsItem, setNewsItem] = useState(null);
  const [loading, setLoading] = useState(true);
  const [updatedContentHtml, setUpdatedContentHtml] = useState('');
  const [metaDescription, setMetaDescription] = useState('');
  const pageType = "isNews";
  const { isSubSite, subSiteSlug } = useSubSite();

  const isMobile = useBreakpoint(); // Utilisation du hook pour savoir si l'écran est mobile

  const blogURL = isSubSite ? `/${subSiteSlug}/actualite` : "/actualite";

  const getExcerpt = (htmlContent) => {
    // Créer un élément temporaire pour parser le HTML
    const tempElement = document.createElement('div');
    tempElement.innerHTML = htmlContent;

    // Extraire le texte brut du HTML
    const textContent = tempElement.textContent || tempElement.innerText || '';

    // Tronquer le texte à 160 caractères
    const excerpt = textContent.substring(0, 160).trim() + (textContent.length > 160 ? '…' : '');

    return excerpt;
  };

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await api.get(`/news/read/${newsSlug}`);
        setNewsItem(response.data[0] || null); // Utiliser le premier élément si les données sont un tableau
      } catch (error) {
        console.error("Erreur lors de la récupération de l'actualité :", error);
      } finally {
        setLoading(false);
      }
    };

    fetchNews();
  }, [newsSlug]);

  useEffect(() => {
    if (updatedContentHtml) {
      const processedHtml = renderTextWithOembed(updatedContentHtml);
      setUpdatedContentHtml(processedHtml);
    }
  }, [updatedContentHtml]);

  useEffect(() => {
    if (newsItem) {
      const contentHtml = newsItem.content.length > 0 ? newsItem.content[0].content : 'Aucune description disponible.';
      const excerpt = getExcerpt(contentHtml);

      // Mise à jour de l'élément Helmet avec l'extrait SEO
      setMetaDescription(excerpt);  // Stockez l'extrait dans l'état ou utilisez-le directement dans Helmet
    }
  }, [newsItem]);

  /**
   * Fonction qui remplace <oembed> par <iframe> pour YouTube (watch -> embed).
   * Retourne la chaîne HTML finale.
   */
  const renderTextWithOembed = (content) => {
    // 1) On parse le HTML
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, 'text/html');

    // 2) Sélectionner tous les <oembed url="...">
    const oembedElements = doc.querySelectorAll('oembed');
    oembedElements.forEach((oembed) => {
      const originalUrl = oembed.getAttribute('url');
      if (!originalUrl) return;

      try {
        const url = new URL(originalUrl);
    
        // Vérifier si c’est une URL YouTube classique
        const isYoutubeClassic =
          (url.hostname === 'www.youtube.com' || url.hostname === 'youtube.com') &&
          url.pathname === '/watch';
    
        // Vérifier si c’est une URL courte youtu.be
        const isYoutubeShort = (url.hostname === 'youtu.be');
    
        let videoId = null;
    
        if (isYoutubeClassic) {
          // Extraire le paramètre ?v=...
          videoId = url.searchParams.get('v');
        } else if (isYoutubeShort) {
          // L'ID de la vidéo est dans l'url.pathname (ex: "/IcBSp45VKvg")
          // On enlève le slash initial
          videoId = url.pathname.slice(1);
        }
    
        // Si on a bien un videoId, on construit l'iframe
        if (videoId) {
          // Construire l’URL embed
          const embedUrl = `https://www.youtube.com/embed/${videoId}?yt:stretch=16:9&vq=hd720p&loop=0&color=red&iv_load_policy=3&rel=0&showinfo=0&autohide=0&controls=1`;
    
          // Créer l’iframe
          const iframe = document.createElement('iframe');
          iframe.src = embedUrl;
          iframe.width = '560';
          iframe.height = '315';
          iframe.allow =
            'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture';
          iframe.allowFullscreen = true;
          iframe.setAttribute('frameborder', '0');
          iframe.setAttribute('allowtransparency', 'true');
    
          // Remplacer le <oembed> par l’iframe
          oembed.parentNode.replaceChild(iframe, oembed);
        } else {
          // Ici, tu peux gérer d’autres plateformes (Vimeo, Dailymotion…), 
          // ou laisser tel quel si l'URL ne correspond pas à un format géré.
        }
      } catch (err) {
        console.error('URL invalide ou problème parsing:', err);
      }
    });
    
    // 3) Retourner le HTML final (le body contient maintenant les iframes).
    return doc.body.innerHTML;
  };

  if (loading) return <div><Spinner /></div>;
  if (!newsItem) return <div>Aucune actualité trouvée.</div>;

  const {
    title,
    createdAt,
    content = [],
    categories = [],
    thumbnail,
    files = [],
    pictures = [],
    banner,
    pictoAccess,
    slug
  } = newsItem;

  const formattedDate = new Date(createdAt).toLocaleDateString();
  const contentHtml = content.length > 0 ? content[0].content : 'Aucune description disponible.';

  return (
    <>
      <Helmet>
        <title>Arras | {title}</title>
        <meta name="description" content={metaDescription || `Toutes les informations sur ${title}`} />
        <meta property="og:title" content={`Arras | ${title}`} />
        <meta property="og:description" content={metaDescription || `Toutes les informations sur ${title}`} />
        <meta property="og:image" content={thumbnail && thumbnail.path ? `${process.env.REACT_APP_MEDIA_BASE_URL}${thumbnail.path}` : 'https://arras.fr/default-thumbnail.jpg'} />
        <meta property="og:url" content={`https://www.arras.fr/actualite/${slug}`} />
        <meta property="og:type" content="article" />
        <meta property="og:site_name" content="Ville d'Arras" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`Arras | ${title}`} />
        <meta name="twitter:description" content={metaDescription || `Toutes les informations sur ${title}`} />
        <meta name="twitter:image" content={thumbnail && thumbnail.path ? `${process.env.REACT_APP_MEDIA_BASE_URL}${thumbnail.path}` : 'https://arras.fr/default-thumbnail.jpg'} />
        <link rel="canonical" href={`https://www.arras.fr/actualite/${slug}`} />
      </Helmet>

      <div className={`${sharedStyles.pageWrapper} ${sharedStyles.newsPage}`}>
        {/* Bannière avec miniature */}
        {thumbnail && thumbnail.path && (
          <BannerWithSocialIcons
            imageUrl={`${process.env.REACT_APP_MEDIA_BASE_URL}${banner.path}`}
          />
        )}

        <div className={`page-content ${sharedStyles.pageContent}`}>
          <section className={`global-section ${sharedStyles.mainSection}`}>
            <div className={`global-container ${sharedStyles.headerContainer}`}>
              <div className={sharedStyles.header}>

                <div className={sharedStyles.headingBlock}>
                  <h1>{title}</h1>
                  <Breadcrumbs pageType={pageType} element={newsItem} />
                </div>

                <div className={sharedStyles.metaContainer}>
                  <div className={sharedStyles.meta}>
                    <ShareSocialOutline
                      color="currentColor"
                      height=""
                      width=""
                      className={sharedStyles.metaIcon}
                    />
                    <p className={sharedStyles.metaInfo}>Partager :</p>
                    <ShareButtons />
                  </div>
                  <div className={sharedStyles.meta}>
                    <TimeOutline
                      color="currentColor"
                      height=""
                      width=""
                      className={sharedStyles.metaIcon}
                    />
                    <p className={sharedStyles.metaInfo}>Publié le : {formattedDate}</p>
                  </div>
                </div>
              </div>

              <ul className={`list--none ${sharedStyles.badgeList}`}>
                {categories.map((category) => (
                  <li key={category.id}>
                    <Badge label={category.name} variant={category.slug} link={`/categorie/${category.slug}`} />
                  </li>
                ))}
              </ul>
            </div>

            <div className={`global-container ${sharedStyles.main}`}>

              {/* Colonne principale */}
              <div className={sharedStyles.mainColumn}>

                {isMobile && (
                  <div className={sharedStyles.mobileSummary}>
                    <Summary contentHtml={contentHtml} setUpdatedContentHtml={setUpdatedContentHtml} />
                  </div>
                )}

                {/* Contenu de l'actualité */}
                <div
                  className={sharedStyles.content}
                  dangerouslySetInnerHTML={{ __html: updatedContentHtml }}
                />

                {/* Fichiers associés */}
                {files.length > 0 && <FileList files={files} />}

                {/* Images associées */}
                {pictures.length > 0 && (
                  <div className={sharedStyles.pictures}>
                    {pictures.map((picture) => (
                      <figure key={picture.id} className={sharedStyles.pictureItem}>
                        <img
                          src={`${process.env.REACT_APP_MEDIA_BASE_URL}${picture.path}`}
                          alt={picture.description || 'Image associée'}
                          className={sharedStyles.picture}
                        />
                        <p className={sharedStyles.credit}>Crédit : {picture.credit}</p>
                      </figure>
                    ))}
                  </div>
                )}

                <div className={sharedStyles.actions}>
                  <Link to={blogURL}>
                    <Button variant="secondary">Retour aux actualités</Button>
                  </Link>
                  <Button variant="primary" onClick={() => window.print()}>Imprimer</Button>
                </div>
              </div>

              {/* Colonne latérale droite */}
              <div className={sharedStyles.sidebar}>
                {pictoAccess && <PictoAccess iframeHtml={pictoAccess} width="250" height="120" />}

                {!isMobile && (
                  <div className={sharedStyles.sticky}>
                    <Summary contentHtml={contentHtml} setUpdatedContentHtml={setUpdatedContentHtml} />
                  </div>
                )}

              </div>
            </div>

          </section>
          <section className="global-section">
            {newsSlug && <NearestNews slug={newsSlug} />}
          </section>

          {/* CTA Map */}
          <MapCta />

          {/* Liens Pratiques */}
          <LiensPratiques />
        </div>

      </div>
    </>
  );
};

export default NewsPage;