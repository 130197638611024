// hooks/useDebounce.js
import { useState, useEffect } from 'react';

/**
 * Hook pour debouncer une valeur.
 * @param {any} value - La valeur à debouncer.
 * @param {number} delay - Le délai en millisecondes.
 * @returns {any} - La valeur debouncée.
 */
const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    // Nettoyage du timeout si la valeur change avant la fin du délai
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

export default useDebounce;
