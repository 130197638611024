// src/components/calendar/ListView.js

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import LoadMoreButton from 'components/common/LoadMoreButton';
import styles from './ListView.module.css';
import listStyles from 'components/news/NewsList.module.css';
import CategoryListArchives from 'components/common/CategoryListArchives';
import Button from 'components/common/Button';
import { AccessibilityContext } from 'contexts/AccessibilityContext';
import { useSubSite } from 'contexts/SubSiteContext';
import { Link } from 'react-router-dom';

const ListView = ({ events, onEventClick }) => {
  const EVENTS_PER_PAGE = 10;
  const { isSubSite, subSiteSlug } = useSubSite();

  // Appel des Hooks en premier
  const [visibleEventsCount, setVisibleEventsCount] = useState(EVENTS_PER_PAGE);

  // Fonction pour charger plus d'événements
  const loadMoreEvents = () => {
    setVisibleEventsCount((prevCount) => prevCount + EVENTS_PER_PAGE);
  };

  // Événements à afficher
  const displayedEvents = events.slice(0, visibleEventsCount);

  // Rendu conditionnel après les Hooks
  if (events.length === 0) {
    return <div>Aucun événement disponible.</div>;
  }

  return (
    <div className="global-container">
      <ul className={`list--none ${styles.listView}`}>
        {displayedEvents.map((event) => (
          <li className={listStyles.listItem}>
            <div className={listStyles.content}>
              <div className={listStyles.infos}>
                <h3 className={listStyles.eventTitle}>{event.title}</h3>
                <div className={listStyles.meta}>

                  {/* Category List */}
                  <ul className={`list--none ${styles.tagList}`}>
                    {event.categories?.map((category) => (
                      <li>
                        <CategoryListArchives key={category.id} variant={category.slug} name={category.name} />
                      </li>
                    ))}
                  </ul>

                  <p className={listStyles.metaItem}>
                    {event.start} {event.end && ` - ${event.end}`}
                  </p>
                  {/* Vérifiez si event.location est défini avant de l'afficher */}
                  {event.location && (
                    <p className={styles.eventLocation}>{event.location}</p>
                  )}
                </div>
              </div>
                  <Link to={isSubSite ? `/${subSiteSlug}/evenement/${event.slug}` : `/evenement/${event.slug}`}>
              <Button
                variant="primary"
                className={listStyles.cardButton}
                key={`${event.title}-${event.start}`}
              >
                Lire la suite
              </Button>
              </Link>
            </div>
          </li>
        ))}
      </ul>

      {/* Bouton "Voir plus" */}
      {visibleEventsCount < events.length && (
        <LoadMoreButton onClick={loadMoreEvents} />
      )}
    </div>
  );
};

ListView.propTypes = {
  events: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      start: PropTypes.string.isRequired,
      end: PropTypes.string,
      location: PropTypes.string,
      categories: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
          slug: PropTypes.string.isRequired,
        })
      ),
    })
  ).isRequired,
  onEventClick: PropTypes.func.isRequired,
};

export default ListView;
