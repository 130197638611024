import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './Summary.module.css';
import useAccessibility from 'hooks/useAccessibility';

const Summary = ({ contentHtml, setUpdatedContentHtml, setExcerpt }) => {
  const accessibilityVariant = useAccessibility();
  const [headings, setHeadings] = useState([]);

  useEffect(() => {
    // Parse le contenu HTML et ajoute des `id` aux titres
    const parser = new DOMParser();
    const doc = parser.parseFromString(contentHtml, 'text/html');
    const headingElements = doc.querySelectorAll('h2, h3');
    
    const headingsData = Array.from(headingElements).map((heading, index) => {
      const id = `heading-${index}`;
      heading.id = id;  // Ajout d'un `id` à chaque titre
      return {
        text: heading.innerText,
        level: heading.tagName,
        id,
      };
    });
    
    setHeadings(headingsData);
    
    // Met à jour `contentHtml` avec les `id` ajoutés et passe au composant parent
    setUpdatedContentHtml(doc.body.innerHTML);

    // Génère un extrait SEO à partir du contenu brut
    const plainText = doc.body.textContent || ''; // Récupère le texte brut
    const excerpt = plainText.substring(0, 160).trim() + (plainText.length > 160 ? '…' : '');
    
    // Transmet l'extrait SEO au parent
    if (setExcerpt) {
      setExcerpt(excerpt);
    }

  }, [contentHtml, setUpdatedContentHtml, setExcerpt]);

  // Ne pas afficher le composant si aucun titre n'est trouvé
  if (headings.length === 0) {
    return null;
  }

  return (
    <nav className={styles.summary}>
      <h3>Sommaire</h3>
      <ul>
        {headings.map((heading) => (
          <li key={heading.id} className={styles[`level${heading.level}`]}>
            <a href={`#${heading.id}`}>{heading.text}</a>
          </li>
        ))}
      </ul>
    </nav>
  );
};

Summary.propTypes = {
  contentHtml: PropTypes.string.isRequired,
  setUpdatedContentHtml: PropTypes.func.isRequired,  // Ajoute une prop pour mettre à jour `contentHtml`
  setExcerpt: PropTypes.func.isRequired,
};

export default Summary;
