// components/common/SearchBar.jsx
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import stylesMobile from './SearchBar.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import api from 'services/api'; // Service API pour les requêtes
import Spinner from 'components/common/Spinner';

const SearchBar = ({ onSearch, isMobile }) => {
  const styles = isMobile ? stylesMobile : null;
  const [query, setQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const searchRef = useRef(null);

  // Appel de l'API pour l'autocomplétion
  useEffect(() => {
    if (query.length > 2) {
      setIsLoading(true);
      api
        .get('/autocomplete', { params: { q: query } })
        .then((response) => {
          if (response.data && response.data.suggestions) {
            const { pages, events, kiosks, associations, news } = response.data.suggestions;

            // Rassembler toutes les suggestions dans un tableau unique
            const results = [
              ...pages.map((item) => ({ label: item.title, type: 'Page' })),
              ...events.map((item) => ({ label: item.title, type: 'Event' })),
              ...kiosks.map((item) => ({ label: item.title || 'Kiosk', type: 'Kiosk' })),
              ...associations.map((item) => ({ label: item.title, type: 'Association' })),
              ...news.map((item) => ({ label: item.title, type: 'News' })),
            ];
            setSuggestions(results);
          }
        })
        .catch((error) => {
          console.error('Erreur lors de la récupération des suggestions:', error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setSuggestions([]);
    }
  }, [query]);

  // Fonction pour gérer la soumission de la recherche
  const handleSearchSubmit = (e, manualQuery) => {
    if (e && e.preventDefault) e.preventDefault();
    const searchTerm = manualQuery !== undefined ? manualQuery : query.trim();

    if (searchTerm) {
      onSearch(searchTerm);
      setQuery('');
      setSuggestions([]);
    } else {
      // Optionnel : Afficher un message d'erreur ou une notification
      alert('Veuillez entrer une requête de recherche valide.');
    }
  };

  // Fonction pour gérer le clic sur une suggestion
  const handleSuggestionClick = (suggestion) => {
    setQuery(suggestion.label);
    handleSearchSubmit(null, suggestion.label);
  };

  // Gestion de la fermeture de la liste des suggestions lorsqu'on clique en dehors
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setSuggestions([]);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.searchContainer} ref={searchRef}>
      <form className={styles.searchForm} onSubmit={(e) => handleSearchSubmit(e)}>
        <button
          type="submit"
          className={styles.searchButton}
          aria-label="Lancer la recherche"
          title="Lancer la recherche"
        >
          <FontAwesomeIcon icon={faMagnifyingGlass} />
        </button>
        <input
          type="text"
          id="search"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Rechercher sur le site"
          className={styles.searchInput}
          aria-label="Champ de recherche"
        />

              {/* Liste des suggestions */}
      {suggestions.length > 0 && (
        <ul className={styles.suggestionsList}>
          {suggestions.map((suggestion, index) => (
            <li
              key={`${suggestion.type}-${index}`}
              className={styles.suggestionItem}
              onClick={() => handleSuggestionClick(suggestion)}
              role="button"
              tabIndex={0}
              onKeyPress={(e) => {
                if (e.key === 'Enter') handleSuggestionClick(suggestion);
              }}
            >
              <span className={styles.suggestionLabel}>{suggestion.label}</span>
              <span className={styles.suggestionType}>({suggestion.type})</span>
            </li>
          ))}
        </ul>
      )}
      </form>



      {isLoading && <div className={styles.loading}><Spinner /></div>}
    </div>
  );
};

SearchBar.propTypes = {
  onSearch: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
};

export default SearchBar;
