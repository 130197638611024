import React, { useEffect, useState } from 'react';
import api from 'services/api';
import KioskCard from 'components/kiosk/KioskCard';
import LoadMoreButton from 'components/common/LoadMoreButton';
import MapCta from 'components/common/MapCta';
import LiensPratiques from 'components/common/LiensPratiques';
import ArchivesHeader from 'components/archives/ArchivesHeader';
import ArchivesFilters from 'components/archives/ArchivesFilters';
import ToggleView from 'components/archives/ToggleView';
import ArchivesGridView from 'components/archives/ArchivesGridView';
import ArchivesListView from 'components/archives/ArchivesListView';
import useFilter from 'hooks/useFilter';
import styles from 'styles/modules/Archives.module.css';
import ListItem from 'components/archives/ListItem';
import useDesignSettingsData from 'hooks/useDesignSettingsData';
import { Helmet } from 'react-helmet';
import Spinner from 'components/common/Spinner';

const KioskPage = () => {
  const [kioskData, setKioskData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [view, setView] = useState('grid');
  const { designSettings, settingsLoading, error } = useDesignSettingsData();

  const {
    filteredItems,
    displayCount,
    searchQuery,
    setSearchQuery,
    loadMore,
    resetFilters,
  } = useFilter(kioskData);

  useEffect(() => {
    const fetchKioskData = async () => {
      try {
        const response = await api.get('/kiosk/');
        setKioskData(response.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des données du kiosque:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchKioskData();
  }, []);

  const handleSearchChange = (e) => setSearchQuery(e.target.value);

    // Vérifications de chargement et d'erreur après l'appel des hooks
    if (settingsLoading) return <div><Spinner /></div>;
    if (error) return <div>Erreur : {error}</div>;

  return (
    <>
              <Helmet>
                <title>Arras | Le Kiosque</title>
                <meta name="description" content="Découvrez toutes les publications municipales d'Arras : magazines, brochures et informations officielles. Accédez à nos contenus numériques et restez informé sur les actualités locales, événements et initiatives de la ville. Retrouvez ici le kiosque numérique de la ville d'Arras." />
                <meta property="og:title" content="Arras | Le Kiosque" />
                <meta property="og:description" content="Bienvenue sur le kiosque numérique d'Arras ! Explorez toutes les publications municipales : magazines, bulletins et plus encore. Restez connecté à l'actualité locale et découvrez nos ressources pour mieux vivre à Arras." />
                <meta property="og:image" content={`${designSettings.defaultKioskBanner}`} />
        <meta property="og:image:alt" content={`Image descriptive pour la page Le Kiosque`} />
                <link rel="canonical" href={`https://www.arras.fr/kiosque`} />
              </Helmet>
    <div className={styles.pageWrapper}>
      <ArchivesHeader
        title="Le Kiosque"
        imageUrl={`${designSettings.defaultKioskBanner}`}
        />
      <div className={`page-content ${styles.pageContent}`}>
        {/* Filters and Header */}
        <section className={`global-section ${styles.headerSection}`}>
          <div className={`global-container ${styles.offsetHeader}`}>
            <ArchivesFilters
              categories={[]}
              selectedCategory={null}
              handleFilterChange={null}
              searchQuery={searchQuery}
              handleSearchChange={handleSearchChange}
              resetFilters={resetFilters}
            />
          </div>
        </section>

        <section className={`global-section ${styles.contentSection}`}>
          <div className="global-container">
            <ToggleView view={view} setView={setView} />
            {loading ? (
              <div><Spinner /></div>
            ) : filteredItems && filteredItems.length > 0 ? (
              view === 'grid' ? (
                <ArchivesGridView
                  items={filteredItems}
                  renderItem={(kiosk) => <KioskCard key={kiosk.id} kiosk={kiosk} />}
                  displayCount={displayCount}
                />
              ) : (
                <ArchivesListView
                  items={filteredItems}
                  renderItem={(kiosk) => (
                    <ListItem
                      key={kiosk.id}
                      title={kiosk.title}
                      meta={[
                        kiosk.publishedAt
                          ? `Publié le ${new Date(kiosk.publishedAt).toLocaleDateString()}`
                          : null,
                      ]}
                      description={kiosk.description}
                      link={`/kiosque/${kiosk.serie.slug}/${kiosk.slug}`}
                    />
                  )}
                  displayCount={displayCount}
                />
              )
            ) : (
              <p>Aucune publication trouvée.</p>
            )}
            {displayCount < filteredItems.length && (
              <LoadMoreButton onClick={loadMore}>Charger plus</LoadMoreButton>
            )}
          </div>
        </section>
        <MapCta />
        <LiensPratiques />
      </div>
    </div>
    </>
  );
};

export default KioskPage;
