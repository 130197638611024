// ColorAccessibilitySettings.jsx
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styles from './ColorAccessibilitySettings.module.css';
import sidebarStyles from 'components/settings/AccessibilitySidebar.module.css';
import { useSubSite } from 'contexts/SubSiteContext'; // Hook pour vérifier le sous-site

const ColorAccessibilitySettings = ({ selectedOption, onChange }) => {

  const { isSubSite } = useSubSite();

  return (
    <div className={`${sidebarStyles.grid} ${styles.colorSettings}`}>
      <label
        className={`${sidebarStyles.gridItem} ${selectedOption === 'standard' ? sidebarStyles.selected : ''
          }`}
      >
        <input
          type="radio"
          name="colorAccessibility"
          value="standard"
          checked={selectedOption === 'standard'}
          onChange={() => onChange('standard')}
        />
        <span className={sidebarStyles.gridHeading}>Par défaut (thème clair)</span>

        {isSubSite ? (
          <ul className={`list--none ${styles.colors}`}>
            <li className={styles.secondaryUltraLight}></li>
            <li className={styles.secondaryLight}></li>
            <li className={styles.secondary}></li>
            <li className={styles.secondaryDark}></li>
            <li className={styles.secondaryUltraDark}></li>
          </ul>
        ) : (
          <ul className={`list--none ${styles.colors}`}>
            <li className={styles.primary}></li>
            <li className={styles.secondary}></li>
            <li className={styles.jeunesse}></li>
            <li className={styles.seniors}></li>
            <li className={styles.mobilite}></li>
            <li className={styles.events}></li>
            <li className={styles.collectivite}></li>
            <li className={styles.inclusive}></li>
            <li className={styles.urgence}></li>
            <li className={styles.carte}></li>
            <li className={styles.partenaires}></li>
            <li className={styles.contact}></li>
            <li className={styles.pinned}></li>
          </ul>
        )}

        Thème couleur par défaut du site.
      </label>
      <label
        className={`${sidebarStyles.gridItem} ${selectedOption === 'blackTheme' ? sidebarStyles.selected : ''
          }`}
      >
        <input
          type="radio"
          name="colorAccessibility"
          value="blackTheme"
          checked={selectedOption === 'blackTheme'}
          onChange={() => onChange('blackTheme')}
        />
        <span className={sidebarStyles.gridHeading}>Contrasté (thème sombre)</span>

        <ul className={`list--none ${styles.colors}`}>
          <li className={styles.secondaryLight}></li>
          <li className={styles.secondary}></li>
          <li className={styles.secondaryDark}></li>
          <li className={styles.secondaryUltraDark}></li>
          <li className={styles.neutral}></li>
        </ul>
        Thème contrasté facilitant la lecture.
      </label>
      <label
        className={`${sidebarStyles.gridItem} ${selectedOption === 'redGreenBlindness' ? sidebarStyles.selected : ''
          }`}
      >
        <input
          type="radio"
          name="colorAccessibility"
          value="redGreenBlindness"
          checked={selectedOption === 'redGreenBlindness'}
          onChange={() => onChange('redGreenBlindness')}
        />
        <span className={sidebarStyles.gridHeading}>Daltonisme rouge-vert</span>

        {isSubSite ? (

          <ul className={`list--none ${styles.colors}`}>
            <li className={styles.rgSecondaryUltraLight}></li>
            <li className={styles.rgSecondaryLight}></li>
            <li className={styles.rgSecondary}></li>
            <li className={styles.rgSecondaryDark}></li>
            <li className={styles.rgSecondaryUltraDark}></li>
          </ul>

        ) : (

          <ul className={`list--none ${styles.colors}`}>
            <li className={styles.rgPrimary}></li>
            <li className={styles.rgSecondary}></li>
            <li className={styles.rgJeunesse}></li>
            <li className={styles.rgSeniors}></li>
            <li className={styles.rgMobilite}></li>
            <li className={styles.rgEvents}></li>
            <li className={styles.rgCollectivite}></li>
            <li className={styles.rgInclusive}></li>
            <li className={styles.rgUrgence}></li>
            <li className={styles.rgCarte}></li>
            <li className={styles.rgPartenaires}></li>
            <li className={styles.rgContact}></li>
            <li className={styles.rgPinned}></li>
          </ul>

        )}


        Couleurs adaptées aux personnes ayant des difficultés à percevoir le rouge et le vert.
      </label>
      <label
        className={`${sidebarStyles.gridItem} ${selectedOption === 'blueYellowBlindness' ? sidebarStyles.selected : ''
          }`}
      >
        <input
          type="radio"
          name="colorAccessibility"
          value="blueYellowBlindness"
          checked={selectedOption === 'blueYellowBlindness'}
          onChange={() => onChange('blueYellowBlindness')}
        />
        <span className={sidebarStyles.gridHeading}>Daltonisme bleu-jaune</span>

        {isSubSite ? (

          <ul className={`list--none ${styles.colors}`}>
            <li className={styles.bySecondaryUltraLight}></li>
            <li className={styles.bySecondaryLight}></li>
            <li className={styles.bySecondary}></li>
            <li className={styles.bySecondaryDark}></li>
            <li className={styles.bySecondaryUltraDark}></li>
          </ul>

        ) : (

          <ul className={`list--none ${styles.colors}`}>
            <li className={styles.byPrimary}></li>
            <li className={styles.bySecondary}></li>
            <li className={styles.byJeunesse}></li>
            <li className={styles.bySeniors}></li>
            <li className={styles.byMobilite}></li>
            <li className={styles.byEvents}></li>
            <li className={styles.byCollectivite}></li>
            <li className={styles.byInclusive}></li>
            <li className={styles.byUrgence}></li>
            <li className={styles.byCarte}></li>
            <li className={styles.byPartenaires}></li>
            <li className={styles.byContact}></li>
            <li className={styles.byPinned}></li>
          </ul>

        )}


        Couleurs adaptées aux personnes ayant des difficultés à percevoir le bleu et le jaune.
      </label>
      <label
        className={`${sidebarStyles.gridItem} ${selectedOption === 'achromatopsia' ? sidebarStyles.selected : ''
          }`}
      >
        <input
          type="radio"
          name="colorAccessibility"
          value="achromatopsia"
          checked={selectedOption === 'achromatopsia'}
          onChange={() => onChange('achromatopsia')}
        />
        <span className={sidebarStyles.gridHeading}>Daltonisme noir-blanc</span>

        {isSubSite ? (

          <ul className={`list--none ${styles.colors}`}>
            <li className={styles.bwSecondaryUltraLight}></li>
            <li className={styles.bwSecondaryLight}></li>
            <li className={styles.bwSecondary}></li>
            <li className={styles.bwSecondaryDark}></li>
            <li className={styles.bwSecondaryUltraDark}></li>
          </ul>

        ) : (

          <ul className={`list--none ${styles.colors}`}>
            <li className={styles.bwPrimary}></li>
            <li className={styles.bwSecondary}></li>
            <li className={styles.bwJeunesse}></li>
            <li className={styles.bwSeniors}></li>
            <li className={styles.bwMobilite}></li>
            <li className={styles.bwEvents}></li>
            <li className={styles.bwCollectivite}></li>
            <li className={styles.bwInclusive}></li>
            <li className={styles.bwUrgence}></li>
            <li className={styles.bwCarte}></li>
            <li className={styles.bwPartenaires}></li>
            <li className={styles.bwContact}></li>
            <li className={styles.bwPinned}></li>
          </ul>

        )}

        Couleurs adaptées aux personnes ayant des difficultés à percevoir les couleurs.
      </label>
    </div >
  );
};

ColorAccessibilitySettings.propTypes = {
  selectedOption: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ColorAccessibilitySettings;
