// Header.jsx
import React, { useState, useRef, useContext, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import api from 'services/api'; // Import du service API

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AccessibilityOutline } from 'react-ionicons';
import { PersonOutline } from 'react-ionicons';
import { faMagnifyingGlass, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import BurgerMenu from '../navigation/BurgerMenu';
import Sidebar from '../navigation/Sidebar';
import SubSidebar from '../navigation/SubSidebar';
import AccessibilitySidebar from 'components/settings/AccessibilitySidebar';
import ProfileSidebar from 'components/settings/ProfileSidebar';
import buttons from 'components/common/Button.module.css';
import stylesDesktop from './Header.module.css';
import stylesMobile from './HeaderMobile.module.css';
import ProfileButton from 'components/common/ProfileButton';
import useAccessibility from 'hooks/useAccessibility';
import useProfile from 'hooks/useProfile';
import { ProfileSidebarContext } from 'contexts/ProfileSidebarContext';
import { AccessibilitySidebarContext } from 'contexts/AccessibilitySidebarContext';
import { useSubSite } from 'contexts/SubSiteContext'; // Hook pour vérifier le sous-site
import useBreakpoint from 'hooks/useBreakpoint';

const Header = (variant) => {
  const isMobile = useBreakpoint();
  const accessibilityVariant = useAccessibility();

  // États pour les sidebars
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [isSubSidebarOpen, setSubSidebarOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  // États pour la recherche et l'autocomplétion
  const [query, setQuery] = useState('');
  const [isMobileSearchOpen, setMobileSearchOpen] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const menuRef = useRef(null);

  // Profil actif
  const { profileOption } = useProfile();

  // Contextes pour les sidebars (desktop)
  const { openProfileSidebar } = useContext(ProfileSidebarContext);
  const { openAccessibilitySidebar } = useContext(AccessibilitySidebarContext);

  // États et handlers pour les sidebars mobiles
  const [isAccessibilitySidebarOpen, setAccessibilitySidebarOpen] = useState(false);
  const [isProfileSidebarOpen, setProfileSidebarOpen] = useState(false);

  const { isSubSite, subSiteSlug, subSiteName } = useSubSite();

  const navigate = useNavigate();

  // Appel de l'API pour l'autocomplétion
  useEffect(() => {
    if (query.length > 2) {
      setIsLoading(true);
      api
        .get('/autocomplete', { params: { q: query } })
        .then((response) => {
          if (response.data && response.data.suggestions) {
            const { pages, events, kiosks, associations, news } = response.data.suggestions;

            // Rassembler toutes les suggestions dans un tableau unique
            const results = [
              ...pages.map((item) => ({ label: item.title, type: 'Page' })),
              ...events.map((item) => ({ label: item.title, type: 'Event' })),
              ...kiosks.map((item) => ({ label: item.title || 'Kiosk', type: 'Kiosk' })),
              ...associations.map((item) => ({ label: item.title, type: 'Association' })),
              ...news.map((item) => ({ label: item.title, type: 'News' })),
            ];
            setSuggestions(results);
          }
        })
        .catch((error) => {
          console.error('Erreur lors de la récupération des suggestions:', error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setSuggestions([]);
    }
  }, [query]);

  // Fonction pour gérer la recherche
  const handleSearchSubmit = (e, manualQuery) => {
    if (e && e.preventDefault) e.preventDefault();
    const searchTerm = manualQuery !== undefined ? manualQuery : query.trim();

    if (searchTerm) {
      const searchUrl = isSubSite
        ? `/${subSiteSlug}/recherches?q=${encodeURIComponent(searchTerm)}`
        : `/recherches?q=${encodeURIComponent(searchTerm)}`;

      navigate(searchUrl);
      setQuery('');
      if (isMobile) {
        setMobileSearchOpen(false);
      }
    } else {
      // Optionnel : Afficher un message d'erreur
      alert('Veuillez entrer une requête de recherche valide.');
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setQuery(suggestion.label);
    handleSearchSubmit({ preventDefault: () => {} }, suggestion.label);
    setSuggestions([]);
  };

  // Gestion des sidebars
  useEffect(() => {
    if (isSidebarOpen || isSubSidebarOpen || isAccessibilitySidebarOpen || isProfileSidebarOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }

    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [isSidebarOpen, isSubSidebarOpen, isAccessibilitySidebarOpen, isProfileSidebarOpen]);

  const handleAccessibilityClick = () => {
    if (isMobile) {
      setAccessibilitySidebarOpen(true);
    } else {
      openAccessibilitySidebar();
    }
  };

  const handleCloseAccessibilitySidebar = () => {
    setAccessibilitySidebarOpen(false);
  };

  const handleProfileClick = () => {
    if (isMobile) {
      setProfileSidebarOpen(true);
    } else {
      openProfileSidebar();
    }
  };

  const handleCloseProfileSidebar = () => {
    setProfileSidebarOpen(false);
  };

  const handleBurgerClick = () => {
    setSidebarOpen(true);
  };

  const handleCloseAllSidebars = () => {
    setSidebarOpen(false);
    setSubSidebarOpen(false);
    setSelectedItem(null);
    if (menuRef.current) {
      menuRef.current.focus();
    }
  };

  const handleCloseSidebar = () => {
    setSidebarOpen(false);
    setSubSidebarOpen(false);
    setSelectedItem(null);
    if (menuRef.current) {
      menuRef.current.focus();
    }
  };

  const handleItemSelect = (item) => {
    setSelectedItem(item);
    setSubSidebarOpen(true);
  };

  const handleSubSidebarClose = () => {
    setSubSidebarOpen(false);
    setSelectedItem(null);
    if (menuRef.current) {
      menuRef.current.focus();
    }
  };

  const subSidebarClass = selectedItem ? selectedItem.target : '';

  // Variantes du profil actif
  const profileVariants = {
    citoyen: { label: 'Personnaliser ma navigation', icon: <PersonOutline /> },
    touriste: { label: 'Profil actif : Touriste', icon: <PersonOutline /> },
    jeune: { label: 'Profil actif : Jeune', icon: <PersonOutline /> },
    senior: { label: 'Profil actif : Senior', icon: <PersonOutline /> },
    parent: { label: 'Profil actif : Parent', icon: <PersonOutline /> },
    professionnel: { label: 'Profil actif : Professionnel', icon: <PersonOutline /> },
  };

  const { label, icon } = profileVariants[profileOption] || profileVariants.citoyen;

  // Sélection des styles en fonction du contexte
  const styles = isMobile ? stylesMobile : stylesDesktop;

  // Sélection du composant Menu en fonction du contexte
  const MenuComponent = BurgerMenu; // Utiliser BurgerMenu

  return (
    <header className={`${styles.header} ${styles[variant]} ${styles[accessibilityVariant]}`}>
      <div className={styles.headerContent}>
        <div className={styles.heading}>
          <MenuComponent onClick={handleBurgerClick} ref={menuRef} />
          <Link className={styles.brand} to="/" ariaLabel="Retour à l'accueil Ville d'Arras">
            Arras
          </Link>
          {isSubSite && (
            <div className={styles.brandContainer}>
              <FontAwesomeIcon icon={faAngleRight} />
              <Link className={styles.brandSubSite} to={`/${subSiteSlug}`} ariaLabel={`Retour sur la page ${subSiteSlug}`}>
                {subSiteName}
              </Link>
            </div>
          )}
        </div>
        {!isMobile && (
          <>
            <form
              className={styles.searchForm}
              onSubmit={handleSearchSubmit}
            >
              <button
                type="submit"
                className={styles.searchButton}
                aria-label="Lancer la recherche"
                title="Lancer la recherche"
              >
                <FontAwesomeIcon icon={faMagnifyingGlass} />
              </button>
              <input
                type="text"
                id="search"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                placeholder="Rechercher sur le site"
                className={styles.searchInput}
                aria-label="Champ de recherche"
              />
               {suggestions.length > 0 && (
              <ul className={styles.suggestionsList}>
                {suggestions.map((suggestion, index) => (
                  <li
                    key={`${suggestion.type}-${index}`}
                    className={styles.suggestionItem}
                    onClick={() => handleSuggestionClick(suggestion)}
                    role="button"
                    tabIndex={0}
                    onKeyPress={() => handleSuggestionClick(suggestion)}
                  >
                    <span className={styles.suggestionLabel}>{suggestion.label}</span>
                    <span className={styles.suggestionType}>({suggestion.type})</span>
                  </li>
                ))}
              </ul>
            )}
            </form>

           

            {/* Bouton d'accessibilité */}
            <button
              className={`${buttons.primary} ${buttons.btnOutline} ${styles.accessButton}`}
              onClick={handleAccessibilityClick}
              aria-label="Paramètres d'accessibilité"
              title="Paramètres d'accessibilité"
            >
              <AccessibilityOutline
                height=""
                width=""
                color="currentColor"
                className={`${styles.icon} ${styles.accessibilityIcon}`}
              />
              <span className={styles.buttonLabel}>Accessibilité</span>
            </button>

            {/* Bouton de personnalisation de profil */}
            {!isSubSite && (
              <ProfileButton
                onClick={handleProfileClick}
                ariaLabel={label}
                title={label}
                variant={profileOption || 'citoyen'}
              >
                <PersonOutline
                  height=""
                  width=""
                  color="currentColor"
                  className={`${styles.icon} ${styles.customizeIcon}`}
                />
                <span className={styles.buttonLabel}>{label}</span>
              </ProfileButton>
            )}
          </>
        )}
      </div>

      {/* Sidebar principale */}
      <Sidebar
        isOpen={isSidebarOpen}
        onClose={handleCloseSidebar}
        onItemSelect={handleItemSelect}
        selectedItem={selectedItem}
        onCloseAllSidebars={handleCloseAllSidebars}
      />

      {/* SubSidebar pour les sous-éléments */}
      <SubSidebar
        isOpen={isSubSidebarOpen}
        onClose={handleSubSidebarClose}
        item={selectedItem}
        subSidebarClass={subSidebarClass}
        onCloseAllSidebars={handleCloseAllSidebars}
      />

      {/* Sidebars spécifiques au mobile */}
      {isMobile && isAccessibilitySidebarOpen && (
        <AccessibilitySidebar
          onClose={handleCloseAccessibilitySidebar}
          onSave={(options) => console.log("Options d'accessibilité enregistrées:", options)}
        />
      )}

      {isMobile && isProfileSidebarOpen && !isSubSite && (
        <ProfileSidebar
          onClose={handleCloseProfileSidebar}
          onSave={(options) => console.log("Options de profil enregistrées:", options)}
        />
      )}
    </header>
  );
};

export default Header;
