// AccessibilitySidebar.jsx
import React, { useContext } from 'react';
import { AccessibilityOutline, AddOutline, RemoveOutline } from 'react-ionicons';
import ColorAccessibilitySettings from './Accessibility/ColorAccessibilitySettings';
import buttons from 'components/common/Button.module.css';
import styles from './AccessibilitySidebar.module.css';
import { AccessibilitySidebarContext } from 'contexts/AccessibilitySidebarContext';
import { AccessibilityContext } from 'contexts/AccessibilityContext';

const AccessibilitySidebar = ({ isOpen, onClose, onSave }) => {
  // Récupération du context pour la version desktop
  const {
    isAccessibilitySidebarOpen: contextIsOpen,
    closeAccessibilitySidebar,
  } = useContext(AccessibilitySidebarContext);

  // Récupération de l'état d’accessibilité
  const {
    selectedOption,
    updateAccessibilityOption,
    zoomLevel,
    updateZoomLevel,
    animationsEnabled,
    updateAnimationsEnabled
  } = useContext(AccessibilityContext);

  /**
   * S’il y a une prop `isOpen`, on l’utilise ; sinon, on retombe sur la valeur du context.
   * Idem pour `onClose`: si on n’a pas la prop, on utilise `closeAccessibilitySidebar`.
   */
  const finalIsOpen = typeof isOpen !== 'undefined' ? isOpen : contextIsOpen;
  const finalOnClose = onClose || closeAccessibilitySidebar;

  // Bouton "Valider mes paramètres"
  const handleValidate = () => {
    if (onSave) {
      onSave({
        selectedOption,
        zoomLevel,
        animationsEnabled,
      });
    }
    finalOnClose();
  };

  // Bouton "Réinitialiser mes paramètres"
  const handleReset = () => {
    updateAccessibilityOption('standard');
    updateZoomLevel(100);
    updateAnimationsEnabled(true);
    localStorage.removeItem('selectedAccessibilityOption');
    localStorage.removeItem('zoomLevel');
    localStorage.setItem('animationsEnabled', JSON.stringify(true));

    finalOnClose();
  };

  return (
    // La div globale reste toujours dans le DOM,
    // et on applique différentes classes selon que c'est "ouvert" ou "fermé"
    <div className={styles.container}>
      {/* Sidebar (avec animation) */}
      <div
        className={
          finalIsOpen
            ? `${styles.sidebar} ${styles.sidebarOpen}`
            : styles.sidebar
        }
      >
        <div className={styles.closeContainer}>
          <div className={styles.headingContainer}>
            <AccessibilityOutline
              height=""
              width=""
              color="currentColor"
              className={`${styles.icon} ${styles.accessibilityIcon}`}
            />
            <span className={styles.heading}>Accessibilité</span>
          </div>
          <button
            className={styles.closeButton}
            onClick={finalOnClose}
            aria-label="Fermer le menu"
          >
            <div className={styles.lineContainer}>
              <span className={styles.line}></span>
              <span className={styles.line}></span>
            </div>
            Fermer
          </button>
        </div>

        <div className={styles.content}>
          Ajustez les paramètres d'accessibilité selon vos besoins.

          {/* Contrôles de zoom */}
          <div className={`${styles.categoryContainer} ${styles.zoomContainer}`}>
            <div className={styles.headingCategoryContainer}>
              <span className={styles.headingCategory}>Zoom</span>
              <div className={styles.textContainer}>
                Actuellement : <span className={styles.percent}>{zoomLevel}%</span>
              </div>
            </div>
            <div className={styles.adjustContainer}>
              <button
                aria-label="Augmenter la taille du texte"
                title="Augmenter la taille du texte"
                onClick={() => updateZoomLevel(zoomLevel + 10)}
              >
                <AddOutline
                  color="currentColor"
                  height=""
                  width=""
                  className={styles.zoomIcon}
                />
              </button>
              <button
                aria-label="Diminuer la taille du texte"
                title="Diminuer la taille du texte"
                onClick={() => updateZoomLevel(zoomLevel - 10)}
              >
                <RemoveOutline
                  color="currentColor"
                  height=""
                  width=""
                  className={styles.zoomIcon}
                />
              </button>
            </div>
          </div>

          {/* Choix du profil de couleurs */}
          <div className={`${styles.categoryContainer} ${styles.colorsContainer}`}>
            <span className={styles.headingCategory}>Profil de couleur</span>
            <ColorAccessibilitySettings
              selectedOption={selectedOption}
              onChange={updateAccessibilityOption}
            />
          </div>

          {/* (Dés)activation des animations */}
          <div className={`${styles.categoryContainer} ${styles.animationsContainer}`}>
            <span className={styles.headingCategory}>Animations</span>
            <p>
              Activer ou désactiver les animations du site pour rendre plus
              évident les éléments interactibles.
            </p>
            <div className={`list--none ${styles.textContainer} ${styles.toggleContainer}`}>
              <label className={styles.switchLabel}>
                <input
                  type="checkbox"
                  checked={animationsEnabled}
                  onChange={(e) => updateAnimationsEnabled(e.target.checked)}
                  className={styles.switchInput}
                  aria-label="Bascule les animations"
                />
                <span className={styles.switchSlider}></span>
                <span className={styles.switchText}>
                  {animationsEnabled ? 'Activées (par défaut)' : 'Désactivées'}
                </span>
              </label>
            </div>
          </div>


        </div>
        {/* Boutons valider / réinitialiser */}
        <div className={styles.buttons}>
          <button
            className={`${buttons.primary} ${styles.saveButton}`}
            onClick={handleValidate}
            aria-label="Valider mes paramètres d'accessibilité"
          >
            Valider mes paramètres d'accessibilité
          </button>
          <button
            className={styles.resetButton}
            onClick={handleReset}
            aria-label="Réinitialiser mes paramètres"
          >
            Réinitialiser mes paramètres
          </button>
        </div>
      </div>

      {/* Overlay (avec animation de fade-in) */}
      <div
        className={
          finalIsOpen
            ? `${styles.overlay} ${styles.overlayOpen}`
            : styles.overlay
        }
        onClick={finalOnClose}
        aria-label="Fermer la sidebar"
      />
    </div>
  );
};

export default AccessibilitySidebar;
