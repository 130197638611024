import React, { useContext, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { AccessibilityContext } from 'contexts/AccessibilityContext';
import HeroSection from 'components/home/subsite/SubsiteHeroSection';
import MapCta from 'components/common/MapCta';
import EventList from 'components/home/EventList';
import KiosqueHome from 'components/home/KiosqueHome';
import LiensPratiques from 'components/common/LiensPratiques';
import LiveInfo from 'components/common/LiveInfo';
import styles from '../Home.module.css';
import substyles from './SubSiteHome.module.css';
import NewsList from 'components/home/NewsList';
import AboutSection from 'components/common/subsite/aboutSection';
import { useSubSite } from 'contexts/SubSiteContext'; // Hook pour vérifier le sous-site
import useDocumentTitle from 'hooks/useDocumentTitle'
import { sub } from 'date-fns';
import api from "services/api";
import { Helmet } from 'react-helmet';
import Spinner from 'components/common/Spinner';

const SubSiteHome = () => {
  const { subSiteSlug } = useParams(); // Récupère le paramètre de la route
  const navigate = useNavigate(); // Permet la redirection
  const { getAccessibilityVariant } = useContext(AccessibilityContext);
  const [subSiteName, setSiteName] = useState(null); // Récupère le nom du sous-site
  const [isValidSubSite, setIsValidSubSite] = useState(null); // État pour vérifier la validité du sous-site

  // Vérifie si le sous-site est valide
  useEffect(() => {
    const fetchSubSites = async () => {
      try {
        const response = await api.get('/subsites/slugs'); // Appel à l'API pour récupérer les slugs
        const subSites = response.data;

        if (subSites.includes(subSiteSlug)) {
          setIsValidSubSite(true);
        } else {
          setIsValidSubSite(false);
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des sous-sites:', error);
        setIsValidSubSite(false);
      }
    };
    const fetchSubSiteName = async () => {
      try {
        const response = await api.get(`/subsites/${subSiteSlug}/name`); // Appel à l'API pour récupérer le nom du sous-site
        setSiteName(response.data);
      } catch (error) {
        console.error('Erreur lors de la récupération du nom du sous-site:', error);
      }
    };

    fetchSubSiteName();
    fetchSubSites();
  }, [subSiteSlug]);


  // Redirige vers une 404 si le sous-site est invalide
  useEffect(() => {
    if (isValidSubSite === false) {
      navigate('/404', { replace: true }); // Redirection vers une page 404
    }
  }, [isValidSubSite, navigate]);

  // Affiche un loader pendant la vérification
  if (isValidSubSite === null) {
    return <div><Spinner /></div>;
  }

  return (
    <>
      <Helmet>
        <title>{subSiteName ? `${subSiteName} | Accueil` : 'Accueil'}</title>
        <meta name="description" content={`Retrouvez toutes les informations sur ${subSiteName}`} />
        <meta property="og:title" content={subSiteName ? `${subSiteName} | Accueil` : 'Accueil'} />
        <meta property="og:description" content={`Retrouvez toutes les informations sur ${subSiteName}`} />
        <link rel="canonical" href={`https://www.arras.fr/${subSiteSlug}`} />
      </Helmet>
      <main className={styles.homePage} aria-label={`Page d'accueil de ${subSiteSlug}`}>
        {/* Bandeau information */}
        <LiveInfo />
        {/* Section Hero, Recherche et Liens rapides */}
        <HeroSection />

        <div className="page-content">

          <AboutSection />

          <EventList accessibilityVariant={getAccessibilityVariant()} />

          <NewsList accessibilityVariant={getAccessibilityVariant()} />

          <MapCta />

        </div>
      </main>
    </>

  );
};

export default SubSiteHome;
