import React from "react";
import { Link } from 'react-router-dom';
import styles from './Badge.module.css';
import catStyles from 'styles/modules/Categories.module.css';
import useAccessibility from 'hooks/useAccessibility';


const Badge = ({ key, label, variant, link }) => {
  const accessibilityVariant = useAccessibility();

  return (
    <Link
      to={link}
      className={`${styles.badge} ${catStyles[variant]} ${catStyles[accessibilityVariant]}`}
      aria-label={`Voir la catégorie ${label}`}
    >
      {label}
    </Link>
  );
};

export default Badge;
