// src/components/common/Banner.js

import React from 'react';
import PropTypes from 'prop-types';
import styles from './Banner.module.css';
import useAccessibility from 'hooks/useAccessibility';

const Banner = ({ imageUrl, altText = 'Bannière' }) => {
  const accessibilityVariant = useAccessibility();


  return (
    <figure className={styles.bannerContainer}>
      <img src={imageUrl} alt={altText} className={styles.bannerImage} loading="eager" />
    </figure>
  );
};

Banner.propTypes = {
  imageUrl: PropTypes.string.isRequired,  // URL de l'image pour la bannière
  altText: PropTypes.string,              // Texte alternatif pour l'image
};

export default Banner;
