import React, { useContext, useEffect, useState } from "react";
import api from "services/api";
import NewsCard from "components/news/NewsCard";
import BlogCard from "components/news/BlogCard"; // Composant BlogCard
import { AccessibilityContext } from "contexts/AccessibilityContext";
import styles from "styles/modules/CardList.module.css";
import { faNewspaper } from "@fortawesome/free-solid-svg-icons";
import InfoCard from "components/common/InfoCard";
import { useSubSite } from 'contexts/SubSiteContext'; // Hook pour vérifier le sous-site
import Spinner from "components/common/Spinner";

const NewsList = () => {
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);
  const { getAccessibilityVariant } = useContext(AccessibilityContext);
  const [accessibilityVariant, setAccessibilityVariant] = useState(
    getAccessibilityVariant()
  );
  const { isSubSite, subSiteSlug } = useSubSite();

  const blogURL = isSubSite ? `/${subSiteSlug}/actualite` : "/actualite";

  useEffect(() => {
    const fetchNews = async () => {
      try {
        let response;
        if (isSubSite) {
          // Pour les sous-sites, on récupère les articles spécifiques
          response = await api.get(`/subsites/${subSiteSlug}/news`);
        }
        else {
          // Pour le site principal, on récupère les articles réguliers
          response = await api.get("/news");
        }
        setNews(response.data.slice(0, 3)); // Limiter à 4 articles
      } catch (error) {
        console.error("Erreur lors de la récupération des articles :", error);
      } finally {
        setLoading(false);
      }
    };

    fetchNews();
  }, []);

  useEffect(
    () => {
      setAccessibilityVariant(getAccessibilityVariant());
    },
    [getAccessibilityVariant]
  );

  if (loading) return <div><Spinner /></div>;

  return (
    <section className="global-section">
      <div className="global-container">
      <h2>Dernières Actualités</h2>
      <ul className={`list--none ${styles.grid}`}>
        {news.map(newsItem =>
          <NewsCard
            key={newsItem.id}
            newsItem={newsItem}
            accessibilityVariant={accessibilityVariant}
          />
        )}
        <InfoCard
          icon={faNewspaper}
          title="Consultez notre Blog"
          text="Explorez les articles et les actualités de notre blog pour rester informé."
          linkTo={blogURL}
          buttonText="Consulter le Blog"
          categorySlug="blog" // Slug spécifique à Blog
        />
      </ul>
      </div>
    </section>
  );
};

export default NewsList;
