// utils/iconMapping.js
import {
  AccessibilityOutline,
  HomeOutline,
  SchoolOutline,
  BriefcaseOutline,
  PeopleOutline,
  WalkOutline,
  HeartOutline,
  BusinessOutline,
  InformationCircleOutline,
} from 'react-ionicons';
import { ReactComponent as CarteInteractive } from 'icons/carte_interactive.svg';
import { ReactComponent as ServicesDemarches } from 'icons/services-et-demarches.svg';
import { ReactComponent as Mobilite } from 'icons/mobilite.svg';
import { ReactComponent as Collectivite } from 'icons/collectivite.svg';
import { ReactComponent as Evenements } from 'icons/evenements.svg';
import { ReactComponent as EnfanceJeunesse } from 'icons/enfance-et-jeunesse.svg';
import { ReactComponent as Seniors } from 'icons/seniors.svg';
import { ReactComponent as VisiterArras } from 'icons/visiter-arras.svg';
import { ReactComponent as ArrasVilleDynamique } from 'icons/ville-inclusive.svg';
import { ReactComponent as Partenaires } from 'icons/partenaires.svg';
import { ReactComponent as Urgence } from 'icons/numeros-durgence.svg';
import { ReactComponent as Contact } from 'icons/contact.svg';

// Mapping des noms de widgets aux composants d'icônes
const iconMapping = {
  'Services & démarches': ServicesDemarches,
  'Carte interactive': CarteInteractive,
  'Stationnement': WalkOutline, // Utilisation de WalkOutline comme alternative
  'Evénements': Evenements,
  'Mobilité': Mobilite,
  'Hébergement': HomeOutline,
  'Restaurants': BusinessOutline,
  'Éducation': SchoolOutline,
  'Evénements Jeunes': PeopleOutline,
  'Stages & Emplois': BriefcaseOutline,
  'Clubs & Associations': PeopleOutline,
  'Services Seniors': AccessibilityOutline,
  'Santé': HeartOutline,
  'Associations': PeopleOutline,
  'Evénements Famille': PeopleOutline,
  'Services Parents': AccessibilityOutline,
  'Commerce Local': BusinessOutline,
  'Réseautage': PeopleOutline,
  // Ajoutez d'autres mappings si nécessaire
  'Votre collectivité': Collectivite,
  'Enfance & jeunesse': EnfanceJeunesse,
  'Seniors': Seniors,
  'Visiter Arras': VisiterArras,
  'Arras ville dynamique': ArrasVilleDynamique,
  'Partenaires': Partenaires,
  "Numéros d'urgence": Urgence,
  'Contact': Contact,
};

export default iconMapping;
