import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { MatomoProvider } from "react-matomo";
import matomoInstance from "./matomo"; // Chemin vers Matomo

const rootElement = document.getElementById("root");

ReactDOM.hydrate(
  <React.StrictMode>
    <MatomoProvider value={matomoInstance}>
      <App />
    </MatomoProvider>
  </React.StrictMode>,
  rootElement
);

// Mesurer la performance
reportWebVitals();
