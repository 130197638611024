// src/components/info/ContactInfo.jsx
import React from 'react';
import styles from './ContactInfo.module.css';
import listStyles from './ListServices.module.css';
import useAccessibility from 'hooks/useAccessibility';

const ContactInfo = ({ footerData }) => {
  const accessibilityVariant = useAccessibility();

  if (!footerData) return null;

  return (
    <div 
      className={`${listStyles.container} ${listStyles[accessibilityVariant]}`} 
      aria-labelledby="contact-info-title"
    >

      <div className={listStyles.content}>
        <div className={listStyles.block}>
          {/* On injecte le HTML du champ coordonnee (si on veut des sauts de ligne, etc.) */}
          <div 
            dangerouslySetInnerHTML={{ __html: footerData.coordonnee ?? '' }}
          />

          {/* Si vous préférez juste afficher du texte :
              <p>{footerData.coordonnee}</p> */}
        </div>

        <div className={listStyles.block}>
          {/* Même chose pour openHours */}

        </div>
      </div>
    </div>
  );
};

export default ContactInfo;
