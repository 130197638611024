import React from 'react';
import { FeatureGroup } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import PointMarker from './PointMarker';

const PointsLayer = ({ points, categoryIcons, onMarkerClick }) => {
  return (
    <FeatureGroup>
      <MarkerClusterGroup>
        {points.map((point) => {
          // Construction de la clé d'icône
          let iconKey = 'default';
          if (point.subCategories) {
            iconKey = `sub_${point.subCategories.id}`;
          } else if (point.category) {
            iconKey = `cat_${point.category.id}`;
          }

          // Récupération de l'icône ou fallback "default"
          const icon = categoryIcons[iconKey] || categoryIcons['default'];

          return (
            <PointMarker
              key={point.id}
              point={point}
              icon={icon}
              onClick={onMarkerClick}
            />
          );
        })}
      </MarkerClusterGroup>
    </FeatureGroup>
  );
};

export default PointsLayer;
