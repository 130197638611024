import React from 'react';
import PropTypes from 'prop-types';
import stylesDesktop from './BurgerMenuDesktop.module.css';
import stylesMobile from './BurgerMenuMobile.module.css';
import { ReactComponent as MenuMobileIcon } from 'icons/menu_mobile.svg';
import useBreakpoint from 'hooks/useBreakpoint';

const BurgerMenu = React.forwardRef(({ onClick }, ref) => {
  const isMobile = useBreakpoint();
  const styles = isMobile ? stylesMobile : stylesDesktop;

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      onClick();  // Ouvre la sidebar avec Enter ou Espace
    }
  };

  return (
    <button
      className={styles.burgerButton}
      onClick={onClick}
      onKeyDown={handleKeyDown}    // Gestion de la touche clavier
      role="button"                // Rôle d'élément cliquable
      tabIndex="0"                 // Permet d'être focusable avec Tab
      ref={ref}                    // Référence pour le focus
      aria-label="Ouvrir le menu"   // Description pour les lecteurs d'écran
    >
      {isMobile ? (
        <>
          <MenuMobileIcon className={styles.icon} />
          <span className={styles.navSeparator}></span>
          <div className={styles.burger}>
            <span className={styles.line}></span>
            <span className={styles.line}></span>
          </div>
        </>
      ) : (
        <>
          <div className={styles.burger}>
            <span className={styles.line}></span>
            <span className={styles.line}></span>
            <span className={styles.line}></span>
          </div>
          <span>Menu</span>
        </>
      )}
    </button>
  );
});

BurgerMenu.propTypes = {
  onClick: PropTypes.func.isRequired,  // Fonction déclenchée lors du clic
};

export default BurgerMenu;
