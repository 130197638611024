import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import api from 'services/api';
import {
  MapContainer,
  TileLayer,
  LayersControl,
  GeoJSON,
  ZoomControl
} from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import '@changey/react-leaflet-markercluster/dist/styles.min.css';

import styles from 'styles/leafletmap.css';
import modalStyles from './MapModal.module.css';
import Button from 'components/common/Button';
import { CaretBackOutline } from 'react-ionicons';

import FilterControls from 'components/map/FilterControls';
import PointsLayer from 'components/map/PointsLayer';
import WorksLayer from 'components/map/WorksLayer';
import EventsLayer from 'components/map/EventsLayer';

import * as turf from '@turf/turf';

// Correction des icônes manquantes dans Leaflet
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl:
    'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
  iconUrl:
    'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
  shadowUrl:
    'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
});

const MapView = () => {
  const [points, setPoints] = useState([]);
  const [travaux, setTravaux] = useState([]);
  const [events, setEvents] = useState([]);
  const [categoriesData, setCategoriesData] = useState([]);

  // Filtres
  const [filtresCategories, setFiltresCategories] = useState([]);
  const [filtresSubCategories, setFiltresSubCategories] = useState([]);
  const [filtresDistrict, setFiltresDistrict] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  // Pour activer/désactiver l'affichage des couches événements / travaux
  const [showEvents, setShowEvents] = useState(false);
  const [showWorks, setShowWorks] = useState(false);

  // Quartiers
  const [districts, setDistricts] = useState([]);

  // Icônes, frontières, etc.
  const [categoryIcons, setCategoryIcons] = useState({});
  const [arrasBoundaries, setArrasBoundaries] = useState(null);
  const [arrasBounds, setArrasBounds] = useState(null);
  const [maskData, setMaskData] = useState(null);

  // État local pour la modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPoint, setSelectedPoint] = useState(null);

  // Pour sélectionner automatiquement la 1ère catégorie (si vous le souhaitez)
  const [hasAutoSelectedCategory, setHasAutoSelectedCategory] = useState(false);

  // *** NOUVEAU : bascule pour ignorer ou non les filtres (méthode C) ***
  const [ignoreFilters, setIgnoreFilters] = useState(false);

  // Hooks de react-router
  const location = useLocation();
  const navigate = useNavigate();

  const mediaBaseUrl = process.env.REACT_APP_MEDIA_BASE_URL;

  // URL de la couche de tuiles
  const Esri_WorldTopoMap_URL =
    'https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}';
  const Esri_WorldTopoMap_attrib = 'Tiles &copy; Esri &mdash; ...';

  // 1. Chargement des données depuis l’API
  useEffect(() => {
    // Points d'intérêt
    api.get('/map/point-of-interest')
      .then((response) => setPoints(response.data))
      .catch((error) => console.error("Erreur (points) :", error));

    // Travaux
    api.get('/map/work-in-progress')
      .then((response) => setTravaux(response.data))
      .catch((error) => console.error("Erreur (travaux) :", error));

    // Événements
    api.get('/map/events')
      .then((response) => setEvents(response.data))
      .catch((error) => console.error("Erreur (events) :", error));

    // Catégories
    api.get('/map/point-of-interest/categories')
      .then((res) => setCategoriesData(res.data))
      .catch((err) => console.error("Erreur (catégories) :", err));

    // Districts
    api.get('/map/point-of-interest/district')
      .then((res) => setDistricts(res.data))
      .catch((err) => console.error("Erreur (districts) :", err));

    // Frontières d'Arras (GeoJSON)
    fetch('/data/arras-boundaries.geojson')
      .then((r) => r.json())
      .then((data) => {
        const arrasFeature = data.features.find(
          (feature) => feature.id === 'relation/1121015'
        );
        if (!arrasFeature) {
          throw new Error("La Feature pour la ville d'Arras n'a pas été trouvée.");
        }
        if (
          arrasFeature.geometry.type !== 'Polygon' &&
          arrasFeature.geometry.type !== 'MultiPolygon'
        ) {
          throw new Error("La géométrie d'Arras n'est pas un Polygon/MultiPolygon.");
        }
        setArrasBoundaries(arrasFeature);

        // Calcul des bounds + élargissement
        const rawBounds = L.geoJSON(arrasFeature).getBounds();
        const extendedBounds = rawBounds.pad(0.5); // On élargit la zone
        setArrasBounds(extendedBounds);

        // Masque pour la zone (optionnel)
        const mask = turf.mask(arrasFeature);
        setMaskData(mask);
      })
      .catch((error) => console.error("Erreur (frontières) :", error));

    // Option : retirer le zoom natif de Leaflet
    const mapContainer = document.querySelector('.leaflet-container');
    if (mapContainer) {
      const zoomControls = mapContainer.querySelectorAll('.leaflet-control-zoom');
      zoomControls.forEach((control) => control.remove());
    }
  }, [mediaBaseUrl]);

  // 2. Construction des icônes pour chaque catégorie / sous-catégorie
  useEffect(() => {
    if (categoriesData.length > 0) {
      const icons = {};
      categoriesData.forEach((cat) => {
        const catIconUrl = cat.iconUrl
          ? `${mediaBaseUrl}/images/${cat.iconUrl}`
          : '/images/carte_dot.svg';

        icons[`cat_${cat.id}`] = L.icon({
          iconUrl: catIconUrl,
          iconSize: [30, 40],
          iconAnchor: [15, 40],
          popupAnchor: [0, -35],
          shadowUrl: '',
        });

        // Sous-catégories
        if (cat.children && cat.children.length > 0) {
          cat.children.forEach((child) => {
            const childIconUrl = child.iconUrl
              ? `${mediaBaseUrl}/images/${child.iconUrl}`
              : '/images/carte_dot.svg';

            icons[`sub_${child.id}`] = L.icon({
              iconUrl: childIconUrl,
              iconSize: [30, 40],
              iconAnchor: [15, 40],
              popupAnchor: [0, -35],
              shadowUrl: '',
            });
          });
        }
      });

      // Icône par défaut
      icons["default"] = L.icon({
        iconUrl: '/images/carte_dot.svg',
        iconSize: [30, 40],
        iconAnchor: [15, 40],
        popupAnchor: [0, -35],
        shadowUrl: '',
      });

      // Icône spéciale pour les événements
      icons["event"] = L.icon({
        iconUrl: '/images/events_dot.svg',
        iconSize: [30, 40],
        iconAnchor: [15, 40],
        popupAnchor: [0, -35],
        shadowUrl: '',
      });

      setCategoryIcons(icons);
    }
  }, [categoriesData, mediaBaseUrl]);

  // 3. Sélection auto de la 1re catégorie (facultatif)
  useEffect(() => {
    if (
      categoriesData.length > 0 &&
      !hasAutoSelectedCategory &&
      filtresCategories.length === 0 &&
      filtresSubCategories.length === 0 &&
      !filtresDistrict &&
      (!searchTerm || searchTerm.trim() === '')
    ) {
      const firstCategory = categoriesData[0];
      setFiltresCategories([firstCategory.id]);

      // Activer toutes ses sous-catégories si on le souhaite
      if (firstCategory.children && firstCategory.children.length > 0) {
        const allSubCatIds = firstCategory.children.map((sc) => sc.id);
        setFiltresSubCategories(allSubCatIds);
      }

      setHasAutoSelectedCategory(true);
    }
  }, [
    categoriesData,
    hasAutoSelectedCategory,
    filtresCategories,
    filtresSubCategories,
    filtresDistrict,
    searchTerm
  ]);

  // 4. Fermeture de la modal si ?modal=1 disparaît de l'URL
  useEffect(() => {
    const sp = new URLSearchParams(location.search);
    if (isModalOpen && !sp.has('modal')) {
      setIsModalOpen(false);
      setSelectedPoint(null);
    }
  }, [location.search, isModalOpen]);

  /**
   * Ferme la modal locale + supprime ?modal=1 dans l'URL
   */
  const closeModal = () => {
    const sp = new URLSearchParams(location.search);
    if (sp.has('modal')) {
      sp.delete('modal');
      navigate({
        pathname: location.pathname,
        search: sp.toString(),
      });
    }
    setIsModalOpen(false);
    setSelectedPoint(null);
  };

  /**
   * Ouvre la modal et ajoute ?modal=1 dans l'URL
   */
  const handleMarkerClick = (point) => {
    const sp = new URLSearchParams(location.search);
    sp.set('modal', '1');
    navigate({
      pathname: location.pathname,
      search: sp.toString(),
    });

    // On conserve la partie develop pour "S'y rendre" etc.
    setSelectedPoint(point);
    setIsModalOpen(true);
  };

  // ---------------------------------------------------------
  //        LOGIQUE DE FILTRAGE (Méthode C : ignoreFilters)
  // ---------------------------------------------------------

  // A. Mode “filtré” (ignoreFilters = false)
  const applyFilters = (pointsArray) => {
    // Si aucun filtre du tout, on retourne un tableau vide
    const noFilterSelected =
      filtresCategories.length === 0 &&
      filtresSubCategories.length === 0 &&
      !filtresDistrict &&
      (!searchTerm || searchTerm.trim() === '');

    if (noFilterSelected) {
      return [];
    }

    return pointsArray.filter((p) => {
      // Filtrage par catégorie
      const matchesCat =
        filtresCategories.length === 0 ||
        filtresCategories.includes(p.category?.id);

      // Filtrage par sous-catégorie
      const matchesSubCat =
        filtresSubCategories.length === 0 ||
        (p.subCategories &&
          filtresSubCategories.includes(p.subCategories.id));

      // Filtrage par quartier
      const matchesDistrict =
        !filtresDistrict ||
        (p.district && p.district.id === parseInt(filtresDistrict, 10));

      // Filtrage par nom (recherche)
      const matchesName = p.name
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase());

      return matchesCat && matchesSubCat && matchesDistrict && matchesName;
    });
  };

  // B. Mode “ignorer les filtres” (ignoreFilters = true)
  const applyOnlySearch = (pointsArray) => {
    // Si pas de recherche saisie, on retourne un tableau vide
    if (!searchTerm || searchTerm.trim() === '') {
      return [];
    }

    // On ne filtre que par nom
    return pointsArray.filter((p) =>
      p.name?.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  // C. Choix du mode
  let pointsFiltres = [];
  if (ignoreFilters) {
    // Mode universel (on ignore categories/district)
    pointsFiltres = applyOnlySearch(points);
  } else {
    // Mode par défaut (on applique tous les filtres)
    pointsFiltres = applyFilters(points);
  }

  // --------------------------------------------------------

  return (
    <section id="mapPage">
      <FilterControls
        categoriesData={categoriesData}
        filtresCategories={filtresCategories}
        setFiltresCategories={setFiltresCategories}
        filtresSubCategories={filtresSubCategories}
        setFiltresSubCategories={setFiltresSubCategories}
        districts={districts}
        filtresDistrict={filtresDistrict}
        setFiltresDistrict={setFiltresDistrict}
        points={points}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        showEvents={showEvents}
        setShowEvents={setShowEvents}
        showWorks={showWorks}
        setShowWorks={setShowWorks}
        // -- Bascule “Ignore Filters”
        ignoreFilters={ignoreFilters}
        setIgnoreFilters={setIgnoreFilters}
      />

      {arrasBounds && (
        <MapContainer
          center={arrasBounds.getCenter()}
          zoom={13}
          style={{ height: '100%', width: '100%' }}
          maxBounds={arrasBounds}
          maxBoundsViscosity={0.7}
          zoomControl={false}
        >
          <ZoomControl position="bottomright" />
          <TileLayer
            attribution={Esri_WorldTopoMap_attrib}
            url={Esri_WorldTopoMap_URL}
          />

          {/* Frontières d'Arras */}
          {arrasBoundaries && (
            <GeoJSON
              data={arrasBoundaries}
              style={{
                color: 'hsla(80, 100%, 47%, 1)',
                weight: 2,
                opacity: 1,
                fillOpacity: 0,
              }}
            />
          )}

          {/* Masque éventuel */}
          {maskData && (
            <GeoJSON
              data={maskData}
              style={{
                fillColor: 'hsla(0, 0, 10%, 1)',
                fillOpacity: 0.25,
                stroke: false,
              }}
            />
          )}

          <LayersControl position="topright">
            <LayersControl.Overlay name="Points d'intérêt" checked>
              <PointsLayer
                points={pointsFiltres}
                onMarkerClick={handleMarkerClick}
                categoryIcons={categoryIcons}
              />
            </LayersControl.Overlay>

            {/* Travaux */}
            {showWorks && (
              <LayersControl.Overlay name="Travaux en cours" checked>
                <WorksLayer travaux={travaux} />
              </LayersControl.Overlay>
            )}

            {/* Événements */}
            {showEvents && (
              <LayersControl.Overlay name="Événements" checked>
                <EventsLayer
                  events={events}
                  onMarkerClick={handleMarkerClick}
                  categoryIcons={categoryIcons}
                />
              </LayersControl.Overlay>
            )}
          </LayersControl>
        </MapContainer>
      )}

      {isModalOpen && selectedPoint && (
        <div
          className={modalStyles.modalOverlay}
          onClick={closeModal}
          role="dialog"
          aria-modal="true"
        >
          <div
            className={modalStyles.modalContainer}
            onClick={(e) => e.stopPropagation()}
          >
            <div className={modalStyles.modalContent}>
              {/* En-tête de la modal */}
              <div className={modalStyles.heading}>
                <h3>{selectedPoint.title || selectedPoint.name}</h3>
                <button
                  className={modalStyles.toggleContainer}
                  aria-label="Fermer la modal"
                  title="Fermer la modal"
                  onClick={closeModal}
                >
                  <CaretBackOutline
                    color="currentColor"
                    className={modalStyles.toggle}
                  />
                </button>
                {selectedPoint.address && <p>{selectedPoint.address}</p>}
                {selectedPoint.location && <p>{selectedPoint.location}</p>}
              </div>

              {/* Boutons d'action */}
              <div className={modalStyles.buttonContainer}>
                {/* On conserve la logique "develop" pour S'y rendre */}
                <Button
                  variant="map"
                  ariaLabel={`Se rendre à ${selectedPoint.title || selectedPoint.name} via google map`}
                  title={`Se rendre à ${selectedPoint.title || selectedPoint.name} via google map`}
                  onClick={() => {
                    if (selectedPoint.latitude && selectedPoint.longitude) {
                      window.open(
                        `https://www.google.com/maps/dir/?api=1&destination=${selectedPoint.latitude},${selectedPoint.longitude}`,
                        '_blank'
                      );
                    }
                  }}
                >
                  S'y rendre
                </Button>

                {selectedPoint.slug && (
                  <a
                    href={
                      selectedPoint.start
                        ? `/evenement/${selectedPoint.slug}`
                        : `/point-interet/${selectedPoint.slug}`
                    }
                    aria-label="Voir toutes les informations"
                    className={`${modalStyles.greenButton} ${modalStyles.buttonOutline}`}
                    onClick={() => closeModal()}
                  >
                    Plus d'informations
                  </a>
                )}
              </div>

              {/* Illustration */}

              {selectedPoint.picture ? (
                <figure className={modalStyles.figure}>
                  <img
                    src={`${mediaBaseUrl}/${selectedPoint.picture.path}`}
                    alt={`Visuel : ${selectedPoint.title || selectedPoint.name}`}
                  />
                </figure>
              ) : selectedPoint.thumbnail ? (
                <figure className={modalStyles.figure}>
                  <img
                    src={`${mediaBaseUrl}/${selectedPoint.thumbnail.path}`}
                    alt={`Visuel : ${selectedPoint.title || selectedPoint.name}`}
                  />
                </figure>
              ) : (
                <></>
              )}

              {/* Corps de la modal */}
              <div className={modalStyles.mapModalInfo}>
                <div className={modalStyles.mapMetaInfo}>
                  {selectedPoint.description && (
                    <p>
                      <em>{selectedPoint.description}</em>
                    </p>
                  )}
                  {selectedPoint.openingHours && (
                    <p>
                      <strong>Horaires d'ouverture :</strong>{' '}
                      {selectedPoint.openingHours}
                    </p>
                  )}
                  {selectedPoint.contactInfo && (
                    <p>
                      <strong>Contact :</strong> {selectedPoint.contactInfo}
                    </p>
                  )}
                  {selectedPoint.start && selectedPoint.end && (
                    <p>
                      <strong>Période :</strong> du {selectedPoint.start} au {selectedPoint.end}
                    </p>
                  )}
                </div>

                {selectedPoint.category && (
                  <div className={modalStyles.mapModalBlock}>
                    <h4>Catégorie :</h4>
                    <span>{selectedPoint.category.name}</span>
                  </div>
                )}

                {selectedPoint.subCategories && (
                  <div className={modalStyles.mapModalBlock}>
                    <h4>Sous-catégorie :</h4>
                    <span>{selectedPoint.subCategories.name}</span>
                  </div>
                )}

                {selectedPoint.district && (
                  <div className={modalStyles.mapModalBlock}>
                    <h4>Quartier :</h4>
                    <span>{selectedPoint.district.name}</span>
                  </div>
                )}

                {/* Événements avec “categories” */}
                {selectedPoint.categories && selectedPoint.categories.length > 0 && (
                  <div className={modalStyles.mapModalBlock}>
                    <h4>Catégorie(s) de l'événement :</h4>
                    {selectedPoint.categories.map((cat) => (
                      <span key={cat.id}>{cat.name} </span>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default MapView;
