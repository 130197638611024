// components/pages/UrgenceNumbersPage.jsx
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import api from 'services/api';
import Breadcrumbs from 'components/common/Breadcrumbs';
import BannerWithSocialIcons from 'components/common/BannerWithSocialIcons';
import sharedStyles from 'styles/modules/ContentPage.module.css';
import styles from './UrgenceNumbersPage.module.css';
import useDesignSettingsData from 'hooks/useDesignSettingsData';
import UrgenceNumbersBlock from 'components/common/UrgenceNumbersBlock';
import MapCta from 'components/common/MapCta';
import LiensPratiques from 'components/common/LiensPratiques';
import useBreakpoint from 'hooks/useBreakpoint';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Importer Font Awesome
import { faEarDeaf, faPhone } from '@fortawesome/free-solid-svg-icons'; // Importer Icone down et Phone
import { Helmet } from 'react-helmet';
import Spinner from 'components/common/Spinner';

const UrgenceNumbersPage = () => {
    const [loading, setLoading] = useState(true);
    const { designSettings, settingsLoading, error } = useDesignSettingsData();
    const isMobile = useBreakpoint();

    if (settingsLoading) return <div><Spinner /></div>;
    if (error) return <div>Erreur : {error}</div>;

    return (
        <>
                  <Helmet>
                    <title>Ville d'Arras | Numéros d'urgence</title>
                    <meta name="description" content="Retrouvez ici tous les numéros d'urgence essentiels pour agir rapidement en cas de besoin. Que ce soit pour une urgence médicale, un accident ou une situation de danger, ces contacts vous permettent d'obtenir de l'aide immédiatement." />
                    <meta property="og:title" content="Ville d'Arras" />
                    <meta property="og:description" content="Retrouvez ici tous les numéros d'urgence essentiels pour agir rapidement en cas de besoin. Que ce soit pour une urgence médicale, un accident ou une situation de danger, ces contacts vous permettent d'obtenir de l'aide immédiatement." />
                    <meta property="og:image" content={`${designSettings.defaultPageBanner}`} />
        <meta property="og:image:alt" content={`Image descriptive pour la page Numéros d'urgence`} />
                    <link rel="canonical" href="https://arras.fr/numero-d-urgence" />

                  </Helmet>
        <div className={`${sharedStyles.pageWrapper} ${sharedStyles.urgencePage}`}>
            {/* Bannière avec miniature */}
            {designSettings && designSettings.defaultPageBanner && (
                <BannerWithSocialIcons
                    imageUrl={`${designSettings.defaultPageBanner}`}
                />
            )}

            <div className={`page-content ${sharedStyles.pageContent}`}>
                <section className={`global-section ${sharedStyles.mainSection}`}>
                    <div className={`global-container ${sharedStyles.headerContainer}`}>
                        <div className={sharedStyles.header}>
                            <div className={sharedStyles.headingBlock}>
                                <h1>Numéros d'urgence</h1>
                                <Breadcrumbs title="Numéros d'urgence" />
                            </div>
                        </div>
                    </div>

                    <div className={`global-container ${styles.main}`}>
                        <div className={styles.block}>
                            <div className={styles.intro}>
                            <FontAwesomeIcon icon={faPhone} className={styles.introIcon} />
                                <div className={styles.introText}>
                                <h2>Vous faites face à une situation d'urgence ?</h2>
                                <p>Retrouvez ici tous les numéros d'urgence essentiels pour agir rapidement en cas de besoin. Que ce soit pour une urgence médicale, un accident ou une situation de danger, ces contacts vous permettent d'obtenir de l'aide immédiatement.</p>
                                </div>
                            </div>
                            <UrgenceNumbersBlock />

                        </div>


                    </div>
                </section>

                {/* CTA Map */}
                <MapCta />

                {/* Liens Pratiques */}
                <LiensPratiques />
            </div>
        </div>
        </>
    );
};

export default UrgenceNumbersPage;
