import React from 'react';
import Button from 'components/common/Button';
import styles from './LiensPratiques.module.css';
import { Link } from 'react-router-dom';
import { ReactComponent as Thelma } from 'icons/symbole_thelma.svg';
import useAccessibility from 'hooks/useAccessibility';

const LiensPratiques = () => {
    const accessibilityVariant = useAccessibility();

    // Données organisées en objets pour un accès direct
    const medias = {
        pharos: { label: "Le Pharos", alt: "Logo Le Pharos", image: "/images/logo_pharos.webp" },
        appStore: { label: "Application Arras - App Store", alt: "Télécharger sur l'App Store", image: "/images/app_store_badge.png", link: "https://apps.apple.com/fr/app/vivre-arras/id6743126306?l=fr-FR" },
        googlePlay: { label: "Application Arras - Google Play", alt: "Télécharger sur Google Play", image: "/images/google_play_badge.png", link: "https://play.google.com/store/apps/details?id=fr.application.arras" },
        arrasApp: { label: "Application ville d'Arras", alt: "Application ville d'Arras", image: "/images/app_arras_phone.webp" },
        thelmaApp: { label: "Application Thelma", alt: "Application Thelma", image: "/images/thelma_phone.webp" },
    };

    const quickLinks = {
        billetterie: { label: "Accéder à la billetterie", ariaLabel: "Accéder à la billetterie", link: "https://billetterie.festik.net/programme-pharos-casino/" },
        thelma: { label: "Accéder à Thelma", ariaLabel: "Accéder à Thelma", link: "https://thelma.app/" },
    };

    // Vérifier si les deux liens sont vides
    const areBothLinksEmpty = !medias.appStore.link && !medias.googlePlay.link;

    return (
        <section className={`global-section ${styles.smartSection}`}>
            <div className="global-container">
                <h2>Liens pratiques</h2>
                <div className={`global-block ${styles.container}`}>
                    {/* Bloc "Le Pharos" */}
                    <div className={`global-block ${styles.item} ${styles.bgPharos}`}>
                        <div className={`global-block ${styles.content}`}>
                            <h3 className={`featured ${styles.responsiveHidden}`}>{medias.pharos.label}</h3>
                            <p className={styles.text}>Ne manquez pas les prochains spectacles.</p>
                            <Link
                                to={quickLinks.billetterie.link}
                                target="_blank"
                                rel="noopener nofollow"
                                aria-label={quickLinks.billetterie.ariaLabel}
                            >
                                <Button variant="white" role="button">{quickLinks.billetterie.label}</Button>
                            </Link>
                        </div>
                        <figure className={`${styles.figure} ${styles.figurePharos}`}>
                            <img
                                src={medias.pharos.image}
                                alt={medias.pharos.alt}
                                title={medias.pharos.label}
                            />
                        </figure>
                    </div>

                    {/* Bloc "Vivre Arras" */}
                    <div className={`global-block ${styles.item} ${styles.bgApp}`}>
                        <div className={`global-block ${styles.content}`}>
                        <img src="/images/vivre_arras_logo_negatif.png" className={styles.vivreArrasIcon} alt="Logo Vivre Arras" title="Vivre Arras" />
                            <p className={styles.text}>Suivez l'actualité de votre ville directement depuis votre smartphone.</p>
                            <div className={styles.buttons}>
                                {/* Afficher les badges ou un message si les deux sont vides */}
                                {areBothLinksEmpty ? (
                                    <></>
                                ) : (
                                    <>
                                        {medias.appStore.link && (
                                            <a
                                                href={medias.appStore.link}
                                                target="_blank"
                                                rel="noopener nofollow"
                                                aria-label={medias.appStore.alt}
                                            >
                                                <img
                                                    src={medias.appStore.image}
                                                    alt={medias.appStore.alt}
                                                    className={styles.appBadge}
                                                />
                                            </a>
                                        )}
                                        {medias.googlePlay.link && (
                                            <a
                                                href={medias.googlePlay.link}
                                                target="_blank"
                                                rel="noopener nofollow"
                                                aria-label={medias.googlePlay.alt}
                                            >
                                                <img
                                                    src={medias.googlePlay.image}
                                                    alt={medias.googlePlay.alt}
                                                    className={styles.appBadge}
                                                />
                                            </a>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                        <figure className={`${styles.figure} ${styles.figurePhone}`}>
                            <img
                                src={medias.arrasApp.image}
                                alt={medias.arrasApp.alt}
                                title={medias.arrasApp.label}
                            />
                        </figure>
                    </div>

                    {/* Bloc "Thelma" */}
                    <div className={`global-block ${styles.item} ${styles.bgThelma}`}>
                        <div className={`global-block ${styles.content}`}>
                            <Thelma className={styles.icon} />
                            <h3 className="featured">Vous constatez une incivilité ?</h3>
                            <p className={styles.text}>Remontez l'information via l'application Thelma.</p>
                            <Link
                                to={quickLinks.thelma.link}
                                target="_blank"
                                rel="noopener nofollow"
                                aria-label={quickLinks.thelma.ariaLabel}
                            >
                                <Button variant="white" role="button">{quickLinks.thelma.label}</Button>
                            </Link>
                        </div>
                        <figure className={`${styles.figure} ${styles.figurePhone}`}>
                            <img
                                src={medias.thelmaApp.image}
                                alt={medias.thelmaApp.alt}
                                title={medias.thelmaApp.label}
                            />
                        </figure>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default LiensPratiques;