import { parseISO, format, eachDayOfInterval, isSameDay } from 'date-fns';

function expandEventsForCalendar(events) {
  return events.flatMap((event) => {
    const startDate = parseISO(event.start);
    const endDate = parseISO(event.end);

    // S’il y a un problème de dates inversées, on peut gérer ici
    if (endDate < startDate) {
      return [];
    }

    // 1) Vérifie si c’est un événement sur une seule journée (même jour)
    if (isSameDay(startDate, endDate)) {
      // On renvoie un seul "event" AVEC les horaires d’origine
      return [
        {
          title: event.title,
          start: event.start,  // On garde la date + heure
          end: event.end,      // Idem
   
            location: event.location,
            description: event.content?.[0]?.content || 'Pas de description',
            categories: event.categories,
            thumbnail: event.thumbnail,
            slug: event.slug || event.id,
      
        },
      ];
    }

    // 2) Sinon, événement multi-jours => on duplique chaque jour
    const intervalDays = eachDayOfInterval({ start: startDate, end: endDate });
    return intervalDays.map((day) => {
      // Ici, on génère des "all day events" (00:00 -> 23:59) pour chaque jour,
      // ou tout autre logique si tu veux garder partiellement les horaires
      const dayStr = format(day, 'yyyy-MM-dd');
      return {
        title: event.title,
        start: dayStr, // Par défaut, on met 00:00
        end: dayStr,   // On met la même date pour "all day"
        allDay: true,
        extendedProps: {
          location: event.location,
          description: event.content?.[0]?.content || 'Pas de description',
          categories: event.categories,
          thumbnail: event.thumbnail,
          slug: event.slug || event.id,
        },
      };
    });
  });
}

export default expandEventsForCalendar;
