import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import api from 'services/api';
import Badge from 'components/common/Badge';
import Button from 'components/common/Button';
import Summary from 'components/common/Summary';
import Breadcrumbs from 'components/common/Breadcrumbs';
import ShareButtons from 'components/common/ShareButtons';
import FileList from 'components/common/FileList';
import BannerWithSocialIcons from 'components/common/BannerWithSocialIcons';
import MapCta from 'components/common/MapCta';
import LiensPratiques from 'components/common/LiensPratiques';
import PictoAccess from 'components/common/PictoAccess';
import NearestEventList from 'components/events/NearestEventList';
import sharedStyles from 'styles/modules/ContentPage.module.css'; // Import styles partagés
import archiveStyle from 'styles/modules/Archives.module.css';
import { TimeOutline, ShareSocialOutline } from 'react-ionicons';
import { ReactComponent as Zoom } from 'icons/zoom.svg';
import { useSubSite } from 'contexts/SubSiteContext';
import useBreakpoint from 'hooks/useBreakpoint'; // Responsive Hook
import { Helmet } from 'react-helmet';
import Spinner from 'components/common/Spinner';

const EventPage = () => {
  const { eventSlug } = useParams();
  const [event, setEvent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [updatedContentHtml, setUpdatedContentHtml] = useState('');
  const [seoExcerpt, setSeoExcerpt] = useState('');
  const pageType = 'isEvent';
  const { isSubSite, subSiteSlug } = useSubSite();

  const isMobile = useBreakpoint(); // Utilisation du hook pour savoir si l'écran est mobile
  const agendaURL = isSubSite ? `/${subSiteSlug}/agenda` : `/agenda`;

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const response = await api.get(`/events/read/${eventSlug}`);
        setEvent(response.data[0] || null);
      } catch (error) {
        console.error("Erreur lors de la récupération de l'événement:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchEvent();
  }, [eventSlug]);

  useEffect(() => {
    if (updatedContentHtml) {
      const processedHtml = renderTextWithOembed(updatedContentHtml);
      setUpdatedContentHtml(processedHtml);
    }
  }, [updatedContentHtml]);

  /**
   * Fonction qui remplace <oembed> par <iframe> pour YouTube (watch -> embed).
   * Retourne la chaîne HTML finale.
   */
  const renderTextWithOembed = (content) => {
    // 1) On parse le HTML
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, 'text/html');

    // 2) Sélectionner tous les <oembed url="...">
    const oembedElements = doc.querySelectorAll('oembed');
    oembedElements.forEach((oembed) => {
      const originalUrl = oembed.getAttribute('url');
      if (!originalUrl) return;

      try {
        const url = new URL(originalUrl);

        // Vérifier si c’est YouTube watch
        const isYoutubeWatch =
          (url.hostname === 'www.youtube.com' || url.hostname === 'youtube.com') &&
          url.pathname === '/watch';

        if (isYoutubeWatch) {
          // Extraire le paramètre v=...
          const videoId = url.searchParams.get('v');
          if (videoId) {
            // Construire l’URL embed
            const embedUrl = `https://www.youtube.com/embed/${videoId}?yt:stretch=16:9&vq=hd720p&loop=0&color=red&iv_load_policy=3&rel=0&showinfo=0&autohide=0&controls=1`;

            // Créer l’iframe
            const iframe = document.createElement('iframe');
            iframe.src = embedUrl;
            iframe.width = '560';
            iframe.height = '315';
            iframe.allow =
              'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture';
            iframe.allowFullscreen = true;
            iframe.setAttribute('frameborder', '0');
            iframe.setAttribute('allowtransparency', 'true');

            // Remplacer le <oembed> par l’iframe
            oembed.parentNode.replaceChild(iframe, oembed);
          }
        } else {
          // Ici, tu peux gérer d’autres plateformes (Vimeo, Dailymotion…), 
          // ou laisser tel quel.
        }
      } catch (err) {
        console.error('URL invalide ou problème parsing:', err);
      }
    });

    // 3) Retourner le HTML final. On peut prendre doc.body ou doc.documentElement.
    return doc.body.innerHTML;
  };

  if (loading) return <div><Spinner /></div>;
  if (!event) return <div>Aucun événement trouvé.</div>;

  const {
    title,
    start,
    end,
    location,
    content,
    categories,
    thumbnail,
    poster,
    files,
    pictures,
    pictoAccess,
    createdAt,
    banner,
    slug
  } = event;

  const formattedDate = new Date(createdAt).toLocaleDateString('fr-FR');

  // --- LOGIQUE DE GESTION DES DATES/HORAIRES ---
  let dateToDisplay = 'Date non spécifiée';

  if (start && end) {
    const startDate = new Date(start);
    const endDate = new Date(end);

    const formattedStartDate = startDate.toLocaleDateString('fr-FR');
    const formattedEndDate = endDate.toLocaleDateString('fr-FR');

    const formattedStartTime = startDate
      .toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit', hour12: false })
      .replace(':', 'h');
    const formattedEndTime = endDate
      .toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit', hour12: false })
      .replace(':', 'h');

    if (formattedStartDate === formattedEndDate) {
      // Si la date est la même pour start et end
      dateToDisplay = `${formattedStartDate} de ${formattedStartTime} à ${formattedEndTime}`;
    } else {
      // Si ce sont des jours différents
      dateToDisplay = `${formattedStartDate} - ${formattedEndDate}`;
    }
  } else if (start && !end) {
    const startDate = new Date(start);
    dateToDisplay = startDate.toLocaleDateString('fr-FR');
  } else if (!start && end) {
    const endDate = new Date(end);
    dateToDisplay = endDate.toLocaleDateString('fr-FR');
  }
  // --- FIN LOGIQUE DE GESTION DES DATES/HORAIRES ---

  return (
    <>
      <Helmet>
        <title>Arras | {title}</title>
        <meta name="description" content={seoExcerpt || 'Découvrez cet événement unique à Arras.'} />
        <meta property="og:title" content={`Arras | ${title}`} />
        <meta property="og:description" content={seoExcerpt || 'Découvrez cet événement unique à Arras.'} />
        <meta property="og:image" content={thumbnail && thumbnail.path ? `${process.env.REACT_APP_MEDIA_BASE_URL}${thumbnail.path}` : 'https://arras.fr/default-thumbnail.jpg'} />
        <meta property="og:url" content={`https://www.arras.fr/evenement/${slug}`} />
        <meta property="og:type" content="article" />
        <meta property="og:site_name" content="Ville d'Arras" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`Arras | ${title}`} />
        <meta name="twitter:description" content={seoExcerpt || 'Découvrez cet événement unique à Arras.'} />
        <meta name="twitter:image" content={thumbnail && thumbnail.path ? `${process.env.REACT_APP_MEDIA_BASE_URL}${thumbnail.path}` : 'https://arras.fr/default-thumbnail.jpg'} />
        <link rel="canonical" href={`https://www.arras.fr/evenement/${slug}`} />
      </Helmet>

      <div className={`${sharedStyles.pageWrapper} ${sharedStyles.eventPage}`}>
        {/* Bannière avec miniature */}
        {banner && banner.path && (
          <BannerWithSocialIcons
            imageUrl={`${process.env.REACT_APP_MEDIA_BASE_URL}${banner.path}`}
          />
        )}

        <div className={`page-content ${sharedStyles.pageContent}`}>
          <section className={`global-section ${sharedStyles.mainSection}`}>
            <div className={`global-container ${sharedStyles.headerContainer}`}>
              <div className={sharedStyles.header}>
                <div className={archiveStyle.headingBlock}>
                  <h1>{title}</h1>
                  <Breadcrumbs pageType={pageType} element={event} />
                </div>

                <div className={sharedStyles.metaContainer}>
                  <div className={sharedStyles.meta}>
                    <ShareSocialOutline
                      color="currentColor"
                      height=""
                      width=""
                      className={sharedStyles.metaIcon}
                    />
                    <p className={sharedStyles.metaInfo}>Partager :</p>
                    <ShareButtons />
                  </div>
                  <div className={sharedStyles.meta}>
                    <TimeOutline
                      color="currentColor"
                      height=""
                      width=""
                      className={sharedStyles.metaIcon}
                    />
                    <p className={sharedStyles.metaInfo}>
                      Publié le : {formattedDate}
                    </p>
                  </div>
                </div>
              </div>

              <ul className={`list--none ${sharedStyles.badgeList}`}>
                {categories.map((category) => (
                  <li key={category.id}>
                    <Badge
                      label={category.name}
                      variant={category.slug}
                      link={`/categorie/${category.slug}`}
                    />
                  </li>
                ))}
              </ul>
            </div>

            <div className={`global-container ${sharedStyles.main}`}>
              {/* Colonne principale */}
              <div className={sharedStyles.mainColumn}>
                {isMobile && (
                  <div className={sharedStyles.mobileSummary}>
                    <Summary
                      contentHtml={content?.[0]?.content || ''}
                      setUpdatedContentHtml={setUpdatedContentHtml}
                      setExcerpt={setSeoExcerpt}
                    />
                  </div>
                )}

                {/* Affichage final de la date + horaires + lieu */}
                <p className={sharedStyles.date}>
                  {dateToDisplay}
                  {location && ` | ${location}`}
                </p>

                {/* Contenu de l'événement */}
                <div
                  className={sharedStyles.content}
                  dangerouslySetInnerHTML={{ __html: updatedContentHtml }}
                />

                {/* Fichiers associés */}
                {files.length > 0 && <FileList files={files} />}

                {/* Images associées */}
                {pictures.length > 0 && (
                  <div className={sharedStyles.pictures}>
                    {pictures.map((picture) => (
                      <figure key={picture.id} className={sharedStyles.pictureItem}>
                        <img
                          src={`${process.env.REACT_APP_MEDIA_BASE_URL}${picture.path}`}
                          alt={picture.description || 'Image associée'}
                          className={sharedStyles.picture}
                        />
                        <p className={sharedStyles.credit}>
                          Crédit : {picture.credit}
                        </p>
                      </figure>
                    ))}
                  </div>
                )}

                <div className={sharedStyles.actions}>
                  <Link to={agendaURL}>
                    <Button variant="secondary">Retour à l'agenda</Button>
                  </Link>
                  <Button
                    variant="primary"
                    onClick={() => window.print()}
                  >
                    Imprimer
                  </Button>
                </div>
              </div>

              {/* Colonne latérale droite */}
              <div className={sharedStyles.sidebar}>
                {poster && poster.path && (
                  <div className={sharedStyles.posterContainer}>
                    <img
                      src={`${process.env.REACT_APP_MEDIA_BASE_URL}${poster.path}`}
                      alt="Affiche de l'événement"
                      className={sharedStyles.poster}
                    />
                    <button
                      className={sharedStyles.zoom}
                      aria-label="Zoomer l'image"
                      title="Zoomer l'image"
                    >
                      <Zoom className={sharedStyles.zoomIcon} />
                    </button>
                  </div>
                )}

                {pictoAccess && (
                  <PictoAccess
                    iframeHtml={pictoAccess}
                    width="250"
                    height="120"
                  />
                )}

                {!isMobile && (
                  <div className={sharedStyles.sticky}>
                    <Summary
                      contentHtml={content?.[0]?.content || ''}
                      setUpdatedContentHtml={setUpdatedContentHtml}
                    />
                  </div>
                )}
              </div>
            </div>
          </section>

          <section className={`global-section ${sharedStyles.nearestEvents}`}>
            <NearestEventList slug={eventSlug} />
          </section>

          {/* CTA Map */}
          <MapCta />

          {/* Liens Pratiques */}
          <LiensPratiques />
        </div>
      </div>
    </>
  );
};

export default EventPage;