import React from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import Button from 'components/common/Button';
import styles from './SubCategory.module.css';
import stylesCard from 'styles/modules/Card.module.css';

const SubCategoryGridItem = ({
    name,
    slug,
    banner,
    buttonText = `Lire la suite`,
}) => {
    // Construction du lien (vers la sous-catégorie)
    const link = `/categorie/${slug}`;

    // Récupérer l'URL actuelle
    const location = useLocation();

    // Vérifier si le lien est actif
    const isActive = location.pathname === link;


    const thumbnailUrl = banner ? banner.path : null;
    const mediaBaseUrl= process.env.REACT_APP_MEDIA_BASE_URL;

    return (
        <li className={stylesCard.card}>

            {/* Thumbnail */}
            <div className={stylesCard.thumbnailContainer}>
                <img
                        src={`${mediaBaseUrl}${thumbnailUrl}`}
                        alt={`Image de la sous-catégorie ${name}`}
                        className={stylesCard.thumbnail}
                    />
            </div>

            <div className={stylesCard.content}>
                {/* Bouton pour aller sur la sous-catégorie */}
                {/* Title and Date */}
                <div className={stylesCard.headingContainer}>
                    <h3 className={stylesCard.title}>{name}</h3>
                </div>
                <div className={stylesCard.buttons}>
                <Link to={link}>
                    <Button variant="secondary" className={stylesCard.cardButton} ariaLabel={`Affiche les éléments de la sous-catégorie ${name}`} title={`Affiche les éléments de la sous-catégorie ${name}`}>
                            {buttonText}
                    </Button>
                    </Link>
                </div>
            </div>


        </li>
    );
};

SubCategoryGridItem.propTypes = {
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    banner: PropTypes.object,
    parentBanner: PropTypes.string, // La bannière fallback de la catégorie parente
    pages: PropTypes.array,
    buttonText: PropTypes.string,
    onButtonClick: PropTypes.func,
};

export default SubCategoryGridItem;
