// useAccessibility.js
import { useContext, useEffect, useState } from 'react'; 
import { AccessibilityContext } from 'contexts/AccessibilityContext';

const useAccessibility = () => {
  const { selectedOption, getAccessibilityVariant } = useContext(AccessibilityContext);
  const [accessibilityVariant, setAccessibilityVariant] = useState(getAccessibilityVariant());

  useEffect(() => {
    setAccessibilityVariant(getAccessibilityVariant());
  }, [selectedOption]);

  return accessibilityVariant;
};

export default useAccessibility;
