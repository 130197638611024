import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Dot from 'components/common/Dot';
import Button from 'components/common/Button';
import styles from 'styles/modules/Card.module.css';
import tooltipStyles from 'styles/modules/Tooltip.module.css';
import catStyles from 'styles/modules/Categories.module.css';

let currentVisibleTooltip = null; // Variable globale au composant

const Card = ({
  thumbnail,
  title,
  date,
  categories,
  description,
  link,
  buttonText = 'Lire la suite',
  buttons = [],
}) => {
  const [isTooltipVisible, setTooltipVisible] = useState(false);

  const handleTooltipToggle = () => {
    // Ferme l'infobulle si elle est déjà ouverte
    if (isTooltipVisible) {
      setTooltipVisible(false);
      currentVisibleTooltip = null;
    } else {
      // Ferme toute autre infobulle visible et ouvre celle-ci
      if (currentVisibleTooltip) {
        currentVisibleTooltip(false);
      }
      setTooltipVisible(true);
      currentVisibleTooltip = setTooltipVisible;
    }
  };

  const handleOverlayClick = () => {
    // Ferme l'infobulle en cliquant sur l'overlay
    setTooltipVisible(false);
    currentVisibleTooltip = null;
  };

  return (
    <li className={styles.card}>
      {/* Overlay */}
      {isTooltipVisible && (
        <div
          className={tooltipStyles.overlay}
          onClick={handleOverlayClick}
          aria-hidden="true"
        ></div>
      )}

      {/* Thumbnail */}
      <div className={styles.thumbnailContainer}>
        {thumbnail ? (
          <img
            src={thumbnail}
            alt={`Image de ${title}`}
            className={styles.thumbnail}
          />
        ) : (
          <div className={styles.placeholder}>Image non disponible</div>
        )}
      </div>

      {/* Content */}
      <div className={styles.content}>
        {/* Categories */}
        {categories && categories.length > 0 && (
          <ul className={`list--none ${styles.dotList}`}>
            {categories.map((category) => (
              <li className={styles.dotItem} key={category.id}>
                <Dot variant={category.slug} link={`/categorie/${category.slug}`} />
              </li>
            ))}
            <button
              className={`arrow-link ${styles.voirPlus}`}
              onClick={handleTooltipToggle}
              aria-label="Afficher les différentes catégories"
            >
              Catégories
              <FontAwesomeIcon icon={faArrowRight} />
              {/* Tooltip */}
              {isTooltipVisible && (
                <div className={tooltipStyles.tooltip}>
                  <ul className={`list--none ${tooltipStyles.tooltipList}`}>
                  {categories.map((category) => (
                    <li className={`list--none ${tooltipStyles.tooltipItem}`} key={category.id}>
                      <span className={`${tooltipStyles.tooltipLabel} ${catStyles[category.slug]}`}>{category.name}</span>
                    </li>
                  ))}
                  </ul>
                </div>
              )}
            </button>
          </ul>
        )}

        {/* Title and Date */}
        <div className={styles.headingContainer}>
          <h3 className={styles.title}>{title}</h3>
          {date && <p className={styles.date}>{date}</p>}
        </div>

        {/* Description/Excerpt */}
        {description && <p className={styles.excerpt}>{description}</p>}

        {/* Buttons */}
        <div className={styles.buttons}>
          {buttons.length > 0 ? (
            buttons.map((btn, index) => (
              <Link to={btn.href} key={index}>
                <Button
                  variant={btn.variant}
                  href={btn.href}
                  target={btn.target}
                  rel={btn.rel}
                >
                  {btn.text}
                </Button>
              </Link>
            ))
          ) : (
            <Button variant="primary" className={styles.cardButton}>
              <Link to={link}>{buttonText}</Link>
            </Button>
          )}
        </div>
      </div>
    </li>
  );
};

Card.propTypes = {
  thumbnail: PropTypes.string,
  title: PropTypes.string.isRequired,
  date: PropTypes.string,
  categories: PropTypes.array,
  description: PropTypes.string,
  link: PropTypes.string,
  buttonText: PropTypes.string,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      variant: PropTypes.string,
      href: PropTypes.string,
      text: PropTypes.string,
      target: PropTypes.string,
      rel: PropTypes.string,
    })
  ),
};

export default Card;
