// QuickLinks.jsx
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import styles from '../QuickLinks.module.css';
import useProfile from 'hooks/useProfile';
import iconMapping from 'utils/iconMapping';
import { getUserProfiles, checkSpecialEvent } from 'services/api'; // Importer les méthodes API
import { InformationCircleOutline } from 'react-ionicons';
import useAccessibility from 'hooks/useAccessibility';
import catStyles from 'styles/modules/Categories.module.css';
import useBreakpoint from 'hooks/useBreakpoint'; // Responsive Hook
import { useSubSite } from 'contexts/SubSiteContext'; // Hook pour vérifier le sous-site
import api from 'services/api'; // Import du service API
import Spinner from 'components/common/Spinner';

// Petite fonction pour nettoyer la chaîne SVG,
// par exemple retirer <?xml ... ?> ou autres métadonnées éventuelles.
const sanitizeSvg = (rawSvg) => {
  if (!rawSvg) return null;
  // Supprimer l'en-tête XML
  return rawSvg.replace(/<\?xml.*?\?>/, '').trim();
};

const QuickLinks = () => {
  const accessibilityVariant = useAccessibility();
  const { profileOption } = useProfile();
  const [quickLinks, setQuickLinks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [specialWidget, setSpecialWidget] = useState(null);
  const { isSubSite, subSiteSlug } = useSubSite();
  const isMobile = useBreakpoint();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (isSubSite) {
          // Récupérer les widgets spécifiques au sous-site
          const response = await api.get(`/subsites/${subSiteSlug}/widgets`);
          setQuickLinks(response.data);
        } else {
          // Récupérer les profils et les événements spéciaux pour le site principal
          const [profilesData, specialEventData] = await Promise.all([
            getUserProfiles(),
            checkSpecialEvent(),
          ]);

          // Trouver le profil actuel dans les données récupérées
          const currentProfile = profilesData.find(profile => profile.slug === profileOption);

          if (currentProfile) {
            setQuickLinks(currentProfile.widgets);
          } else {
            setQuickLinks([]);
          }

          if (specialEventData.special_event && specialEventData.widget) {
            setSpecialWidget(specialEventData.widget);
          }
        }

        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    fetchData();
  }, [isSubSite, subSiteSlug, profileOption]);

  if (loading) {
    return (
      <div className={styles.quickLinks}>
        <Spinner />
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.quickLinks}>
        Erreur lors du chargement des liens rapides.
      </div>
    );
  }

  // Définir des liens par défaut si aucun widget n'est disponible
  const defaultQuickLinks = [
    { id: 1, name: 'Accueil', type: 'link', target: { type: 'link', url: '/' } },
    { id: 2, name: 'À Propos', type: 'link', target: { type: 'link', url: '/a-propos' } },
    { id: 3, name: 'Contact', type: 'link', target: { type: 'link', url: '/contact' } },
  ];

  // Optionnel : Inclure les liens par défaut si aucun widget n'est disponible
  const links = quickLinks.length > 0 ? quickLinks : defaultQuickLinks;

  // Ajouter le widget spécial en tête si disponible et si nous ne sommes pas sur un sous-site
  const combinedLinks = !isSubSite && specialWidget
    ? [specialWidget, ...links]
    : links;

  // Limiter à six liens au maximum
  const linksToRender = combinedLinks.slice(0, 6);

  return (
    <div className={styles.quickLinks} aria-labelledby="quick-links">
      <h2 id="quick-links" className="sr-only">Liens rapides</h2>

      <ul className={`list--none ${styles.iconsContainer}`}>
        {linksToRender.map(link => {
          // On nettoie éventuellement le code SVG s'il existe
          const sanitizedIcon = sanitizeSvg(link.icon);

          // Déterminer l'URL en fonction du type de widget
          let url = '#';
          let classSlug = 'slug';
          if (link.target) {
            switch (link.type) {
              case 'link':
                url = link.target.url;
                classSlug = link.target.url.replace('/', '');
                break;
              case 'page':
                url = isSubSite
                  ? `/${subSiteSlug}/page/${link.target.slug}`
                  : `/page/${link.target.slug}`;
                break;
              default:
                url = '#';
            }
          }

          // Déterminer l’icône fallback si l’icône SVG n’est pas dispo
          const IconComponent = iconMapping[link.name]
            || iconMapping['Accueil']
            || InformationCircleOutline;

          return (
            <li key={link.id}>
              {isMobile ? (
                // Vue mobile
                <a
                  href={url}
                  className={`
                    ${styles.linkItem} 
                    ${styles.responsiveItem} 
                    ${catStyles[classSlug]} 
                    ${catStyles[accessibilityVariant]}
                  `}
                  aria-label={link.name}
                >
                  <span className={styles.label}>
                    {link.name}
                  </span>
                  <FontAwesomeIcon
                    className={styles.arrow}
                    icon={faArrowRight}
                  />
                </a>
              ) : (
                // Vue desktop
                <a
                  href={url}
                  className={`${styles.linkItem} ${styles.desktopItem}`}
                  aria-label={link.name}
                >
                  <span
                    className={`${styles.iconWrapper} ${catStyles[classSlug]} ${catStyles[accessibilityVariant]}`}
                  >
                    {sanitizedIcon ? (
                      // Injection du code SVG
                      <span
                        className={styles.icon}
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{ __html: sanitizedIcon }}
                      />
                    ) : (
                      // Icône fallback
                      <IconComponent
                        color="currentColor"
                        width=""
                        height=""
                        className={styles.icon}
                      />
                    )}
                  </span>
                  <span className={styles.label}>
                    {link.name}
                  </span>
                </a>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default QuickLinks;
