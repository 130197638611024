// PointMarker.jsx
import React from 'react';
import { Marker, Popup } from 'react-leaflet';

const PointMarker = ({ point, icon, onClick }) => {
  return (
    <Marker
      position={[point.latitude, point.longitude]}
      icon={icon}
      eventHandlers={{
        click: () => onClick(point),
      }}
    >
      <Popup>
        {point.name}
      </Popup>
    </Marker>
  );
};

export default PointMarker;
