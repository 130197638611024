import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faXTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import styles from './ShareButtons.module.css';
import useAccessibility from 'hooks/useAccessibility';

const ShareButtons = () => {
  const accessibilityVariant = useAccessibility();
  const [currentUrl, setCurrentUrl] = useState('');

  useEffect(() => {
    setCurrentUrl(window.location.href);
  }, []);

  const shareOnFacebook = (e) => {
    e.preventDefault();
    const urlToShare = encodeURIComponent(currentUrl);
    const fbWebLink = `https://www.facebook.com/sharer/sharer.php?u=${urlToShare}`;
    window.open(fbWebLink, '_blank');
  };
  

  const shareLinks = {
    twitter: `https://twitter.com/intent/tweet?url=${encodeURIComponent(currentUrl)}&text=Découvrez cette page!`,
    linkedin: `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(currentUrl)}`,
  };

  return (
    <div className={styles.shareButtons}>
      <a
        href="#"
        onClick={shareOnFacebook}
        aria-label="Partager sur Facebook"
      >
        <FontAwesomeIcon icon={faFacebook} />
      </a>

      <a
        href={shareLinks.twitter}
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Partager sur Twitter"
      >
        <FontAwesomeIcon icon={faXTwitter} />
      </a>

      <a
        href={shareLinks.linkedin}
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Partager sur LinkedIn"
      >
        <FontAwesomeIcon icon={faLinkedin} />
      </a>
    </div>
  );
};

export default ShareButtons;