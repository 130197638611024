// RenderEventContent.js
import React from 'react';
import styles from './RenderEventContent.module.css';
import stylesCategory from 'styles/modules/Categories.module.css';

const RenderEventContent = (eventInfo) => {
  // On récupère les catégories depuis extendedProps si disponibles
  const categories = eventInfo.event.extendedProps.categories || [];
  
  // Filtrer pour exclure la catégorie "evenements" s'il y a d'autres catégories
  const filteredCategories = categories.filter(cat => cat.slug !== 'evenements');
  console.log(filteredCategories);
  const category = filteredCategories.length > 0 ? filteredCategories[0] : (categories.length > 0 ? categories[0] : null);
  
  // Définir la couleur de la catégorie ou une couleur par défaut
  const categoryColor = category ? stylesCategory[category.slug] : styles.defaultDot;

  return (
    <div className={styles.eventContent}>
      <span className={`${styles.dot} ${categoryColor}`} />
      <span className={styles.eventTitle}>{eventInfo.event.title}</span>
    </div>
  );
};

export default RenderEventContent;
