import React from 'react';
import PropTypes from 'prop-types';
import styles from './LoadMoreButton.module.css';
import { Add } from 'react-ionicons';
import useAccessibility from 'hooks/useAccessibility';

const LoadMoreButton = ({ onClick }) => {
  const accessibilityVariant = useAccessibility();

  return (
    <div className="global-container">
    <button className={styles.loadMoreButton} onClick={onClick} aria-label="Charger plus de résultats">
      <Add
        color="currentColor"
        height=""
        width=""
        className={styles.icon}
      />
      Voir plus
    </button>
    <span className="separatorLine"></span>
    </div>
  );
};

LoadMoreButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default LoadMoreButton;
