// KiosqueHome.jsx
import React, { useEffect, useState } from 'react';
import Button from 'components/common/Button';
import api from 'services/api'; // Importation du module api
import styles from './KiosqueHome.module.css';
import { Link } from 'react-router-dom';
import Spinner from 'components/common/Spinner'

const KiosqueHome = () => {
  const [data, setData] = useState(null); // État pour les données
  const [isLoading, setIsLoading] = useState(true); // État de chargement
  const [error, setError] = useState(null); // État d'erreur

  const kioskURL = '/kiosque/'; // URL de l'API pour les données du kiosque
  const arrasActuUrl = kioskURL + 'arras-actu/'; // URL de la page Arras Actu

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get('/kiosk/last-arrasactu');
        setData(response.data);
      } catch (err) {
        setError(err.message || 'Une erreur est survenue');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);




  // Gestion de l'état de chargement
  if (isLoading) {
    return <div><Spinner /></div>;
  }

  // Gestion de l'état d'erreur
  if (error) {
    return <div>Erreur : {error}</div>;
  }

  const contentExcerpt =
  data.description ? data.description.replace(/<\/?[^>]+(>|$)/g, '').slice(0, 100) + '...' : '';

  // Si les données sont disponibles, nous les affichons
  return (
    <section className={`global-section ${styles.kiosqueSection}`}>
      <div className="global-container">
        <h2>Le Kiosque</h2>
        <div className={`global-block ${styles.featured}`}>
          <div className={`global-block ${styles.featuredItem}`}>
            <figure className={styles.figure}>
              <img
                src={`${process.env.REACT_APP_MEDIA_BASE_URL}${data.poster.path}` || '../images/placeholder_img.webp'} // Utilisez data.imageUrl si disponible
                alt={`Arras Actu édition n°${data.publicationNumber}`}
                title={`Arras Actu édition n°${data.publicationNumber}`}
              />
            </figure>
            <div className={`global-block ${styles.featuredContent}`}>
              <div className={`global-block ${styles.headingBlock}`}>
                <h3 className="featured">{data.title}</h3>
                <p className={styles.meta}>
                  {`Publié le ${new Date(data.publishedAt).toLocaleDateString()}`}
                </p>
              </div>
              <p className={styles.text}>{contentExcerpt}</p>
              <div className={`global-block ${styles.featuredButtons}`}>
              <Link to={`${process.env.REACT_APP_MEDIA_BASE_URL}${data.file.path}`}>

                <Button variant="primary">Télécharger</Button>
                </Link>

                <Button variant="secondary">
                <Link to={`${arrasActuUrl}${data.slug}`}>
                  Lire en ligne
                  </Link>
                  </Button>
              </div>
            </div>
          </div>
          <div className={`global-block ${styles.misc}`}>
            <div className={`global-block ${styles.miscItem} ${styles.actu}`}>
              <div className={`global-block ${styles.headingBlock}`}>
                <h3>Arras Actu</h3>
                <p>Découvrez toutes les parutions d'Arras Actu.</p>
              </div>
              <div className={`global-block ${styles.miscButtons}`}>
              <Link to={`${arrasActuUrl}`}>

                <Button variant="primary">Voir toutes les parutions</Button>
                </Link>
              </div>
            </div>
            <div className={`global-block ${styles.miscItem} ${styles.autres}`}>
              <div className={`global-block ${styles.headingBlock}`}>
                <h3>Autres documents</h3>
                <p>Découvrez toutes les autres publications.</p>
              </div>
              <div className={`global-block ${styles.miscButtons}`}>
                <Link to={`${kioskURL}`}>
                <Button variant="white">Voir toutes les publications</Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default KiosqueHome;
