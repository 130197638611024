import React from 'react';
import PropTypes from 'prop-types';
import BannerWithSocialIcons from 'components/common/BannerWithSocialIcons';
import Breadcrumbs from 'components/common/Breadcrumbs';
import styles from 'styles/modules/Archives.module.css';

const ArchivesHeader = ({ title, imageUrl, parent, pageType, description }) => (
  <>
    <BannerWithSocialIcons imageUrl={imageUrl} />
    <section className={`global-section ${styles.headerSection}`}>
      <div className={`global-container ${styles.header}`}>
        <div className={styles.headingBlock}>
          <h1>{title}</h1>
          <Breadcrumbs title={title} parent={parent} pageType={pageType} />
          <div className={styles.headerDescription}
            // Utiliser dangerouslySetInnerHTML pour injecter le HTML contenu dans description
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>
      </div>
    </section>
  </>
);

ArchivesHeader.propTypes = {
  title: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  parent: PropTypes.object,
  pageType: PropTypes.string,
  description: PropTypes.string, // Assurez-vous que description est bien une chaîne de caractères qui contient du HTML sécurisé
};

export default ArchivesHeader;
