import React, { useState } from 'react';
import PropTypes from 'prop-types';
import EventCard from 'components/events/EventCard';
import EventFeatured from 'components/events/EventFeatured';
import LoadMoreButton from 'components/common/LoadMoreButton';
import listStyles from 'styles/modules/CardList.module.css';
import styles from './GridView.module.css';
import { AccessibilityContext } from 'contexts/AccessibilityContext';

const GridView = ({ events, onEventClick, accessibilityVariant }) => {
    const EVENTS_PER_PAGE = 4;
  
    // Appel des Hooks en premier
    const [visibleEventsCount, setVisibleEventsCount] = useState(EVENTS_PER_PAGE);
  
    // Sélectionner l'événement en vedette
    const [featuredEvent, ...otherEvents] = events;
  
    // Événements à afficher
    const displayedEvents = otherEvents.slice(0, visibleEventsCount);
  
    // Fonction pour charger plus d'événements
    const loadMoreEvents = () => {
      setVisibleEventsCount((prevCount) => prevCount + EVENTS_PER_PAGE);
    };
  
    // Rendu conditionnel après les Hooks
    if (events.length === 0) {
      return <div>Aucun événement disponible.</div>;
    }
  
    return (
      <div className={`global-container ${listStyles.listSection} ${styles.gridSection}`}>
        {/* Événement en vedette */}
        <EventFeatured event={featuredEvent} accessibilityVariant={accessibilityVariant} />
  
        {/* Grille des autres événements */}
        <ul className={`list--none ${listStyles.grid}`}>
          {displayedEvents.map((event) => (
            <EventCard
              key={event.id || event.slug}
              event={event}
              onEventClick={onEventClick}
            />
          ))}
        </ul>
  
        {/* Bouton "Voir plus" */}
        {visibleEventsCount < otherEvents.length && (
          <LoadMoreButton onClick={loadMoreEvents} />
        )}
      </div>
    );
  };
  
  GridView.propTypes = {
    events: PropTypes.array.isRequired,
    onEventClick: PropTypes.func.isRequired,
    accessibilityVariant: PropTypes.string,
  };
  
  export default GridView;