// NavItem.jsx
import React from 'react';
import PropTypes from 'prop-types';
import styles from './NavItem.module.css';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const NavItem = React.forwardRef(
  ({ label, onClick, isSelected, icon, slug, context, type, to }, ref) => {
    const commonProps = {
      className: classNames(
        styles.navItem,
        styles[`${context}NavItem`],
        styles[slug],
        {
          [styles.selectedCategory]: isSelected,
        }
      ),
      'aria-label': label,
      ref: ref
    };


    return (
      <li>
        {to ? (
          // Si "to" est présent, c'est un lien (Link) : permet clic droit / nouvel onglet
          <Link
            to={to}
            {...commonProps}
            onClick={onClick}
          >
 {icon && <>
            <span className={styles.icon} dangerouslySetInnerHTML={{ __html: icon }} />              
            </>}
            <span className={styles.label}>{label}</span>
          </Link>
        ) : (
          // Sinon, c'est une catégorie sans lien direct, on reste sur un bouton
          <button
            {...commonProps}
            onClick={onClick}
          >
            {icon && <>
            <span className={styles.icon} dangerouslySetInnerHTML={{ __html: icon }} />              
            </>}
            <span className={styles.label}>{label}</span>
            {type === 'category' && (
              <FontAwesomeIcon icon={faChevronRight} className={styles.arrow} />
            )}
          </button>
        )}
      </li>
    );
  }
);

NavItem.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isSelected: PropTypes.bool,
  icon: PropTypes.string,
  slug: PropTypes.string.isRequired,
  context: PropTypes.oneOf(['sidebar', 'subsidebar']).isRequired,
  type: PropTypes.string.isRequired,
  to: PropTypes.string
};


export default NavItem;
