// App.js
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate  } from "react-router-dom";
import Home from "pages/Home";
import Header from "components/layout/Header";
import Footer from "components/layout/Footer";
import Layout from "components/layout/Layout";
import Page from "pages/Page";
import CategoriePage from "pages/CategoriePage";
import SearchResults from "pages/SearchResults";
import EventPage from "pages/EventPage";
import Agenda from "pages/AgendaPage";
import Blog from "pages/BlogPage";
import NewsPage from "pages/NewsPage";
import MapPage from "pages/MapPage";
import PointOfInterestPage from "pages/PointOfInterestPage";
import KioskPage from "pages/KioskPage";
import KioskSeriePage from "pages/KioskSeriePage";
import MagazinePage from "pages/MagazinePage";
import AssociationsPage from "pages/AssociationsPage";
import AssociationPage from "pages/AssociationPage";
import ContactPage from "pages/ContactPage";
import CookiesPage from "pages/CookiesPage";
import EcrireMairePage from "pages/EcrireMairePage";
import MentionsLegalesPage from "pages/MentionsLegalesPage";
import UrgenceNumbersPage from "pages/UrgenceNumbersPage";
import ActesAdministratifsPage from "pages/ActesAdministratifsPage";
import WebcamPage from "pages/WebcamPage";
import SearchResultsSubsite from "pages/SearchResultsSubsite";
import NotFoundPage from "pages/errors/NotFoundPage";
import DynamicSubSiteRoute from "components/routes/DynamicSubSiteRoute";
import ProfileSidebar from "components/settings/ProfileSidebar";
import AccessibilitySidebar from "components/settings/AccessibilitySidebar";
import Modal from "components/common/Modal";
import Sidebar from "components/navigation/Sidebar";
import ScrollToTop from "./components/routes/ScrollToTop";
import { AccessibilityProvider } from "contexts/AccessibilityContext";
import { ProfileProvider } from "contexts/ProfileContext";
import { ProfileSidebarProvider } from "./contexts/ProfileSidebarContext";
import { AccessibilitySidebarProvider } from "contexts/AccessibilitySidebarContext";
import { ModalProvider } from "contexts/ModalContext";
import { SubSiteProvider } from "contexts/SubSiteContext";
import useDocumentTitle from "hooks/useDocumentTitle";
import "./App.css";
import "styles/variables.css";
import "styles/main.css";
import "styles/access.css";
import "styles/fc.css";
import { Helmet } from 'react-helmet';
import api from "services/api";           // <-- On importe l'instance axios
import Spinner from "components/common/Spinner"; // Ou le Spinner que tu utilises déjà
import ComingSoonPage from "pages/ComingSoonPage";     // <-- Crée cette page si elle n'existe pas
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import redirectionRoutes  from "components/routes/Redirections";
import MatomoScript from './MatomoScript';

function App() {
  const isRedirectionEnabled = process.env.REACT_APP_ENABLE_REDIRECTION === "true";


  if ('scrollRestoration' in window.history) {
    window.history.scrollRestoration = 'manual';
  }
  
  const [isLanding, setIsLanding] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api
      .get("/settings/landing")
      .then((res) => {
        setIsLanding(res.data.isLanding);
      })
      .catch((error) => {
        console.error("Erreur lors de la récupération /settings/landing :", error);
        setIsLanding(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <Spinner />;
  }

  // ----- Début du rendu principal -----
  return (
    <div>

    
    {process.env.NODE_ENV === 'production' && <MatomoScript />}

    <AccessibilityProvider>
      <AccessibilitySidebarProvider>
        <ProfileSidebarProvider>
          <ProfileProvider>
            <ModalProvider>
            <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}>

              <Router> {/* Un seul Router pour toute l'app */}
                {isLanding ? (
                  // ----- Mode Landing page seulement -----
                  <Routes>
                    <Route path="/" element={<ComingSoonPage />} />
                    {/* On redirige toute route vers la landing */}
                    <Route path="*" element={<Navigate to="/" />} />
                  </Routes>
                ) : (
                  // ----- Mode "normal" de l'application -----
                  <SubSiteProvider>
                    <Layout>
                      <Header />
                      <ScrollToTop />
                      <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/recherches" element={<SearchResults />} />
                        <Route path="/agenda" element={<Agenda />} />
                        <Route path="/actualite" element={<Blog />} />
                        <Route path="/carte" element={<MapPage />} />
                        <Route path="/contact" element={<ContactPage />} />
                        <Route
                          path="/mentions-legales"
                          element={<MentionsLegalesPage />}
                        />
                        <Route path="/cookies" element={<CookiesPage />} />
                        <Route
                          path="/ecrire-au-maire"
                          element={<EcrireMairePage />}
                        />
                        <Route
                          path="/les-actes-administratifs"
                          element={<ActesAdministratifsPage />}
                        />
                        <Route
                          path="/associations"
                          element={<AssociationsPage />}
                        />
                        <Route
                          path="/numero-d-urgence"
                          element={<UrgenceNumbersPage />}
                        />
                        <Route path="/webcams" element={<WebcamPage />} />
                        <Route
                          path="/point-interet/:slug"
                          element={<PointOfInterestPage />}
                        />
                        <Route
                          path="/actualite/:newsSlug"
                          element={<NewsPage />}
                        />
                        <Route
                          path="/categorie/:categorySlug"
                          element={<CategoriePage />}
                        />
                        <Route
                          path="/evenement/:eventSlug"
                          element={<EventPage />}
                        />
                        <Route
                          path="/page/:categorySlug/:pageSlug"
                          element={<Page />}
                        />
                        <Route
                          path="/page/:pageSlug"
                          element={<Page />}
                        />
                        <Route path="/kiosque" element={<KioskPage />} />
                        <Route
                          path="/kiosque/:serieSlug"
                          element={<KioskSeriePage />}
                        />
                        <Route
                          path="/kiosque/:serieSlug/:magazineSlug"
                          element={<MagazinePage />}
                        />
                        <Route
                          path="/associations/:associationSlug"
                          element={<AssociationPage />}
                        />
                        <Route
                          path="/:subSiteSlug"
                          element={<DynamicSubSiteRoute />}
                        />
                        <Route
                          path="/:subSiteSlug/page/:pageSlug"
                          element={<Page />}
                        />
                        <Route
                          path="/:subSiteSlug/recherches"
                          element={<SearchResultsSubsite />}
                        />
                        <Route
                          path="/:subSiteSlug/agenda"
                          element={<Agenda />}
                        />
                        <Route
                          path="/:subSiteSlug/evenement/:eventSlug"
                          element={<EventPage />}
                        />
                        <Route
                          path="/:subSiteSlug/actualite"
                          element={<Blog />}
                        />
                        <Route
                          path="/:subSiteSlug/actualite/:newsSlug"
                          element={<NewsPage />}
                        />
                        <Route
                          path="/:subSiteSlug/carte"
                          element={<MapPage />}
                        />

                        {isRedirectionEnabled && redirectionRoutes}

                        <Route path="*" element={<NotFoundPage />} />
                      </Routes>
                      <Footer />
                    </Layout>
                    <ProfileSidebar />
                    <AccessibilitySidebar />
                    <Sidebar />
                  </SubSiteProvider>
                )}
                <Modal />
              </Router>
            </GoogleReCaptchaProvider>
            </ModalProvider>
          </ProfileProvider>
        </ProfileSidebarProvider>
      </AccessibilitySidebarProvider>
    </AccessibilityProvider>
    </div>
  );
}

export default App;