import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import Button from 'components/common/Button';
import styles from './SubCategory.module.css';
import stylesList from 'components/news/NewsList.module.css';

const SubCategoryItem = ({
  name,
  slug,
  banner,
  pages = [],
  buttonText = `Lire la suite`,
  onButtonClick,
}) => {
  // Construction du lien (vers la sous-catégorie)
  const link = `/categorie/${slug}`;

  // Récupérer l'URL actuelle
  const location = useLocation();

  const [categoryData, setCategoryData] = useState(null);

  // Vérifier si le lien est actif
  const isActive = location.pathname === link;

  return (
    <li className={stylesList.listItem}>
      <div className={stylesList.content}>
        <div className={stylesList.infos}>
          <h3 className={stylesList.title}>{name}</h3>
        </div>
        <Link to={link}>
        <Button variant="secondary" className={stylesList.cardButton} ariaLabel={`Affiche les éléments de la sous-catégorie ${name}`} title={`Affiche les éléments de la sous-catégorie ${name}`}>
          {buttonText}
        </Button>
      </Link>
      </div>
    </li>
  );
};

SubCategoryItem.propTypes = {
  name: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  banner: PropTypes.object,
  pages: PropTypes.array,
  buttonText: PropTypes.string,
  onButtonClick: PropTypes.func,
};

export default SubCategoryItem;
