import React from 'react';
import styles from 'components/layout/Footer.module.css';  // Fichier CSS pour le Footer

const MetaFooter = () => {

    return (
        <div className={`global-container ${styles.legalsContainer}`}>
            <p>© {new Date().getFullYear()} Ville d'Arras. Tous droits réservés.</p>
            <ul className={`list--none ${styles.legals}`}>
                <li><a href="/contact" className={`arrow-link ${styles.legalItem}`} aria-label="Contactez-nous" title="Contactez-nous">Contact</a></li>
                <li><a href="/ecrire-au-maire" className={`arrow-link ${styles.legalItem}`} aria-label="Ecrire à M. le Maire" title="Ecrire à M. le Maire">Ecrire à M. le Maire</a></li>
                <li><a href="/page/charte-graphique" className={`arrow-link ${styles.legalItem}`} aria-label="Charte graphique" title="Charte graphique">Charte graphique</a></li>
                <li><a href="/page/espace-presse" className={`arrow-link ${styles.legalItem}`} aria-label="Espace presse" title="Espace presse">Espace presse</a></li>
                <li><a href="/mentions-legales" className={`arrow-link ${styles.legalItem}`} aria-label="Voir les mentions légales" title="Voir les mentions légales">Mentions légales</a></li>
                <li><a href="/cookies" className={`arrow-link ${styles.legalItem}`} aria-label="Voir la politique de cookies" title="Voir la politique de cookies">Cookies</a></li>
            </ul>
        </div>
    );

};

export default MetaFooter;