/// Sidebar.jsx
import React, { useState, useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faTwitter,
  faXTwitter
} from '@fortawesome/free-brands-svg-icons';
import stylesDesktop from './SidebarDesktop.module.css';
import stylesMobile from './SidebarMobile.module.css';
import SearchBar from 'components/navigation/SearchBar'; // Import du nouveau SearchBar
import NavItem from './NavItem';
import api from 'services/api';
import { useNavigate, Link } from 'react-router-dom';
import { useSubSite } from 'contexts/SubSiteContext';
import Webcam from 'icons/webcam';
import useBreakpoint from 'hooks/useBreakpoint';
import ProfileButton from 'components/common/ProfileButton';
import useAccessibility from 'hooks/useAccessibility';
import useProfile from 'hooks/useProfile';
import { ProfileSidebarContext } from 'contexts/ProfileSidebarContext';
import { AccessibilitySidebarContext } from 'contexts/AccessibilitySidebarContext';
import AccessibilitySidebar from 'components/settings/AccessibilitySidebar';
import ProfileSidebar from 'components/settings/ProfileSidebar';
import buttons from 'components/common/Button.module.css';
import { AccessibilityOutline } from 'react-ionicons';
import { PersonOutline } from 'react-ionicons';
import Spinner from 'components/common/Spinner';

const Sidebar = ({ isOpen, onClose, onItemSelect, selectedItem, onCloseAllSidebars }) => {
  const isMobile = useBreakpoint();
  const styles = isMobile ? stylesMobile : stylesDesktop;

  const [menu, setMenu] = useState(null);
  const [loading, setLoading] = useState(true);
  const [liveMessage, setLiveMessage] = useState('');
  const firstNavItemRef = useRef(null);
  const [error, setError] = useState(null);

  // Profil actif
  const { profileOption } = useProfile();

  // Contextes pour les sidebars (desktop)
  const { openProfileSidebar } = useContext(ProfileSidebarContext);
  const { openAccessibilitySidebar } = useContext(AccessibilitySidebarContext);

  // États et handlers pour les sidebars mobiles
  const [isAccessibilitySidebarOpen, setAccessibilitySidebarOpen] = useState(false);
  const [isProfileSidebarOpen, setProfileSidebarOpen] = useState(false);

  const iconMap = {
    facebook: faFacebook,
    twitter: faXTwitter,
    instagram: faInstagram,
    linkedin: faLinkedin,
  };

  const [socialLinks, setSocialLinks] = useState([]);
  const navigate = useNavigate();

  const { isSubSite, subSiteSlug } = useSubSite();

  useEffect(() => {
    const fetchSocialLinks = async () => {
      try {
        const response = await api.get('/general-informations/social-networks');
        setSocialLinks(response.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des réseaux sociaux:', error);
      }
    };

    fetchSocialLinks();
  }, [isSubSite, subSiteSlug]);

  useEffect(() => {
    const fetchMenu = async () => {
      try {
        const response = await api.get(
          isSubSite
            ? `/subsites/${subSiteSlug}/navigation/menu/main`
            : '/navigation/menu/main'
        );
        setMenu(response.data);
        setError(null);
      } catch (error) {
        console.error('Erreur lors de la récupération du menu:', error);
        setError('Impossible de charger le menu.');
      } finally {
        setLoading(false);
      }
    };

    if (isOpen) {
      fetchMenu();
      firstNavItemRef.current?.focus();
    }
  }, [isOpen, isSubSite, subSiteSlug]);

  // Fonction pour gérer la recherche depuis SearchBar
  const handleSearch = (searchTerm) => {
    const searchUrl = isSubSite
      ? `/${subSiteSlug}/recherches?q=${encodeURIComponent(searchTerm)}`
      : `/recherches?q=${encodeURIComponent(searchTerm)}`;
    navigate(searchUrl);
    onCloseAllSidebars();
  };

  // Fonction pour gérer le clic sur une suggestion
  const handleSuggestionClick = (suggestion) => {
    handleSearch(suggestion.label);
  };

  const getItemLink = (item) => {
    if (item.type === 'link') {
      return item.target; // lien direct (externe ou interne)
    } else if (item.type === 'page') {
      return isSubSite
        ? `/${subSiteSlug}/page/${item.target}`
        : `/page/${item.slug}/${item.target}`;
    }
    return null; // pas de lien direct pour les catégories
  };

  const handleAccessibilityClick = () => {
      setAccessibilitySidebarOpen(true);
      openAccessibilitySidebar();
  };

  const handleCloseAccessibilitySidebar = () => {
    setAccessibilitySidebarOpen(false);
  };

  const handleProfileClick = () => {

      setProfileSidebarOpen(true);
      openProfileSidebar();
    
  };

  const handleCloseProfileSidebar = () => {
    setProfileSidebarOpen(false);
  };

  // Variantes du profil actif
  const profileVariants = {
    citoyen: { label: 'Personnaliser ma navigation', icon: <PersonOutline /> },
    touriste: { label: 'Profil actif : Touriste', icon: <PersonOutline /> },
    jeune: { label: 'Profil actif : Jeune', icon: <PersonOutline /> },
    senior: { label: 'Profil actif : Senior', icon: <PersonOutline /> },
    parent: { label: 'Profil actif : Parent', icon: <PersonOutline /> },
    professionnel: { label: 'Profil actif : Professionnel', icon: <PersonOutline /> },
  };

  const { label, icon } = profileVariants[profileOption] || profileVariants.citoyen;

  return (
    <>
      {isOpen && <div className={styles.overlay} onClick={onClose} aria-hidden="true"></div>}

      <div
        className={`${styles.sidebar} ${isOpen ? styles.open : ''}`}
        role="navigation"
        aria-hidden={!isOpen}
        aria-label="Menu de navigation"
      >
        {/* Conteneur pour le header de la sidebar */}
        <div className={styles.headerContainer}>
          {isMobile ? (
            // Barre de recherche pour le mobile avec autocomplétion
            <SearchBar onSearch={handleSearch} isMobile={true} />
          ) : (
            // Bouton "Fermer" pour le desktop
            <div className={styles.closeContainer}>
              <button className={styles.closeButton} onClick={onClose} aria-label="Fermer le menu">
                <div className={styles.lineContainer}>
                  <span className={styles.line}></span>
                  <span className={styles.line}></span>
                </div>
                Fermer
              </button>
            </div>
          )}
        </div>

        <nav className={styles.mainMenu} aria-label="Menu principal">
          <ul className={`list--none ${styles.navList}`}>
            {loading ? (
              <Spinner />
            ) : error ? (
              <p className={styles.error}>{error}</p>
            ) : (
              menu &&
              menu.items.map((item, index) => (
                <NavItem
                  key={item.id}
                  label={item.name}
                  isSelected={selectedItem && selectedItem.id === item.id}
                  ref={index === 0 ? firstNavItemRef : null}
                  slug={item.target}
                  context="sidebar"
                  type={item.type}
                  icon={item.icon}
                  to={getItemLink(item)}
                  onClick={() => {
                    if (item.type === 'category') {
                      onItemSelect(item);
                      setLiveMessage(`${item.name}, sous-menu ouvert`);
                    } else {
                      // Si c'est un lien ou une page, on ferme toutes les sidebars après navigation
                      onCloseAllSidebars();
                    }
                  }}
                />
              ))
            )}
          </ul>

          {isMobile && (
            <div className={styles.buttonContainer}>
              {/* Bouton d'accessibilité */}
              <button
                className={`${buttons.primary} ${buttons.btnOutline} ${styles.accessButton}`}
                onClick={handleAccessibilityClick}
                aria-label="Paramètres d'accessibilité"
                title="Paramètres d'accessibilité"
              >
                <AccessibilityOutline
                  height=""
                  width=""
                  color="currentColor"
                  className={`${styles.icon} ${styles.accessibilityIcon}`}
                />
                <span className={styles.buttonLabel}>Accessibilité</span>
              </button>

              {/* Bouton de personnalisation de profil */}
              {!isSubSite && (
                <ProfileButton
                  onClick={handleProfileClick}
                  ariaLabel={label}
                  title={label}
                  variant={profileOption || 'citoyen'}
                >
                  <PersonOutline
                    height=""
                    width=""
                    color="currentColor"
                    className={`${styles.icon} ${styles.customizeIcon}`}
                  />
                  <span className={styles.buttonLabel}>{label}</span>
                </ProfileButton>
              )}
            </div>
          )}

          <div className={styles.socialsContainer}>
            <p className={styles.socialsLabel}>Retrouvez-nous sur les réseaux sociaux :</p>
            <ul className={`list--none ${styles.socials}`}>
              <li>
                <a
                  href="/webcams"
                  className={styles.socialsIcon}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Ouvrir la webcam de la ville d'Arras"
                  title="Ouvrir la webcam de la ville d'Arras"
                >
                  <Webcam />
                </a>
              </li>
              {socialLinks.map((link) => (
                <li key={link.name}>
                  <a
                    href={link.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.socialsIcon}
                    aria-label={link.name}
                  >
                    <FontAwesomeIcon icon={iconMap[link.icon] || faFacebook} />
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </nav>

        {isMobile && (
          <button className={styles.closeButton} onClick={onClose} aria-label="Ferme le menu">
            <div className={styles.burger}>
              <span className={styles.line}></span>
              <span className={styles.line}></span>
            </div>
            <span className={styles.label}>Fermer</span>
          </button>
        )}

        {/* Sidebars spécifiques au mobile */}
        {isMobile && isAccessibilitySidebarOpen && (
          <AccessibilitySidebar
            onClose={handleCloseAccessibilitySidebar}
            onSave={(options) => console.log("Options d'accessibilité enregistrées:", options)}
          />
        )}

        {isMobile && isProfileSidebarOpen && !isSubSite && (
          <ProfileSidebar
            onClose={handleCloseProfileSidebar}
            onSave={(options) => console.log("Options de profil enregistrées:", options)}
          />
        )}
      </div>

      <div aria-live="polite" className="sr-only">
        {liveMessage}
      </div>
    </>
  );
};

Sidebar.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onItemSelect: PropTypes.func,
  selectedItem: PropTypes.object,
  onCloseAllSidebars: PropTypes.func,
};

export default Sidebar;
