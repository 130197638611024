// components/WorkInProgressCircle.jsx
import React from 'react';
import { Circle, Popup } from 'react-leaflet';

const WorkInProgressCircle = ({ travail }) => {
  // Calculer le rayon à partir de l'aire affectée
  const area = parseFloat(travail.affectedArea); // Assurez-vous que c'est un nombre
  const radius = Math.sqrt(area / Math.PI); // Rayon en mètres

  return (
    <Circle
      center={[travail.latitude, travail.longitude]}
      radius={radius} // En mètres
      color="hsla(356, 95%, 46%, 1)" // Vous pouvez personnaliser la couleur
      fillColor="hsla(356, 95%, 46%, 1)"
      fillOpacity={0.5}
    >
      <Popup>
        <h3>Travaux en cours</h3>
        <p>{travail.description}</p>
        <p>Date de début : {travail.startDate}</p>
        <p>Date de fin prévue : {travail.endDate}</p>
        <p>Zone affectée : {travail.affectedArea} m²</p>
        <p>Statut : {travail.status}</p>
        {/* Afficher le district si disponible */}
        {travail.district && <p>Quartier : {travail.district.name}</p>}
      </Popup>
    </Circle>
  );
};

export default WorkInProgressCircle;
