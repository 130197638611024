// AccessibilitySidebarContext.js
import React, { createContext, useState } from 'react';

export const AccessibilitySidebarContext = createContext();

export const AccessibilitySidebarProvider = ({ children }) => {
  const [isAccessibilitySidebarOpen, setAccessibilitySidebarOpen] = useState(false);

  const openAccessibilitySidebar = () => setAccessibilitySidebarOpen(true);
  const closeAccessibilitySidebar = () => setAccessibilitySidebarOpen(false);

  return (
    <AccessibilitySidebarContext.Provider
      value={{
        isAccessibilitySidebarOpen,
        openAccessibilitySidebar,
        closeAccessibilitySidebar,
      }}
    >
      {children}
    </AccessibilitySidebarContext.Provider>
  );
};
