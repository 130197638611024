// MatomoScript.js
import { useEffect } from 'react';

const MatomoScript = () => {
  useEffect(() => {
    // Créer la variable globale _paq si elle n'existe pas déjà
    window._paq = window._paq || [];
    window._paq.push(['trackPageView']);
    window._paq.push(['enableLinkTracking']);
    
    // Création et insertion du script Matomo dans le DOM
    const u = "//stats.arras.fr/";
    window._paq.push(['setTrackerUrl', u + 'matomo.php']);
    window._paq.push(['setSiteId', '1']);
    
    const d = document;
    const g = d.createElement('script');
    const s = d.getElementsByTagName('script')[0];
    g.async = true;
    g.src = u + 'matomo.js';
    s.parentNode.insertBefore(g, s);
  }, []);

  return null; // Ce composant n'affiche rien
};

export default MatomoScript;
