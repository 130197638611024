// MapCta.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import styles from './MapCta.module.css';
import { ReactComponent as CarteInteractive } from 'icons/carte_interactive.svg';
import { useSubSite } from 'contexts/SubSiteContext';
import useDesignSettingsData from 'hooks/useDesignSettingsData';
import Spinner from 'components/common/Spinner'

const MapCta = () => {
  const { isSubSite, subSiteSlug } = useSubSite();
  const { designSettings, settingsLoading, error } = useDesignSettingsData();

  if (settingsLoading) return <div><Spinner /></div>;
  if (error) return <div>Erreur : {error}</div>;

  let imgURL = '';
  if (designSettings.defaultMapBanner) {
    imgURL = designSettings.defaultMapBanner;
  }

  const mapURL = isSubSite ? `/${subSiteSlug}/carte` : '/carte';

  return (
    <section className={`global-section ${styles.cta}`}>
      {isSubSite ? (
        <div className={`global-container ${styles.subsiteCtaContainer}`}>
          <figure className={styles.mediaContainer}>
            <img
              src={imgURL || '/images/carte_interactive_schema.webp'}
              alt="Carte interactive d'Arras"
              title="Carte interactive d'Arras"
              aria-label="Carte interactive d'Arras"
            />
          </figure>
          <div className={styles.content}>
            <CarteInteractive className={styles.icon} />
            <h2>
              Accédez à notre <span>Carte Interactive</span>
            </h2>
            <p>
              Découvrez les différents événements, parkings, espaces verts,
              structures, ...
            </p>
            <Link to={mapURL} className={styles.subsiteButton}>
              Explorer Arras
            </Link>
          </div>
        </div>
      ) : (
        <div className={`global-container ${styles.ctaContainer}`}>
          <figure className={styles.mediaContainer}>
            <img
              src={imgURL || '/images/placeholder_img.webp'}
              alt="Carte interactive d'Arras"
              title="Carte interactive d'Arras"
              aria-label="Carte interactive d'Arras"
            />
          </figure>
          <div className={styles.content}>
            <CarteInteractive className={styles.icon} />
            <h2>
              Accédez à notre <span>Carte Interactive</span>
            </h2>
            <p>
              Découvrez les différents événements, parkings, espaces verts,
              structures, ...
            </p>
            <Link to={mapURL} className={styles.greenButton} aria-label="Explorer Arras">
              Explorer Arras
            </Link>
          </div>
        </div>
      )}
    </section>
  );
};

export default MapCta;

