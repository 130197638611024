// components/WorksLayer.jsx
import React from 'react';
import { LayersControl, FeatureGroup } from 'react-leaflet';
import WorkInProgressCircle from './WorkInProgressCircle';

const WorksLayer = ({ travaux }) => {
  if (travaux.length === 0) {
    return null;
  }

  return (
    <LayersControl.Overlay name="Travaux en cours" checked>
      <FeatureGroup>
        {travaux.map((travail) => (
          <WorkInProgressCircle key={travail.id} travail={travail} />
        ))}
      </FeatureGroup>
    </LayersControl.Overlay>
  );
};

export default WorksLayer;
