import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import api from 'services/api';

function useDesignSettingsData() {
  const location = useLocation();

  const [designSettings, setDesignSettings] = useState(null);
  const [settingsLoading, setSettingsLoading] = useState(true);
  const [error, setError] = useState(null);

  const [subSiteSlug, setSubSiteSlug] = useState(undefined);
  const [isSubSite, setIsSubSite] = useState(false);

  // Déterminer subSiteSlug à partir de l'URL
  useEffect(() => {
    const pathSegments = location.pathname.split('/').filter(Boolean);
    const slug = pathSegments[0] || null;
    setSubSiteSlug(slug);
  }, [location]);

  // Une fois que subSiteSlug est connu (qu'il soit null ou non), on vérifie la whitelist (si nécessaire)
  // puis on récupère les design settings.
  useEffect(() => {
    // On attend que subSiteSlug soit défini (il peut être null mais pas undefined)
    if (subSiteSlug === undefined) return;

    const fetchData = async () => {
      setSettingsLoading(true);
      setError(null);

      try {
        let endpoint;

        // Si on a un slug, vérifier d'abord la whitelist
        if (subSiteSlug) {
          const whitelistResponse = await api.get('/subsites/slugs');
          const whitelist = whitelistResponse.data;
          const isSub = whitelist.includes(subSiteSlug);
          setIsSubSite(isSub);

          endpoint = isSub
            ? `/subsites/${subSiteSlug}/settings/design`
            : '/settings/design';
        } else {
          // Pas de slug => endpoint principal
          setIsSubSite(false);
          endpoint = '/settings/design';
        }

        // Une fois qu'on sait si c'est un sous-site ou non, on récupère les design settings
        const response = await api.get(endpoint);
        setDesignSettings(response.data);
      } catch (err) {
        console.error('Erreur lors de la récupération des paramètres de design:', err);
        setError('Impossible de charger les paramètres de design.');
      } finally {
        setSettingsLoading(false);
      }
    };

    fetchData();
  }, [subSiteSlug]);

  return { designSettings, settingsLoading, error };
}

export default useDesignSettingsData;
