import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import buttons from 'components/common/Button.module.css';
import styles from './SearchBar.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import api from 'services/api'; // Pour les requêtes à l'API
import Spinner from 'components/common/Spinner';

const SearchBar = ({ onSearch }) => {
  const [query, setQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // Appeler l'API pour les suggestions
  useEffect(() => {
    if (query.length > 2) {
      setIsLoading(true);
      api
        .get('/autocomplete', { params: { q: query } })
        .then((response) => {
          if (response.data && response.data.suggestions) {
            const { pages, events, kiosks, associations, news } = response.data.suggestions;

            // Rassembler toutes les suggestions dans un tableau unique
            const results = [
              ...pages.map((item) => ({ label: item.title, type: 'Page' })),
              ...events.map((item) => ({ label: item.title, type: 'Event' })),
              ...kiosks.map((item) => ({ label: item.name || 'Kiosk', type: 'Kiosk' })),
              ...associations.map((item) => ({ label: item.title, type: 'Association' })),
              ...news.map((item) => ({ label: item.title, type: 'News' })),
            ];
            setSuggestions(results);
          }
        })
        .catch((error) => {
          console.error('Erreur lors de la récupération des suggestions:', error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setSuggestions([]);
    }
  }, [query]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSearch(query);
    setSuggestions([]); // Vider les suggestions après la recherche
  };

  const handleSuggestionClick = (suggestion) => {
    setQuery(suggestion.label);
    onSearch(suggestion.label);
    setSuggestions([]);
  };

  return (
    <div className={styles.searchBarContainer}>
      <form className={styles.searchForm} onSubmit={handleSubmit}>
        <label htmlFor="search" className="sr-only">
          Rechercher
        </label>
        <input
          type="text"
          id="search"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Rechercher sur le site"
          className={styles.searchInput}
          aria-label="Champ de recherche"
        />
        <button
          type="submit"
          className={`${buttons.secondary} ${styles.searchButton}`}
          aria-label="Lancer la recherche"
          title="Lancer la recherche"
        >
          <FontAwesomeIcon icon={faMagnifyingGlass} />
          <span>Rechercher</span>
        </button>
      </form>

      {/* Liste des suggestions */}
      {suggestions.length > 0 && (
        <ul className={styles.suggestionsList}>
          {suggestions.map((suggestion, index) => (
            <li
              key={`${suggestion.type}-${index}`}
              className={styles.suggestionItem}
              onClick={() => handleSuggestionClick(suggestion)}
              role="button"
              tabIndex={0}
              onKeyPress={() => handleSuggestionClick(suggestion)}
            >
              <span className={styles.suggestionLabel}>{suggestion.label}</span>
              <span className={styles.suggestionType}>({suggestion.type})</span>
            </li>
          ))}
        </ul>
      )}

      {isLoading && <div className={styles.loading}><Spinner /></div>}
    </div>
  );
};

SearchBar.propTypes = {
  onSearch: PropTypes.func.isRequired,
};

export default SearchBar;
