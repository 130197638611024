import React from 'react';
import PropTypes from 'prop-types';
import styles from './PictoAccess.module.css';
import useAccessibility from 'hooks/useAccessibility';

const PictoAccess = ({ iframeHtml, width = '400', height = '150' }) => {
  const accessibilityVariant = useAccessibility();

  // Parse the iframe HTML string and modify width and height
  const modifiedIframeHtml = iframeHtml
    .replace(/width=".*?"/, `width="${width}"`)
    .replace(/height=".*?"/, `height="${height}"`);

  return (
    <div className={styles.pictoAccess}>
      <h3>Accessibilité</h3>
      {/* Inject the modified HTML */}
      <div dangerouslySetInnerHTML={{ __html: modifiedIframeHtml }} />
    </div>
  );
};

PictoAccess.propTypes = {
  iframeHtml: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default PictoAccess;
