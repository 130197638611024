import React, { useEffect, useState } from 'react';
import api from 'services/api';
import NewsCard from 'components/news/NewsCard';
import ListItem from 'components/archives/ListItem';
import LoadMoreButton from 'components/common/LoadMoreButton';
import MapCta from 'components/common/MapCta';
import LiensPratiques from 'components/common/LiensPratiques';
import ArchivesHeader from 'components/archives/ArchivesHeader';
import ArchivesFilters from 'components/archives/ArchivesFilters';
import ToggleView from 'components/archives/ToggleView';
import ArchivesGridView from 'components/archives/ArchivesGridView';
import ArchivesListView from 'components/archives/ArchivesListView';
import useFilter from 'hooks/useFilter';
import styles from 'styles/modules/Archives.module.css';
import { useSubSite } from 'contexts/SubSiteContext';
import useDesignSettingsData from 'hooks/useDesignSettingsData';
import { Helmet } from 'react-helmet';
import Spinner from 'components/common/Spinner';

const BlogPage = () => {
  const [news, setNews] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [view, setView] = useState('grid');

  const { isSubSite, subSiteSlug } = useSubSite();
  const { designSettings, settingsLoading, error } = useDesignSettingsData();

  // Appel du hook useFilter avant tout return
  const {
    filteredItems,
    displayCount,
    selectedCategory,
    setSelectedCategory,
    searchQuery,
    setSearchQuery,
    loadMore,
    resetFilters,
  } = useFilter(news);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (isSubSite) {
          const response = await api.get(`/subsites/${subSiteSlug}/news`);
          setNews(response.data);
        } else {
          const response = await api.get('/news');
          setNews(response.data);
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des données :', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [isSubSite, subSiteSlug]);

  // Vérifications de chargement et d'erreur après l'appel des hooks
  if (settingsLoading) return <div><Spinner /></div>;
  if (error) return <div>Erreur : {error}</div>;

  const eventURL = isSubSite ? `/${subSiteSlug}/actualite` : '/actualite';

  const handleFilterChange = (category) => setSelectedCategory(category);
  const handleSearchChange = (e) => setSearchQuery(e.target.value);

  return (
    <>
      <Helmet>
        <title>Arras | Actualités</title>
        <meta name="description" content="Suivez toute l’actualité de la ville d’Arras : événements, projets, initiatives locales et informations pratiques. Restez informé(e) sur tout ce qui fait vivre votre ville." />
        <meta property="og:title" content="Arras | Actualités" />
        <meta property="og:description" content="Découvrez les dernières actualités d’Arras : événements, nouveautés, et initiatives locales. Ne manquez rien de ce qui se passe dans votre ville !" />
        <meta property="og:image" content={`${designSettings.defaultNewsBanner}`} />
        <meta property="og:image:alt" content={`Image descriptive pour la page Actualités`} />
        <link rel="canonical" href={`https://www.arras.fr/actualite`} />
      </Helmet>
      <div className={styles.pageWrapper}>
        <ArchivesHeader
          title="Actualités"
          imageUrl={`${designSettings.defaultNewsBanner}`}
        />
        <div className={`page-content ${styles.pageContent}`}>
          <section className={`global-section ${styles.headerSection}`}>
            <div className={`global-container ${styles.offsetHeader}`}>
              <ArchivesFilters
                categories={categories}
                selectedCategory={selectedCategory}
                handleFilterChange={handleFilterChange}
                searchQuery={searchQuery}
                handleSearchChange={handleSearchChange}
                resetFilters={resetFilters}
              />
            </div>
          </section>

          <section className={`global-section ${styles.contentSection}`}>
            <div className="global-container">
              <ToggleView view={view} setView={setView} />
              {loading ? (
                <div><Spinner /></div>
              ) : filteredItems && filteredItems.length > 0 ? (
                view === 'grid' ? (
                  <ArchivesGridView
                    items={filteredItems}
                    renderItem={(newsItem) => (
                      <NewsCard key={newsItem.id} newsItem={newsItem} />
                    )}
                    displayCount={displayCount}
                  />
                ) : (
                  <ArchivesListView
                    items={filteredItems}
                    renderItem={(newsItem) => (
                      <ListItem
                        key={newsItem.id}
                        title={newsItem.title}
                        meta={[`Publié le ${new Date(newsItem.createdAt).toLocaleDateString()}`]}
                        description={
                          newsItem.content[0]?.content
                            ? newsItem.content[0].content.replace(/<\/?[^>]+(>|$)/g, '').slice(0, 100) + '...'
                            : 'Aucune description disponible.'
                        }
                        categories={newsItem.categories}
                        link={`${eventURL}/${newsItem.slug}`}
                      />
                    )}
                    displayCount={displayCount}
                  />
                )
              ) : (
                <p>Aucune actualité trouvée.</p>
              )}
              {displayCount < filteredItems.length && (
                <LoadMoreButton onClick={loadMore}>Charger plus</LoadMoreButton>
              )}
            </div>
          </section>
          <MapCta />
          <LiensPratiques />
        </div>
      </div>
    </>
  );
};

export default BlogPage;
