import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import api from 'services/api';

// Création du contexte
const SubSiteContext = createContext({
  isSubSite: false,
  subSiteSlug: null,
  loading: true,
  error: null,
});

// Mapping des thèmes pour les subSiteSlugs spécifiques
const themeMapping = {
  'projet-abbaye-saint-vaast': {
    /* Palette Primaire - Tons Pastel Bleus */
    '--primary': 'hsla(193, 79%, 38%, 1)',               // Bleu pastel moyen
    '--primary-light': 'hsla(193, 79%, 50%, 1)',         // Bleu pastel clair
    '--primary-ultra-light': 'hsla(193, 79%, 70%, 1)',  // Bleu pastel très clair
    '--primary-dark': 'hsla(193, 79%, 30%, 1)',          // Bleu pastel foncé
    '--primary-ultra-dark': 'hsla(193, 79%, 15%, 1)',   // Bleu pastel très foncé

    /* Palette Secondaire - Tons Pastel Bleus Dégradés */
    '--secondary': 'hsla(193, 79%, 38%, 1)',
    '--secondary-light': 'hsla(193, 79%, 50%, 1)',
    '--secondary-ultra-light': 'hsla(193, 79%, 70%, 1)',
    '--secondary-dark': 'hsla(193, 79%, 30%, 1)',
    '--secondary-ultra-dark': 'hsla(193, 79%, 15%, 1)',

    '--services': 'hsla(193, 79%, 38%, 1)',
    '--services-light': 'hsla(193, 79%, 50%, 1)',
    '--services-ultra-light': 'hsla(193, 79%, 70%, 1)',
    '--services-dark': 'hsla(193, 79%, 30%, 1)',
    '--services-ultra-dark': 'hsla(193, 79%, 15%, 1)',

    '--carte': 'hsla(193, 79%, 38%, 1)',
    '--carte-light': 'hsla(193, 79%, 50%, 1)',
    '--carte-ultra-light': 'hsla(193, 79%, 70%, 1)',
    '--carte-dark': 'hsla(193, 79%, 30%, 1)',
    '--carte-ultra-dark': 'hsla(193, 79%, 15%, 1)',

    '--events': 'hsla(193, 79%, 38%, 1)',
    '--events-light': 'hsla(193, 79%, 50%, 1)',
    '--events-ultra-light': 'hsla(193, 79%, 70%, 1)',
    '--events-dark': 'hsla(193, 79%, 30%, 1)',
    '--events-ultra-dark': 'hsla(193, 79%, 15%, 1)',

    '--contact': 'hsla(193, 79%, 38%, 1)',
    '--contact-light': 'hsla(193, 79%, 50%, 1)',
    '--contact-ultra-light': 'hsla(193, 79%, 70%, 1)',
    '--contact-dark': 'hsla(193, 79%, 30%, 1)',
    '--contact-ultra-dark': 'hsla(193, 79%, 15%, 1)',

    /* Accessibility */

    '--red-green-primary': 'hsla(215, 60%, 52%, 1)',
    '--red-green-primary-light': 'hsla(215, 60%, 65%, 1)',
    '--red-green-primary-ultra-light': 'hsla(215, 60%, 80%, 1)',
    '--red-green-primary-dark': 'hsla(215, 60%, 45%, 1)',
    '--red-green-primary-ultra-dark': 'hsla(215, 60%, 30%, 1)',

    '--red-green-secondary': 'hsla(215, 60%, 52%, 1)',
    '--red-green-secondary-light': 'hsla(215, 60%, 65%, 1)',
    '--red-green-secondary-ultra-light': 'hsla(215, 60%, 80%, 1)',
    '--red-green-secondary-dark': 'hsla(215, 60%, 45%, 1)',
    '--red-green-secondary-ultra-dark': 'hsla(215, 60%, 30%, 1)',

    '--red-green-services': 'hsla(215, 60%, 52%, 1)',
    '--red-green-services-light': 'hsla(215, 60%, 65%, 1)',
    '--red-green-services-ultra-light': 'hsla(215, 60%, 80%, 1)',
    '--red-green-services-dark': 'hsla(215, 60%, 45%, 1)',
    '--red-green-services-ultra-dark': 'hsla(215, 60%, 30%, 1)',

    '--red-green-carte': 'hsla(215, 60%, 52%, 1)',
    '--red-green-carte-light': 'hsla(215, 60%, 65%, 1)',
    '--red-green-carte-ultra-light': 'hsla(215, 60%, 80%, 1)',
    '--red-green-carte-dark': 'hsla(215, 60%, 45%, 1)',
    '--red-green-carte-ultra-dark': 'hsla(215, 60%, 30%, 1)',

    '--red-green-events': 'hsla(215, 60%, 52%, 1)',
    '--red-green-events-light': 'hsla(215, 60%, 65%, 1)',
    '--red-green-events-ultra-light': 'hsla(215, 60%, 80%, 1)',
    '--red-green-events-dark': 'hsla(215, 60%, 45%, 1)',
    '--red-green-events-ultra-dark': 'hsla(215, 60%, 30%, 1)',

    '--red-green-contact': 'hsla(215, 60%, 52%, 1)',
    '--red-green-contact-light': 'hsla(215, 60%, 65%, 1)',
    '--red-green-contact-ultra-light': 'hsla(215, 60%, 80%, 1)',
    '--red-green-contact-dark': 'hsla(215, 60%, 45%, 1)',
    '--red-green-contact-ultra-dark': 'hsla(215, 60%, 30%, 1)',


    '--blue-yellow-primary': 'hsla(198, 60%, 45%, 1)',
    '--blue-yellow-primary-light': 'hsla(198, 60%, 55%, 1)',
    '--blue-yellow-primary-ultra-light': 'hsla(198, 60%, 70%, 1)',
    '--blue-yellow-primary-dark': 'hsla(198, 60%, 35%, 1)',
    '--blue-yellow-primary-ultra-dark': 'hsla(198, 60%, 20%, 1)',

    '--blue-yellow-secondary': 'hsla(198, 60%, 45%, 1)',
    '--blue-yellow-secondary-light': 'hsla(198, 60%, 55%, 1)',
    '--blue-yellow-secondary-ultra-light': 'hsla(198, 60%, 70%, 1)',
    '--blue-yellow-secondary-dark': 'hsla(198, 60%, 35%, 1)',
    '--blue-yellow-secondary-ultra-dark': 'hsla(198, 60%, 20%, 1)',

    '--blue-yellow-services': 'hsla(198, 60%, 45%, 1)',
    '--blue-yellow-services-light': 'hsla(198, 60%, 55%, 1)',
    '--blue-yellow-services-ultra-light': 'hsla(198, 60%, 70%, 1)',
    '--blue-yellow-services-dark': 'hsla(198, 60%, 35%, 1)',
    '--blue-yellow-services-ultra-dark': 'hsla(198, 60%, 20%, 1)',

    '--blue-yellow-carte': 'hsla(198, 60%, 45%, 1)',
    '--blue-yellow-carte-light': 'hsla(198, 60%, 55%, 1)',
    '--blue-yellow-carte-ultra-light': 'hsla(198, 60%, 70%, 1)',
    '--blue-yellow-carte-dark': 'hsla(198, 60%, 35%, 1)',
    '--blue-yellow-carte-ultra-dark': 'hsla(198, 60%, 20%, 1)',

    '--blue-yellow-events': 'hsla(198, 60%, 45%, 1)',
    '--blue-yellow-events-light': 'hsla(198, 60%, 55%, 1)',
    '--blue-yellow-events-ultra-light': 'hsla(198, 60%, 70%, 1)',
    '--blue-yellow-events-dark': 'hsla(198, 60%, 35%, 1)',
    '--blue-yellow-events-ultra-dark': 'hsla(198, 60%, 20%, 1)',

    '--blue-yellow-contact': 'hsla(198, 60%, 45%, 1)',
    '--blue-yellow-contact-light': 'hsla(198, 60%, 55%, 1)',
    '--blue-yellow-contact-ultra-light': 'hsla(198, 60%, 70%, 1)',
    '--blue-yellow-contact-dark': 'hsla(198, 60%, 35%, 1)',
    '--blue-yellow-contact-ultra-dark': 'hsla(198, 60%, 20%, 1)',


    '--black-white-primary': 'hsla(0, 0%, 40%, 1)',
    '--black-white-primary-light': 'hsla(0, 0%, 50%, 1)',
    '--black-white-primary-ultra-light': 'hsla(0, 0%, 70%, 1)',
    '--black-white-primary-dark': 'hsla(0, 0%, 25%, 1)',
    '--black-white-primary-ultra-dark': 'hsla(0, 0%, 15%, 1)',

    '--black-white-secondary': 'hsla(0, 0%, 40%, 1)',
    '--black-white-secondary-light': 'hsla(0, 0%, 50%, 1)',
    '--black-white-secondary-ultra-light': 'hsla(0, 0%, 70%, 1)',
    '--black-white-secondary-dark': 'hsla(0, 0%, 25%, 1)',
    '--black-white-secondary-ultra-dark': 'hsla(0, 0%, 15%, 1)',

    '--black-white-services': 'hsla(0, 0%, 40%, 1)',
    '--black-white-services-light': 'hsla(0, 0%, 50%, 1)',
    '--black-white-services-ultra-light': 'hsla(0, 0%, 70%, 1)',
    '--black-white-services-dark': 'hsla(0, 0%, 25%, 1)',
    '--black-white-services-ultra-dark': 'hsla(0, 0%, 15%, 1)',

    '--black-white-carte': 'hsla(0, 0%, 40%, 1)',
    '--black-white-carte-light': 'hsla(0, 0%, 50%, 1)',
    '--black-white-carte-ultra-light': 'hsla(0, 0%, 70%, 1)',
    '--black-white-carte-dark': 'hsla(0, 0%, 25%, 1)',
    '--black-white-carte-ultra-dark': 'hsla(0, 0%, 15%, 1)',

    '--black-white-events': 'hsla(0, 0%, 40%, 1)',
    '--black-white-events-light': 'hsla(0, 0%, 50%, 1)',
    '--black-white-events-ultra-light': 'hsla(0, 0%, 70%, 1)',
    '--black-white-events-dark': 'hsla(0, 0%, 25%, 1)',
    '--black-white-events-ultra-dark': 'hsla(0, 0%, 15%, 1)',

    '--black-white-contact': 'hsla(0, 0%, 40%, 1)',
    '--black-white-contact-light': 'hsla(0, 0%, 50%, 1)',
    '--black-white-contact-ultra-light': 'hsla(0, 0%, 70%, 1)',
    '--black-white-contact-dark': 'hsla(0, 0%, 25%, 1)',
    '--black-white-contact-ultra-dark': 'hsla(0, 0%, 15%, 1)',


    '--dark-primary': 'hsla(193, 79%, 38%, 1)',
    '--dark-primary-light': 'hsla(193, 79%, 50%, 1)',
    '--dark-primary-ultra-light': 'hsla(193, 79%, 70%, 1)',
    '--dark-primary-dark': 'hsla(193, 79%, 30%, 1)',
    '--dark-primary-ultra-dark': 'hsla(193, 79%, 15%, 1)',

    '--dark-secondary': 'hsla(193, 79%, 38%, 1)',
    '--dark-secondary-light': 'hsla(193, 79%, 50%, 1)',
    '--dark-secondary-ultra-light': 'hsla(193, 79%, 70%, 1)',
    '--dark-secondary-dark': 'hsla(193, 79%, 30%, 1)',
    '--dark-secondary-ultra-dark': 'hsla(193, 79%, 15%, 1)',

    '--dark-services': 'hsla(193, 79%, 38%, 1)',
    '--dark-services-light': 'hsla(193, 79%, 50%, 1)',
    '--dark-services-ultra-light': 'hsla(193, 79%, 70%, 1)',
    '--dark-services-dark': 'hsla(193, 79%, 30%, 1)',
    '--dark-services-ultra-dark': 'hsla(193, 79%, 15%, 1)',

    '--dark-carte': 'hsla(193, 79%, 38%, 1)',
    '--dark-carte-light': 'hsla(193, 79%, 50%, 1)',
    '--dark-carte-ultra-light': 'hsla(193, 79%, 70%, 1)',
    '--dark-carte-dark': 'hsla(193, 79%, 30%, 1)',
    '--dark-carte-ultra-dark': 'hsla(193, 79%, 15%, 1)',

    '--dark-events': 'hsla(193, 79%, 38%, 1)',
    '--dark-events-light': 'hsla(193, 79%, 50%, 1)',
    '--dark-events-ultra-light': 'hsla(193, 79%, 70%, 1)',
    '--dark-events-dark': 'hsla(193, 79%, 30%, 1)',
    '--dark-events-ultra-dark': 'hsla(193, 79%, 15%, 1)',

    '--dark-contact': 'hsla(193, 79%, 38%, 1)',
    '--dark-contact-light': 'hsla(193, 79%, 50%, 1)',
    '--dark-contact-ultra-light': 'hsla(193, 79%, 70%, 1)',
    '--dark-contact-dark': 'hsla(193, 79%, 30%, 1)',
    '--dark-contact-ultra-dark': 'hsla(193, 79%, 15%, 1)',

  },
  'ville-de-noel': {
    /* Palette Primaire - Tons Pastel Bleus */
    '--primary': 'hsla(356, 95%, 36%, 1)',
    '--primary-light': 'hsla(356, 95%, 46%, 1)',
    '--primary-ultra-light': 'hsla(356, 95%, 65%, 1)',
    '--primary-dark': 'hsla(356, 95%, 26%, 1)',
    '--primary-ultra-dark': 'hsla(356, 95%, 15%, 1)',

    /* Palette Secondaire - Tons Pastel Bleus Dégradés */
    '--secondary': 'hsla(356, 95%, 36%, 1)',
    '--secondary-light': 'hsla(356, 95%, 46%, 1)',
    '--secondary-ultra-light': 'hsla(356, 95%, 65%, 1)',
    '--secondary-dark': 'hsla(356, 95%, 26%, 1)',
    '--secondary-ultra-dark': 'hsla(356, 95%, 15%, 1)',

    '--services': 'hsla(356, 95%, 36%, 1)',
    '--services-light': 'hsla(356, 95%, 46%, 1)',
    '--services-ultra-light': 'hsla(356, 95%, 65%, 1)',
    '--services-dark': 'hsla(356, 95%, 26%, 1)',
    '--services-ultra-dark': 'hsla(356, 95%, 15%, 1)',

    '--carte': 'hsla(356, 95%, 36%, 1)',
    '--carte-light': 'hsla(356, 95%, 46%, 1)',
    '--carte-ultra-light': 'hsla(356, 95%, 65%, 1)',
    '--carte-dark': 'hsla(356, 95%, 26%, 1)',
    '--carte-ultra-dark': 'hsla(356, 95%, 15%, 1)',

    '--events': 'hsla(356, 95%, 36%, 1)',
    '--events-light': 'hsla(356, 95%, 46%, 1)',
    '--events-ultra-light': 'hsla(356, 95%, 65%, 1)',
    '--events-dark': 'hsla(356, 95%, 26%, 1)',
    '--events-ultra-dark': 'hsla(356, 95%, 15%, 1)',

    '--contact': 'hsla(356, 95%, 36%, 1)',
    '--contact-light': 'hsla(356, 95%, 46%, 1)',
    '--contact-ultra-light': 'hsla(356, 95%, 65%, 1)',
    '--contact-dark': 'hsla(356, 95%, 26%, 1)',
    '--contact-ultra-dark': 'hsla(356, 95%, 15%, 1)',

    /* Accessibility */

    '--red-green-primary': 'hsla(0, 100%, 27%, 1)',
    '--red-green-primary-light': 'hsla(0, 100%, 37%, 1)',
    '--red-green-primary-ultra-light': 'hsla(0, 100%, 50%, 1)',
    '--red-green-primary-dark': 'hsla(0, 100%, 17%, 1)',
    '--red-green-primary-ultra-dark': 'hsla(0, 100%, 10%, 1)',

    '--red-green-secondary': 'hsla(0, 100%, 27%, 1)',
    '--red-green-secondary-light': 'hsla(0, 100%, 37%, 1)',
    '--red-green-secondary-ultra-light': 'hsla(0, 100%, 50%, 1)',
    '--red-green-secondary-dark': 'hsla(0, 100%, 17%, 1)',
    '--red-green-secondary-ultra-dark': 'hsla(0, 100%, 10%, 1)',

    '--red-green-services': 'hsla(0, 100%, 27%, 1)',
    '--red-green-services-light': 'hsla(0, 100%, 37%, 1)',
    '--red-green-services-ultra-light': 'hsla(0, 100%, 50%, 1)',
    '--red-green-services-dark': 'hsla(0, 100%, 17%, 1)',
    '--red-green-services-ultra-dark': 'hsla(0, 100%, 10%, 1)',

    '--red-green-carte': 'hsla(0, 100%, 27%, 1)',
    '--red-green-carte-light': 'hsla(0, 100%, 37%, 1)',
    '--red-green-carte-ultra-light': 'hsla(0, 100%, 50%, 1)',
    '--red-green-carte-dark': 'hsla(0, 100%, 17%, 1)',
    '--red-green-carte-ultra-dark': 'hsla(0, 100%, 10%, 1)',

    '--red-green-events': 'hsla(0, 100%, 27%, 1)',
    '--red-green-events-light': 'hsla(0, 100%, 37%, 1)',
    '--red-green-events-ultra-light': 'hsla(0, 100%, 50%, 1)',
    '--red-green-events-dark': 'hsla(0, 100%, 17%, 1)',
    '--red-green-events-ultra-dark': 'hsla(0, 100%, 10%, 1)',

    '--red-green-contact': 'hsla(0, 100%, 27%, 1)',
    '--red-green-contact-light': 'hsla(0, 100%, 37%, 1)',
    '--red-green-contact-ultra-light': 'hsla(0, 100%, 50%, 1)',
    '--red-green-contact-dark': 'hsla(0, 100%, 17%, 1)',
    '--red-green-contact-ultra-dark': 'hsla(0, 100%, 10%, 1)',


    '--blue-yellow-primary': 'hsla(0, 100%, 27%, 1)',
    '--blue-yellow-primary-light': 'hsla(0, 100%, 37%, 1)',
    '--blue-yellow-primary-ultra-light': 'hsla(0, 100%, 60%, 1)',
    '--blue-yellow-primary-dark': 'hsla(0, 100%, 18%, 1)',
    '--blue-yellow-primary-ultra-dark': 'hsla(0, 100%, 10%, 1)',

    '--blue-yellow-secondary': 'hsla(0, 100%, 27%, 1)',
    '--blue-yellow-secondary-light': 'hsla(0, 100%, 37%, 1)',
    '--blue-yellow-secondary-ultra-light': 'hsla(0, 100%, 60%, 1)',
    '--blue-yellow-secondary-dark': 'hsla(0, 100%, 18%, 1)',
    '--blue-yellow-secondary-ultra-dark': 'hsla(0, 100%, 10%, 1)',

    '--blue-yellow-services': 'hsla(0, 100%, 27%, 1)',
    '--blue-yellow-services-light': 'hsla(0, 100%, 37%, 1)',
    '--blue-yellow-services-ultra-light': 'hsla(0, 100%, 60%, 1)',
    '--blue-yellow-services-dark': 'hsla(0, 100%, 18%, 1)',
    '--blue-yellow-services-ultra-dark': 'hsla(0, 100%, 10%, 1)',

    '--blue-yellow-carte': 'hsla(0, 100%, 27%, 1)',
    '--blue-yellow-carte-light': 'hsla(0, 100%, 37%, 1)',
    '--blue-yellow-carte-ultra-light': 'hsla(0, 100%, 60%, 1)',
    '--blue-yellow-carte-dark': 'hsla(0, 100%, 18%, 1)',
    '--blue-yellow-carte-ultra-dark': 'hsla(0, 100%, 10%, 1)',

    '--blue-yellow-events': 'hsla(0, 100%, 27%, 1)',
    '--blue-yellow-events-light': 'hsla(0, 100%, 37%, 1)',
    '--blue-yellow-events-ultra-light': 'hsla(0, 100%, 60%, 1)',
    '--blue-yellow-events-dark': 'hsla(0, 100%, 18%, 1)',
    '--blue-yellow-events-ultra-dark': 'hsla(0, 100%, 10%, 1)',

    '--blue-yellow-contact': 'hsla(0, 100%, 27%, 1)',
    '--blue-yellow-contact-light': 'hsla(0, 100%, 37%, 1)',
    '--blue-yellow-contact-ultra-light': 'hsla(0, 100%, 60%, 1)',
    '--blue-yellow-contact-dark': 'hsla(0, 100%, 18%, 1)',
    '--blue-yellow-contact-ultra-dark': 'hsla(0, 100%, 10%, 1)',


    '--black-white-primary': 'hsla(0, 0%, 20%, 1)',
    '--black-white-primary-light': 'hsla(0, 0%, 30%, 1)',
    '--black-white-primary-ultra-light': 'hsla(0, 0%, 40%, 1)',
    '--black-white-primary-dark': 'hsla(0, 0%, 15%, 1)',
    '--black-white-primary-ultra-dark': 'hsla(0, 0%, 10%, 1)',

    '--black-white-secondary': 'hsla(0, 0%, 20%, 1)',
    '--black-white-secondary-light': 'hsla(0, 0%, 30%, 1)',
    '--black-white-secondary-ultra-light': 'hsla(0, 0%, 40%, 1)',
    '--black-white-secondary-dark': 'hsla(0, 0%, 15%, 1)',
    '--black-white-secondary-ultra-dark': 'hsla(0, 0%, 10%, 1)',

    '--black-white-services': 'hsla(0, 0%, 20%, 1)',
    '--black-white-services-light': 'hsla(0, 0%, 30%, 1)',
    '--black-white-services-ultra-light': 'hsla(0, 0%, 40%, 1)',
    '--black-white-services-dark': 'hsla(0, 0%, 15%, 1)',
    '--black-white-services-ultra-dark': 'hsla(0, 0%, 10%, 1)',

    '--black-white-carte': 'hsla(0, 0%, 20%, 1)',
    '--black-white-carte-light': 'hsla(0, 0%, 30%, 1)',
    '--black-white-carte-ultra-light': 'hsla(0, 0%, 40%, 1)',
    '--black-white-carte-dark': 'hsla(0, 0%, 15%, 1)',
    '--black-white-carte-ultra-dark': 'hsla(0, 0%, 10%, 1)',

    '--black-white-events': 'hsla(0, 0%, 20%, 1)',
    '--black-white-events-light': 'hsla(0, 0%, 30%, 1)',
    '--black-white-events-ultra-light': 'hsla(0, 0%, 40%, 1)',
    '--black-white-events-dark': 'hsla(0, 0%, 15%, 1)',
    '--black-white-events-ultra-dark': 'hsla(0, 0%, 10%, 1)',

    '--black-white-contact': 'hsla(0, 0%, 20%, 1)',
    '--black-white-contact-light': 'hsla(0, 0%, 30%, 1)',
    '--black-white-contact-ultra-light': 'hsla(0, 0%, 40%, 1)',
    '--black-white-contact-dark': 'hsla(0, 0%, 15%, 1)',
    '--black-white-contact-ultra-dark': 'hsla(0, 0%, 10%, 1)',


    '--dark-primary': 'hsla(356, 95%, 36%, 1)',
    '--dark-primary-light': 'hsla(356, 95%, 46%, 1)',
    '--dark-primary-ultra-light': 'hsla(356, 95%, 65%, 1)',
    '--dark-primary-dark': 'hsla(356, 95%, 26%, 1)',
    '--dark-primary-ultra-dark': 'hsla(356, 95%, 15%, 1)',

    '--dark-secondary': 'hsla(356, 95%, 36%, 1)',
    '--dark-secondary-light': 'hsla(356, 95%, 46%, 1)',
    '--dark-secondary-ultra-light': 'hsla(356, 95%, 65%, 1)',
    '--dark-secondary-dark': 'hsla(356, 95%, 26%, 1)',
    '--dark-secondary-ultra-dark': 'hsla(356, 95%, 15%, 1)',

    '--dark-services': 'hsla(356, 95%, 36%, 1)',
    '--dark-services-light': 'hsla(356, 95%, 46%, 1)',
    '--dark-services-ultra-light': 'hsla(356, 95%, 65%, 1)',
    '--dark-services-dark': 'hsla(356, 95%, 26%, 1)',
    '--dark-services-ultra-dark': 'hsla(356, 95%, 15%, 1)',

    '--dark-carte': 'hsla(356, 95%, 36%, 1)',
    '--dark-carte-light': 'hsla(356, 95%, 46%, 1)',
    '--dark-carte-ultra-light': 'hsla(356, 95%, 65%, 1)',
    '--dark-carte-dark': 'hsla(356, 95%, 26%, 1)',
    '--dark-carte-ultra-dark': 'hsla(356, 95%, 15%, 1)',

    '--dark-events': 'hsla(356, 95%, 36%, 1)',
    '--dark-events-light': 'hsla(356, 95%, 46%, 1)',
    '--dark-events-ultra-light': 'hsla(356, 95%, 65%, 1)',
    '--dark-events-dark': 'hsla(356, 95%, 26%, 1)',
    '--dark-events-ultra-dark': 'hsla(356, 95%, 15%, 1)',

    '--dark-contact': 'hsla(356, 95%, 36%, 1)',
    '--dark-contact-light': 'hsla(356, 95%, 46%, 1)',
    '--dark-contact-ultra-light': 'hsla(356, 95%, 65%, 1)',
    '--dark-contact-dark': 'hsla(356, 95%, 26%, 1)',
    '--dark-contact-ultra-dark': 'hsla(356, 95%, 15%, 1)',
  },
  // Ajoutez d'autres subSiteSlugs et leurs variables si nécessaire
};

// Provider
export const SubSiteProvider = ({ children }) => {
  const location = useLocation();
  const [subSiteSlug, setSubSiteSlug] = useState(null);
  const [subSiteName, setSubSiteName] = useState(null);
  const [isSubSite, setIsSubSite] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Extraire subSiteSlug depuis l'URL
  useEffect(() => {
    const pathSegments = location.pathname.split('/').filter(Boolean);
    const slug = pathSegments[0] || null;
    setSubSiteSlug(slug);
  }, [location]);

  // Vérifier si subSiteSlug est dans la liste blanche
  useEffect(() => {
    const fetchWhitelist = async () => {
      if (subSiteSlug) {
        setLoading(true);
        try {
          const response = await api.get('/subsites/slugs');
          const whitelist = response.data;
          setIsSubSite(whitelist.includes(subSiteSlug));
        } catch (err) {
          setError('Erreur lors de la récupération de la liste des sous-sites');
          console.error(err);
        } finally {
          setLoading(false);
        }
      } else {
        setIsSubSite(false);
        setLoading(false);
      }
    };


    fetchWhitelist();
  }, [subSiteSlug]);

  useEffect(() => {
    if (isSubSite) {
      const fetchData = async () => {
        try {
          const response = await api.get(`/subsites/${subSiteSlug}/name`);
          setSubSiteName(response.data);
        } catch (err) {
          setError('Erreur lors de la récupération du nom du sous-site');
          console.error(err);
        }
      };
      fetchData();
    }
  }, [isSubSite, subSiteSlug]);


  // Mettre à jour les variables CSS dynamiquement
  useEffect(() => {
    const root = document.documentElement;

    if (isSubSite && themeMapping[subSiteSlug]) {
      // Appliquer les nouvelles variables CSS
      const themeVariables = themeMapping[subSiteSlug];
      for (const [variable, value] of Object.entries(themeVariables)) {
        root.style.setProperty(variable, value);
      }
    } else {
      // Réinitialiser les variables aux valeurs par défaut si nécessaire
      const defaultVariables = {
        '--primary': 'hsla(201, 29%, 30%, 1)',
        '--primary-light': 'hsla(201, 29%, 45%, 1)',
        '--primary-ultra-light': 'hsla(201, 29%, 60%, 1)',
        '--primary-dark': 'hsla(201, 29%, 20%, 1)',
        '--primary-ultra-dark': 'hsla(201, 29%, 15%, 1)',

        '--secondary': 'hsla(193, 79%, 38%, 1)',
        '--secondary-light': 'hsla(193, 79%, 50%, 1)',
        '--secondary-ultra-light': 'hsla(193, 79%, 70%, 1)',
        '--secondary-dark': 'hsla(193, 79%, 30%, 1)',
        '--secondary-ultra-dark': 'hsla(193, 79%, 15%, 1)',

        '--services': 'hsla(201, 29%, 30%, 1)',
        '--services-light': 'hsla(201, 29%, 45%, 1)',
        '--services-ultra-light': 'hsla(201, 29%, 60%, 1)',
        '--services-dark': 'hsla(201, 29%, 20%, 1)',
        '--services-ultra-dark': 'hsla(201, 29%, 15%, 1)',

        '--carte': 'hsla(80, 100%, 37%, 1)',
        '--carte-light': 'hsla(80, 100%, 47%, 1)',
        '--carte-ultra-light': 'hsla(80, 100%, 70%, 1)',
        '--carte-dark': 'hsla(80, 100%, 27%, 1)',
        '--carte-ultra-dark': 'hsla(80, 100%, 15%, 1)',

        '--events': 'hsla(327, 100%, 45%, 1)',
        '--events-light': 'hsla(327, 100%, 55%, 1)',
        '--events-ultra-light': 'hsla(327, 100%, 75%, 1)',
        '--events-dark': 'hsla(327, 100%, 35%, 1)',
        '--events-ultra-dark': 'hsla(327, 100%, 25%, 1)',

        '--contact': 'hsla(201, 45%, 68%, 1)',
        '--contact-light': 'hsla(201, 45%, 78%, 1)',
        '--contact-ultra-light': 'hsla(201, 45%, 90%, 1)',
        '--contact-dark': 'hsla(201, 45%, 58%, 1)',
        '--contact-ultra-dark': 'hsla(201, 45%, 48%, 1)',

        /* Accessibility */

        '--red-green-primary': 'hsla(210, 20%, 33%, 1)',
        '--red-green-primary-light': 'hsla(210, 20%, 40%, 1)',
        '--red-green-primary-ultra-light': 'hsla(210, 20%, 55%, 1)',
        '--red-green-primary-dark': 'hsla(210, 20%, 25%, 1)',
        '--red-green-primary-ultra-dark': 'hsla(210, 20%, 15%, 1)',

        '--red-green-secondary': 'hsla(215, 60%, 52%, 1)',
        '--red-green-secondary-light': 'hsla(215, 60%, 65%, 1)',
        '--red-green-secondary-ultra-light': 'hsla(215, 60%, 80%, 1)',
        '--red-green-secondary-dark': 'hsla(215, 60%, 45%, 1)',
        '--red-green-secondary-ultra-dark': 'hsla(215, 60%, 30%, 1)',

        '--red-green-services': 'hsla(210, 20%, 33%, 1)',
        '--red-green-services-light': 'hsla(210, 20%, 40%, 1)',
        '--red-green-services-ultra-light': 'hsla(210, 20%, 55%, 1)',
        '--red-green-services-dark': 'hsla(210, 20%, 25%, 1)',
        '--red-green-services-ultra-dark': 'hsla(210, 20%, 15%, 1)',

        '--red-green-carte': 'hsla(84, 100%, 59%, 1)',
        '--red-green-carte-light': 'hsla(84, 100%, 69%, 1)',
        '--red-green-carte-ultra-light': 'hsla(84, 100%, 80%, 1)',
        '--red-green-carte-dark': 'hsla(84, 100%, 49%, 1)',
        '--red-green-carte-ultra-dark': 'hsla(84, 100%, 39%, 1)',

        '--red-green-events': 'hsla(303, 60%, 64%, 1)',
        '--red-green-events-light': 'hsla(303, 60%, 74%, 1)',
        '--red-green-events-ultra-light': 'hsla(303, 60%, 84%, 1)',
        '--red-green-events-dark': 'hsla(303, 60%, 54%, 1)',
        '--red-green-events-ultra-dark': 'hsla(303, 60%, 44%, 1)',

        '--red-green-contact': 'hsla(214, 28%, 82%, 1)',
        '--red-green-contact-light': 'hsla(214, 28%, 90%, 1)',
        '--red-green-contact-ultra-light': 'hsla(214, 28%, 95%, 1)',
        '--red-green-contact-dark': 'hsla(214, 28%, 72%, 1)',
        '--red-green-contact-ultra-dark': 'hsla(214, 28%, 62%, 1)',


        '--blue-yellow-primary': 'hsla(210, 50%, 42%, 1)',
        '--blue-yellow-primary-light': 'hsla(210, 50%, 55%, 1)',
        '--blue-yellow-primary-ultra-light': 'hsla(210, 50%, 70%, 1)',
        '--blue-yellow-primary-dark': 'hsla(210, 50%, 30%, 1)',
        '--blue-yellow-primary-ultra-dark': 'hsla(210, 50%, 20%, 1)',

        '--blue-yellow-secondary': 'hsla(198, 60%, 45%, 1)',
        '--blue-yellow-secondary-light': 'hsla(198, 60%, 55%, 1)',
        '--blue-yellow-secondary-ultra-light': 'hsla(198, 60%, 70%, 1)',
        '--blue-yellow-secondary-dark': 'hsla(198, 60%, 35%, 1)',
        '--blue-yellow-secondary-ultra-dark': 'hsla(198, 60%, 20%, 1)',

        '--blue-yellow-services': 'hsla(210, 50%, 42%, 1)',
        '--blue-yellow-services-light': 'hsla(210, 50%, 55%, 1)',
        '--blue-yellow-services-ultra-light': 'hsla(210, 50%, 70%, 1)',
        '--blue-yellow-services-dark': 'hsla(210, 50%, 30%, 1)',
        '--blue-yellow-services-ultra-dark': 'hsla(210, 50%, 20%, 1)',

        '--blue-yellow-carte': 'hsla(120, 61%, 35%, 1)',
        '--blue-yellow-carte-light': 'hsla(120, 61%, 45%, 1)',
        '--blue-yellow-carte-ultra-light': 'hsla(120, 61%, 70%, 1)',
        '--blue-yellow-carte-dark': 'hsla(120, 61%, 25%, 1)',
        '--blue-yellow-carte-ultra-dark': 'hsla(120, 61%, 15%, 1)',

        '--blue-yellow-events': 'hsla(322, 79%, 44%, 1)',
        '--blue-yellow-events-light': 'hsla(322, 79%, 54%, 1)',
        '--blue-yellow-events-ultra-light': 'hsla(322, 79%, 70%, 1)',
        '--blue-yellow-events-dark': 'hsla(322, 79%, 34%, 1)',
        '--blue-yellow-events-ultra-dark': 'hsla(322, 79%, 20%, 1)',

        '--blue-yellow-contact': 'hsla(0, 0%, 83%, 1)',
        '--blue-yellow-contact-light': 'hsla(0, 0%, 90%, 1)',
        '--blue-yellow-contact-ultra-light': 'hsla(0, 0%, 95%, 1)',
        '--blue-yellow-contact-dark': 'hsla(0, 0%, 75%, 1)',
        '--blue-yellow-contact-ultra-dark': 'hsla(0, 0%, 60%, 1)',


        '--black-white-primary': 'hsla(0, 0%, 30%, 1)',
        '--black-white-primary-light': 'hsla(0, 0%, 35%, 1)',
        '--black-white-primary-ultra-light': 'hsla(0, 0%, 50%, 1)',
        '--black-white-primary-dark': 'hsla(0, 0%, 20%, 1)',
        '--black-white-primary-ultra-dark': 'hsla(0, 0%, 10%, 1)',

        '--black-white-secondary': 'hsla(0, 0%, 40%, 1)',
        '--black-white-secondary-light': 'hsla(0, 0%, 50%, 1)',
        '--black-white-secondary-ultra-light': 'hsla(0, 0%, 70%, 1)',
        '--black-white-secondary-dark': 'hsla(0, 0%, 25%, 1)',
        '--black-white-secondary-ultra-dark': 'hsla(0, 0%, 15%, 1)',

        '--black-white-services': 'hsla(0, 0%, 30%, 1)',
        '--black-white-services-light': 'hsla(0, 0%, 35%, 1)',
        '--black-white-services-ultra-light': 'hsla(0, 0%, 50%, 1)',
        '--black-white-services-dark': 'hsla(0, 0%, 20%, 1)',
        '--black-white-services-ultra-dark': 'hsla(0, 0%, 10%, 1)',

        '--black-white-carte': 'hsla(0, 0%, 85%, 1)',
        '--black-white-carte-light': 'hsla(0, 0%, 90%, 1)',
        '--black-white-carte-ultra-light': 'hsla(0, 0%, 90%, 1)',
        '--black-white-carte-dark': 'hsla(0, 0%, 75%, 1)',
        '--black-white-carte-ultra-dark': 'hsla(0, 0%, 65%, 1)',

        '--black-white-events': 'hsla(0, 0%, 90%, 1)',
        '--black-white-events-light': 'hsla(0, 0%, 92%, 1)',
        '--black-white-events-ultra-light': 'hsla(0, 0%, 92%, 1)',
        '--black-white-events-dark': 'hsla(0, 0%, 80%, 1)',
        '--black-white-events-ultra-dark': 'hsla(0, 0%, 70%, 1)',

        '--black-white-contact': 'hsla(0, 0%, 90%, 1)',
        '--black-white-contact-light': 'hsla(0, 0%, 92%, 1)',
        '--black-white-contact-ultra-light': 'hsla(0, 0%, 92%, 1)',
        '--black-white-contact-dark': 'hsla(0, 0%, 80%, 1)',
        '--black-white-contact-ultra-dark': 'hsla(0, 0%, 70%, 1)',


        '--dark-primary': 'hsla(193, 79%, 50%, 1)',
        '--dark-primary-light': 'hsla(193, 79%, 65%, 1)',
        '--dark-primary-ultra-light': 'hsla(193, 79%, 75%, 1)',
        '--dark-primary-dark': 'hsla(193, 79%, 35%, 1)',
        '--dark-primary-ultra-dark': 'hsla(193, 79%, 20%, 1)',

        '--dark-secondary': 'hsla(193, 79%, 38%, 1)',
        '--dark-secondary-light': 'hsla(193, 79%, 50%, 1)',
        '--dark-secondary-ultra-light': 'hsla(193, 79%, 65%, 1)',
        '--dark-secondary-dark': 'hsla(193, 79%, 23%, 1)',
        '--dark-secondary-ultra-dark': 'hsla(193, 79%, 8%, 1)',

        '--dark-services': 'hsla(201, 29%, 30%, 1)',
        '--dark-services-light': 'hsla(201, 29%, 45%, 1)',
        '--dark-services-ultra-light': 'hsla(201, 29%, 60%, 1)',
        '--dark-services-dark': 'hsla(201, 29%, 20%, 1)',
        '--dark-services-ultra-dark': 'hsla(201, 29%, 15%, 1)',

        '--dark-carte': 'hsla(80, 100%, 37%, 1)',
        '--dark-carte-light': 'hsla(80, 100%, 47%, 1)',
        '--dark-carte-ultra-light': 'hsla(80, 100%, 70%, 1)',
        '--dark-carte-dark': 'hsla(80, 100%, 27%, 1)',
        '--dark-carte-ultra-dark': 'hsla(80, 100%, 15%, 1)',

        '--dark-events': 'hsla(327, 100%, 45%, 1)',
        '--dark-events-light': 'hsla(327, 100%, 55%, 1)',
        '--dark-events-ultra-light': 'hsla(327, 100%, 75%, 1)',
        '--dark-events-dark': 'hsla(327, 100%, 35%, 1)',
        '--dark-events-ultra-dark': 'hsla(327, 100%, 25%, 1)',

        '--dark-contact': 'hsla(201, 45%, 68%, 1)',
        '--dark-contact-light': 'hsla(201, 45%, 78%, 1)',
        '--dark-contact-ultra-light': 'hsla(201, 45%, 90%, 1)',
        '--dark-contact-dark': 'hsla(201, 45%, 58%, 1)',
        '--dark-contact-ultra-dark': 'hsla(201, 45%, 48%, 1)',
      };
      for (const [variable, value] of Object.entries(defaultVariables)) {
        root.style.setProperty(variable, value);
      }
    }
  }, [isSubSite, subSiteSlug]);

  return (
    <SubSiteContext.Provider value={{ isSubSite, subSiteSlug, subSiteName, loading, error }}>
      {children}
    </SubSiteContext.Provider>
  );
};

// Hook personnalisé pour utiliser le contexte
export const useSubSite = () => useContext(SubSiteContext);
