import React from 'react';
import { FeatureGroup } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { Marker, Popup } from 'react-leaflet';

const EventsLayer = ({ events, onMarkerClick, categoryIcons }) => {
  return (
    <FeatureGroup>
      <MarkerClusterGroup>
        {events.map((evt) => {
          const icon = categoryIcons['event'] || categoryIcons['default'];
          return (
            <Marker
              key={`evt-${evt.id}`}
              position={[evt.latitude, evt.longitude]}
              icon={icon}
              eventHandlers={{
                click: () => onMarkerClick(evt),
              }}
            >
              <Popup>{evt.title}</Popup>
            </Marker>
          );
        })}
      </MarkerClusterGroup>
    </FeatureGroup>
  );
};

export default EventsLayer;
