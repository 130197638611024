// components/pages/WebcamPage.jsx
import React, { useState } from "react";
import Breadcrumbs from "components/common/Breadcrumbs";
import BannerWithSocialIcons from "components/common/BannerWithSocialIcons";
import sharedStyles from "styles/modules/ContentPage.module.css";
import styles from "./WebcamPage.module.css";
import useDesignSettingsData from "hooks/useDesignSettingsData";
import MapCta from "components/common/MapCta";
import LiensPratiques from "components/common/LiensPratiques";
import useBreakpoint from "hooks/useBreakpoint";
import { Helmet } from "react-helmet";
import Spinner from "components/common/Spinner";

const WebcamPage = () => {
  const [loading, setLoading] = useState(true);
  const { designSettings, settingsLoading, error } = useDesignSettingsData();
  const isMobile = useBreakpoint();

  if (settingsLoading)
    return (
      <div>
        <Spinner />
      </div>
    );
  if (error) return <div>Erreur : {error}</div>;

  return (
    <>
      <Helmet>
        <title>Ville d'Arras | Webcams</title>
        <meta
          name="description"
          content="Retrouvez ici les webcams de la Place des Héros et la Darse Méaulens, rue aux Foulons."
        />
        <meta property="og:title" content="Ville d'Arras | Webcams" />
        <meta
          property="og:description"
          content="Retrouvez ici les webcams de la Place des Héros et la Darse Méaulens, rue aux Foulons."
        />
        <meta
          property="og:image"
          content={`${designSettings.defaultPageBanner}`}
        />
        <meta
          property="og:image:alt"
          content="Image descriptive pour la page Webcams"
        />
        <link rel="canonical" href="https://arras.fr/webcams" />
      </Helmet>
      <div className={`${sharedStyles.pageWrapper} ${sharedStyles.webcamPage}`}>
        {/* Bannière avec miniature */}
        {designSettings && designSettings.defaultPageBanner && (
          <BannerWithSocialIcons
            imageUrl={`${designSettings.defaultPageBanner}`}
          />
        )}

        <div className={`page-content ${sharedStyles.pageContent}`}>
          <section className={`global-section ${sharedStyles.mainSection}`}>
            <div className={`global-container ${sharedStyles.headerContainer}`}>
              <div className={sharedStyles.header}>
                <div className={sharedStyles.headingBlock}>
                  <h1>Webcams</h1>
                  <Breadcrumbs title="Webcams" />
                </div>
              </div>
            </div>

            <div className={`global-container ${styles.main}`}>
              <div className={styles.block}>
                <div className={styles.intro}>
                  <div className={styles.introText}>
                    <h2>Place des héros et capitainerie</h2>
                    <p>
                      Retrouvez ici les webcam de la Place des Héros et la Darse
                      Méaulens, rue aux Foulons.
                    </p>
                  </div>
                </div>
                <div className={styles.content}>
                  <iframe
                    align="middle"
                    allowfullscreen=""
                    frameborder="0"
                    height="600"
                    scrolling="no"
                    src="https://pv.viewsurf.com/684/Arras-Place-des-heros"
                    width="100%"
                  ></iframe>

                  <iframe
                    align="middle"
                    allowfullscreen=""
                    frameborder="0"
                    height="600"
                    scrolling="no"
                    src="https://pv.viewsurf.com/854/Arras-Place-des-heros-timelapse"
                    width="100%"
                  ></iframe>

                  <iframe
                    align="middle"
                    allowfullscreen=""
                    frameborder="0"
                    height="600"
                    scrolling="no"
                    src="https://pv.viewsurf.com/688/Arras-Capitainerie"
                    width="100%"
                  ></iframe>
                </div>
              </div>
            </div>
          </section>

          {/* CTA Map */}
          <MapCta />

          {/* Liens Pratiques */}
          <LiensPratiques />
        </div>
      </div>
    </>
  );
};

export default WebcamPage;
