import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Importer Font Awesome
import { faEarDeaf, faPhone } from '@fortawesome/free-solid-svg-icons'; // Importer Icone down et Phone
import styles from 'pages/UrgenceNumbersPage.module.css';

const UrgenceNumbersBlock = () => {

    const links = [
        { id: 1, label: 'Samu', desc: 'Urgence médicale', url: 'tel:15', number: '15' },
        { id: 2, label: 'Police Secours', desc: 'Signaler une infraction', url: 'tel:17', number: '17' },
        { id: 3, label: 'Pompiers', desc: 'Situation de péril ou accident', url: 'tel:18', number: '18' },
        { id: 4, label: 'Urgence SMS', desc: 'En cas de difficulté pour parler ou entendre', icon: faEarDeaf, url: 'sms:114', number: '114' },
        { id: 5, label: 'Urgences Europe', desc: 'Urgence médicale, infraction, péril', url: 'tel:112', number: '112' },
        { id: 6, label: 'Enfance en danger', url: 'tel:119', number: '119' },
        { id: 7, label: 'Médecins de garde', desc: 'Permanence de soins ambulatoires', url: 'tel:116117', number: '116 117' },
        { id: 8, label: 'Femmes victimes de violence', url: 'tel:3919', number: '39 19' },
    ];

    return (
        <div className={styles.listContainer}>
            <ul className={`list--none ${styles.list}`}>
                {links.map(link => (
                    <li key={link.id}>
                        <a
                            href={link.url}
                            className={styles.item}
                            aria-label={`Contacter ${link.label}`}
                            title={`Contacter ${link.label}`}>
                            <span className={styles.number}>{link.number}</span>
                            <div className={styles.itemContent}>
                                <div className={styles.heading}>
                                    <h3>{link.label}</h3>

                                    {link.icon && (
                                        <FontAwesomeIcon icon={link.icon} className={styles.urgencyIcon} />
                                    )}
                                </div>
                                {link.desc && (
                                    <p className={styles.description}>{link.desc}</p>
                                )}
                            </div>
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default UrgenceNumbersBlock;