// Lightbox.jsx
import React from 'react';
import styles from './Lightbox.module.css'; // Le CSS associé
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faXmark } from '@fortawesome/free-solid-svg-icons';

const Lightbox = ({ images, currentIndex, onClose, onPrev, onNext }) => {
  if (!images || images.length === 0) return null;

  // Gestion du clic sur le conteneur overlay
  // => Si on clique directement sur l'overlay, onClose();
  // => Si on clique sur l'élément enfant (.content), onStopPropagation() 
  // pour ne pas fermer la lightbox accidentellement.
  const handleOverlayClick = () => {
    onClose();
  };

  const handleContentClick = (e) => {
    // Empêche la propagation du clic vers l'overlay
    e.stopPropagation();
  };

  return (
    <div
      className={styles.overlay}
      role="dialog"
      aria-modal="true"
      onClick={handleOverlayClick}     // ferme la lightbox si clic sur overlay
    >
      <div className={styles.content} onClick={handleContentClick}>
        <button
          className={styles.closeBtn}
          aria-label="Fermer la lightbox"
          onClick={onClose}
        >
          <FontAwesomeIcon icon={faXmark} />
        </button>

        <div className={styles.imageContainer}>
          <button
            className={styles.prevBtn}
            onClick={onPrev}
            aria-label="Image précédente"
            >
          <FontAwesomeIcon icon={faChevronLeft} className={styles.icon} />
          </button>

          <img
            src={images[currentIndex]?.src}
            alt={images[currentIndex]?.alt || 'Agrandissement'}
          />

          <button
            className={styles.nextBtn}
            onClick={onNext}
            aria-label="Image suivante"
          >
            <FontAwesomeIcon icon={faChevronRight} className={styles.icon} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Lightbox;
