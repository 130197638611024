// services/api.js
import axios from 'axios';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL, // Utilisation de la variable d'environnement
    headers: {
        'Content-Type': 'application/json',
    },
});

// Intercepter les requêtes pour ajouter le token JWT si disponible
api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Méthode pour obtenir les profils utilisateur
export const getUserProfiles = async () => {
    try {
        const response = await api.get('/user-profiles');
        return response.data;
    } catch (error) {
        console.error('Erreur lors de la récupération des profils utilisateur:', error);
        throw error;
    }
};

// Méthode pour vérifier l'événement spécial
export const checkSpecialEvent = async () => {
    try {
        const response = await api.get('/special_event');
        return response.data; // Supposons que la réponse est { special_event: true/false, widget: { ... } }
    } catch (error) {
        console.error('Erreur lors de la vérification de l\'événement spécial:', error);
        throw error;
    }
};

// Nouvelle méthode pour obtenir les DesignSettings
export const getDesignSettings = async () => {
    try {
        const response = await api.get('/settings/design');
        return response.data;
    } catch (error) {
        console.error('Erreur lors de la récupération des paramètres de design:', error);
        throw error;
    }
};

/**
 * Récupère les infos du footer (slug=main par défaut, ou subsite=xxx).
 */
export const getFooter = async (subsiteSlug = '') => {
    // subsiteSlug = 'arras' => GET /footer?subsite=arras
    // sinon GET /footer
    const url = subsiteSlug ? `${subsiteSlug}/footer` : `${subsiteSlug}/footer`;
    const response = await api.get(url);
    return response.data;
};
// Vous pouvez ajouter d'autres méthodes API ici

export default api;
