// SubNavItem.jsx
import React from 'react';
import PropTypes from 'prop-types';
import styles from './NavItem.module.css';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const SubNavItem = React.forwardRef(
  ({ label, target, onClick, isSelected, slug, context, to , type}, ref) => {
    const commonProps = {
      className: classNames(
        styles.navItem,
        styles[`${context}NavItem`],
        styles[slug],
        {
          [styles.selectedCategory]: isSelected,
        }
      ),
      'aria-label': label,
      ref: ref
    };

    const url = target ? target : to;

    return (
      <li>
        {to ? (
          <Link
            to={to}
            {...commonProps}
            onClick={onClick}
          >
            <span className={styles.label}>{label}</span>
            <FontAwesomeIcon icon={faArrowUpRightFromSquare} className={styles.arrow} aria-label="Ouvrir un lien externe" />
          </Link>
        ) : (
          <button
            {...commonProps}
            onClick={onClick}
          >
            <span className={styles.label}>{label}</span>
            <FontAwesomeIcon icon={faArrowUpRightFromSquare} className={styles.arrow} aria-label="Ouvrir un lien externe" />
          </button>
        )}
      </li>
    );
  }
);

SubNavItem.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isSelected: PropTypes.bool,
  slug: PropTypes.string.isRequired,
  context: PropTypes.oneOf(['sidebar', 'subsidebar']).isRequired,
  to: PropTypes.string
};

export default SubNavItem;
