import React from 'react';
import PropTypes from 'prop-types';
import filterStyles from 'components/calendar/EventFilter.module.css';
import styles from 'styles/modules/Archives.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

const AssociationsArchiveFilter = ({
  selectedCategory,
  handleFilterChange,
  searchQuery,
  handleSearchChange,
  resetFilters,
}) => {
  return (
    <div className={filterStyles.filterWrap}>
      <div className={filterStyles.filterContainer}>
        <p className={filterStyles.label}>Filtrer le contenu :</p>
        <div className={filterStyles.filters}>

          {/* Champ de recherche */}
          <label htmlFor="searchInput" className="sr-only">
            Rechercher :
          </label>
          <input
            type="text"
            id="searchInput"
            placeholder="Rechercher..."
            value={searchQuery}
            onChange={handleSearchChange}
            className={filterStyles.nameFilter}
          />

          {/* Dropdown “en dur” pour les deux catégories */}
          <div className={filterStyles.dropdown}>
            <label htmlFor="categoryFilter" className="sr-only">
              Catégorie
            </label>
            <select
              id="categoryFilter"
              value={selectedCategory}
              onChange={(e) => handleFilterChange(e.target.value)}
              className={filterStyles.categoryFilter}
            >
              <option value="all">Toutes les catégories</option>
              <option value="culturelle">Culturelle</option>
              <option value="sportive">Sportive</option>
            </select>
            <FontAwesomeIcon icon={faCaretDown} />
          </div>
        </div>
      </div>

      {/* Bouton pour réinitialiser */}
      <div className={styles.filters}>
        <div className={filterStyles.filterContainer}>
          <button
            className={`arrow-link ${styles.resetTags}`}
            onClick={resetFilters}
          >
            Réinitialiser les filtres
          </button>
        </div>
      </div>
    </div>
  );
};

AssociationsArchiveFilter.propTypes = {
  selectedCategory: PropTypes.string.isRequired,
  handleFilterChange: PropTypes.func.isRequired,
  searchQuery: PropTypes.string.isRequired,
  handleSearchChange: PropTypes.func.isRequired,
  resetFilters: PropTypes.func.isRequired,
};

export default AssociationsArchiveFilter;
