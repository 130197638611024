import React from 'react';
import PropTypes from 'prop-types';
import Card from 'components/common/Card';
import { useSubSite } from 'contexts/SubSiteContext';

const NewsCard = ({ newsItem }) => {
  const { title, createdAt, content, slug, thumbnail, categories = [] } = newsItem;
  const { isSubSite, subSiteSlug } = useSubSite();

const newsUrl = isSubSite ? `/${subSiteSlug}/actualite/${slug}` : `/actualite/${slug}`;
  const contentExcerpt =
    content[0]?.content
      ? content[0].content.replace(/<\/?[^>]+(>|$)/g, '').slice(0, 100) + '...'
      : '';

  const formattedDate = `Publié le ${new Date(createdAt).toLocaleDateString()}`;

  const thumbnailUrl =
    thumbnail && thumbnail.path
      ? `${process.env.REACT_APP_MEDIA_BASE_URL}${thumbnail.path}`
      : null;

  return (
    <Card
      thumbnail={thumbnailUrl}
      title={title}
      date={formattedDate}
      categories={categories}
      description={contentExcerpt}
      link={newsUrl}
      buttonText="Lire la suite"
    />
  );
};

NewsCard.propTypes = {
  newsItem: PropTypes.shape({
    title: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    content: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        content: PropTypes.string,
      })
    ).isRequired,
    slug: PropTypes.string.isRequired,
    thumbnail: PropTypes.shape({
      path: PropTypes.string,
    }),
    categories: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
      })
    ),
  }).isRequired,
};

export default NewsCard;
