// src/components/associations/Gallery.js
import React from 'react';
import PropTypes from 'prop-types';
import styles from './Gallery.module.css'; // Créez ce fichier pour les styles

const Gallery = ({ images }) => {
  return (
    <div className={styles.gallery}>
      {images.map((image) => (
        <figure key={image.id} className={styles.galleryItem}>
          <img
            src={`${process.env.REACT_APP_MEDIA_BASE_URL}${image.url}`}
            alt={image.description || 'Image de l\'association'}
            className={styles.galleryImage}
          />
          {image.description && <figcaption className={styles.caption}>{image.description}</figcaption>}
        </figure>
      ))}
    </div>
  );
};

Gallery.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      url: PropTypes.string.isRequired,
      description: PropTypes.string,
    })
  ).isRequired,
};

export default Gallery;
