import React from 'react';
import PropTypes from 'prop-types';
import Card from 'components/common/Card';
import { useSubSite } from 'contexts/SubSiteContext';

const EventCard = ({ event }) => {
  const {
    title = 'Titre non spécifié',
    start,
    end,
    categories = [],
    thumbnail,
    slug,
  } = event;
  const { isSubSite, subSiteSlug } = useSubSite();

  const eventUrl = isSubSite
    ? `/${subSiteSlug}/evenement/${slug}`
    : `/evenement/${slug}`;

  // --- LOGIQUE DE GESTION DES DATES/HORAIRES ---
  let dateToDisplay = 'Date non spécifiée';

  if (start && end) {
    const startDate = new Date(start);
    const endDate = new Date(end);

    // Formats de date (jj/mm/aaaa)
    const formattedStartDate = startDate.toLocaleDateString('fr-FR');
    const formattedEndDate = endDate.toLocaleDateString('fr-FR');

    // Formats d'horaires (HHhMM)
    const formattedStartTime = startDate
      .toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit', hour12: false })
      .replace(':', 'h');
    const formattedEndTime = endDate
      .toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit', hour12: false })
      .replace(':', 'h');

    // Si la date (jour/mois/année) est la même, on affiche "09/01/2025 de 20h00 à 23h00"
    if (formattedStartDate === formattedEndDate) {
      dateToDisplay = `${formattedStartDate} de ${formattedStartTime} à ${formattedEndTime}`;
    } else {
      // Sinon, on affiche "09/01/2025 - 10/01/2025"
      dateToDisplay = `${formattedStartDate} - ${formattedEndDate}`;
    }
  } else if (start && !end) {
    // S’il n’y a que la date de début
    const startDate = new Date(start);
    dateToDisplay = startDate.toLocaleDateString('fr-FR');
  } else if (!start && end) {
    // S’il n’y a que la date de fin
    const endDate = new Date(end);
    dateToDisplay = endDate.toLocaleDateString('fr-FR');
  }

  // --- FIN LOGIQUE DE GESTION DES DATES/HORAIRES ---

  const thumbnailUrl =
    thumbnail && thumbnail.path
      ? `${process.env.REACT_APP_MEDIA_BASE_URL}${thumbnail.path}`
      : null;

  return (
    <Card
      thumbnail={thumbnailUrl}
      title={title}
      date={dateToDisplay}
      categories={categories}
      link={eventUrl}
      buttonText="Lire la suite"
    />
  );
};

EventCard.propTypes = {
  event: PropTypes.shape({
    title: PropTypes.string,
    start: PropTypes.string,
    end: PropTypes.string,
    categories: PropTypes.array,
    thumbnail: PropTypes.shape({
      path: PropTypes.string,
    }),
    slug: PropTypes.string,
  }).isRequired,
};

export default EventCard;
