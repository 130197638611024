// components/associations/AssociationCard.js
import React from 'react';
import PropTypes from 'prop-types';
import Card from 'components/common/Card';

const AssociationCard = ({ association }) => {
  const {
    name,
    presentation,
    logo,
    contactMail,
    website,
    slug,
  } = association;

  const contentExcerpt =
    presentation ? presentation.replace(/<\/?[^>]+(>|$)/g, '').slice(0, 100) + '...' : 'Aucune description disponible.';

  const thumbnailUrl = `${process.env.REACT_APP_MEDIA_BASE_URL}${logo}`;

  const buttons = [
    {
      variant: 'secondary',
      href: `/associations/${slug}`,
      text: 'En savoir plus',
    },
  ];


  return (
    <Card
      thumbnail={thumbnailUrl}
      title={name}
      description={contentExcerpt}
      buttons={buttons}
    />
  );
};

AssociationCard.propTypes = {
  association: PropTypes.shape({
    name: PropTypes.string.isRequired,
    presentation: PropTypes.string,
    gallery: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
      })
    ),
    contactMail: PropTypes.string,
    website: PropTypes.string,
    slug: PropTypes.string.isRequired,
  }).isRequired,
};

export default AssociationCard;
