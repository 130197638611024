import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AccessibilityContext } from 'contexts/AccessibilityContext';
import MapView from 'components/map/MapView';
import style from './MapPage.module.css';
import { Helmet } from 'react-helmet';

const MapPage = () => {
    const navigate = useNavigate();
    const { accessibilityOptions, getAccessibilityVariant } = useContext(AccessibilityContext);
    
    return (
        <>
                  <Helmet>
                    <title>Arras | Carte interactive</title>
                    <meta name="description" content="Explorez la ville d'Arras grâce à notre carte interactive. Découvrez les lieux d'intérêt, les événements, les services municipaux et bien plus encore. Utilisez la carte pour naviguer facilement et trouver ce que vous cherchez en quelques clics." />
                    <meta property="og:title" content="Arras | Carte interactive" />
                    <meta property="og:description" content="Découvrez la ville d'Arras autrement grâce à notre carte interactive ! Trouvez facilement les points d'intérêt, événements et services municipaux. Explorez chaque recoin de la ville en quelques clics." />
                    <link rel="canonical" href={`https://www.arras.fr/carte`} />
                  </Helmet>
        <main className={style.mapPage} aria-label="Page de la carte interactive de la ville d'Arras">
        <div className={style.mapContainer}>
            <MapView />
        </div>
        </main>
        </>
    );
    };


export default MapPage;