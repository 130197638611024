// Page.js
import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import api from 'services/api';
import Breadcrumbs from 'components/common/Breadcrumbs';
import BannerWithSocialIcons from 'components/common/BannerWithSocialIcons';
import Summary from 'components/common/Summary';
import FileList from 'components/common/FileList';
import Badge from 'components/common/Badge';
import Button from 'components/common/Button';
import ShareButtons from 'components/common/ShareButtons';
import sharedStyles from 'styles/modules/ContentPage.module.css';
import MapCta from 'components/common/MapCta';
import LiensPratiques from 'components/common/LiensPratiques';
import styles from './Page.module.css';
import filestyle from 'components/common/FileList.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFilePdf,
  faFileImage,
  faFileAlt,
  faExternalLinkAlt,
} from '@fortawesome/free-solid-svg-icons';
import { ShareSocialOutline } from 'react-ionicons';
import CardBlock from 'components/pages/CardBlock'; // Importer le composant CardBlock
import PictoAccess from 'components/common/PictoAccess';
import { Helmet } from 'react-helmet';
import useBreakpoint from 'hooks/useBreakpoint'; // Responsive Hook
import Spinner from 'components/common/Spinner';

const Page = () => {
  const { pageSlug } = useParams();
  const [page, setPage] = useState(null);
  const [seoExcerpt, setSeoExcerpt] = useState('');
  const [loading, setLoading] = useState(true);
  const [updatedContentHtml, setUpdatedContentHtml] = useState('');

  const isMobile = useBreakpoint(); // Utilisation du hook pour savoir si l'écran est mobile

  useEffect(() => {
    const fetchPageData = async () => {
      try {
        const response = await api.get(`/pages/${pageSlug}`);
        setPage(response.data || null);
      } catch (error) {
        console.error('Erreur lors de la récupération de la page:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPageData();
  }, [pageSlug]);

  if (loading) return <div><Spinner /></div>;
  if (!page) return <div>Aucune page trouvée.</div>;

  const { title, createdAt, blocks = [], categories = [], thumbnail, files, banner, pictoAccess, slug } = page;

  const category = categories[0] || 'Aucune catégorie';

  // Trier les blocs par leur propriété `position`
  const sortedBlocks = [...blocks].sort((a, b) => a.position - b.position);

  const pageDescription = seoExcerpt || 'Une description de la page pour le SEO.';
  const pageImage = thumbnail && thumbnail.path ? `${process.env.REACT_APP_MEDIA_BASE_URL}${thumbnail.path}` : 'https://arras.fr/default-thumbnail.jpg';

  return (
    <>
      <Helmet>
        <title>Ville d'Arras | {title}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={`Ville d'Arras | ${title}`} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:image" content={pageImage} />
        <meta property="og:url" content={`https://arras.fr/pages/${slug}`} />
        <meta property="og:type" content="article" />
        <meta property="og:site_name" content="Ville d'Arras" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`Ville d'Arras | ${title}`} />
        <meta name="twitter:description" content={pageDescription} />
        <meta name="twitter:image" content={pageImage} />
        <link rel="canonical" href={`https://arras.fr/pages/${slug}`} />
      </Helmet>

      <div className={`${sharedStyles.pageWrapper} ${sharedStyles.newsPage}`}>
        {/* Bannière avec miniature */}
        {thumbnail && thumbnail.path && (
          <BannerWithSocialIcons
            imageUrl={`${process.env.REACT_APP_MEDIA_BASE_URL}${banner.path}`}
          />
        )}

        <div className={`page-content ${sharedStyles.pageContent}`}>
          <section className={`global-section ${sharedStyles.mainSection}`}>
            <div className={`global-container ${sharedStyles.headerContainer}`}>
              <div className={sharedStyles.header}>
                <div className={sharedStyles.headingBlock}>
                  <h1>{title}</h1>
                  <Breadcrumbs pageType="isPage" element={page} category={category} />
                </div>

                <div className={sharedStyles.metaContainer}>
                  <div className={sharedStyles.meta}>
                    <ShareSocialOutline
                      color="currentColor"
                      height=""
                      width=""
                      className={sharedStyles.metaIcon}
                    />
                    <p className={sharedStyles.metaInfo}>Partager :</p>
                    <ShareButtons />
                  </div>
                </div>
              </div>

              <ul className={`list--none ${sharedStyles.badgeList}`}>
                {categories.map((category) => (
                  <li key={category.id}>
                    <Badge
                      label={category.name}
                      variant={category.slug}
                      link={`/categorie/${category.slug}`}
                    />
                  </li>
                ))}
              </ul>
            </div>

            <div className={`global-container ${sharedStyles.main}`}>
              {/* Colonne principale */}
              <div className={sharedStyles.mainColumn}>
              
              {isMobile && (
                <div className={sharedStyles.mobileSummary}>
                  <Summary
                    contentHtml={blocks.find((block) => block.type === 'text')?.content || ''}
                    setUpdatedContentHtml={setUpdatedContentHtml}
                    setExcerpt={setSeoExcerpt}
                  />
                </div>
              )}

              <div className={sharedStyles.content}>
                
                {/* Affichage des blocs triés */}
                {sortedBlocks.map((block) => {
                  if (block.type === 'text') {
                    const renderTextWithOembed = (content) => {
                      const parser = new DOMParser();
                      const doc = parser.parseFromString(content, 'text/html');

                      // Rechercher les éléments <oembed>
                      const oembedElements = doc.querySelectorAll('oembed'); 
                      oembedElements.forEach((oembed) => {
                        const originalUrl = oembed.getAttribute('url');
                        
                        if (!originalUrl) return;
                        
                        try {
                          const url = new URL(originalUrl);
                      
                          // Vérifier rapidement que c’est un lien YouTube de type watch
                          const isYoutubeWatch = (url.hostname === 'www.youtube.com' || url.hostname === 'youtube.com') 
                                                 && url.pathname === '/watch';
                          if (isYoutubeWatch) {
                            // Récupérer le paramètre v=...
                            const videoId = url.searchParams.get('v');
                            if (videoId) {
                              // Construire l’URL embed
                              const embedUrl = `https://www.youtube.com/embed/${videoId}?yt:stretch=16:9&vq=hd720p&loop=0&color=red&iv_load_policy=3&rel=0&showinfo=0&autohide=0&controls=1`;
                      
                              // Créer l’iframe
                              const iframe = document.createElement('iframe');
                              iframe.src = embedUrl;
                              iframe.width = '560';
                              iframe.height = '315';
                              iframe.allow =
                                'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture';
                              iframe.allowFullscreen = true;
                              iframe.setAttribute('frameborder', '0');
                              iframe.setAttribute('allowtransparency', 'true');
                              
                              // Remplacer le oembed par l’iframe
                              oembed.parentNode.replaceChild(iframe, oembed);
                            }
                          }
                          else {
                            // Si l’URL n’est pas un watch (ou pas YouTube), 
                            // tu peux gérer d’autres plateformes (Vimeo, Dailymotion...) ou laisser tel quel
                          }
                      
                        } catch (err) {
                          console.error('URL invalide ou problème parsing:', err);
                        }
                      });
                      

                      return doc.body.innerHTML;
                    };

                    return (
                      <div
                        key={block.id}
                        className={sharedStyles.description}
                        dangerouslySetInnerHTML={{
                          __html: renderTextWithOembed(
                            updatedContentHtml || block.content
                          ),
                        }}
                      />
                    );
                  }

                  if (block.type === 'file' && block.fileType === 'image') {
                    return (
                      <figure key={block.id} className={sharedStyles.pictureItem}>
                        <img
                          src={`${process.env.REACT_APP_MEDIA_BASE_URL}${block.path}`}
                          alt={block.description || 'Image associée'}
                          className={sharedStyles.picture}
                        />
                        {block.description && (
                          <p className={sharedStyles.credit}>
                            Crédit : {block.description}
                          </p>
                        )}
                      </figure>
                    );
                  }

                  if (block.type === 'file' && block.fileType !== 'image') {
                    const getFileIcon = (type) => {
                      switch (type) {
                        case 'pdf':
                          return faFilePdf;
                        case 'image':
                          return faFileImage;
                        default:
                          return faFileAlt;
                      }
                    };
                    return (
                      <ul key={block.id} className={`list--none ${styles.fileList}`}>
                        <li className={filestyle.fileItem}>
                          <div className={filestyle.fileInfo}>
                            <p className={filestyle.fileName}>{block.sectionTitle}</p>
                            <p className={filestyle.fileDescription}>
                              <em>
                                {block.description || 'Aucune description disponible'}
                              </em>
                            </p>
                          </div>
                          {/* Partie droite : Icône de type de fichier et bouton d'ouverture */}
                          <div className={filestyle.fileActions}>
                            <FontAwesomeIcon
                              icon={getFileIcon(block.fileType)}
                              className={filestyle.fileIcon}
                            />
                            <button
                              onClick={() =>
                                window.open(
                                  `${process.env.REACT_APP_MEDIA_BASE_URL}${block.path}`,
                                  '_blank'
                                )
                              }
                              aria-label={`Ouvrir ${block.fileName} dans un nouvel onglet`}
                              title={`Ouvrir ${block.fileName} dans un nouvel onglet`}
                              className={filestyle.icon}
                            >
                              <FontAwesomeIcon icon={faExternalLinkAlt} />
                            </button>
                          </div>
                        </li>
                      </ul>
                    );
                  }

                  if (block.type === 'picture') {
                    return (
                      <div key={block.id} className={sharedStyles.pictureItem}>
                        <img
                          src={`${process.env.REACT_APP_MEDIA_BASE_URL}${block.path}`}
                          alt={block.description || 'Image associée'}
                          className={sharedStyles.picture}
                        />
                        {block.credit && (
                          <p className={sharedStyles.credit}>Crédit : {block.credit}</p>
                        )}
                      </div>
                    );
                  }

                  // Nouveau cas pour le bloc 'card'
                  if (block.type === 'card') {
                    return <CardBlock key={block.id} block={block} />;
                  }

                  return null;
                })}

                {/* Liste de fichiers associés */}
                {files && files.length > 0 && <FileList files={files} />}

                </div>

                <div className={sharedStyles.actions}>
                  <Button variant="primary" onClick={() => window.print()}>
                    Imprimer
                  </Button>
                </div>
              </div>

              {/* Colonne latérale droite */}
              <div className={sharedStyles.sidebar}>
                {pictoAccess && <PictoAccess iframeHtml={pictoAccess} width="250" height="120" />}
                
                {!isMobile && (
                <div className={sharedStyles.sticky}>
                  <Summary
                    contentHtml={blocks.find((block) => block.type === 'text')?.content || ''}
                    setUpdatedContentHtml={setUpdatedContentHtml}
                    setExcerpt={setSeoExcerpt}
                  />
                </div>
              )}

              </div>
            </div>
          </section>

          {/* CTA Map */}
          <MapCta />

          {/* Liens Pratiques */}
          <LiensPratiques />
        </div>
      </div>
    </>
  );
};

export default Page;
