// ProfileSidebarContext.js
import React, { createContext, useState } from 'react';

export const ProfileSidebarContext = createContext();

export const ProfileSidebarProvider = ({ children }) => {
  const [isProfileSidebarOpen, setProfileSidebarOpen] = useState(false);

  const openProfileSidebar = () => setProfileSidebarOpen(true);
  const closeProfileSidebar = () => setProfileSidebarOpen(false);


  return (
    <ProfileSidebarContext.Provider
      value={{
        isProfileSidebarOpen,
        openProfileSidebar,
        closeProfileSidebar,
      }}
    >
      {children}
    </ProfileSidebarContext.Provider>
  );
};
