// MentionsLegalesPage.js
import React, { useState } from 'react';
import Breadcrumbs from 'components/common/Breadcrumbs';
import BannerWithSocialIcons from 'components/common/BannerWithSocialIcons';
import styles from './ContactPage.module.css';
import legalStyles from 'styles/modules/legalsPage.module.css';
import sharedStyles from 'styles/modules/ContentPage.module.css';
import useDesignSettingsData from 'hooks/useDesignSettingsData';
import { Helmet } from 'react-helmet';

const MentionsLegales = () => {

    const { designSettings, settingsLoading, error } = useDesignSettingsData();

    return (
        <>
            <Helmet>
                <title>Arras | Mentions légales</title>
                <meta name="description" content="Consultez les mentions légales de notre site pour connaître les informations relatives à l'éditeur, les conditions d'utilisation et la protection des données personnelles. Transparence et respect des normes légales garanties." />
                <meta property="og:title" content="Arras | Mentions légales" />
                <meta property="og:description" content="Découvrez les mentions légales de notre site, incluant les informations sur l'éditeur, les conditions d'utilisation et la politique de confidentialité. Assurez-vous que votre utilisation du site respecte les normes en vigueur." />
                <link rel="canonical" href={`https://www.arras.fr/mentions-legales`} />
            </Helmet>
            <div className={`${styles.contactPage} ${styles.legalsPage} ${sharedStyles.pageWrapper}`}>
                {/* Bannière avec miniature */}
                {designSettings && designSettings.defaultPageBanner && (
                    <BannerWithSocialIcons
                        imageUrl={`${designSettings.defaultPageBanner}`}
                    />
                )}

                <div className={`page-content ${sharedStyles.pageContent}`}>
                    <section className={`global-section ${sharedStyles.mainSection}`}>
                        <div className={`global-container ${sharedStyles.headerContainer}`}>
                            <div className={sharedStyles.header}>
                                <div className={sharedStyles.headingBlock}>
                                    <h1>Ville d'Arras - Mentions légales</h1>
                                    <Breadcrumbs title="Mentions légales" />
                                </div>
                            </div>
                        </div>

                        <div className={`global-container ${styles.main}`}>
                            <div className={`${styles.block} ${legalStyles.content}`}>
                                <h3><a href="https://arras.fr" aria-label="Accéder au site de la ville d'Arras">arras.fr</a> est le site officiel de la Ville d’Arras</h3>

                                <h2>MAIRIE D'ARRAS</h2>
                                <address>
                                    Place Guy Mollet<br />
                                    BP 70913 - 62022 ARRAS Cedex<br />
                                    Tél : <a href="tel:+33321505050">03 21 50 50 50</a><br />
                                    Fax : 03 21 50 50 00<br />
                                </address>

                                <p>
                                    Directeur de publication : Frédéric LETURQUE, Maire d’Arras<br />
                                    Gestion éditoriale : Mathieu Pajot, Directeur de Cabinet adjoint et Directeur de la Communication, et les agents de la Direction de la Communication.<br />
                                    Contributeurs : ensemble des services municipaux<br />
                                    Crédits Photographies : Ville d’Arras
                                </p>

                                <h2>Réalisation &amp; hébergement</h2>
                                <h3>Agence MBS</h3>
                                <p>
                                    Développement du site et hébergement du site arras.fr par l’agence MBS. <a href="https://mbscom.fr" aria-label="Consulter le site web de MBS">mbscom.fr</a><br />
                                    Mis en ligne le 16/01/25.
                                </p>

                                <h2>Droits d'auteurs et copyright</h2>
                                <p>
                                    Le site de la ville est une œuvre de l’esprit protégée par la législation en vigueur. Le site
                                    comme le contenu présent sur ce site (textes, images, vidéos, logos, graphismes, cartes, etc.)
                                    est protégé par les lois relatives aux droits d’auteur et au copyright. La reproduction, la
                                    diffusion, l’adaptation ou toute autre utilisation non autorisée de ces éléments, en tout ou
                                    en partie, est strictement interdite sans l’accord préalable de la commune ou des titulaires
                                    des droits concernés.
                                </p>
                                <p>
                                    Les droits afférents aux illustrations et photographies contenues dans ce site appartiennent,
                                    sauf mention contraire, à la ville d’Arras. Les tiers qui souhaitent utiliser, reproduire ou diffuser tout ou partie du contenu de ce site doivent obtenir une autorisation préalable et écrite. Toute utilisation non conforme pourra entraîner des actions légales.
                                </p>

                                <h2>Références</h2>
                                <ul>
                                    <li>Loi 78-17 du 6 janvier 1978 modifiée - Loi relative à l'informatique, aux fichiers et aux libertés</li>
                                    <li>Loi n° 2018-493 du 20 juin 2018 relative à la protection des données personnelles</li>
                                    <li>Le code de la propriété intellectuelle dans la partie "Droit d’auteur" du code</li>
                                    <li>Loi du 29 juillet 1881 - Loi sur la liberté de la presse</li>
                                    <li>Loi 2000-719 du 1er août 2000 - Extrait de la loi portant sur la responsabilité des prestataires techniques</li>
                                </ul>
                                <p>
                                    <a href="https://www.cnil.fr/" aria-label="Consulter le site web de la CNIL">Site de la CNIL</a>
                                </p>

                                <h2>Avertissements</h2>
                                <p>
                                    Les informations publiées sont régulièrement vérifiées. Toutefois, nous rappelons que le contenu du site web peut comporter des inexactitudes techniques, erreurs typographiques ou omissions, et que ce contenu peut être modifié ou mis à jour sans préavis. En conséquence, la responsabilité de la Ville d’Arras ne serait être engagée La ville d'Arras décline toute responsabilité en cas d’erreur, inexactitudes techniques, d'omission ou autre. Elle ne peut être également tenue pour responsable des conséquences éventuelles de ces modifications.
                                </p>
                                <p>
                                    Pour signaler une erreur sur le site ou demander la rectification d'informations, n'hésitez pas à nous contacter.
                                </p>
                                <p>
                                    Les informations techniques qui se trouvent sur ce site n'ont qu'une valeur informative et sont susceptibles d'évoluer en fonction des modifications législatives et réglementaires. La ville d'Arras ne peut être tenue responsable de l'interprétation que vous pourriez faire des informations contenues dans ce site.
                                </p>
                                <p>
                                    Tout visiteur du site est considéré comme acceptant l'application des lois françaises. Il appartient à l'utilisateur de ce site de prendre toutes les mesures appropriées de façon à protéger ses propres données et/ou logiciels de la contamination par d'éventuels virus circulant sur le réseau Internet. De manière générale, la ville d'Arras décline toute responsabilité quant à un éventuel dommage survenu pendant la consultation du présent site.
                                </p>
                                <p>
                                    Dans l'esprit d'Internet, les liens proposés vers d'autres sites sont communiqués à titre indicatif et ne sauraient engager la responsabilité de la ville d'Arras, tant en ce qui concerne les contenus que les conditions d'accès. A l'inverse, toute personne (physique ou morale) souhaitant créer un ou (des) lien(s) vers une ou plusieurs page(s) du présent site doit préalablement en informer le webmestre du site.
                                    Néanmoins, par principe sont autorisés, sans accord express préalable :
                                </p>
                                <p>
                                    La citation, respectant le droit moral de l'auteur par l'indication de son nom et de la source. La citation est nécessairement courte, cette notion s'appréciant tant par rapport à la publication dont elle est extraite que par rapport à celle dans laquelle elle est introduite. La citation illustre un propos et ne doit pas concurrencer la publication à laquelle elle est empruntée. La multiplication des citations, aboutissant à une anthologie, est considérée comme une œuvre dérivée, et donc soumise à l'accord préalable de l'auteur ou de l'ayant droit ;
                                </p>
                                <p>
                                    La création d'un lien, à la condition impérative que ce lien ouvre une nouvelle fenêtre du navigateur et que la page atteinte par le lien ne soit pas imbriquée à l'intérieur d'autres pages, en particulier par voie de cadres (ou "frames"). L'URL figurant dans le lien doit être <a href="https://www.arras.fr" aria-label="Consulter le site web de la ville d'Arras">www.arras.fr</a>.
                                </p>
                                <p>
                                    Dans les autres cas, et notamment :
                                </p>
                                <ul>
                                    <li>
                                        Si le contenu du site doit s'intégrer dans la navigation de votre site, en particulier par voie de cadres (ou frames)
                                    </li>
                                    <li>
                                        Si l'accès aux pages contenant le lien vers la ville d'Arras n'est pas gratuit
                                    </li>
                                </ul>
                                <p>
                                    Vous devez demander l'autorisation expresse de la ville d'Arras par courrier électronique à la <a href="/contact" aria-label="Contacter le directeur de la communication">Direction de la communication</a>.
                                </p>
                                <p>
                                    Par ailleurs, la ville d'Arras ne prend aucun engagement concernant tout autre site auquel vous pourriez avoir accès via son site web, étant expressément rappelé qu'un lien vers un autre site ne signifie aucunement que la Ville d’Arras assume une quelconque responsabilité quant au contenu ou l'usage qui peut être fait de tels sites. Elle dégage toute responsabilité concernant les liens créés par d'autres sites vers ses propres sites. En effet, l’existence de tels liens ne peut permettre d'induire que la ville d'Arras cautionne ces sites ou qu'il en approuve le contenu. Afin de garantir l'identité et l'intégrité de son site la ville d'Arras se réserve le droit d'interdire les liens qui ne répondraient pas à l'objet dudit site ou qui pourraient porter préjudice à l'image de l'institution.
                                </p>
                            </div>


                        </div>
                    </section >
                </div >
            </div >
        </>
    );
};

export default MentionsLegales;
