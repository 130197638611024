// src/components/common/Breadcrumbs.js

import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Breadcrumbs.module.css';
import useAccessibility from 'hooks/useAccessibility';
import Page from 'pages/Page';
import PageType from 'components/pages/PageType';
import { useSubSite } from 'contexts/SubSiteContext'; // Hook pour vérifier le sous-site

const Breadcrumbs = ({ pageType, element, title , category, parent}) => {
  const { isSubSite, subSiteSlug } = useSubSite();
  let breadcrumbs = [];
  if (isSubSite) {
    breadcrumbs = [{ name: 'Accueil', link: `/${subSiteSlug}` }]; // Toujours commencer par l'accueil
  } else {
    breadcrumbs = [{ name: 'Accueil', link: '/' }]; // Toujours commencer par l'accueil
  }

  if (pageType === "isEvent") {
    // Pour les événements, le fil d'Ariane est : Accueil > Agenda > [Titre de l'événement]
    if (isSubSite) {
      breadcrumbs.push({ name: 'Agenda', link: `/${subSiteSlug}/agenda` });
    } else {
    breadcrumbs.push({ name: 'Agenda', link: '/agenda' });
    }
    breadcrumbs.push({ name: element?.title || 'Événement', link: null });

  } else if (pageType === "isPage") {
    // Pour les pages, le fil d'Ariane est : Accueil > [Nom de la catégorie] > [Titre de la page]
    if (category) {
      if (isSubSite) {

      } else {
        breadcrumbs.push({
          name: category.name,
          link: `/categorie/${category.slug}`,
        });     
       }

    } 
    if (element.subCategory) {
      if (isSubSite) {

      } else {
        breadcrumbs.push({
          name: element.subCategory.name,
          link: `/categorie/${element.subCategory.slug}`,
        });     
       }

    } 

// Ajouter la page actuelle (non cliquable)
    breadcrumbs.push({
      name: element.title,
      link: null,
    });
  } else if (pageType==="isMagazine") {
    breadcrumbs.push({
      name: "Kiosque",
      link: "/kiosque",
    });

    breadcrumbs.push({
      name: element.serie.name,
      link: `/kiosque/${element.serie.slug}`,
    });

    breadcrumbs.push({
      name: element.title,
      link: null,
    });
  } else if (pageType==="isNews") {
    if (isSubSite) {
      breadcrumbs.push({
        name: "Actualité",
        link: `/${subSiteSlug}/actualite`,
      });
    } 
    else {
    breadcrumbs.push({
      name: "Actualité",
      link: "/actualite",
    });
  }
    breadcrumbs.push({
      name: element.title,
      link: null,
    });

  } else if (pageType==="isAsso") {
    breadcrumbs.push({
      name: "Associations",
      link: "/associations",
    });

    breadcrumbs.push({
      name: element.name,
      link: null,
    });
  } else if (pageType==="isCategory") {
    if (parent) {
      breadcrumbs.push({
        name: parent.name,
        link: `/categorie/${parent.slug}`,
      });
    }
    // Pour les catégories, le fil d'Ariane est : Accueil > [Nom de la catégorie]
    breadcrumbs.push({
      name: title,
      link: null,
    });



  }
  
  else  {
    breadcrumbs.push({
      name: title,
      link: null,
    });
  }

  return (
    <nav aria-label="Fil d'Ariane" className={styles.breadcrumbs}>
      <ul>
        {breadcrumbs.map((crumb, index) => (
          <li key={index}>
            {crumb.link ? (
              <Link to={crumb.link}>{crumb.name}</Link>
            ) : (
              <span>{crumb.name}</span>
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Breadcrumbs;
