// AccessibilityContext.js
import React, { createContext, useState, useEffect } from 'react';

export const AccessibilityContext = createContext({
  selectedOption: 'standard',
  updateAccessibilityOption: () => {},
  getAccessibilityVariant: () => 'standard',
  zoomLevel: 100,
  updateZoomLevel: () => {},
  animationsEnabled: true,          // Par défaut
  updateAnimationsEnabled: () => {}
});

export const AccessibilityProvider = ({ children }) => {
  const [selectedOption, setSelectedOption] = useState(() => {
    const storedOption = localStorage.getItem('selectedAccessibilityOption');
    return storedOption ? storedOption : 'standard';
  });

  const [zoomLevel, setZoomLevel] = useState(() => {
    const storedZoom = localStorage.getItem('zoomLevel');
    return storedZoom ? parseInt(storedZoom, 10) : 100;
  });

  // Nouvel état pour les animations
  const [animationsEnabled, setAnimationsEnabled] = useState(() => {
    const savedValue = localStorage.getItem('animationsEnabled');
    return savedValue !== null ? JSON.parse(savedValue) : true;
  });

  useEffect(() => {
    // Mettre à jour l'accessibilité de la taille de police
    const root = document.documentElement;
    const initialRootFontSize = 62.5;
    const newRootFontSize = (initialRootFontSize * zoomLevel) / 100;
    root.style.setProperty('--root-font-size', `${newRootFontSize}%`);
  }, [zoomLevel]);

  useEffect(() => {
    localStorage.setItem('selectedAccessibilityOption', selectedOption);
  }, [selectedOption]);

  useEffect(() => {
    localStorage.setItem('zoomLevel', zoomLevel);
  }, [zoomLevel]);

  // Effet pour les animations
  useEffect(() => {
    localStorage.setItem('animationsEnabled', JSON.stringify(animationsEnabled));
    document.documentElement.style.setProperty(
      '--transition',
      animationsEnabled ? 'all .2s ease-out' : 'none'
    );
  }, [animationsEnabled]);

  const updateAccessibilityOption = (option) => {
    setSelectedOption(option);
  };

  const updateZoomLevel = (level) => {
    setZoomLevel(level);
  };

  const updateAnimationsEnabled = (enabled) => {
    setAnimationsEnabled(enabled);
  };

  const getAccessibilityVariant = () => {
    switch (selectedOption) {
      case 'redGreenBlindness':
        return 'redGreen';
      case 'blueYellowBlindness':
        return 'blueYellow';
      case 'achromatopsia':
        return 'blackWhite';
      case 'blackTheme':
        return 'dark';
      default:
        return 'standard';
    }
  };

  useEffect(() => {
    const variantClass = getAccessibilityVariant();
    const body = document.body;
    const accessibilityClasses = ['redGreen', 'blueYellow', 'blackWhite', 'dark'];
    body.classList.remove(...accessibilityClasses);

    if (variantClass !== 'standard') {
      body.classList.add(variantClass);
    }
  }, [selectedOption]);

  return (
    <AccessibilityContext.Provider
      value={{
        selectedOption,
        updateAccessibilityOption,
        getAccessibilityVariant,
        zoomLevel,
        updateZoomLevel,
        animationsEnabled,
        updateAnimationsEnabled
      }}
    >
      {children}
    </AccessibilityContext.Provider>
  );
};
