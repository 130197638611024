import React, { useState, useEffect } from 'react';
import api from 'services/api'; // Votre service API configuré avec axios
import MapCta from 'components/common/MapCta';
import LiensPratiques from 'components/common/LiensPratiques';
import styles from './ActesAdministratifsPage.module.css';
import sharedStyles from 'styles/modules/ContentPage.module.css';
import Breadcrumbs from 'components/common/Breadcrumbs';
import useDesignSettingsData from 'hooks/useDesignSettingsData';
import BannerWithSocialIcons from 'components/common/BannerWithSocialIcons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet';

const ActesAdministratifsPage = () => {
    const { designSettings } = useDesignSettingsData();

    const [activeCategory, setActiveCategory] = useState('arretes'); // Onglet actif
    const [openYears, setOpenYears] = useState({});
    const [openMonths, setOpenMonths] = useState({});

    const [data, setData] = useState([]);

    useEffect(() => {
        // Appel à l’API pour récupérer les actes administratifs
        api.get('/actes-adminsitratifs')
            .then((response) => {
                setData(response.data);
            })
            .catch((err) => {
                console.error(err);
            });
    }, []);

    // Mapping entre nos catégories et les types renvoyés par l'API
    const categoriesMap = {
        arretes: 'arrete',
        pv: 'pv',
        deliberations: 'deliberation' // ou 'decision' ou autre si besoin
    };

    // Filtrer les données en fonction de la catégorie active
    const filteredData = data.filter((item) => item.type === categoriesMap[activeCategory]);

    // On groupe les données par année et par mois
    const groupedData = filteredData.reduce((acc, item) => {
        const date = new Date(item.publicationDate);
        const year = date.getFullYear();
        // Obtenir le mois en français (long) 
        const monthName = date.toLocaleString('fr-FR', { month: 'long' });

        if (!acc[year]) acc[year] = {};
        if (!acc[year][monthName]) acc[year][monthName] = [];
        acc[year][monthName].push(item);
        return acc;
    }, {});

    // Trier les années de manière décroissante (années plus récentes en premier)
    const sortedYears = Object.keys(groupedData).sort((a, b) => b - a);

    // On trie les publications de chaque mois par date décroissante
    sortedYears.forEach((year) => {
        const monthsForYear = Object.keys(groupedData[year]);
        monthsForYear.forEach((month) => {
            groupedData[year][month].sort((a, b) => new Date(b.publicationDate) - new Date(a.publicationDate));
        });
    });

    // Fonction pour basculer l'état ouvert/fermé d'une année
    const toggleYear = (year) => {
        setOpenYears((prev) => ({ ...prev, [year]: !prev[year] }));
    };

    // Fonction pour basculer l'état ouvert/fermé d'un mois
    const toggleMonth = (year, month) => {
        setOpenMonths((prev) => ({
            ...prev,
            [year]: { ...prev[year], [month]: !prev[year]?.[month] },
        }));
    };


    return (
        <>
            <Helmet>
                <title>Arras | Les Actes Administratifs</title>
                <meta name="description" content="Retrouvez sur cette page les décisions, les arrêtés et les PV de la ville d'Arras. Cliquez sur l'onglet qui vous intéresse pour consulter les actes administratifs de votre choix. Vous pouvez également effectuer une recherche par mot-clé, par service ou par date pour chaque type d'acte (Délibérations/Décisions, Arrêtés, PV)." />
                <meta property="og:title" content="Arras | Les Actes Administratifs" />
                <meta property="og:description" content="Retrouvez sur cette page les décisions, les arrêtés et les PV de la ville d'Arras. Cliquez sur l'onglet qui vous intéresse pour consulter les actes administratifs de votre choix. Vous pouvez également effectuer une recherche par mot-clé, par service ou par date pour chaque type d'acte (Délibérations/Décisions, Arrêtés, PV)." />
                <link rel="canonical" href={`https://www.arras.fr/les-actes-administratifs`} />
            </Helmet>
            <div className={`${styles.actesPage} ${sharedStyles.pageWrapper}`}>
                {/* Bannière avec miniature */}
                {designSettings && designSettings.administratifBanner && (
                    <BannerWithSocialIcons
                        imageUrl={`${designSettings.administratifBanner}`}
                    />
                )}

                <div className={`page-content ${sharedStyles.pageContent}`}>
                    <section className={`global-section ${sharedStyles.mainSection}`}>
                        <div className={`global-container ${sharedStyles.headerContainer}`}>
                            <div className={sharedStyles.header}>
                                <div className={sharedStyles.headingBlock}>
                                    <h1>Les Actes Administratifs</h1>
                                    <Breadcrumbs title="Les Actes Administratifs" />
                                </div>
                            </div>
                        </div>

                        <div className={`global-container ${styles.main}`}>
                            <div className={styles.mainColumn}>
                                <p className={styles.intro}>
                                    Retrouvez sur cette page les <strong>décisions</strong>, les <strong>arrêtés</strong> et les <strong>PV</strong> de la ville d'Arras.<br />
                                    Cliquez sur l'onglet qui vous intéresse pour consulter les actes administratifs de votre choix.<br />
                                    Vous pouvez également effectuer une <strong>recherche par mot-clé, par service ou par date</strong> pour chaque type d'acte (Délibérations/Décisions, Arrêtés, PV).
                                </p>

                                <div className={styles.toggles}>
                                    {/* Boutons d'onglets */}
                                    {['arretes', 'pv', 'deliberations'].map((category) => (
                                        <button
                                            key={category}
                                            className={`${styles.catToggle} ${activeCategory === category ? styles.isActive : ''}`}
                                            onClick={() => setActiveCategory(category)}
                                            aria-label={`Bascule sur la catégorie ${category}`}
                                        >
                                            {category === 'arretes' ? 'Arrêtés' : category === 'pv' ? 'PV' : 'Délibérations / Décisions'}
                                        </button>
                                    ))}
                                </div>

                                {/* Contenu des catégories */}
                                {['arretes', 'pv', 'deliberations'].map((category) => (
                                    <div
                                        key={category}
                                        className={`${styles.listContainer} ${activeCategory === category ? '' : styles.hidden}`}
                                        id={category}
                                    >
                                        <p>
                                            Retrouvez {category === 'arretes'
                                                ? 'tous les arrêtés municipaux.'
                                                : category === 'pv'
                                                    ? 'tous les procès-verbaux.'
                                                    : 'toutes les délibérations du conseil municipal.'}
                                        </p>

                                        {/* Liste des années dynamiques */}
                                        {sortedYears.map((year) => (
                                            <div className={styles.listItem} key={year}>
                                                <button
                                                    className={styles.yearToggle}
                                                    onClick={() => toggleYear(year)}
                                                    aria-label={`Déploie le contenu de l'année ${year}`}
                                                >
                                                    <h2>{year}</h2>
                                                    <FontAwesomeIcon icon={faCaretDown} />
                                                </button>

                                                {/* Contenu de l'année */}
                                                {openYears[year] && (
                                                    <ul className={`list--none ${styles.yearContent}`}>
                                                        {Object.keys(groupedData[year]).map((month) => (
                                                            <li className={styles.yearItem} key={`${year}-${month}`}>
                                                                <button
                                                                    className={styles.monthToggle}
                                                                    onClick={() => toggleMonth(year, month)}
                                                                    aria-label={`Déploie le contenu du mois ${month}`}
                                                                >
                                                                    <h3>{month.charAt(0).toUpperCase() + month.slice(1)}</h3>
                                                                    <FontAwesomeIcon icon={faCaretDown} />
                                                                </button>

                                                                {/* Contenu du mois */}
                                                                {openMonths[year]?.[month] && (
                                                                    <div className={styles.monthContent}>
                                                                        <table className={styles.monthTable}>
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>Date</th>
                                                                                    <th>Lieu</th>
                                                                                    <th>Fichier</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {groupedData[year][month].map((pub) => (
                                                                                    <tr key={pub.id}>
                                                                                        <td>{new Date(pub.publicationDate).toLocaleDateString('fr-FR')}</td>
                                                                                        <td>{pub.place}{pub.place ? ` - ${pub.date}` : null}</td>
                                                                                        <td>
                                                                                            <a href={`${process.env.REACT_APP_MEDIA_BASE_URL}${pub.file.path}`} target="_blank" rel="noopener noreferrer" aria-label="Télécharger le fichier">
                                                                                                <FontAwesomeIcon icon={faFilePdf} /> {pub.file.fileName}
                                                                                            </a>
                                                                                        </td>
                                                                                    </tr>
                                                                                ))}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                )}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </section>

                    {/* CTA Map */}
                    <MapCta />

                    {/* Liens Pratiques */}
                    <LiensPratiques />
                </div >
            </div >
        </>
    );
};

export default ActesAdministratifsPage;
