import React, { useContext, useEffect, useState } from "react";
import api from "services/api";
import EventFeatured from "../events/EventFeatured";
import EventCard from "../events/EventCard";
import InfoCard from "components/common/InfoCard";
import { AccessibilityContext } from "contexts/AccessibilityContext";
import styles from "./EventList.module.css";
import listStyles from "styles/modules/CardList.module.css";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import SpecialEventCard from "../events/SpecialEventCard"; // Importer si nécessaire
import { useSubSite } from 'contexts/SubSiteContext';
import Spinner from "components/common/Spinner";

const EventList = () => {
  const [events, setEvents] = useState([]);
  const [featuredEvent, setFeaturedEvent] = useState(null);
  const [otherEvents, setOtherEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const { getAccessibilityVariant } = useContext(AccessibilityContext);
  const [accessibilityVariant, setAccessibilityVariant] = useState(
    getAccessibilityVariant()
  );
  const { isSubSite, subSiteSlug } = useSubSite();

  const agendaURL = isSubSite ? `/${subSiteSlug}/agenda` : "/agenda";

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        let response;
        if (isSubSite) {
          // Pour les sous-sites, on récupère les événements spécifiques
          response = await api.get(`/subsites/${subSiteSlug}/events`);
          const eventsData = response.data;
          setEvents(eventsData);

          // Utiliser le premier événement comme événement en vedette
          if (eventsData.length > 0) {
            setFeaturedEvent(eventsData[0]);
            setOtherEvents(eventsData.slice(1, 4)); // Les trois suivants
          }
        } else {
          // Pour le site principal, on récupère les événements réguliers
          response = await api.get("/events/regular");
          const eventsData = response.data;
          setEvents(eventsData);

          // Trouver l'événement en vedette
          const featured = eventsData.find(event => event.isFeatured);
          setFeaturedEvent(featured);

          // Les autres événements (en excluant l'événement en vedette)
          setOtherEvents(eventsData.filter(event => event !== featured).slice(0, 3));
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des événements:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchEvents();
  }, [isSubSite, subSiteSlug]);

  useEffect(
    () => {
      setAccessibilityVariant(getAccessibilityVariant());
    },
    [getAccessibilityVariant]
  );

  if (loading) {
    return <div><Spinner /></div>;
  }

  return (
    <section className="global-section">
      <div className="global-container">
        <h2>Agenda & événements</h2>
        {featuredEvent &&
          <EventFeatured
            key="feat-card-agenda"
            event={featuredEvent}
            accessibilityVariant={accessibilityVariant}
          />}

        <ul className={`list--none ${listStyles.grid}`}>
          {otherEvents.map(event =>
            <EventCard
              key={event.id}
              event={event}
              accessibilityVariant={accessibilityVariant}
            />
          )}
          <InfoCard
            key="info-card-agenda"
            icon={faCalendarAlt}
            title="Ne manquez pas les prochains événements !"
            text="Découvrez tous les événements à venir dans votre ville."
            linkTo={agendaURL}
            buttonText="Consulter l'Agenda"
            categorySlug="agenda"
            accessibilityVariant={accessibilityVariant}
          />
        </ul>
        {/* Ne pas afficher SpecialEventCard pour les sous-sites */}
        {!isSubSite && (
          <SpecialEventCard
            key="special-event-card"
            accessibilityVariant={accessibilityVariant}
          />
        )}
      </div>
    </section>
  );
};

export default EventList;
