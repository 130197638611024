// Helpers.js ou dans le même fichier

/**
 * Supprime les accents et diacritiques d'une chaîne de caractères
 * @param {string} str
 * @returns {string}
 */
export default function removeAccents(str) {
    if (!str) return '';
    return str
      .normalize('NFD') // Convertit les caractères accentués en char + diacritiques
      .replace(/[\u0300-\u036f]/g, ''); // Supprime les diacritiques
  }
  