// src/components/EventModal.js

import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import EventFeatured from 'components/events/EventFeatured';
import styles from './EventModal.module.css';
import useAccessibility from 'hooks/useAccessibility';

Modal.setAppElement('#root');

const EventModal = ({ isOpen, onRequestClose, event }) => {
  const accessibilityVariant = useAccessibility();

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Détails de l'événement"
      className={styles.modal}
      overlayClassName={styles.overlay}
    >
      <EventFeatured event={event} accessibilityVariant={accessibilityVariant} />
      
      <button className={styles.closeButton} onClick={onRequestClose} aria-label="Fermer la fenêtre">
            <div className={styles.lineContainer}>
              <span className={styles.line}></span>
              <span className={styles.line}></span>
            </div>
            Fermer
          </button>
    </Modal>
  );
};

EventModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  event: PropTypes.object.isRequired,
  accessibilityVariant: PropTypes.func.isRequired,
};

export default EventModal;
