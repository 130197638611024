import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/common/Button';
import { Funnel } from 'react-ionicons';
import styles from 'styles/modules/Archives.module.css';
import filterStyles from 'components/calendar/EventFilter.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Importer Font Awesome
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'; // Importer Icone down

const ArchivesFilters = ({
  categories,
  selectedCategory,
  handleFilterChange,
  searchQuery,
  handleSearchChange,
  resetFilters,
}) => (
  <div className={filterStyles.filterWrap}>
    <div className={filterStyles.filterContainer}>
      <p className={filterStyles.label}>Filtrer le contenu :</p>
      <div className={filterStyles.filters}>

        <label htmlFor="searchInput" className="sr-only">Rechercher :</label>
        <input
          type="text"
          id="searchInput"
          placeholder="Rechercher..."
          value={searchQuery}
          onChange={handleSearchChange}
          className={filterStyles.nameFilter}
        />

        {categories && (
          <div className={filterStyles.dropdown}>

            <label htmlFor="categoryFilter" className="sr-only">Filtrer par catégorie</label>
            <select
              id="categoryFilter"
              value={selectedCategory}
              onChange={(e) => handleFilterChange(e.target.value)}
              className={filterStyles.categoryFilter}
            >
              <option value="all">Toutes les catégories</option>
              {categories.map((category) => (
                <option key={category.id} value={category.slug}>
                  {category.name}
                </option>
              ))}
            </select>
            <FontAwesomeIcon icon={faCaretDown} />
          </div>
        )}
      </div>
    </div>

    {/* Termes filtrés à dynamiser */}
    <div className={styles.filters}>
      <div className={filterStyles.filterContainer}>
        <ul className={`list--none ${styles.filterList}`}>
          <li><a href="/" className={styles.autoTag} aria-label="Supprimer le filtre Terme automatique">Terme automatique<span>x</span></a></li>
          <li><a href="/" className={styles.filteredTag} aria-label="Supprimer le filtre Terme automatique">Terme filtré<span>x</span></a></li>
          <li><a href="/" className={styles.filteredTag} aria-label="Supprimer le filtre Terme automatique">Terme filtré<span>x</span></a></li>
        </ul>
        <button className={`arrow-link ${styles.resetTags}`} onClick={resetFilters}>
          Réinitialiser les filtres
        </button>
      </div>
    </div>

  </div>
);

ArchivesFilters.propTypes = {
  categories: PropTypes.array,
  selectedCategory: PropTypes.string.isRequired,
  handleFilterChange: PropTypes.func.isRequired,
  searchQuery: PropTypes.string.isRequired,
  handleSearchChange: PropTypes.func.isRequired,
  resetFilters: PropTypes.func.isRequired,
};

export default ArchivesFilters;
