import React from "react";
import { Route, Navigate } from "react-router-dom";

const redirectionRoutes = [
  // 1) Annuaire -> aucune catégorie
  <Route
    key="associations-all"
    path="/page/l-annuaire-des-associations"
    element={<Navigate to="/associations" replace />}
  />,

  // 2) Annuaire -> catégorie "culturelle"
  <Route
    key="associations-culturelles"
    path="/page/l-annuaire-des-associations-culturelles"
    element={<Navigate to="/associations?cat=culturelle" replace />}
  />,

  // 3) Annuaire -> catégorie "sportive"
  <Route
    key="associations-sportives"
    path="/page/l-annuaire-des-associations-sportives"
    element={<Navigate to="/associations?cat=sportive" replace />}
  />
];

export default redirectionRoutes;
