// src/components/common/BannerWithSocialIcons.js

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faXTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import Banner from './Banner';
import SocialIcons from './SocialIcons';
import api from 'services/api'; // Importer votre service API
import styles from './BannerWithSocialIcons.module.css';
import useAccessibility from 'hooks/useAccessibility';

const iconMap = {
  facebook: faFacebook,
  twitter: faXTwitter,
  instagram: faInstagram,
  linkedin: faLinkedin,
};

const BannerWithSocialIcons = ({ imageUrl, altText = 'Bannière' }) => {
  const accessibilityVariant = useAccessibility();
  const [socialLinks, setSocialLinks] = useState([]);

  useEffect(() => {
    const fetchSocialLinks = async () => {
      try {
        const response = await api.get('/general-informations/social-networks');
        setSocialLinks(response.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des réseaux sociaux:', error);
      }
    };

    fetchSocialLinks();
  }, []);

  return (
    <div className={styles.container}>
      <SocialIcons key={socialLinks.id} />
      <Banner imageUrl={imageUrl} altText={altText} />
    </div>
  );
};

BannerWithSocialIcons.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  altText: PropTypes.string,
};

export default BannerWithSocialIcons;
