// contexts/ProfileContext.js
import React, { createContext, useState, useEffect } from 'react';

export const ProfileContext = createContext();

export const ProfileProvider = ({ children }) => {
  const [profileOption, setProfileOption] = useState(() => {
    // Lire la valeur depuis localStorage lors de l'initialisation
    return localStorage.getItem('selectedProfileOption') || 'citoyen';
  });

  // Sauvegarder le profil dans localStorage à chaque changement
  useEffect(() => {
    localStorage.setItem('selectedProfileOption', profileOption);
  }, [profileOption]);

  const updateProfileOption = (option) => {
    setProfileOption(option);
  };

  return (
    <ProfileContext.Provider value={{ profileOption, updateProfileOption }}>
      {children}
    </ProfileContext.Provider>
  );
};
