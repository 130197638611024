import React from 'react';
import PropTypes from 'prop-types';
import useAccessibility from 'hooks/useAccessibility';
import accessClasses from 'styles/accessClasses.css';

const Layout = ({ children }) => {
  const accessibilityVariant = useAccessibility();

  return (
    <div className={`main-wrapper ${accessibilityVariant}`}>
      {children}
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
