import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import api from 'services/api';
import Breadcrumbs from 'components/common/Breadcrumbs';
import ShareButtons from 'components/common/ShareButtons';
import BannerWithSocialIcons from 'components/common/BannerWithSocialIcons';
import sharedStyles from 'styles/modules/ContentPage.module.css';
import styles from './PointOfInterestPage.module.css';
import archiveStyle from 'styles/modules/Archives.module.css';
import Button from 'components/common/Button';
import useDesignSettingsData from 'hooks/useDesignSettingsData';
import PictoAccess from 'components/common/PictoAccess';
import LiensPratiques from 'components/common/LiensPratiques';
import { ShareSocialOutline } from 'react-ionicons';
import { Helmet } from 'react-helmet';
import Spinner from 'components/common/Spinner';
import { isMobile } from 'react-device-detect';

const PointOfInterestPage = () => {
  const { slug } = useParams();
  const [point, setPoint] = useState(null);
  const [loading, setLoading] = useState(true);
  const { designSettings, settingsLoading, error } = useDesignSettingsData();
  const mediaBaseUrl = process.env.REACT_APP_MEDIA_BASE_URL;

  // On récupère le deepLink via variable d'environnement
  const deepLink = process.env.REACT_APP_DEEPLINK;
  
  useEffect(() => {
    const fetchPointData = async () => {
      try {
        const response = await api.get(`/map/point-of-interest/read/${slug}`);
        setPoint(response.data);
      } catch (error) {
        console.error("Erreur lors de la récupération du point d'intérêt :", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPointData();
  }, [slug]);

  if (loading) return <div><Spinner /></div>;
  if (!point) return <div>Point d'intérêt introuvable.</div>;
  if (settingsLoading) return <div><Spinner /></div>;
  if (error) return <div>Erreur : {error}</div>;

  const {
    name,
    description,
    openingHours,
    contactInfo,
    address,
    categories,
    themes,
    district,
    thumbnail,
    pictoAccess,
    picture
  } = point;

  // Gestion du clic sur le bouton "Retour à la carte"
  const handleBackClick = () => {
    if (isMobile) {
      // Si l'historique contient des pages, on revient en arrière
      if (window.history.length > 1) {
          window.location.href = "/cartes";
      } else {
        // Redirection selon la présence d'un deep link
        if (deepLink) {
          window.location.href = "/cartes";
        } else {
          window.location.href = "/cartes";
        }
      }
    } else {
      window.location.href = "/cartes";
    }
  };

  return (
    <>
      <Helmet>
        <title>Ville d'Arras | {name}</title>
        <meta name="description" content={`Toutes les informations sur ${name}`} />
        <meta property="og:title" content={name} />
        <meta property="og:description" content={`Toutes les informations sur ${name}`} />
        <meta property="og:image" content={`${designSettings.defaultPOIBanner}`} />
        <meta property="og:image:alt" content={`Image descriptive pour la page ${name}`} />
        <link rel="canonical" href={`https://arras.fr/point-interet/${slug}`} />
      </Helmet>
      <div className={`${sharedStyles.pageWrapper} ${sharedStyles.poiPage}`}>
        <BannerWithSocialIcons imageUrl={`${designSettings.defaultPOIBanner}`} />
        <div className={`page-content ${sharedStyles.pageContent}`}>
          <section className={`global-section ${sharedStyles.mainSection}`}>
            <div className={`global-container ${sharedStyles.headerContainer}`}>
              <div className={sharedStyles.header}>
                <div className={archiveStyle.headingBlock}>
                  <h1>{name}</h1>
                  <Breadcrumbs title={name} />
                </div>
                <div className={sharedStyles.metaContainer}>
                  <div className={sharedStyles.meta}>
                    <ShareSocialOutline
                      color="currentColor"
                      height=""
                      width=""
                      className={sharedStyles.metaIcon}
                    />
                    <p className={sharedStyles.metaInfo}>Partager :</p>
                    <ShareButtons />
                  </div>
                </div>
              </div>
            </div>
            <div className={`global-container ${sharedStyles.main}`}>
              {/* Colonne principale */}
              <div className={sharedStyles.mainColumn}>
                {/* Informations de contact */}
                <div className={styles.contactInfo}>
                  {address && (
                    <p>
                      <strong>Adresse :</strong> {address}
                    </p>
                  )}
                  {contactInfo && (
                    <p>
                      <strong>Contact :</strong> {contactInfo}
                    </p>
                  )}
                  {openingHours && (
                    <p>
                      <strong>Horaires d'ouverture :</strong> {openingHours}
                    </p>
                  )}
                </div>
                {/* Informations supplémentaires */}
                <div className={styles.infoSection}>
                  {description && (
                    <div className={styles.infoItem}>
                      <h2>Description :</h2>
                      <p>{description}</p>
                    </div>
                  )}
                  {categories && categories.length > 0 && (
                    <div className={styles.infoItem}>
                      <h2>Catégories :</h2>
                      <ul className="list--none">
                        {categories.map((category) => (
                          <li key={category.id}>{category.name}</li>
                        ))}
                      </ul>
                    </div>
                  )}
                  {district && (
                    <div className={styles.infoItem}>
                      <h2>Quartier :</h2>
                      <p>{district.name}</p>
                    </div>
                  )}
                  {themes && themes.length > 0 && (
                    <div className={styles.infoItem}>
                      <h2>Thèmes :</h2>
                      <ul className="list--none">
                        {themes.map((theme) => (
                          <li key={theme.id}>{theme.name}</li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
                {/* Actions */}
                <div className={sharedStyles.actions}>
                  <Button variant="map" onClick={handleBackClick}>
                    Retour à la carte
                  </Button>
                  <Button variant="primary" onClick={() => window.print()}>
                    Imprimer
                  </Button>
                </div>
              </div>
              {/* Colonne latérale droite */}
              <div className={sharedStyles.sidebar}>
                {/* Affichage de la photo uniquement si l'appareil n'est pas mobile */}
                {!isMobile && picture && (
                  <figure className={styles.figure}>
                    <img
                      src={`${mediaBaseUrl}/${picture.path}`}
                      className={styles.thumbnail}
                      alt={picture ? `Image de ${picture.name}` : "Photo manquante"}
                    />
                  </figure>
                )}
                {pictoAccess && <PictoAccess iframeHtml={pictoAccess} width="250" height="120" />}
              </div>
            </div>
          </section>
          {/* Liens Pratiques */}
          <LiensPratiques />
        </div>
      </div>
    </>
  );
};

export default PointOfInterestPage;
