import React from 'react';
import PropTypes from 'prop-types';
import styles from './CardBlock.module.css'; // Fichier CSS pour styliser les cartes
import placeholder from 'fallback/placeholder_img.webp'; // Vérifiez que ce chemin est correct

const CardBlock = ({ block }) => {
  return (
    <div className={styles.cardBlock}>
      {block.title && <h2 className={styles.blockTitle}>{block.title}</h2>}
      <div className={styles.cardsContainer}>
        {block.cards.map((card) => (
          <div key={card.id} className={styles.card}>
            <figure className={styles.figure}>
              <img
                src={
                  card.image
                    ? `${process.env.REACT_APP_MEDIA_BASE_URL_UPLOAD}${card.image}`
                    : placeholder
                }
                alt={card.title || 'Image par défaut'}
                className={styles.cardImage}
                onError={(e) => {
                  e.target.onerror = null; // Empêche une boucle infinie en cas d'erreur
                  e.target.src = placeholder; // Remplace par le placeholder en cas d'échec de chargement
                }}
              />
            </figure>
            <div className={styles.cardContent}>
              {card.title && <h3 className={styles.cardTitle}>{card.title}</h3>}
              {card.content && (
                <div
                  className={styles.cardText}
                  dangerouslySetInnerHTML={{ __html: card.content }}
                />
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

CardBlock.propTypes = {
  block: PropTypes.shape({
    title: PropTypes.string,
    cards: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string,
        content: PropTypes.string,
        image: PropTypes.string,
        position: PropTypes.number,
      })
    ),
  }).isRequired,
};

export default CardBlock;
