import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import styles from './QuickLinks.module.css';
import useProfile from 'hooks/useProfile';
import iconMapping from 'utils/iconMapping';
import { getUserProfiles, checkSpecialEvent } from 'services/api';
import { InformationCircleOutline } from 'react-ionicons';
import useAccessibility from 'hooks/useAccessibility';
import catStyles from 'styles/modules/Categories.module.css';
import useBreakpoint from 'hooks/useBreakpoint';
import { useSubSite } from 'contexts/SubSiteContext';
import Spinner from 'components/common/Spinner';

// Petite fonction pour nettoyer la chaîne SVG et enlever par exemple l'en-tête XML
// ou d'autres attributs indésirables, si nécessaire
const sanitizeSvg = (rawSvg) => {
  if (!rawSvg) return null;
  
  // Supprimer la ligne <?xml version="1.0" encoding="UTF-8"?>
  // (on pourrait enlever d’autres attributs si besoin)
  return rawSvg.replace(/<\?xml.*?\?>/, '').trim();
};

const QuickLinks = () => {
  const accessibilityVariant = useAccessibility();
  const { profileOption } = useProfile();
  const [profiles, setProfiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [specialWidget, setSpecialWidget] = useState(null);
  const { isSubSite, subSiteSlug } = useSubSite();
  const isMobile = useBreakpoint();

  // Charger les profils et vérifier l'événement spécial au montage
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [profilesData, specialEventData] = await Promise.all([
          getUserProfiles(),
          checkSpecialEvent(),
        ]);
        setProfiles(profilesData);

        if (specialEventData.special_event && specialEventData.widget) {
          setSpecialWidget(specialEventData.widget);
        }

        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Trouver le profil actuel
  const currentProfile = profiles.find(profile => profile.slug === profileOption);

  if (loading) {
    return (
      <div className={styles.quickLinks}>
        <Spinner />
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.quickLinks}>
        Erreur lors du chargement des liens rapides.
      </div>
    );
  }

  if (!currentProfile) {
    return (
      <div className={styles.quickLinks}>
        Profil non trouvé.
      </div>
    );
  }

  const quickLinks = currentProfile.widgets.length > 0 ? currentProfile.widgets : [];

  // Définir des liens par défaut si aucun widget n'est disponible
  const defaultQuickLinks = [
    { id: 1, name: 'Accueil', type: 'link', target: { type: 'link', url: '/' } },
    { id: 2, name: 'À Propos', type: 'link', target: { type: 'link', url: '/a-propos' } },
    { id: 3, name: 'Contact', type: 'link', target: { type: 'link', url: '/contact' } },
  ];

  // Optionnel : Inclure les liens par défaut si aucun widget n'est disponible
  const links = quickLinks.length > 0 ? quickLinks : defaultQuickLinks;

  // Ajouter le widget spécial en tête si disponible
  const combinedLinks = specialWidget
    ? [specialWidget, ...links]
    : links;

  // Limiter à six liens au maximum
  const linksToRender = combinedLinks.slice(0, 6);

  return (
    <div className={styles.quickLinks} aria-labelledby="quick-links">
      <h2 id="quick-links" className="sr-only">
        Liens rapides
      </h2>

      <ul className={`list--none ${styles.iconsContainer}`}>
        {linksToRender.map(link => {
          // Fallback si on ne trouve pas de correspondance
          const IconComponent =
            iconMapping[link.name] ||
            iconMapping['Accueil'] ||
            InformationCircleOutline;

          // Déterminer l'URL
          let url = '#';
          let classSlug = 'slug';

          switch (link.type) {
            case 'category':
              // Slug de catégorie
              const categorySlug = link.target?.slug
                ? link.target.slug
                : link.target.name
                    .toLowerCase()
                    .replace(/ & /g, '-and-')
                    .replace(/\s+/g, '-');
              url = `/categorie/${categorySlug}`;
              classSlug = categorySlug;
              break;
            case 'link':
              url = link.target.url;
              classSlug = link.target.url.replace('/', '');
              break;
            case 'page':
              url = `/page/${link.target.slug}`;
              break;
            default:
              url = '#';
          }

          // Nettoyage (optionnel) de la chaîne SVG
          const sanitizedIcon = sanitizeSvg(link.icon);

          return (
            <li key={link.id}>
              {/* Vue mobile */}
              {isMobile ? (
                <a
                  href={url}
                  className={`
                    ${styles.linkItem} 
                    ${styles.responsiveItem} 
                    ${catStyles[classSlug]} 
                    ${catStyles[accessibilityVariant]}
                  `}
                  aria-label={link.name}
                >
                  <span className={styles.label}>{link.name}</span>
                  <FontAwesomeIcon className={styles.arrow} icon={faArrowRight} />
                </a>
              ) : (
                // Vue desktop
                <a
                  href={url}
                  className={`${styles.linkItem} ${styles.desktopItem}`}
                  aria-label={link.name}
                >
                  <span
                    className={`${styles.iconWrapper} ${catStyles[classSlug]} ${catStyles[accessibilityVariant]}`}
                  >
                    {sanitizedIcon ? (
                      // On injecte la chaîne SVG, si elle est disponible
                      <span
                        className={styles.icon}
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{ __html: sanitizedIcon }}
                      />
                    ) : (
                      // Sinon, on affiche l'icône par défaut
                      <IconComponent
                        color="currentColor"
                        width=""
                        height=""
                        className={styles.icon}
                      />
                    )}
                  </span>
                  <span className={styles.label}>{link.name}</span>
                </a>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default QuickLinks;
