// ContactPage.js
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'; // <-- Hook v3
import Breadcrumbs from 'components/common/Breadcrumbs';
import BannerWithSocialIcons from 'components/common/BannerWithSocialIcons';
import MapCta from 'components/common/MapCta';
import LiensPratiques from 'components/common/LiensPratiques';
import Button from 'components/common/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faPhone } from '@fortawesome/free-solid-svg-icons';
import api from 'services/api';
import useDesignSettingsData from 'hooks/useDesignSettingsData';

import styles from './ContactPage.module.css';
import sharedStyles from 'styles/modules/ContentPage.module.css';

const ContactPage = () => {
  // ----- HOOK reCAPTCHA v3 -----
  const { executeRecaptcha } = useGoogleReCaptcha();

  // ----- Récupération éventuelle de la bannière / design settings -----
  const { designSettings } = useDesignSettingsData();

  // ----- State du formulaire -----
  const [formData, setFormData] = useState({
    nom: '',
    prenom: '',
    email: '',
    telephone: '',
    objet: '',
    message: '',
  });

  // ----- Gère la saisie des champs -----
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // ----- Soumission du formulaire -----
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Vérifier que reCAPTCHA est disponible
    if (!executeRecaptcha) {
      alert('reCAPTCHA non initialisé. Réessayez plus tard.');
      return;
    }

    try {
      // 1) Obtenir le token reCAPTCHA v3 (invisible), action = 'contact_form'
      const token = await executeRecaptcha('contact_form');

      // 2) Construire la charge utile
      const payload = {
        firstname: formData.prenom,
        lastname: formData.nom,
        email: formData.email,
        phone: formData.telephone,
        object: formData.objet,
        message: formData.message,
        recaptchaToken: token,
      };

      // 3) Envoyer au backend
      const response = await api.post('/contact/submit', payload);

      alert('Votre message a été envoyé avec succès.');

      // Réinitialiser éventuellement les champs
      setFormData({
        nom: '',
        prenom: '',
        email: '',
        telephone: '',
        objet: '',
        message: '',
      });

    } catch (error) {
      console.error('Erreur lors de la soumission du formulaire:', error);
      alert('Une erreur est survenue lors de la soumission du formulaire.');
    }
  };

  return (
    <>
      <Helmet>
        <title>Arras | Contact</title>
        <meta name="description" content="Besoin de contacter la ville d’Arras ? Retrouvez toutes les informations pour nous joindre : numéros utiles, adresses, horaires et formulaire de contact. Nous sommes à votre écoute !" />
        <meta property="og:title" content="Arras | Contact" />
        <meta property="og:description" content="Une question ou une demande ? Contactez facilement la ville d’Arras : téléphone, email, adresse ou formulaire. Nous sommes là pour vous accompagner !" />
        <link rel="canonical" href={`https://www.arras.fr/contact`} />
      </Helmet>

      <div className={`${styles.contactPage} ${sharedStyles.pageWrapper}`}>
        {/* Bannière avec miniature */}
        {designSettings && designSettings.defaultPageBanner && (
          <BannerWithSocialIcons imageUrl={`${designSettings.defaultPageBanner}`} />
        )}

        <div className={`page-content ${sharedStyles.pageContent}`}>
          <section className={`global-section ${sharedStyles.mainSection}`}>
            <div className={`global-container ${sharedStyles.headerContainer}`}>
              <div className={sharedStyles.header}>
                <div className={sharedStyles.headingBlock}>
                  <h1>Contact</h1>
                  <Breadcrumbs title="Contact" />
                </div>
              </div>
            </div>

            <div className={`global-container ${styles.main}`}>
              <div className={styles.block}>
                <h2>Besoin d'une information ou d'un renseignement ?</h2>
                <div className={styles.listContainer} aria-labelledby="contact-info-title">
                  <h3 id="contact-info-title">Coordonnées de la Mairie</h3>
                  <div className={styles.listContent}>
                    <div className={styles.listBlock}>
                      <h4>Mairie d'Arras</h4>
                      <p>
                        Place Guy Mollet
                        <br />
                        BP 70913 - 62022 ARRAS Cedex
                      </p>
                    </div>
                    <div className={styles.listBlock}>
                      <h4>Du lundi au vendredi :</h4>
                      8h-12h15 et 13h45-17h (18h le mercredi)
                      <em>Le Service Etat Civil est fermé le jeudi matin</em>
                    </div>
                  </div>
                </div>

                <div className={styles.container} aria-labelledby="allo-mairie-title">
                  <h3 id="allo-mairie-title">Allo Mairie</h3>
                  <div className={styles.content}>
                    <div className={styles.listBlock}>
                      <p>Votre Mairie joignable 7J/7 - 24H/24</p>
                      <p>
                        Renseignements, démarches administratives, propreté,
                        voirie, éclairage public, incivilités, dégraffitage
                      </p>
                      <a
                        href="tel:0805090062"
                        aria-label="Téléphoner à la mairie au 0805090062"
                        title="Téléphoner à la mairie"
                        className={styles.freePhone}
                      >
                        <FontAwesomeIcon icon={faPhone} className={styles.icon} />
                        <div>
                          0 805 0900 62
                          <span>Service &amp; Appel gratuits</span>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className={`${styles.block} ${styles.formContainer}`}>
                <div className={styles.headerForm}>
                  <h2 id="form-title">Formulaire de contact</h2>
                  <p id="form-instructions">
                    Veuillez remplir les champs obligatoires marqués d'une étoile (
                    <span className={styles.danger}>*</span>).
                  </p>
                </div>

                <form
                  aria-labelledby="form-title"
                  aria-describedby="form-instructions"
                  onSubmit={handleSubmit}
                >
                  {/* Objet */}
                  <div className={styles.formGroup}>
                    <label htmlFor="objet" className={styles.required}>
                      Votre demande s'adresse à
                    </label>
                    <div className={styles.dropdown}>
                      <select
                        id="objet"
                        name="objet"
                        value={formData.objet}
                        onChange={handleChange}
                        required
                        aria-required="true"
                      >
                        <option value="" disabled>
                          Choisissez un objet
                        </option>
                        <option value="contact-general">Contact général</option>
                        <option value="drh-emploi-recrutement">
                          DRH Emploi Recrutement
                        </option>
                        <option value="education-ecole">Education / Ecole</option>
                        <option value="service-jeunesse">Service jeunesse</option>
                        <option value="etat-civil">Etat civil</option>
                        <option value="mediatheque-reseau-m">
                          Médiathèques / Réseau M
                        </option>
                        <option value="conservatoire">Conservatoire</option>
                        <option value="musee-des-beaux-arts">
                          Musée des Beaux-Arts
                        </option>
                        <option value="salle-municipale-le-casino-d-arras">
                          Salle municipale Le Casino d'Arras
                        </option>
                        <option value="salle-municipale-le-pharos">
                          Salle municipale Le Pharos
                        </option>
                        <option value="office-de-tourisme">
                          Office de tourisme
                        </option>
                      </select>
                      <FontAwesomeIcon icon={faCaretDown} />
                    </div>
                  </div>

                  {/* Nom */}
                  <div className={styles.formGroup}>
                    <label htmlFor="nom" className={styles.required}>
                      Nom
                    </label>
                    <input
                      type="text"
                      id="nom"
                      name="nom"
                      value={formData.nom}
                      onChange={handleChange}
                      required
                      aria-required="true"
                      placeholder="Entrez votre nom"
                    />
                  </div>

                  {/* Prénom */}
                  <div className={styles.formGroup}>
                    <label htmlFor="prenom" className={styles.required}>
                      Prénom
                    </label>
                    <input
                      type="text"
                      id="prenom"
                      name="prenom"
                      value={formData.prenom}
                      onChange={handleChange}
                      required
                      aria-required="true"
                      placeholder="Entrez votre prénom"
                    />
                  </div>

                  {/* Email */}
                  <div className={styles.formGroup}>
                    <label htmlFor="email">Adresse e-mail</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                      aria-required="true"
                      placeholder="exemple@domaine.com"
                    />
                  </div>

                  {/* Téléphone */}
                  <div className={styles.formGroup}>
                    <label htmlFor="telephone" className={styles.required}>
                      Téléphone
                    </label>
                    <input
                      type="tel"
                      id="telephone"
                      name="telephone"
                      value={formData.telephone}
                      onChange={handleChange}
                      required
                      aria-required="true"
                      placeholder="0123456789"
                    />
                  </div>

                  {/* Message */}
                  <div className={styles.formGroup}>
                    <label htmlFor="message" className={styles.required}>
                      Message
                    </label>
                    <textarea
                      id="message"
                      name="message"
                      rows="5"
                      value={formData.message}
                      onChange={handleChange}
                      required
                      aria-required="true"
                      placeholder="Votre message ici..."
                    ></textarea>
                  </div>

                  {/* Bouton envoyer */}
                  <div className={styles.formGroup}>
                    <Button
                      variant="primary"
                      type="submit"
                      ariaLabel="Envoyer le formulaire"
                    >
                      Envoyer
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </section>

          {/* CTA Map */}
          <MapCta />

          {/* Liens Pratiques */}
          <LiensPratiques />
        </div>
      </div>
    </>
  );
};

export default ContactPage;
