import React from 'react';
import PropTypes from 'prop-types';
import styles from './Button.module.css';
import useAccessibility from 'hooks/useAccessibility';

const Button = ({ children, onClick, type = 'button', variant = 'primary', disabled = false, title = '', ariaLabel = '', role = 'button', target }) => {
  const accessibilityVariant = useAccessibility();

  return (
    <button
      className={`${styles.button} ${styles[variant]} ${styles[accessibilityVariant]}`}
      onClick={onClick}
      type={type}
      disabled={disabled}
      title={title}
      aria-label={ariaLabel}
      role={role}
      target={target}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,  // Le texte ou composant enfant du bouton
  onClick: PropTypes.func,              // Fonction exécutée lors du clic
  type: PropTypes.string,               // Type du bouton (submit, button)
  variant: PropTypes.oneOf(['primary', 'secondary', 'white', 'neutral, map']),  // Style du bouton
  disabled: PropTypes.bool,             // Désactiver le bouton ou non
  title: PropTypes.string,              // Titre du bouton
  ariaLabel: PropTypes.string,          // Label ARIA pour l'accessibilité
  role: PropTypes.string                // Rôle du bouton
};

export default Button;
