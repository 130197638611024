import { useEffect, useState } from 'react';
import api from 'services/api';

const useWhitelist = (endpoint) => {
  const [whitelist, setWhitelist] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchWhitelist = async () => {
      try {
        const response = await api.get(endpoint);
        setWhitelist(response.data);
      } catch (err) {
        setError('Erreur lors du chargement de la liste blanche');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchWhitelist();
  }, [endpoint]);

  const isAllowed = (slug) => whitelist.includes(slug);

  return { whitelist, isAllowed, loading, error };
};

export default useWhitelist;
