import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import api from 'services/api';
import NewsCard from 'components/news/NewsCard'; // Import du composant NewsCard
import listStyles from 'styles/modules/CardList.module.css';
import { useSubSite } from 'contexts/SubSiteContext';
import Spinner from 'components/common/Spinner';

const NearestNews = ({ slug }) => {
  const [nearestNews, setNearestNews] = useState([]);
  const [loading, setLoading] = useState(true);
  const { isSubSite, subSiteSlug } = useSubSite();

  useEffect(() => {
    const fetchNearestNews = async () => {
      try {
        let response;
        if (isSubSite) {
          response = await api.get(`/subsites/${subSiteSlug}/news/latest`);
        }
        else {
          response = await api.get(`/news/${slug}/nearest`);
        }
        setNearestNews(response.data);
      } catch (error) {
        console.error("Erreur lors de la récupération des actualités proches :", error);
      } finally {
        setLoading(false);
      }
    };

    fetchNearestNews();
  }, [slug]);

  if (loading) return <div><Spinner /></div>;
  if (nearestNews.flat().length === 0) return <div>Aucune actualité proche trouvée.</div>;

  return (
    <div className={`global-container ${listStyles.listSection}`}>
      <h2>Actualités liées</h2>
      <div className={listStyles.grid}>
        {nearestNews.map((group) =>
          group.map((newsItem) => (
            <NewsCard
              key={newsItem.id}
              newsItem={{
                title: newsItem.title,
                createdAt: newsItem.createdAt,
                content: newsItem.content,
                thumbnail: newsItem.thumbnail,
                slug: newsItem.slug,
                categories: newsItem.categories,
              }}
            />
          ))
        )}
      </div>
    </div>
  );
};

NearestNews.propTypes = {
  slug: PropTypes.string.isRequired,
};

export default NearestNews;
