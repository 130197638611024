import React from 'react';
import PropTypes from 'prop-types';
import { Grid, List } from 'react-ionicons';
import styles from 'styles/modules/Archives.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

const ToggleView = ({ view, setView }) => (
  <div className={styles.togglesContainer}>
    <div className={styles.toggles}>
      <button
        className={`${styles.toggle} ${view === 'grid' ? styles.active : ''}`}
        onClick={() => setView('grid')}
      >
        <Grid
          color="currentColor"
          height=""
          width=""
          className={styles.icon}
          aria-label="Vue en grille"
          title="Vue en grille"
        />
        Vue en grille
      </button>
      <button
        className={`${styles.toggle} ${view === 'list' ? styles.active : ''}`}
        onClick={() => setView('list')}
      >
        <List
          color="currentColor"
          height=""
          width=""
          className={styles.icon}
          aria-label="Vue en liste"
          title="Vue en liste"
        />
        Vue en liste
      </button>
    </div>
    <div className={styles.dropdownContainer}>
      <select
        className={styles.viewSelect}
        aria-label="Sélectionnez un type de vue"
        title="Sélectionnez un type de vue"
        value={view}
        onChange={(e) => setView(e.target.value)}
      >
        <option value="grid">Vue en grille</option>
        <option value="list">Vue en liste</option>
      </select>
      <FontAwesomeIcon icon={faCaretDown} />
    </div>
  </div>
);

ToggleView.propTypes = {
  view: PropTypes.string.isRequired,
  setView: PropTypes.func.isRequired,
};

export default ToggleView;
