import { useState, useEffect } from 'react';

const useFilter = (items = [], defaultDisplayCount = 8) => {
  const [allItems, setAllItems] = useState(items);
  const [filteredItems, setFilteredItems] = useState(items);
  const [displayCount, setDisplayCount] = useState(defaultDisplayCount);
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [searchQuery, setSearchQuery] = useState('');

  // Update allItems and filteredItems when items prop changes
  useEffect(() => {
    setAllItems(items);
    setFilteredItems(items);
  }, [items]);

  // Filter items when search query or selected category changes
  useEffect(() => {
    let result = allItems;

    if (selectedCategory && selectedCategory !== 'all') {
      result = result.filter((item) => {
        if (item.categories) {
          return item.categories.some((cat) => cat.slug === selectedCategory);
        } else if (item.subCategorySlug) {
          return item.subCategorySlug === selectedCategory;
        }
        return true;
      });
    }

    if (searchQuery) {
      result = result.filter((item) => {
        if (item.title) {
        return item.title.toLowerCase().includes(searchQuery.toLowerCase());
        } else if (item.name) {
        return item.name.toLowerCase().includes(searchQuery.toLowerCase());
        } else {
        console.error('Item does not have a title or name:', item);
        return false;
        }
      });
    }
   

    setFilteredItems(result);
  }, [allItems, selectedCategory, searchQuery]);

  const loadMore = () => setDisplayCount((prevCount) => prevCount + 6);

  const resetFilters = () => {
    setSelectedCategory('all');
    setSearchQuery('');
    setDisplayCount(defaultDisplayCount);
  };

  return {
    filteredItems,
    displayCount,
    selectedCategory,
    setSelectedCategory,
    searchQuery,
    setSearchQuery,
    loadMore,
    resetFilters,
  };
};

export default useFilter;
