import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Badge from 'components/common/Badge';
import Dot from 'components/common/Dot';
import Button from 'components/common/Button';
import styles from './EventFeatured.module.css';
import tooltipStyles from 'styles/modules/Tooltip.module.css';
import useAccessibility from 'hooks/useAccessibility';
import { useSubSite } from 'contexts/SubSiteContext';
import catStyles from 'styles/modules/Categories.module.css';

// Petite fonction utilitaire pour décoder toutes les entités HTML
function decodeHtmlEntities(htmlString) {
  const txt = document.createElement('textarea');
  txt.innerHTML = htmlString;
  return txt.value;
}

let currentVisibleTooltip = null; // Variable globale au composant

const EventFeatured = ({ event }) => {
  // Vérifier si le contexte est accessible ici
  const accessibilityVariant = useAccessibility();

  const {
    title = 'Titre non spécifié',
    start,
    end,
    location = 'Lieu non spécifié',
    content = [],
    categories = [],
    thumbnail,
    slug,
  } = event;

  const { isSubSite, subSiteSlug } = useSubSite();

  const eventUrl = isSubSite
    ? `/${subSiteSlug}/evenement/${slug}`
    : `/evenement/${slug}`;

  // ------------------------------------------------------
  // 1) On récupère le HTML brut depuis content
  // ------------------------------------------------------
  const rawWysiwygContent = content?.[0]?.content || '';

  // ------------------------------------------------------
  // 2) On décode les entités HTML (ex.: &nbsp; → espace)
  // ------------------------------------------------------
  const decodedHtml = decodeHtmlEntities(rawWysiwygContent);

  // ------------------------------------------------------
  // 3) On supprime toutes les balises et on nettoie les espaces
  // ------------------------------------------------------
  const cleanedText = decodedHtml
    .replace(/<[^>]+>/g, '') // Supprime toutes les balises HTML
    .replace(/\r?\n|\r/g, ' ') // Supprime les retours à la ligne
    .replace(/\s\s+/g, ' ') // Remplace espaces multiples par un seul
    .trim(); // Retire les espaces de début et fin

  // ------------------------------------------------------
  // 4) Création d’un extrait de texte (excerpt)
  // ------------------------------------------------------
  const excerptLength = 200;
  const contentExcerpt =
    cleanedText.length > excerptLength
      ? cleanedText.slice(0, excerptLength) + '...'
      : cleanedText || 'Aucune description disponible.';

  // ------------------------------------------------------
  // 5) Gestion des dates/horaires
  // ------------------------------------------------------
  let dateToDisplay = 'Date non spécifiée';

  if (start && end) {
    const startDate = new Date(start);
    const endDate = new Date(end);

    const formattedStartDate = startDate.toLocaleDateString('fr-FR');
    const formattedEndDate = endDate.toLocaleDateString('fr-FR');

    const formattedStartTime = startDate
      .toLocaleTimeString('fr-FR', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      })
      .replace(':', 'h');
    const formattedEndTime = endDate
      .toLocaleTimeString('fr-FR', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      })
      .replace(':', 'h');

    if (formattedStartDate === formattedEndDate) {
      // Même jour -> 09/01/2025 de 20h00 à 23h00
      dateToDisplay = `${formattedStartDate} de ${formattedStartTime} à ${formattedEndTime}`;
    } else {
      // Jours différents -> 09/01/2025 - 10/01/2025
      dateToDisplay = `${formattedStartDate} - ${formattedEndDate}`;
    }
  } else if (start && !end) {
    // Uniquement la date de début
    const startDate = new Date(start);
    dateToDisplay = startDate.toLocaleDateString('fr-FR');
  } else if (!start && end) {
    // Uniquement la date de fin
    const endDate = new Date(end);
    dateToDisplay = endDate.toLocaleDateString('fr-FR');
  }

  // ------------------------------------------------------
  // 6) Gestion du tooltip
  // ------------------------------------------------------
  const [isTooltipVisible, setTooltipVisible] = useState(false);

  const handleTooltipToggle = () => {
    // Ferme l'infobulle si elle est déjà ouverte
    if (isTooltipVisible) {
      setTooltipVisible(false);
      currentVisibleTooltip = null;
    } else {
      // Ferme toute autre infobulle visible et ouvre celle-ci
      if (currentVisibleTooltip) {
        currentVisibleTooltip(false);
      }
      setTooltipVisible(true);
      currentVisibleTooltip = setTooltipVisible;
    }
  };

  const handleOverlayClick = () => {
    // Ferme l'infobulle en cliquant sur l'overlay
    setTooltipVisible(false);
    currentVisibleTooltip = null;
  };

  return (
    <div className={`${styles.featuredEvent} ${styles[accessibilityVariant]}`}>
      {/* Partie gauche : Image et badges */}
      <div className={styles.imageContainer}>
        {thumbnail && thumbnail.path ? (
          <img
            src={`${process.env.REACT_APP_MEDIA_BASE_URL}${thumbnail.path}`}
            alt={`Image de l'événement ${title}`}
            className={styles.thumbnail}
          />
        ) : (
          <div className={styles.placeholder}>Image non disponible</div>
        )}
        {categories.length > 0 && (
          <ul className={`list--none ${styles.badgeList}`}>
            {categories.map((category) => (
              <li key={category.id}>
                <Badge
                  label={category.name}
                  variant={category.slug}
                  link={`/categorie/${category.slug}`}
                />
              </li>
            ))}
          </ul>
        )}
      </div>

      {/* Overlay */}
      {isTooltipVisible && (
        <div
          className={tooltipStyles.overlay}
          onClick={handleOverlayClick}
          aria-hidden="true"
        />
      )}

      {/* Partie droite : Informations de l'événement */}
      <div className={styles.eventInfo}>
        {categories.length > 0 && (
          <ul className={`list--none ${styles.dotList}`}>
            {categories.map((category) => (
              <li key={category.id}>
                <Dot
                  variant={category.slug}
                  link={`/categorie/${category.slug}`}
                />
              </li>
            ))}

            <button
              className={`arrow-link ${styles.voirPlus}`}
              onClick={handleTooltipToggle}
              aria-label="Afficher les différentes catégories"
            >
              Catégories
              <FontAwesomeIcon icon={faArrowRight} />
              {/* Tooltip */}
              {isTooltipVisible && (
                <div className={tooltipStyles.tooltip}>
                  <ul className={`list--none ${tooltipStyles.tooltipList}`}>
                    {categories.map((category) => (
                      <li
                        className={`list--none ${tooltipStyles.tooltipItem}`}
                        key={category.id}
                      >
                        <span
                          className={`${tooltipStyles.tooltipLabel} ${catStyles[category.slug]}`}
                        >
                          {category.name}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </button>
          </ul>
        )}

        <div className={styles.heading}>
          <h3 className="featured">{title}</h3>
          <p className={styles.date}>
            {dateToDisplay}
            {location && ` | ${location}`}
          </p>
        </div>

        <p className={styles.excerpt}>{contentExcerpt}</p>

        <Link to={eventUrl}>
          <Button variant="primary">Lire la suite</Button>
        </Link>
      </div>
    </div>
  );
};

EventFeatured.propTypes = {
  event: PropTypes.shape({
    title: PropTypes.string,
    start: PropTypes.string,
    end: PropTypes.string,
    location: PropTypes.string,
    content: PropTypes.array,
    categories: PropTypes.array,
    thumbnail: PropTypes.shape({
      path: PropTypes.string,
    }),
    slug: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  accessibilityVariant: PropTypes.string,
};

export default EventFeatured;
