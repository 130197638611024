import React from 'react';
import PropTypes from 'prop-types';
import styles from './ProfileButton.module.css';
import buttonStyles from './Button.module.css';

const ProfileButton = ({ children, onClick, type = 'button', variant = 'citoyen', disabled = false, title = '', ariaLabel = '', role = 'button' }) => {

  return (
    <button
      className={`${buttonStyles.button} ${styles[variant]}`}
      onClick={onClick}
      type={type}
      disabled={disabled}
      title={title}
      aria-label={ariaLabel}
      role={role}
    >
      {children}
    </button>
  );
};

ProfileButton.propTypes = {
  children: PropTypes.node.isRequired,  // Le texte ou composant enfant du bouton
  onClick: PropTypes.func,              // Fonction exécutée lors du clic
  type: PropTypes.string,               // Type du bouton (submit, button)
  variant: PropTypes.oneOf(['citoyen', 'touriste', 'jeune', 'senior', 'parent', 'professionnel']),  // Style du bouton
  disabled: PropTypes.bool              // Désactiver le bouton ou non
};

export default ProfileButton;