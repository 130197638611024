import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styles from './aboutSection.module.css';
import Button from 'components/common/Button';
import button from 'components/common/Button.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { ReactComponent as Zoom } from 'icons/zoom.svg';
import Spinner from 'components/common/Spinner';
import { useSubSite } from 'contexts/SubSiteContext';
import api from 'services/api';

// Notre Lightbox
import Lightbox from '../Lightbox';

const AboutSection = () => {
  const { subSiteSlug, isSubSite } = useSubSite();

  const [subSiteData, setSubSiteData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  // Gestion slider
  const [currentSlide, setCurrentSlide] = useState(0);
  const autoplayRef = useRef();

  // Gestion lightbox (ou “zoom”)
  const [showLightbox, setShowLightbox] = useState(false);

  let isVaast = false
  if (subSiteSlug === 'projet-abbaye-saint-vaast') 
    {
        isVaast = true;
    }
   

  useEffect(() => {
    const fetchData = async () => {
      if (!subSiteSlug) return;
      try {
        setLoading(true);
        const response = await api.get(`/subsites/${subSiteSlug}/infos`);
        setSubSiteData(response.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (isSubSite) {
      fetchData();
    } else {
      setLoading(false);
      setSubSiteData(null);
    }
  }, [subSiteSlug, isSubSite]);

  const mediaBaseUrl = process.env.REACT_APP_MEDIA_BASE_URL;

  // Slider : aller à l'index calculé
  const goToSlide = (index, slideCount) => {
    return (index + slideCount) % slideCount;
  };

  // Navigation
  const nextSlide = (slideCount) => {
    setCurrentSlide((prev) => goToSlide(prev + 1, slideCount));
  };
  const prevSlide = (slideCount) => {
    setCurrentSlide((prev) => goToSlide(prev - 1, slideCount));
  };

  // Autoplay
  useEffect(() => {
    const stopAutoplay = () => clearInterval(autoplayRef.current);

    if (subSiteData?.gallery && subSiteData.gallery.length > 1) {
      autoplayRef.current = setInterval(() => {
        nextSlide(subSiteData.gallery.length);
      }, 6000);
    }

    return () => stopAutoplay();
  }, [subSiteData, currentSlide]);

  const handleMouseOver = () => clearInterval(autoplayRef.current);
  const handleMouseOut = () => {
    if (subSiteData?.gallery && subSiteData.gallery.length > 1) {
      autoplayRef.current = setInterval(() => {
        nextSlide(subSiteData.gallery.length);
      }, 6000);
    }
  };

  // État de chargement / erreur
  if (loading) {
    return (
      <p>
        <Spinner />
      </p>
    );
  }

  if (error) {
    return <p>Une erreur est survenue : {error}</p>;
  }

  if (!subSiteData) {
    return null;
  }

  // Construction de slides
  const slides =
    subSiteData.gallery && subSiteData.gallery.length > 0
      ? subSiteData.gallery.map((item) => ({
          src: mediaBaseUrl + item.url,
          alt: item.fileName || 'Slide',
        }))
      : [
          {
            src: '/images/placeholder_img.webp',
            alt: 'Image placeholder par défaut',
          },
        ];

  const slideCount = slides.length;

  return (
    <section className={`global-section ${styles.about}`}>
      <div className={`global-container ${styles.aboutContainer}`}>
        {/* Content */}
        <div className={styles.content}>
          <h2>{subSiteData.name || 'Nom du projet introuvable'}</h2>
          <div className={styles.aboutText}>
            <div dangerouslySetInnerHTML={{ __html: subSiteData.description }} />
          </div>
          <div className={styles.buttonContainer}>
            <Link to={`/${subSiteSlug}/page/${subSiteData.aboutPage}`}>
              <Button variant="white">En savoir plus</Button>
            </Link>
            {isVaast && (

            
            <Link   to="https://mpembed.com/show/?m=xeX7CBQGA1H&mpu=1445">
              <button
                aria-label="Accéder à la visite virtuelle"
                className={`${button.white} ${button.btnOutline}`}
              >
                Faire la visite virtuelle
              </button>
            </Link>
            )}
          </div>
        </div>

        {/* Slider */}
        <div
          className={styles.slider}
          aria-labelledby={subSiteData.name}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
        >
          {slides.map((slide, index) => {
            let className = styles.slide;
            if (index === currentSlide) {
              className = `${styles.slide} ${styles.active}`;
            } else if (index === goToSlide(currentSlide - 1, slideCount)) {
              className = `${styles.slide} ${styles.previous}`;
            } else if (index === goToSlide(currentSlide + 1, slideCount)) {
              className = `${styles.slide} ${styles.next}`;
            }

            return (
              <div
                key={index}
                className={className}
                role="group"
                aria-roledescription="slide"
                aria-label={`Diapositive ${index + 1} sur ${slideCount}`}
                aria-hidden={index !== currentSlide}
              >
                <img src={slide.src} alt={slide.alt} />
              </div>
            );
          })}

          {slideCount > 1 && (
            <>
              <button
                className={`${styles.sliderControl} ${styles.controlLeft}`}
                aria-label="Diapositive précédente"
                onClick={() => prevSlide(slideCount)}
              >
                <FontAwesomeIcon icon={faChevronLeft} />
              </button>
              <button
                className={`${styles.sliderControl} ${styles.controlRight}`}
                aria-label="Diapositive suivante"
                onClick={() => nextSlide(slideCount)}
              >
                <FontAwesomeIcon icon={faChevronRight} />
              </button>
            </>
          )}

          <div className="sr-only" aria-live="polite" aria-atomic="true">
            {`Diapositive ${currentSlide + 1} sur ${slideCount} affichée.`}
          </div>

          {/* Bouton Zoom : ouvre la lightbox */}
          <button
            className={styles.zoom}
            aria-label="Zoomer l'image"
            title="Zoomer l'image"
            onClick={() => setShowLightbox(true)}
          >
            <Zoom className={styles.zoomIcon} />
          </button>
        </div>
      </div>

      {/* Lightbox en overlay */}
      {showLightbox && (
        <Lightbox
          images={slides}
          currentIndex={currentSlide}
          onClose={() => setShowLightbox(false)}
          onPrev={() => prevSlide(slideCount)}
          onNext={() => nextSlide(slideCount)}
        />
      )}
    </section>
  );
};

export default AboutSection;
