import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Card from 'components/common/Card';
import api from 'services/api';
import Spinner from 'components/common/Spinner';

const PageCard = ({ page, categorySlug }) => {
  const [pageData, setPageData] = useState(null);
  const [loading, setLoading] = useState(true);
  const pageUrl = `/page/${page.slug}`;

  useEffect(() => {
    const fetchPageData = async () => {
      try {
        const response = await api.get(`/pages/${page.slug}`);
        if (response.data) {
          setPageData(response.data); // Adjust according to actual API response
        } else {
          console.error('No data received for page:', page.slug);
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des données de la page:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPageData();
  }, [page.slug]);

  if (loading) return <div><Spinner /></div>;
  if (!pageData) return <div>Aucune donnée pour cette page.</div>;

  const formattedDate = pageData.createdAt
    ? `Publié le ${new Date(pageData.createdAt).toLocaleDateString()}`
    : 'Date non disponible';

  const thumbnailUrl =
    pageData.thumbnail && pageData.thumbnail.path
      ? `${process.env.REACT_APP_MEDIA_BASE_URL}${pageData.thumbnail.path}`
      : null;

  return (
    <Card
      thumbnail={thumbnailUrl}
      title={pageData.title || 'Titre non disponible'}
      categories={pageData.categories || []}
      link={pageUrl}
      buttonText="Lire la suite"
    />
  );
};

PageCard.propTypes = {
  page: PropTypes.shape({
    id: PropTypes.number.isRequired,
    slug: PropTypes.string.isRequired,
  }).isRequired,
  categorySlug: PropTypes.string.isRequired,
};

export default PageCard;

