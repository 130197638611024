import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import api from 'services/api';
import Badge from 'components/common/Badge';
import Dot from 'components/common/Dot';
import Button from 'components/common/Button';
import styles from './SpecialEventCard.module.css';
import tooltipStyles from 'styles/modules/Tooltip.module.css';
import catStyles from 'styles/modules/Categories.module.css';
import Spinner from 'components/common/Spinner'

const initialState = {
  event: null,
  loading: true,
  isTooltipVisible: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_EVENT':
      return { ...state, event: action.payload, loading: false };
    case 'SET_LOADING':
      return { ...state, loading: action.payload };
    case 'TOGGLE_TOOLTIP':
      return { ...state, isTooltipVisible: action.payload };
    default:
      return state;
  }
};

const SpecialEventCard = ({ accessibilityVariant }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { event, loading, isTooltipVisible } = state;

  // Récupération de l'événement spécial
  useEffect(() => {
    const fetchSpecialEvent = async () => {
      try {
        const response = await api.get('/events/special');
        dispatch({ type: 'SET_EVENT', payload: response.data[0] });
      } catch (error) {
        console.error("Erreur lors de la récupération de l'événement spécial:", error);
        dispatch({ type: 'SET_LOADING', payload: false });
      }
    };

    fetchSpecialEvent();
  }, []);

  if (loading) {
    return <div><Spinner /></div>;
  }

  if (!event) {
    return null;
  }

  const {
    title = 'Titre non spécifié',
    start,
    end,
    location = 'Lieu non spécifié',
    content = [],
    categories = [],
    thumbnail,
    type = 'Type d\'événement',
  } = event;

  const eventUrl = `/evenement/${event.slug}`;

  const contentExcerpt = content[0]?.content
    ? content[0].content.replace(/<\/?[^>]+(>|$)/g, '').slice(0, 100) + '...'
    : 'Aucune description disponible.';

  const formattedStartDate = start
    ? new Date(start).toLocaleDateString()
    : 'Date de début non spécifiée';

  const formattedEndDate = end
    ? new Date(end).toLocaleDateString()
    : 'Date de fin non spécifiée';

  const handleTooltipToggle = () => {
    dispatch({ type: 'TOGGLE_TOOLTIP', payload: !isTooltipVisible });
  };

  const handleOverlayClick = () => {
    dispatch({ type: 'TOGGLE_TOOLTIP', payload: false });
  };

  return (
    <div className={`${styles.featuredEvent} ${styles[accessibilityVariant]}`}>

      {/* Overlay */}
      {isTooltipVisible && (
        <div
          className={tooltipStyles.overlay}
          onClick={handleOverlayClick}
          aria-hidden="true"
        ></div>
      )}

      {/* Partie gauche : Informations de l'événement */}
      <div className={styles.eventInfo}>
        <span className={styles.eventType}>{type}</span>
        {categories.length > 0 && (
          <ul className={`list--none ${styles.dotList}`}>
            {categories.map((category) => (
              <li key={category.id}>
                <Dot
                  key={category.id}
                  variant={category.slug}
                  link={`/categorie/${category.slug}`}
                />
              </li>
            ))}
            <button
              className={`arrow-link ${styles.voirPlus}`}
              onClick={handleTooltipToggle}
              aria-label="Afficher les différentes catégories"
            >
              Catégories
              <FontAwesomeIcon icon={faArrowRight} />
              {/* Tooltip */}
              {isTooltipVisible && (
                <div className={tooltipStyles.tooltip}>
                  <ul className={`list--none ${tooltipStyles.tooltipList}`}>
                    {categories.map((category) => (
                      <li
                        className={`list--none ${tooltipStyles.tooltipItem}`}
                        key={category.id}
                      >
                        <span
                          className={`${tooltipStyles.tooltipLabel} ${catStyles[category.slug]}`}
                        >
                          {category.name}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </button>
          </ul>
        )}

        <div className={styles.heading}>
          <h3 className="featured">{title}</h3>
          <p className={styles.date}>
            {formattedStartDate}
            {end && ` - ${formattedEndDate}`}
            {location && ` | ${location}`}
          </p>
        </div>

        <p className={styles.excerpt}>{contentExcerpt}</p>

        <Link to={eventUrl}>
          <Button variant="primary">Lire la suite</Button>
        </Link>
      </div>

      {/* Partie droite : Image et badges */}
      <div className={styles.imageContainer}>
        {thumbnail && thumbnail.path ? (
          <img
            src={`${process.env.REACT_APP_MEDIA_BASE_URL}${thumbnail.path}`}
            alt={`Image de l'événement ${title}`}
            className={styles.thumbnail}
          />
        ) : (
          <div className={styles.placeholder}>Image non disponible</div>
        )}
        {categories.length > 0 && (
          <ul className={`list--none ${styles.badgeList}`}>
            {categories.map((category) => (
              <li key={category.id}>
                <Badge
                  key={category.id}
                  label={category.name}
                  variant={category.slug}
                  link={`/categorie/${category.slug}`}
                />
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

SpecialEventCard.propTypes = {
  accessibilityVariant: PropTypes.string,
};

export default SpecialEventCard;
