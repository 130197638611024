import React, { useContext, useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styles from './CategoryListArchives.module.css';
import catStyles from 'styles/modules/Categories.module.css';
import useAccessibility from 'hooks/useAccessibility';


const CategoryListArchives = ({ key, variant, link, name }) => {
  const accessibilityVariant = useAccessibility();

  return (
    <Link
      to={link}
      className={`${styles[variant]} ${styles.link} ${catStyles[accessibilityVariant]}`}
      aria-label={`Lien vers la catégorie ${name}`}
      title={`Lien vers la catégorie ${name}`}
    >{name}</Link>
  );
};

CategoryListArchives.propTypes = {
  link: PropTypes.string.isRequired,               // Lien
  colorVariant: PropTypes.string,                  // Variante de couleur
  accessibilityVariant: PropTypes.string,           // Variante pour l'accessibilité
  name: PropTypes.string.isRequired       // Nom de la catégorie
};


export default CategoryListArchives;