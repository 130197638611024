// CookiesPageRGPD.js
import React, { useState, useEffect } from 'react';
import Breadcrumbs from 'components/common/Breadcrumbs';
import BannerWithSocialIcons from 'components/common/BannerWithSocialIcons';
import styles from './ContactPage.module.css';
import legalStyles from 'styles/modules/legalsPage.module.css';
import sharedStyles from 'styles/modules/ContentPage.module.css';
import useDesignSettingsData from 'hooks/useDesignSettingsData';
import { Helmet } from 'react-helmet';
import Button from 'components/common/Button';

const CookiesPageRGPD = () => {
  const { designSettings } = useDesignSettingsData();

  /**
   * On récupère la préférence de consentement (true/false) dans le localStorage (ou un cookie).
   * S’il n’y a rien, on considère par défaut que c'est refusé (false) ou qu’on est dans un état neutre.
   * À vous de définir la logique de départ qui vous convient.
   */
  const [analyticsEnabled, setAnalyticsEnabled] = useState(() => {
    const storedPreference = localStorage.getItem('analyticsConsent');
    if (storedPreference === 'true') return true;
    if (storedPreference === 'false') return false;
    return false; // Valeur par défaut
  });

  /**
   * État pour contrôler l’affichage du "bloc de préférences".
   * L'utilisateur clique sur "Modifier mes préférences" pour le faire apparaître.
   */
  const [showPreferences, setShowPreferences] = useState(false);

  /**
   * Dès que analyticsEnabled change, on exécute la logique pour
   * activer ou désactiver le tracking (Matomo).
   */
  useEffect(() => {
    if (analyticsEnabled) {
      // Exemple : activer le script Matomo
      // window._paq.push(["enableCookies"]);
      // ou charger dynamiquement le script Matomo si besoin.
      console.log('Tracking analytique activé.');
    } else {
      // Exemple : désactiver le script Matomo
      // window._paq.push(["disableCookies"]);
      console.log('Tracking analytique désactivé.');
    }
  }, [analyticsEnabled]);

  /**
   * Fonction qui gère l'acceptation du tracking
   */
  const handleAcceptAnalytics = () => {
    setAnalyticsEnabled(true);
    localStorage.setItem('analyticsConsent', 'true');
  };

  /**
   * Fonction qui gère le refus du tracking
   */
  const handleRefuseAnalytics = () => {
    setAnalyticsEnabled(false);
    localStorage.setItem('analyticsConsent', 'false');
  };

  return (
    <>
      <Helmet>
        <title>Arras | Cookies & RGPD</title>
        <meta
          name="description"
          content="Politique de confidentialité et d’utilisation des traceurs (cookies & localStorage) pour le site de la Ville d'Arras."
        />
        <meta property="og:title" content="Arras | Cookies & RGPD" />
        <meta
          property="og:description"
          content="Découvrez comment la Ville d'Arras gère vos données personnelles et l'utilisation de cookies et autres traceurs."
        />
        <link rel="canonical" href={`https://www.arras.fr/cookies`} />
      </Helmet>

      <div className={`${styles.contactPage} ${styles.legalsPage} ${sharedStyles.pageWrapper}`}>
        {/* Bannière avec miniature */}
        {designSettings && designSettings.defaultPageBanner && (
          <BannerWithSocialIcons imageUrl={`${designSettings.defaultPageBanner}`} />
        )}

        <div className={`page-content ${sharedStyles.pageContent}`}>
          <section className={`global-section ${sharedStyles.mainSection}`}>
            <div className={`global-container ${sharedStyles.headerContainer}`}>
              <div className={sharedStyles.header}>
                <div className={sharedStyles.headingBlock}>
                  <h1>Politique de confidentialité et d’utilisation des traceurs</h1>
                  <Breadcrumbs title="Cookies & RGPD" />
                </div>
              </div>
            </div>

            <div className={`global-container ${styles.main}`}>
              <div className={`${styles.block} ${legalStyles.content}`}>
                <h2>1. Introduction</h2>
                <p>
                  La présente page décrit la politique de confidentialité et d’utilisation des traceurs
                  (ci-après « Cookies ») sur le site de la Ville d’Arras. Nous sommes soucieux de respecter
                  votre vie privée et de protéger les informations que vous nous transmettez.
                </p>

                <h2>2. Données collectées et utilisation</h2>
                <h3>2.1 Données locales (LocalStorage)</h3>
                <p>
                  Nous n’utilisons pas de cookies pour stocker des informations personnelles sur les utilisateurs.
                  Toutefois, certaines données peuvent être stockées en localStorage, par exemple pour conserver
                  vos préférences d’affichage ou faciliter votre navigation. Ces données ne sont pas transmises
                  à des tiers et restent strictement confidentielles.
                </p>

                <h3>2.2 Matomo (Analytics)</h3>
                <p>
                  Nous utilisons <strong>Matomo</strong> afin de mesurer l’audience de notre site. Matomo est
                  une solution d’analyses d’audience qui peut utiliser des cookies ou un autre mécanisme similaire
                  pour collecter des informations (comme l’adresse IP anonymisée ou le type de navigateur). Ces
                  données sont uniquement utilisées à des fins statistiques dans le but d’améliorer votre expérience
                  utilisateur et de mieux comprendre comment le site est utilisé.
                </p>
                <p>
                  <strong>Important :</strong> Les informations collectées via Matomo sont anonymisées et ne nous
                  permettent pas de vous identifier directement. De plus, aucune de ces informations n’est
                  partagée avec des tiers. Vous pouvez librement choisir de désactiver la collecte
                  statistique si vous le souhaitez (voir la section « Gestion des préférences » ci-dessous).
                </p>

                <h2>3. Base légale du traitement</h2>
                <p>
                  Conformément au Règlement général sur la protection des données (RGPD), le traitement de
                  vos données est fondé sur :
                </p>
                <ul>
                  <li>
                    <strong>Votre consentement</strong> pour l’activation des cookies de mesure d’audience
                    (Matomo) ou d’autres traceurs non essentiels.
                  </li>
                  <li>
                    <strong>L’intérêt légitime</strong> du responsable de traitement pour assurer le
                    fonctionnement technique du site via le localStorage (traceurs strictement nécessaires).
                  </li>
                </ul>

                <h2>4. Gestion des préférences</h2>
                <p>
                  Vous avez la possibilité de gérer vos préférences en matière de traceurs&nbsp;:
                </p>
                <ul>
                  <li>
                    <strong>Désactivation de Matomo&nbsp;:</strong> Vous pouvez refuser l’utilisation de Matomo
                    (ou de tout autre outil analytique) en modifiant vos préférences dans le bandeau de
                    confidentialité ou en paramétrant votre navigateur pour bloquer ces traceurs.
                  </li>
                  <li>
                    <strong>Suppression du localStorage&nbsp;:</strong> Vous pouvez à tout moment effacer
                    manuellement les données stockées en localStorage en utilisant les paramètres de votre
                    navigateur. Cela pourrait toutefois altérer certaines fonctionnalités du site.
                  </li>
                </ul>
 {/* Bouton pour afficher les préférences */}
 <Button
                  type="button"
                  onClick={() => setShowPreferences(!showPreferences)}
                  style={{ marginBottom: '1rem', width: '200px' }}
                >
                  {showPreferences ? 'Fermer la gestion des préférences' : 'Modifier mes préférences'}
                </Button>

                {showPreferences && (
                  <div
                    style={{
                      border: '1px solid #ccc',
                      padding: '1rem',
                      borderRadius: '5px',
                      backgroundColor: '#f9f9f9',
                    }}
                  >
                    <h4>Préférences de tracking</h4>
                    <p>
                      Actuellement, le tracking analytique est :{' '}
                      <strong>{analyticsEnabled ? 'Activé' : 'Désactivé'}</strong>
                    </p>
                    <p>Souhaitez-vous activer ou désactiver le tracking analytique (Matomo) ?</p>
                    <button
                      onClick={handleAcceptAnalytics}
                      style={{
                        backgroundColor: analyticsEnabled ? '#4caf50' : '#ccc',
                        color: '#fff',
                        marginRight: '1rem',
                        padding: '0.5rem',
                        border: 'none',
                        borderRadius: '4px',
                      }}
                    >
                      Activer
                    </button>
                    <button
                      onClick={handleRefuseAnalytics}
                      style={{
                        backgroundColor: !analyticsEnabled ? '#f44336' : '#ccc',
                        color: '#fff',
                        padding: '0.5rem',
                        border: 'none',
                        borderRadius: '4px',
                      }}
                    >
                      Désactiver
                    </button>
                  </div>
                )}
                <h2>5. Conservation des données</h2>
                <p>
                  Les données collectées via Matomo sont conservées pour la durée strictement nécessaire à
                  l’analyse de l’audience. Les données stockées en localStorage restent dans votre navigateur
                  jusqu’à ce que vous les supprimiez vous-même ou que vous réinitialisiez vos paramètres.
                </p>

                <h2>6. Vos droits</h2>
                <p>
                  Conformément au RGPD, vous disposez d’un droit d’accès, de rectification, d’effacement et de
                  limitation du traitement de vos données, ainsi qu’un droit d’opposition et de portabilité
                  (lorsque celui-ci est applicable). Pour exercer vos droits, vous pouvez nous contacter à
                  l’adresse suivante : <a href="mailto:dpo@villedarras.fr">dpo@villedarras.fr</a>.
                </p>

                <h2>7. Contact & réclamations</h2>
                <p>
                  Pour toute question ou toute réclamation relative au traitement de vos données personnelles,
                  veuillez nous contacter :
                </p>
                <address>
                  DPO Ville d’Arras<br />
                  1, Place Guy Mollet<br />
                  62000 Arras<br />
                  <a href="mailto:dpo@villedarras.fr">dpo@villedarras.fr</a>
                </address>
                <p>
                  En cas de difficulté non résolue, vous pouvez introduire une réclamation auprès de
                  l’autorité de contrôle de votre pays de résidence (en France, la <strong>CNIL</strong>).
                </p>

                <h2>8. Modification de la politique</h2>
                <p>
                  Nous nous réservons le droit de modifier la présente politique de confidentialité et
                  d’utilisation des traceurs à tout moment, notamment pour nous conformer aux évolutions
                  législatives et réglementaires. Nous vous invitons à consulter régulièrement cette page
                  pour vous tenir informé(e) de tout changement.
                </p>

                <h2>9. Version et mise à jour</h2>
                <p>
                  Version : 1.0 <br />
                  Dernière mise à jour : 15 janvier 2025
                </p>

              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default CookiesPageRGPD;
