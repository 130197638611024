import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/common/Button';
import styles from 'components/news/NewsList.module.css';
import { Link } from 'react-router-dom';

const ListItem = ({
  title,
  meta = [],
  description,
  onButtonClick,
  buttonText = 'Lire la suite',
  imageUrl,
  categories = [],
  link,
}) => (
  <li className={styles.listItem}>
    <div className={styles.content}>
      {imageUrl && (
        <div className={styles.imageContainer}>
          <img src={imageUrl} alt={title} className={styles.image} />
        </div>
      )}
      <div className={styles.infos}>
        <h3 className={styles.itemTitle}>{title}</h3>
        <div className={styles.meta}>
          {/* Categories */}
          {categories.length > 0 && (
            <ul className={`list--none ${styles.tagList}`}>
              {categories.map((category) => (
                <li 
                key={category.id} 
                className={category.slug}               
                >
                  {category.name}
                </li>
              ))}
            </ul>
          )}
          {/* Meta Information */}
          {meta.map((metaItem, index) => (
            <p key={index} className={styles.metaItem}>
              {metaItem}
            </p>
          ))}
        </div>
        {/* Description */}
      </div>
      <Link to={link}>
      <Button
        variant="primary"
        className={styles.cardButton}
        onClick={onButtonClick}
        as={link ? 'a' : 'button'}
        href={link}
      >
        {buttonText}
      </Button>
      </Link>
    </div>
  </li>
);

ListItem.propTypes = {
  title: PropTypes.string.isRequired,
  meta: PropTypes.arrayOf(PropTypes.string),
  description: PropTypes.string,
  onButtonClick: PropTypes.func,
  buttonText: PropTypes.string,
  imageUrl: PropTypes.string,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      slug: PropTypes.string,
      name: PropTypes.string.isRequired,
    })
  ),
  link: PropTypes.string,
};

export default ListItem;
