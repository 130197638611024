import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import api from 'services/api'; // Import du service API
import styles from 'components/common/LiveInfo.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { ChatboxEllipsesOutline } from 'react-ionicons';
import Button from 'components/common/Button';

const LiveInfo = () => {
  const [notification, setNotification] = useState(null);

  // Récupérer les informations depuis l'API
  useEffect(() => {
    const fetchNotification = async () => {
      try {
        const response = await api.get('/notifications/latest'); // Endpoint pour récupérer la notification active
        const currentDate = new Date();

        // Vérifier si la notification est valide en fonction de la date
        const notificationData = response.data;
        if (
          notificationData &&
          new Date(notificationData.createdAt) <= currentDate &&
          new Date(notificationData.endAt) >= currentDate
        ) {
          setNotification(notificationData);
        }
      } catch (error) {
        console.error('Erreur lors de la récupération de la notification live:', error);
      }
    };

    fetchNotification();
  }, []);


  // Ne pas afficher le composant si la notification est nulle
  if (!notification) return null;

  const { message, link } = notification;

  return (
    <section className={`global-section ${styles.section}`}>
      <div className={`global-container ${styles.container}`}>
        <ChatboxEllipsesOutline
          color="currentColor"
          height=""
          width=""
          className={styles.icon}
        />
        <div className={styles.content}>
          <div className={styles.message}>
            <div data-text={message}>
              <span>{message}</span>
            </div>
          </div>
          {link && (
            <Link to={link}>
              <Button variant="white">
                <span>En savoir plus</span>
                <FontAwesomeIcon icon={faArrowRight} />
              </Button>
            </Link>
          )}
        </div>
      </div>
    </section>
  );
};

export default LiveInfo;
