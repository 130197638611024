// Modal.js
import React, { useEffect, useRef, useContext } from 'react';
import styles from './Modal.module.css';
import { ModalContext } from 'contexts/ModalContext'; // Importer le contexte
import useAccessibility from 'hooks/useAccessibility';
import accessClasses from 'styles/accessClasses.css';
import { CaretBackOutline, CaretForwardOutline } from 'react-ionicons';

const Modal = () => {
  const { isModalOpen, modalContent, closeModal } = useContext(ModalContext);
  const modalRef = useRef(null);
  const accessibilityVariant = useAccessibility();


  useEffect(() => {
    if (isModalOpen) {
      const focusedElementBeforeModal = document.activeElement;
      modalRef.current.focus();

      document.body.style.overflow = 'hidden';

      return () => {
        if (focusedElementBeforeModal) {
          focusedElementBeforeModal.focus();
        }
        document.body.style.overflow = '';
      };
    }
  }, [isModalOpen]);

  if (!isModalOpen) {
    return null;
  }

  return (
    <div
    className={`modal-main ${accessibilityVariant}`}
    >

    <div
      className={styles.modalOverlay}
      onClick={closeModal}
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-title"
    >
      <div
        className={styles.modalContent}
        onClick={(e) => e.stopPropagation()}
        tabIndex="-1"
        ref={modalRef}
      >
        
        <button
        className={styles.toggleContainer}
        aria-label="Fermer la modal"
        onClick={closeModal}
      >
        <CaretBackOutline
            color="currentColor"
            className={styles.toggle}
          />
      </button>
        {modalContent}
      </div>
    </div>
    </div>

  );
};

export default Modal;
